
import { PermissionAnswer, Permission, PermissionIndexType } from "wowok";
import { PermissionIconProp } from "../util/Icons";

export const make_permIconProp = (answer:PermissionAnswer | undefined, index:PermissionIndexType, force?:boolean, guards?:string[]) : PermissionIconProp | undefined => {
    return (answer && answer.object) ?  {object:answer.object, 
      permission:Permission.HasPermission(answer, index)?.has === true,
      guard:Permission.HasPermission(answer, index)?.guard,
      admin:answer?.admin, owner:answer?.owner, force:force, guards:guards} : undefined;
}

export const make_permOwnerIconProp = (answer:PermissionAnswer | undefined, guards?:string[]) : PermissionIconProp | undefined => {
    return (answer && answer.object) ? {object:answer.object, 
        permission:answer?.owner ? true : false, guard:undefined, admin:answer?.admin, owner:answer?.owner, guards:guards} : undefined;
}

export const make_permAdminIconProp = (answer:PermissionAnswer | undefined, guards?:string[]) : PermissionIconProp | undefined => {
    return (answer && answer.object) ? {object:answer.object, 
        permission:answer?.admin ? true : false, guard:undefined, admin:answer?.admin, owner:answer?.owner, guards:guards} : undefined;
}


