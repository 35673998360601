
import React, { useEffect, useState } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import SettingHeader from '../SettingHeader';
import { useLocation } from 'react-router-dom';
import Guard from './Guard'
import Basic from './Basic'
import Bounty from './Bounty'
import Refund from './Refund';
import { Pannel, a11yProps,  } from '../../util/Common';

export default function DemandSetting (props:any) {  
  const location = useLocation()
  var t = parseInt(location.hash.slice(1));
  if (t < 0 || t > 3 ||  isNaN(t))  { t = 0; }
  window.location.hash = '#' + t;

  const id = props?.contents?.fields?.id?.id ?? '';
  const [value, setValue] = React.useState(t);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    window.location.hash = '#' + newValue;
    setValue(newValue);
  };

    return (
      <Paper sx={{ width: '100%', pt:'.4em', }} >
        <SettingHeader name={id ? 'Demand Setting' : 'Launch Demand'}/>
        <Tabs value={value} onChange={handleChange} aria-label="reward" sx={{height:id ? '3em' : '3.8em', margin:'.4em 1em'}}>
          <Tab label="Basic" {...a11yProps(0)} sx={{textTransform:'none'}} value={0}/>
          <Tab disabled={id ? undefined : true} value={1}
            icon={id ? undefined : <VpnKeyIcon className='trans270' 
            sx={{with:'0.6em', height:'0.6em', marginRight:'-0.1em!important'}}/>} iconPosition="start" 
            label={'Bounty'} {...a11yProps(1)} sx={{textTransform:'none'}}/>
          <Tab disabled={id ? undefined : true} value={2}
            icon={id ? undefined : <VpnKeyIcon className='trans270' 
            sx={{with:'0.6em', height:'0.6em', marginRight:'-0.1em!important'}}/>} iconPosition="start" label="Guard" 
            {...a11yProps(2)} sx={{textTransform:'none'}}/>
          <Tab disabled={id ? undefined : true} value={3}
            icon={id ? undefined : <VpnKeyIcon className='trans270' 
            sx={{with:'0.6em', height:'0.6em', marginRight:'-0.1em!important'}}/>} iconPosition="start" label="Refund" 
            {...a11yProps(2)} sx={{textTransform:'none'}}/>       
        </Tabs>
        <Pannel value={value} index={0}>
            <Basic {...props}/>
        </Pannel>
        <Pannel value={value} index={1}>
          <Bounty {...props} />
        </Pannel>
        <Pannel value={value} index={2}>
          <Guard {...props} />
        </Pannel>
        <Pannel value={value} index={3}>
          <Refund {...props} />
        </Pannel>
      </Paper>
    );
}