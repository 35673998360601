import React, { useState } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { useTheme} from '@mui/material/styles';
import { Box } from '@mui/material';
import { Permission as WowokPermission, IsValidAddress} from 'wowok';
import { SettingTitle, SettingLaunch, ADDRESS_VALIDATOR, SettingInputText, SettingTips, } from '../Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { Transaction as TransactionBlock } from '@mysten/sui/transactions';
import { generateRandomString } from '../../../util';
  
export default function Owner (props:any) {
    const id = props.contents.fields.id.id ?? '';
    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();
    const [owner, setOwner] = useState(props?.contents?.fields?.builder ?? '');
    const bOwner = props?.contents?.fields?.builder === wallet?.address;

    const launch = async () => {
        if (!IsValidAddress(owner)) {
            enqueueSnackbar('New owner invalid', { variant: "error" });
            document.getElementById('owner')?.focus();
            return;
        }

        if (!wallet.connected) {
            enqueueSnackbar('Please login wallet', { variant: "error" });
            document.getElementById('header-wallet-cmd')?.click();
            return 
        }
        
        try {
            const txb = new TransactionBlock();
            let p = WowokPermission.From(txb, id);
            p.change_owner(owner);
            props.exe(generateRandomString(8), txb)
        } catch (e) {
            console.error('excute error: ', e);
            enqueueSnackbar('Launch failed', { variant: "error" });
        }
    }
  
    return (
        <Box sx={{maxWidth:'100%', padding:'0 2em', pb:'2em',}}>
        <SettingTips text='Owner manages the admin staff and does not perform specific business operations. '/>
          <SettingTitle title='Owner' tips='Take care! New owner has all the controls of this permission' 
                perm={{object:id,  permission:bOwner, owner:bOwner}}/>
            <SettingInputText placeholder='Enter new owner address'  err_empty='Please enter new owner address' maxlength={66}
            validator={ADDRESS_VALIDATOR} value={owner} autoFocus noPerm={id && !bOwner}
            id='owner' event={(type, value, id) => {
                setOwner(value);
            }}/>
          
          <SettingLaunch text='Launch' event={(t) => {
            if (t === 'click') launch();
          }}/>
        </Box>
    );  
  }