
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Box } from '@mui/material';
import { PermissionAnswer, Arbitration as WowokArbitration, Permission, PermissionIndex, PermissionIndexType, Passport,
    MAX_ENDPOINT_LENGTH, IsValidAddress, IsValidEndpoint, 
} from 'wowok';
import { SettingTitle, ADDRESS_VALIDATOR, SettingLaunch, SettingInputText, ENDPOINT_VALIDATOR} from '../Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { Transaction as TransactionBlock } from '@mysten/sui/transactions';
import { generateRandomString } from '../../../util';
import { make_permIconProp } from '../PermCheck';
import { useState } from 'react';

interface Data {
    guard: string,
    endpoint: string,
}

export default function Customer (props:any) {
    const id = props?.contents?.fields?.id?.id ?? '';
    const permission = props?.contents?.fields?.permission;
    const answer: PermissionAnswer | undefined = props?.perms;
    const type = WowokArbitration.parseObjectType(props?.type);

    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();

    const [data, setData] = useState<Data>({
        guard:props?.contents?.fields?.usage_guard ?? "", 
        endpoint:props?.contents?.fields?.endpoint ?? "", 
    });

    const launch = async () => {      
        if (!wallet.connected) {
            enqueueSnackbar('Please login wallet', { variant: "error" });
            document.getElementById('header-wallet-cmd')?.click();
            return 
        }

        const txb = new TransactionBlock(); // new session
        const obj = WowokArbitration.From(txb, type, permission, id);
        const pid = generateRandomString(8);
        const perms:PermissionIndexType[] = []; 

        if (data.guard !== props?.contents?.fields?.usage_guard && (data.guard || props?.contents?.fields?.usage_guard)) {
            perms.push(PermissionIndex.arbitration_guard);
        }

        if (data.endpoint !== props?.contents?.fields?.endpoint && (data.endpoint || props?.contents?.fields?.endpoint)) {
            perms.push(PermissionIndex.arbitration_endpoint);
        }

        props.PermissionCheck({id:pid, txb:txb, answer:answer, index:perms, handler:
            (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:Passport) => {
            try {
                if (id === pid) {
                    if (index.includes(PermissionIndex.arbitration_guard)) {
                        obj.set_guard(data.guard, passport?.get_object())
                    };    
                    if (index.includes(PermissionIndex.arbitration_endpoint)) {
                        obj.set_endpoint(data.endpoint, passport?.get_object())
                    };    
                    passport?.destroy(); // destroy passport
                    props.exe(pid, txb);
                }
            } catch (e) {
                console.log(e)
                enqueueSnackbar( 'Launch Failed', { variant: "error" });
            }}
        });
    }
    
    return (
        <Box sx={{maxWidth:'100%', padding:'0 2em', pb:'2em',}}>
            <SettingTitle title='Endpoint' tips='Endpoint about the detailed interaction interface: url or ipfs.'
                perm={make_permIconProp(answer, PermissionIndex.arbitration_endpoint)}/>
            <SettingInputText placeholder='Enter endpoint about the detailed interaction interface'  maxlength={MAX_ENDPOINT_LENGTH}
                validator={ENDPOINT_VALIDATOR}  value={data.endpoint} multiline maxRows={4} autoFocus
                noPerm={Permission.HasPermission(answer, PermissionIndex.arbitration_endpoint, true)?.has === false}
                id='customer-endpoint' event={(type, value, id) => {
                    data.endpoint = value; 
                    setData({...data});
                }}/>
            <SettingTitle title='Customer Guard' tips='Only when the Guard conditions are met can a request for arbitration be submitted.'
                perm={make_permIconProp(answer, PermissionIndex.arbitration_guard)}/>
            <SettingInputText placeholder='Enter the guard to apply for arbitration'  maxlength={66}
                validator={ADDRESS_VALIDATOR} value={data.guard} noNumberSpin
                noPerm={Permission.HasPermission(answer, PermissionIndex.arbitration_guard, true)?.has === false}
                id='customer-guard' event={(type, value, id) => {
                    data.guard = value; 
                    setData({...data});
                }}/>
            <SettingLaunch text='Launch' id='customtrading' event={(t) => {
                if (t === 'click') {
                    if (data.guard && !IsValidAddress(data.guard)) {
                        enqueueSnackbar('Customer Guard invalid', { variant: "error" });
                        document.getElementById('customer-guard')?.focus();
                        return 
                    }
                    if (data.endpoint && !IsValidEndpoint(data.endpoint)) {
                        enqueueSnackbar('Endpoint invalid', { variant: "error" });
                        document.getElementById('customer-endpoint')?.focus();
                        return 
                    }
                    launch();
                }
            }}/>
        </Box>
    );  
  }