import React, { useCallback, useMemo, useState } from 'react';
import ReactFlow, { addEdge, MarkerType, Position, Connection, Panel, Node, Edge, useNodesState, useEdgesState, Controls, } from 'reactflow';
import 'reactflow/dist/style.css';
import { generateRandomString } from '../../util'
import GuardEdge from './GuardEdge';
import GuardNode from './GuardNode';
import { GuardParser, DeGuardData, DeGuardConstant, Passport as WowokPassport, QueryPassportResult, Repository, Protocol, FnCallType } from 'wowok';
import dagre from '@dagrejs/dagre';
import { Button, Dialog, Box, IconButton, DialogTitle, DialogContent } from '@mui/material';
import { Transaction as TransactionBlock } from '@mysten/sui/transactions';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';
import { Transition } from '../util/Common';
import { useTheme} from '@mui/material/styles';
import { store_key_graph } from '../../util';

function build_init(initNodes:Node[], initEdges:Edge[], parent_id:string, parent_object:DeGuardData | undefined) {
    //@ 为什么reserve ?
    parent_object?.child?.forEach((c, index) => {
        let current_id = generateRandomString(16); let current_object = c; 
        initNodes.push({id:current_id, position:{x:0, y:0}, data:{data:current_object, index:index}, type:"CustomNode", 
            sourcePosition:Position.Top, targetPosition:Position.Bottom});
        initEdges.push({id:current_id+parent_id, source:current_id , target:parent_id , animated: true,
            markerEnd: { type: MarkerType.ArrowClosed, width:16, height:16, color:'#E8A7D5' }, type:"CustomEdge"})
        build_init(initNodes, initEdges, current_id, current_object);
    })    
}

const nodeWidth = 200;
const nodeHeight = 50;

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const getLayoutedElements = (nodes:Node[], edges:Edge[]) => {
    dagreGraph.setGraph({ rankdir:  'BT'});

    nodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target, {width:100, height:100});
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);

        // We are shifting the dagre node position (anchor=center center) to the top left
        // so it matches the React Flow node anchor point (top left).
        node.position = {
        x: nodeWithPosition.x ,
        y: nodeWithPosition.y ,
        };

        return node;
    });

    return { nodes, edges };
};

export interface NodeData {
    data: DeGuardData,
    index: number,
    constant: DeGuardConstant,
    init?: boolean
}

const GuardGraph = (props:any) => {
    const id = props?.contents?.fields?.id?.id ?? ''; 
    const wallet = useWallet();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const store_key = store_key_graph(id, 'guard graph');
    const [rfInstance, setRfInstance] = React.useState<any>(null);
    let content:any  = undefined;

    try {
        content = GuardParser.DeGuardObject_FromData(props?.contents?.fields?.constants, props?.contents?.fields?.input?.fields?.bytes);
    } catch (e) {
        console.log(e);
    }

    const snapGrid:[number, number] = [10, 10];
    const defaultViewport = { x: 0, y: 0, zoom: 1.5 };
    const nodeTypes = useMemo(() => ({ CustomNode: (props:any) => <GuardNode constant_table={content.constant} {...props}/>}), []);
    const edgeTypes = useMemo(() => ({ CustomEdge: GuardEdge}), []);

    const fitViewOptions = { padding: 0.5 };
    const pos = {sourcePosition:Position.Bottom, targetPosition:Position.Top};

    let root_id = generateRandomString(16) as string;  let root_object  = content ? (content?.object as DeGuardData) : undefined;
    const initNodes: Node[] = root_object ? [{id:root_id, position:{x:0, y:0}, data:{data:root_object, init:true, index:0}, type:"CustomNode", ...pos}] : [];
    const initEdges: Edge[] = [];

    build_init(initNodes, initEdges, root_id, root_object);
    //console.log(initNodes)
    let { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(initNodes, initEdges);

    const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);
    const [queryResult, setQueryResult] = useState<QueryPassportResult | undefined>(undefined);
    
    const onNodeDragStop = () => {
        if (rfInstance) {
            const flow = rfInstance.toObject();
            localStorage.setItem(store_key, JSON.stringify(flow));
        }
    }

    return ( <Box sx={{height:'100%', mb:'1em'}}>
    <ReactFlow 
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        snapToGrid={true}
        snapGrid={snapGrid}
        fitViewOptions={fitViewOptions}
        fitView
        deleteKeyCode = { null }
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        defaultViewport={defaultViewport}
        onNodeDragStop={ onNodeDragStop }
        onMoveEnd={ onNodeDragStop }
        isValidConnection={()=>false}
        onInit={(rf) => setRfInstance(rf) }
    >
        <Controls />
        { id && 
            <Panel position="top-right">
                <div style={{display:'flex', alignItems:'center', }}>
                    <Button variant='outlined' className='cmdButton' onClick={ async () => {
                        if (!wallet.connected || !wallet.address) {
                            enqueueSnackbar('Please login wallet', { variant: "error" });
                            document.getElementById('header-wallet-cmd')?.click();
                            return ;
                        }

                        const pid = generateRandomString(8); 
                        const txb = new TransactionBlock(); 
                        props.GuardCheck({id:pid, txb:txb, guards:[id], 
                            handler: async (id:string, txb:TransactionBlock, passport?:WowokPassport) => {
                                if (id === pid && passport) {
                                    try {
                                        const r = await passport?.query_result_async(wallet.address!);
                                        //console.log(r)
                                        if (r) {
                                            setQueryResult(r);
                                        } else {
                                            setQueryResult({txb:txb, result:false, guards:[id]});
                                        }      
                                        return                                   
                                    } catch(e) {
                                        console.log(e);
                                    }
                                }
                                setQueryResult({txb:txb, result:false, guards:[id]});
                            }})
                    }}
                    style={{padding:'.2em .4em', fontSize:'.9em', textTransform:'none'}}>Verify Guard</Button>
                </div> 
            </Panel>            
        }
    </ReactFlow>
    <Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth={'xs'} disableRestoreFocus 
            open={queryResult ? true : false}
            TransitionComponent={Transition}
            keepMounted
            onClose={()=>setQueryResult(undefined)}
            >
            <DialogTitle sx={{textAlign:'center'}} > Verify Guard
                <IconButton sx={{float:'right', marginTop:'-.2em'}}  onMouseDown={()=>{setQueryResult(undefined)}}> 
                    <CloseIcon /> </IconButton>  
            </DialogTitle>
                <DialogContent sx={{minHeight:'8em', display:'flex', justifyContent:'center', alignItems:'center', pb:'2em'}}>
                <span style={{fontWeight:600, fontSize:'2.2em',  color:theme.palette.primary.main, letterSpacing:3}}>
                    {(queryResult?.result === true) ? 'SUCCESSFULLY':'FAILED'}
                </span>
                </DialogContent> 
            </Dialog>  
    </Box>
    );
}

export default GuardGraph;