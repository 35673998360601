import '../../css/App.css';
import '@suiet/wallet-kit/style.css';
import ObjectContent from './ObjectContent';
import { Entity, Protocol, Bcs, Tags, ResourceData, query_object, IsValidAddress } from 'wowok'
import { useLocation } from "react-router-dom";
import NotFound from './NotFound';
import Loading from './Loading';
import { useCallback, useEffect, useState } from 'react';
import { SuiObjectResponse, DynamicFieldPage } from '@mysten/sui/client'
import Personal from '../personal/Personal';
import { useWallet } from '@suiet/wallet-kit';
import { EntityInfo } from '../util/Account';
import PersonalSetting from '../launch/personal/Personal';
import { Transaction as TransactionBlock } from '@mysten/sui/transactions';
interface QueryResult {
  obj: SuiObjectResponse | null;
  obj_err: string;
  obj_status: 'loading' | 'done' | 'error';
  dyn: DynamicFieldPage | null;
  dyn_err: string;
  dyn_status: 'loading' | 'done' | 'error';
  fields: SuiObjectResponse[];
  fields_err: string;
  fields_status: 'loading' | 'done' | 'error';
}

function App(props:any) {

  let path = useLocation();
  let objectid = path.pathname.length === 67 ? path.pathname.slice(1) : '';

  const INIT:QueryResult = {obj:null, obj_err:'', obj_status:'loading', dyn:null, dyn_err:'', dyn_status:'loading', 
    fields:[], fields_err:'', fields_status:'loading'};
  const [result, setResult] = useState<QueryResult>(INIT);
  const [ent_info, setEntInfo] = useState<EntityInfo>({status:'none', err:''});
  const [tags, setTags] = useState<Tags[]>([]);
  const [resource, setResource] = useState<ResourceData[]>([]);

  const handleObject = (data:SuiObjectResponse | null, err?:string) => {
    result.obj_err = err ?? '';
    result.obj_status = err ? 'error' : 'done';
    result.obj = err ? null : data;
    setResult({...result});
  }

  const handleDynamic = (data: DynamicFieldPage | null, err?:string) => {
    result.dyn_err = err ?? '';
    result.dyn_status = err ? 'error' : 'done';
    result.dyn = err ? null : data;
    setResult({...result});
  }

  const handleFields = (data:SuiObjectResponse[], err?:string) => {
    result.fields_err = err ?? '';
    result.fields_status = err ? 'error' : 'done';
    result.fields = err ? [] : data;
    setResult({...result});
  }

  const HasFields = (hasFields:boolean) => {
    result.fields_status = hasFields ? 'loading' : 'done';
    result.fields = [];
    result.fields_err = '';
    setResult({...result});
  }

  const QueryResource = (resourceId:string) => {
    Protocol.Client().getDynamicFields({parentId:resourceId}).then((res) => {
      if (res.data.length > 0) {
        Protocol.Client().multiGetObjects({ids:res.data.map(v => v.objectId), options:{showContent:true}}).then((fields) => {
          const r = fields.map((i:any) => {
            return {name:i.data.content.fields.name, address:i.data.content.fields.value}
          })
          setResource([...r]);
        }).catch((e) => {
          console.log(e)
        })  
      }
    }).catch((e) =>{
      console.log(e)
    });
  }

 
  const QueryObject = (id:string) => {
    if (!id) return ;
    query_object({id: id,  onBegin:(id)=>{setResult({...INIT})}, onObjectRes:(id, objectResult)=>{
          handleObject(objectResult);
      }, onDynamicRes:(id, dynamicResult)=>{
          handleDynamic(dynamicResult);
          HasFields(dynamicResult.data.length > 0);
      }, onFieldsRes:(id, fieldsResult)=>{
          handleFields(fieldsResult);
      }, onObjectErr:(id, objectError)=>{
        handleObject(null, objectError.code);
        if (objectError.code === "notExists") {
          QueryEntity(id); // query entity address
        }
      }, onDynamicErr:(id:string, dynamic_err:any)=>{
          handleDynamic(null, dynamic_err);
      }, onFieldsErr:(id:string, fields_err:any)=>{
        handleDynamic(null, fields_err);
    }});  
  };
  
  useEffect(() => {
    QueryObject(objectid);
  }, []);

  const QueryEntity = (address:string) => {
    if (address) {
      setEntInfo({status:'loading', err:''});
      //@ 用同一个txb！！！
      const txb = new TransactionBlock();
      const entity = Entity.From(txb);
      entity.query_ent(address);
      Protocol.Client().devInspectTransactionBlock({sender:address, transactionBlock:txb}).then((res) => {
        if (res.results?.length === 1 && res.results[0].returnValues?.length === 1 )  {
            const r1 = Bcs.getInstance().de_ent(Uint8Array.from(res.results[0].returnValues[0][0]));
            ent_info.status = 'done'; ent_info.info = r1.avatar; ent_info.resource = r1.resource?.some ?? '';
            ent_info.like = r1.like; ent_info.dislike = r1.dislike; ent_info.err = '';
            setEntInfo({...ent_info});

            if (r1.resource?.some) {
              QueryResource(r1.resource?.some);
              Protocol.Client().getObject({id:r1.resource?.some, options:{showContent:true, showPreviousTransaction:true, showType:true}}).then((res2) => {
                  ent_info.resource_object = res2?.data;
                  ent_info.lastActive = res2.data?.previousTransaction ?? '';
                  setEntInfo({...ent_info});
                  const tags:Tags[] = (res2?.data?.content as any)?.fields?.tags?.map((v:any) => {
                    return {address:v.fields.object, nick:v.fields.nick, tags:v.fields.tags}
                  })
                  setTags([...tags]);
              }).catch((e) => {
                console.log(e);
                ent_info.status = 'error';
                ent_info.err = e;
                setEntInfo({...ent_info});
              });              
            }
          }
        }).catch((e)=>{
          console.log(e)
          ent_info.status = 'error';
          ent_info.err = e;
          setEntInfo({...ent_info});
        })          
    }
  }
  let item: any = null;
  if (result.obj_status === 'loading' || result.dyn_status === 'loading' || result.fields_status === 'loading' || ent_info.status === 'loading') {
    if (!IsValidAddress(objectid)) return <NotFound />
    return (<><Loading /></>);
  } else if (result.obj && result.obj?.data) {
    item =  <ObjectContent dynamic={result.dyn?.data} 
    contents={result.obj.data?.content} 
    owner={result.obj?.data?.owner ?? ''}
    type={result.obj.data?.type}
    fields={result.fields}
    {...props} />;
  } else if (ent_info.status === 'done') {
    if (!ent_info?.info) { // not found
      return (<><NotFound objectid={objectid}/></>)
    }

    if (path.search.toLowerCase().includes('setting')) {
      item = (<PersonalSetting {...props} info={ent_info} tags={tags} resource={resource} />)
    } else {
      item = (<Personal {...props} info={ent_info} tags={tags} resource={resource}  />)
    }
  } else {
    return (<><NotFound objectid={objectid}/></>)
  }

  return (<div>
    {item}
  </div>)
}

export default App;
