import { Box, Tooltip, } from '@mui/material';
import * as React from 'react';
import { Handle, Position, useReactFlow, Node, Edge, MarkerType } from 'reactflow';
import { DeGuardData, IsValidValueType, IsValidOperatorType } from 'wowok';
import { grey, pink, green, red } from '@mui/material/colors';
import { useTheme} from '@mui/material/styles';
import { Label } from '../launch/guard/Label';
import { constTable } from '../launch/guard/GuardGraph';

const UNSET_ERR = 'unset';

const GuardNode = (props:any) => {
    //console.log(props)
    const theme = useTheme();
    const { getEdges } = useReactFlow();
    //const constant_table = props.constant_table;
    const [focus, setFocus] = React.useState(false);
    const data =  props.data?.data as DeGuardData;
    const border =  focus? {border:'1px solid', borderColor:theme.palette.primary.main}  : {border:'1px solid grey'} 

    var bConst = 0;
    if (data.type !== undefined && IsValidValueType(data.type)) {
        bConst = 1;
    } else if (data?.cmd === undefined && data?.value !== undefined && IsValidValueType(data.type)) {
        bConst = 1;
    }

    const txt = Label(props.data?.data, props.constant_table); // 链上的常量，非存储空间的常量！
    // const bWitness = constTable('any')?.find((v)=>v.identifier === props.data?.data?.identifier)?.bWitness ? true : false;
    var bgcolor = '#eee';
    if (bConst) {
        bgcolor = green[50];
    } 
    
    return (
        <div className='GuardCustomNode' onMouseOver={()=>{setFocus(true);}} onMouseOut={()=>setFocus(false)} >
            <Box padding={1}  bgcolor={bgcolor}  borderRadius={3} id={props.id}
                sx={{...border, fontsize:'.8em'}}>
                    <div style={{display:'flex', alignItems:'center',}}>
                        <span style={{color:txt===UNSET_ERR ? red[300]:'black', padding:'0 8px'}}>
                            {txt} 
                        </span>  
                    </div>
            </Box>
            {getEdges().find((v)=>v.target === props.id) && <Handle type='target' position={Position.Bottom}></Handle> }
            {getEdges().find((v)=>v.source === props.id) && <Handle type='source' position={Position.Top}></Handle> }
            {/*<Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth={'md'} disableRestoreFocus 
                open={open}
                style={{}}
                TransitionComponent={Transition}
                keepMounted
                onClose={()=>setOpen(false)}
                >
                <DialogTitle sx={{textAlign:'center'}} > {'Guard Condition' + ret_type()} 
                    <IconButton sx={{float:'right', marginTop:'-.2em'}} onMouseDown={()=>{setOpen(false)}}> <CloseIcon /> </IconButton>   
                </DialogTitle>
                    <DialogContent >
                        {bConst === 0 && <div>
                            {q && q.from === 'query' && <div>
                                <SettingShowValue title={'Query ' + object} tips='Data from querying object.'
                                    value={<Address address={data.value} showType={true}/> }/>
                                <SettingShowValue title={'Query'} tips='Query function.' 
                                    value={q.name} />
                                </div>}
                            {q && q.from === 'type' && <div>
                                <SettingShowValue title={q.group ?? 'Logic' } tips='Data from logics.'
                                    value={q.name}/>
                                </div>}
                            </div>}
                        {bConst === 1 && <div>
                            {data.value}
                            </div>}
                    </DialogContent> 
            </Dialog> */} 
        </div>
    );
}

export default GuardNode;