
import React, { useState, useEffect } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Box,  } from '@mui/material';
import { PermissionAnswer, PermissionIndex, Permission, Progress, ParentProgress, IsValidAddress, MAX_NAME_LENGTH, IsValidInt,
  PermissionIndexType, Passport} from 'wowok';
import { SettingTitle,  SettingLaunch, ADDRESS_VALIDATOR, SettingInputText, INT_VALIDATOR,} from '../Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { Transaction as TransactionBlock } from '@mysten/sui/transactions';
import { make_permIconProp } from '../PermCheck';
import { generateRandomString } from '../../../util';

export default function Parent (props:any) {
    //console.log(props)
    const id = props?.contents?.fields?.id?.id ?? '';
    const machine = props?.contents?.fields?.machine;
    const answer: PermissionAnswer | undefined = props?.perms;
    const [data, setData] = useState<ParentProgress>({
      parent_id:props?.contents?.fields?.parent?.fields?.parent, 
      parent_session_id:props?.contents?.fields?.parent?.fields?.session_index, 
      next_node: props?.contents?.fields?.parent?.fields?.next_node, 
      forward: props?.contents?.fields?.parent?.fields?.forward, 
    });

    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();

    const launch = async (op:'add' | 'remove') => {      
      if (!wallet.connected) {
        enqueueSnackbar('Please login wallet', { variant: "error" });
        document.getElementById('header-wallet-cmd')?.click();
        return 
      }
      
      if (!answer) {
        enqueueSnackbar('Permission error', { variant: "error" });
        return 
      }

      const txb = new TransactionBlock(); // new session
      const obj = Progress.From(txb, machine, answer?.object!, id);
      const pid = generateRandomString(8);
      props.PermissionCheck({id:pid, txb:txb, answer:answer, index:[PermissionIndex.progress_parent], handler:
        (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:Passport) => {
          try {
            if (id === pid) {
              if (index.includes(PermissionIndex.progress_parent)) {
                if (op === 'add') {
                    obj.parent({...data});
                } else if (op === 'remove') {
                    obj.parent_none();
                }
              };  
              passport?.destroy(); // destroy passport
              props.exe(pid, txb);
            }
          } catch (e) {
            console.log(e)
            enqueueSnackbar( 'Launch Failed', { variant: "error" });
          }
        }
      });
    }
    
    return (
        <Box sx={{maxWidth:'100%', padding:'0 2em', pb:'2em',}}>
            <SettingTitle title='Parent Progress' tips='Parent Progress address' required
              perm={make_permIconProp(answer, PermissionIndex.progress_parent)}/>
            <SettingInputText placeholder='Enter parent Progress address' autoFocus
              noPerm={Permission.HasPermission(answer, PermissionIndex.progress_parent, true)?.has === false}
                validator={ADDRESS_VALIDATOR} maxlength={66} value={data.parent_id} 
                  id='parent-parentid' event={(type, value, id) => {
                    data.parent_id = value;
                    setData({...data})
            }}/>
          <SettingTitle title='Parent session id' tips='Parent Progress session id.' required
            perm={make_permIconProp(answer, PermissionIndex.progress_parent)}/>
          <SettingInputText placeholder='Session id'  type='number' err_empty='Please enter parent Progress session id'
            noPerm={Permission.HasPermission(answer, PermissionIndex.progress_parent, true)?.has === false}
            validator={INT_VALIDATOR} maxlength={4} value={data.parent_session_id} 
              id='parent-session' event={(type, value, id) => {
                data.parent_session_id = value;
                setData({...data})
          }}/>
                    
          <SettingTitle title='Parent forward Node name' tips='Forward Node that in parent session' required
            perm={make_permIconProp(answer, PermissionIndex.progress_parent)}/>
          <SettingInputText placeholder='Enter parent forward Node name' err_empty='Please Enter parent forward Node name'
            noPerm={Permission.HasPermission(answer, PermissionIndex.progress_parent, true)?.has === false}
            maxlength={MAX_NAME_LENGTH} value={data.next_node} 
              id='parent-nextnode' event={(type, value, id) => {
                data.next_node = value ;
                setData({...data})
          }}/>
        
        <SettingTitle title='Parent Forward name' tips='Forward name that in parent session' required
          perm={make_permIconProp(answer, PermissionIndex.progress_parent)}/>
          <SettingInputText placeholder='Enter parent Forward  name' err_empty='Please Enter parent Forward name'
            noPerm={Permission.HasPermission(answer, PermissionIndex.progress_parent, true)?.has === false}
            maxlength={MAX_NAME_LENGTH} value={data.forward} 
              id='parent-forward' event={(type, value, id) => {
                data.forward = value;
                setData({...data})
          }}/>
   
          <div style={{display:'flex', alignItems:'baseline'}}>
             <SettingLaunch text='Launch' cancel = {props?.contents?.fields?.parent?.fields?.parent ? 'Cancel Parent' : undefined} 
             event={(t) => {
                if (t === 'click') {
                    if (!IsValidAddress(data.parent_id)) {
                        enqueueSnackbar('Parent address invalid', { variant: "error" });
                        document.getElementById('parent-parentid')?.focus();
                        return ;
                    }
                    if (!IsValidInt(data.parent_session_id)) {
                        enqueueSnackbar('Parent session-id invalid', { variant: "error" });
                        document.getElementById('parent-nextnode')?.focus();
                        return ;                   
                    }
                    if (!data.next_node) {
                        enqueueSnackbar('Parent forward Node invalid', { variant: "error" });
                        document.getElementById('parent-session')?.focus();
                        return ;                   
                    }
                    if (!data.forward) {
                        enqueueSnackbar('Parent Forward invalid', { variant: "error" });
                        document.getElementById('parent-forward')?.focus();
                        return ;                   
                    }
                    launch('add');
                }  else if (t === 'cancel') {
                    launch('remove');
                }
            }}/>
          </div>
        </Box>
    );  
  }