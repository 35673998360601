
import React, { useState } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { useTheme} from '@mui/material/styles';
import { Box,  } from '@mui/material';
import { IsValidAddress, MAX_DESCRIPTION_LENGTH, PermissionAnswer, Demand as WowokDemand, 
  Permission as WowokPermission, PermissionIndex, PermissionIndexType, Passport as WowokPassport, } from 'wowok';
import { SettingTitle, SettingTimePicker,  SettingLaunch, SettingTimePickerResult, ADDRESS_VALIDATOR,
  SettingInputText, SettingOwnedObject, SettingOwnedObjectResult
} from '../Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { generateRandomString, sliceType } from '../../../util';
import { make_permIconProp, make_permOwnerIconProp } from '../PermCheck';
import { Transaction as TransactionBlock } from '@mysten/sui/transactions';
import { Address } from '../../util/Address';
import { useAccount, add_resource_launched } from '../../util/Account';
export interface BasicData {
    description: string;
    new_permission: boolean;
    permission: string;
    bounty: SettingOwnedObjectResult | null;
    deadline: SettingTimePickerResult | null;
}
  
export default function Basic (props:any) {
    const id = props?.contents?.fields?.id?.id ?? '';
    const type = WowokDemand.parseObjectType(props?.type);
    const permission  = props?.contents?.fields?.permission;
    const answer: PermissionAnswer | undefined = props?.perms;
    const account = useAccount();
    const [data, setData] = useState<BasicData>({
      description:props?.contents?.fields?.description ?? "", 
      permission: permission ?? "", 
      bounty: id ? null : {object: '', type:'', balance: '', coinType: 'loading'},
      deadline:props?.contents?.fields?.time_expire ? {bDuration:false, time:parseInt(props?.contents?.fields?.time_expire), bLock:false} : null,
      new_permission:false});

    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();

    const launch = async (txb:TransactionBlock, demand?:WowokDemand) => {      
      try {
        if (demand) { // modify old
          // others txb in async progress.
          if (data.new_permission) {
              let permission = WowokPermission.New(txb, '');
              demand.change_permission(permission.get_object());
              permission.launch();            
          } else if (data.permission !== props?.contents?.fields?.permission) {
            demand.change_permission(data.permission);
          }     
          props.exe(generateRandomString(8), txb);
        } else { // new
            var obj:any = data!.bounty!.object;
            if (sliceType(data!.bounty!.type) === '0x2::coin::Coin') {
              if (data!.bounty!.type.includes('sui::SUI')) {
                obj = txb.splitCoins(txb.gas, [data.bounty!.balance])[0];
              } else {
                obj = txb.splitCoins(obj, [data.bounty!.balance])[0];
              }
            }

            if (data.new_permission) {
                let permission = WowokPermission.New(txb, '');
                let d = WowokDemand.New(txb, data!.bounty!.type, data.deadline!.bDuration, data.deadline!.time,
                   permission.get_object(), data.description, obj);
                add_resource_launched(account, txb, [d.launch(), permission.launch()]);
                props.exe(generateRandomString(8), txb, 'demand::Demand');         
            } else {
              const query_txb = new TransactionBlock(); //@ new session before object.From
              const p = WowokPermission.From(query_txb, data.permission);
              const pid = generateRandomString(8);
              const perm = PermissionIndex.demand;
              const tips = <div style={{display:'flex', alignItems:'center'}}>
                <Address address={data.permission} />
                <span>: No Permission for Launching Demand</span>
                </div>;
              p.QueryPermissions(permission, wallet.address??'', [perm], (answer:PermissionAnswer) => {
                const i = WowokPermission.HasPermission(answer, perm, true);
                if (!i?.has) {
                  enqueueSnackbar(tips, { variant: "error" });
                  return;
                }
                //用原有的txb继续提交
                props.GuardCheck({id:pid, txb:txb, answer:answer, guards:i.guard ? [i.guard] : [], handler:
                  (id:string, txb:TransactionBlock, passport?:WowokPassport) => {
                    try {
                      if (id === pid) {
                        let d = WowokDemand.New(txb, data!.bounty!.type, data.deadline!.bDuration, data.deadline!.time, 
                          data.permission, data.description, obj, passport?.get_object());
                        passport?.destroy(); // destroy passport
                        add_resource_launched(account, txb, [d.launch()]);
                        props.exe(generateRandomString(8), txb, 'demand::Demand');
                      }
                    } catch (e) {
                      console.log(e)
                      enqueueSnackbar( 'Launch Failed', { variant: "error" });
                    }
                  }
                });
              });  
            }          
          }
        } catch (e) {
            console.log(e);
            enqueueSnackbar('Launch failed', { variant: "error" });
        }
    }

    
    return (
        <Box sx={{maxWidth:'100%', padding:'0 2em', pb:'2em',}}>
          <SettingTitle title='Description' perm={make_permIconProp(answer, PermissionIndex.demand_description)}/>
          <SettingInputText placeholder='Describe your personalized need' maxlength={MAX_DESCRIPTION_LENGTH} multiline maxRows={6} autoFocus
            value={data.description} noPerm={WowokPermission.HasPermission(answer, PermissionIndex.demand_description)?.has === false}
            event={(type, value, id)=>{
                data.description = value;
                setData({...data});                
            }}/>
  
          <SettingTitle title='Permission' tips='Manage your objects and personnel. Achieve separation of operations and management.' required={!id} checkbox='Create a New Permission for me' 
            id='permission' noPerm={(!answer || answer.owner)  ? false:true} 
            perm={data.new_permission ? undefined : make_permOwnerIconProp(answer) }
            event={(type, value, id) => {
              data.new_permission = type === 'check' ? value : false
              setData({...data});
            }}/> 
            {data.new_permission && (<SettingInputText  disabled fullWidth value='Create a new Permission, and as the creator, you hold all permission indexes'
                placeholder='Create a new Permission, and as the creator, you hold all permission indexes' maxlength={66} 
                    sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: '#A6008B',
                        },
                      }}/>) }
            {!data.new_permission &&  <SettingInputText placeholder='Enter Permission object address' err_empty='Please enter Permission object address' 
                validator={ADDRESS_VALIDATOR} maxlength={66} value={data.permission} noPerm={answer && !answer.owner}
                  id='permission' event={(type, value, id) => {
                      data.permission = value;
                      setData({...data})                      
                }}/>}
          { data.bounty && <div>
              <SettingTitle title='Bounty Object' required={!id} 
              tips='Bounty type cannot be modified after launch. Bounty can be added but not subtracted and any address can be recharged. ' />
              { !wallet.connected && <div className='cmdText' style={{color:theme.palette.primary.main, marginLeft:'1em'}} 
                onClick={() => {document.getElementById('header-wallet-cmd')?.click();} }>Login to select your transferable asserts</div>}
              { wallet.connected && <SettingOwnedObject owner={wallet.address ?? ''} event={(type, value, id) => {
                  if (type === 'value' && !id) {
                      data.bounty = value as SettingOwnedObjectResult;
                  } 
                  setData({...data});
          }}/> }
        </div> }
          <SettingTimePicker required={!id} bDuration={data.deadline ? false: true} time={data.deadline ? data.deadline.time : undefined} 
          duration={id ? 0 : 1} event={(type, value, id) => {
            if (type === 'value') {
              data.deadline = value as SettingTimePickerResult;
            } else {
              data.deadline = null;
            }
            setData({...data});
          }} tips='The start time is based on the timestamp of the successful on-chain transaction. Deadline can be extended but not shortened. Bounty can be reclaimed after exceeding the deadline.'
          perm={make_permIconProp(answer, PermissionIndex.demand_expand_time)}/>
          <SettingLaunch text='Launch' event={(t, v, _) => {
            if (t === 'click') {
              if (!data.new_permission && !IsValidAddress(data.permission)) {
                enqueueSnackbar('Permission not specified', { variant: "error" });
                document.getElementById('permission')?.focus();
                return;
              }
              
              if (!id && !data.bounty?.object) {
                enqueueSnackbar( 'Bounty not specified', { variant: "error" });
                return;
              }
              
              if (!id && data?.bounty?.err) {
                enqueueSnackbar( 'Bounty invalid', { variant: "error" });
                return;
              }
              
              if (!data.deadline || (id && data.deadline.bDuration && data.deadline.time < 0)) {
                document.getElementById('setting-time-number')?.focus();
                enqueueSnackbar('Duration or deadline not specified', { variant: "error" });
                return;
              }
        
              if (!wallet.connected) {
                enqueueSnackbar('Please login wallet', { variant: "error" });
                document.getElementById('header-wallet-cmd')?.click();
                return 
              }
              
              if (id) {
                const txb = new TransactionBlock(); // new session
                const obj = WowokDemand.From(txb, type, permission, id);
                const pid = generateRandomString(8);
                const perms: PermissionIndexType[] = [];
                if (data.description !== props?.contents?.fields?.description) {
                  perms.push(PermissionIndex.demand_description);
                };
                if (data.deadline) {
                  if (!((data.deadline.bDuration && data.deadline.time === 0) || 
                    (!data.deadline.bDuration && props?.contents?.fields?.time_expire && 
                      data.deadline.time === parseInt(props?.contents?.fields?.time_expire))
                  )) {
                    perms.push(PermissionIndex.demand_expand_time);
                  }
                }
                props.PermissionCheck({id:pid, txb:txb, answer:answer, index:perms, handler:
                  (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:WowokPassport) => {
                    try {
                      if (id === pid) {
                        if (index.includes(PermissionIndex.demand_description)) {
                          obj.set_description(data.description, passport?.get_object());
                        };  
                        if (index.includes(PermissionIndex.demand_expand_time) && data.deadline) {
                          obj.expand_time(data.deadline.bDuration, data.deadline.time, passport?.get_object());
                        }     
                        passport?.destroy(); // destroy passport
                        launch(txb, obj);          
                      }
                    } catch (e) {
                      console.log(e)
                      enqueueSnackbar( 'Launch Failed', { variant: "error" });
                    }
                  }
                });
              } else {
                launch(new TransactionBlock());
              }
            }
    }}/>
        </Box>
    );  
  }