import React, { useEffect, useState } from 'react';
import '../../css/Common.css';
import { Paper, Button, Tooltip, } from '@mui/material';
import { Machine, Progress as WowokProgress, Protocol, PermissionAnswer, PermissionIndex, Permission,
  PermissionIndexType, Passport, } from 'wowok';
import { grey } from '@mui/material/colors';
import MaterialTable, {MTableToolbar} from "material-table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { PaginatedEvents, SuiEvent } from '@mysten/sui/client';
import { Transaction as TransactionBlock } from '@mysten/sui/transactions';
import { Address } from '../util/Address';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { PermIcon } from '../util/Icons';
import { make_permIconProp } from '../launch/PermCheck';
import { generateRandomString } from '../../util';
import { useAccount, add_resource_launched } from '../util/Account';

export default function Progress(props:any) {
    //console.log(props)
    const id = props?.contents?.fields?.id?.id ?? '';
    const wallet = useWallet();
    const { enqueueSnackbar } = useSnackbar();
    const account  = useAccount();

    const permission = props?.contents?.fields?.permission;
    const answer: PermissionAnswer | undefined = props?.perms;

    const [events, setEvents] = useState<PaginatedEvents | null>(null);
    const QueryEvents = (count:number, result:SuiEvent[], type:string, cursor?:any) => {
        Protocol.Client().queryEvents({query:{MoveEventType:type}, cursor:cursor}).then((res) => {
            count += 1;
            res.data.filter((v) => (v.parsedJson as any)?.machine === id).forEach((v) => {
                let f = result.find((i) => (i.parsedJson as any).object === (v.parsedJson as any).object);
                if (f) {
                    f = {...v};
                } else {
                    result.push(v);
                }
            });

            res.data = [...result];
            setEvents(res);

            if (res.hasNextPage && count <= 8) {
                QueryEvents(count, result, type, res.nextCursor);
            }
        }).catch((e) => {
            console.log(e)
        });
    }

    var result:SuiEvent[] = [];
    var count = 0;
    useEffect(() =>  {
        /*  Unsupported("This query type is not supported by the full node.")
        Protocol.Client().queryEvents({query:{MoveEventField:{path:'/demand', value:id}}}).then((res) => {
            setEvents(res);
            console.log(res);
        }).catch((e) => {
            console.log(e)
        });*/
        const t = Protocol.Instance().Package('wowok') + '::progress::NewProgressEvent';
        QueryEvents(count, result, t);
    }, []);

    let len = events ? events.data.length : 0;
    len = len >= 20 ? 20 : (len <= 5 ? 5 : 10);

    const launch = async () => {      
        if (!wallet.connected) {
          enqueueSnackbar('Please login wallet', { variant: "error" });
          document.getElementById('header-wallet-cmd')?.click();
          return 
        }
        
        if (!props?.contents?.fields?.bPublished) {
            enqueueSnackbar('Machine not published yet', { variant: "error" });
            return;
        }
        if (props?.contents?.fields?.bPaused) {
            enqueueSnackbar('Machine has paused', { variant: "error" });
            return;
        }
        
        const txb = new TransactionBlock();
        const obj = Machine.From(txb, permission, id);
        const pid = generateRandomString(8);
        props.PermissionCheck({id:pid, txb:txb, answer:answer, index:[PermissionIndex.progress], handler:
          (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:Passport) => {
            try {
              if (id === pid) {
                if (index.includes(PermissionIndex.progress)) {
                  add_resource_launched(account, txb, [WowokProgress.New(txb, obj.get_object(), permission, null, passport?.get_object()).launch()]);
                };    
                passport?.destroy(); // destroy passport
                props.exe(pid, txb);        
              }
            } catch (e) {
              console.log(e)
              enqueueSnackbar( 'Launch Failed', { variant: "error" });
            }
          }
        });
    }

    var btnTips:any =  undefined;
    if (props?.contents?.fields?.bPublished === false) {
      btnTips = 'Machine Not published.'
    } else if (props?.contents?.fields?.bPaused === true) {
      btnTips = 'Machine had paused.'
    }

    return (
        <Paper className='noboxshadow' >
            <MaterialTable title=''
            columns={[
              { title: '', field: 'index', type: 'numeric', align:'left', width:'1%', render:(data, type) => {
                  return <div style={{fontStyle:'italic', color:grey[400], fontWeight:200}}>{data.index}</div>
              }},
              { title: 'Progress', field: 'progress', render:(data, t) => {
                return (<div style={{display:'flex', alignItems:'center'}}>
                    <Address address={data.progress} showType={'Progress'}/>
                  </div>)
              }},
              { title: 'Signer', field: 'signer', render:(data, type) => {
                  return (<div >
                    <Address address={data.signer}/>
                  </div>)
              }},
              { title: 'Task', field: 'task', render:(data, type) => {
                return (<div > 
                    {
                        data.task && <Address address={data.task} showType={true}/>
                    }
                    {
                        !data.task && <div style={{color:grey[400]}}>No task bind yet</div>
                    }
                </div>)
            }},
              { title: 'Time', field: 'time', render:(data, type) => {
                const ts = new Date(parseInt(data.time ?? ''));
                return (<div>
                    {ts.toLocaleString()}
                </div>)
              }},
              { title: 'Tx', field: 'tx',  align:'left', width:'6em', render:(data, type) => {
                return (<div>
                    <Tooltip title={data.tx}>
                       <span className='cmdText' onClick={()=>window.open(Protocol.Instance().ExplorerUrl(data.tx, 'txblock'), '_blank')?.focus()}>
                        {data.tx.slice(0, 6)} 
                        </span>
                    </Tooltip>
                </div>)
              }},
            ]}
            data={events ? events.data.map((v, i) => {
              return {index:i+1, progress:(v.parsedJson as any).object, signer:v.sender, task:(v.parsedJson as any).task, tx:v.id.txDigest, time:v.timestampMs}
            }) : []}   
            options={{padding: "dense", tableLayout:'fixed',
                pageSize: len, 
                pageSizeOptions: [5, 10, 20],}}
            actions={[]}
            components={{
              Header: (props) => {
                return (<TableHead {...props} >
                  <TableRow>
                    <TableCell variant='head' style={{width:'18px'}}>{props.columns[0].title}</TableCell>
                    <TableCell variant='head' align='center' style={{width:'10em'}}>
                      <div style={{display:'flex'}}>
                        <div>{props.columns[1].title}</div>                  
                      </div>
                    </TableCell>
                    <TableCell variant='head' align='left' style={{width:'10em'}}>
                        <div>{props.columns[2].title}</div>
                    </TableCell>
                    <TableCell variant='head' align='left' style={{}}>
                      <div> {props.columns[3].title}</div>
                      </TableCell>
                    <TableCell variant='head' style={{width:'14em'}}>{props.columns[4].title}</TableCell>
                    <TableCell variant='head' align='center' style={{width:'4em'}}>{props.columns[5].title}</TableCell>
                  </TableRow>
                </TableHead>)
              },
                Toolbar: props => (
                    <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                      <Tooltip title={btnTips}><span>
                    <Button variant='contained' sx={{ml:'1em', textTransform:'none', padding:'.2em .8em', alignItems:'center', display:'flex'}} 
                        disabled={(Permission.HasPermission(answer, PermissionIndex.progress, true)?.has === false) || btnTips}
                        onClick={ () => { launch() }}>
                        {<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
                        <span>+ Launch Progress</span> 
                        <PermIcon {...make_permIconProp(answer, PermissionIndex.progress)} />
                        </div>}
                    </Button> </span>     
                    </Tooltip>                
                    <MTableToolbar {...props} />
                    </div>
                ),
            }}
          />     
        </Paper>
      );  
}