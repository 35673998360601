
import React, { useState } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Box, Select, MenuItem,  } from '@mui/material';
import { Repository_Policy_Mode, PermissionIndex, Repository, Permission as WowokPermission, MAX_DESCRIPTION_LENGTH, IsValidAddress,
  PermissionAnswer,  PermissionIndexType, Passport as WowokPassport, } from 'wowok';
import { SettingTitle,  SettingLaunch, ADDRESS_VALIDATOR, SettingInputText, } from '../Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { Transaction as TransactionBlock } from '@mysten/sui/transactions';
import { make_permIconProp, make_permOwnerIconProp } from '../PermCheck';
import { generateRandomString } from '../../../util';
import { Address } from '../../util/Address';
import { useAccount,add_resource_launched } from '../../util/Account';
export interface BasicData {
    description: string;
    new_permission: boolean;
    permission: string;
    mode: Repository_Policy_Mode;
}
  
export default function Basic (props:any) {
  const id = props?.contents?.fields?.id?.id ?? '';
  const permission  = props?.contents?.fields?.permission;
  const answer: PermissionAnswer | undefined = props?.perms;
    const [data, setData] = useState<BasicData>({
      description:props?.contents?.fields?.description ?? "", 
      permission:props?.contents?.fields?.permission ?? "", 
      mode:id ? props?.contents?.fields?.policy_mode as Repository_Policy_Mode: Repository_Policy_Mode.POLICY_MODE_FREE,
      new_permission:false});

    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();
    const account = useAccount();

    const launch = async (txb:TransactionBlock, repo?:Repository) => {      
      try {
        if (repo) { // modify old
          // others txb in async progress.
          if (data.new_permission) {
              let permission = WowokPermission.New(txb, '');
              repo.change_permission(permission.get_object());
              permission.launch();            
          } else if (data.permission !== props?.contents?.fields?.permission) {
            repo.change_permission(data.permission);
          }     
          props.exe(generateRandomString(8), txb); 
        } else { // new
            if (data.new_permission) {
                let permission = WowokPermission.New(txb, '');
                let d = Repository.New(txb, permission.get_object(), data.description, data.mode);
                add_resource_launched(account, txb, [d.launch(), permission.launch()]);
                props.exe(generateRandomString(8), txb, 'repository::Repository');      
            } else {
              // 获取 perm 的当前权限
              const query_txb = new TransactionBlock(); //@ new session before object.From
              const p = WowokPermission.From(query_txb, data.permission);
              const pid = generateRandomString(8);
              const perm = PermissionIndex.repository;
              const tips = <div style={{display:'flex', alignItems:'center'}}>
                <Address address={data.permission} />
                <span>: No Permission for Launching Repository</span>
                </div>;
              p.QueryPermissions(permission, wallet.address??'', [perm], (answer:PermissionAnswer) => {
                const i = WowokPermission.HasPermission(answer, perm, true);
                if (!i?.has) {
                  enqueueSnackbar(tips, { variant: "error" });
                  return;
                }
                //用原有的txb继续提交
                props.GuardCheck({id:pid, txb:txb, answer:answer, guards:i.guard ? [i.guard] : [], handler:
                  (id:string, txb:TransactionBlock, passport?:WowokPassport) => {
                    try {
                      if (id === pid) {
                        let d = Repository.New(txb, data.permission, data.description, data.mode, passport?.get_object());
                        passport?.destroy(); // destroy passport
                        add_resource_launched(account, txb, [d.launch()]);
                        props.exe(generateRandomString(8), txb, 'repository::Repository');
                      }
                    } catch (e) {
                      console.log(e)
                      enqueueSnackbar( 'Launch Failed', { variant: "error" });
                    }
                  }
                });
              });   
            }          
          }
        } catch (e) {
            console.log(e);
            enqueueSnackbar('Launch failed', { variant: "error" });
        }
    }
    
    const tips = 'Relax mode: Allows entry of data other than policy. Used for informal, non-consensus situations.\nStrict mode: Prohibits entry of data beyond policy. Used in formal, fully consensus contexts.'
    return (
        <Box sx={{maxWidth:'100%', padding:'0 2em', pb:'2em',}}>
          <SettingTitle title='Description' perm={make_permIconProp(answer, PermissionIndex.repository_description)}/>
          <SettingInputText placeholder='Enter the name or description of the Repository' maxlength={MAX_DESCRIPTION_LENGTH} multiline maxRows={6} autoFocus
            value={data.description} noPerm={WowokPermission.HasPermission(answer, PermissionIndex.repository_description)?.has === false}
            event={(type, value, id)=>{
              data.description = value;
              setData({...data});
            }}/>
  
          <SettingTitle title='Permission' tips='Manage objects and personnel. Achieve separation of operations and management.' required={!id} checkbox='Create a New Permission for me' 
            id='permission' noPerm={(!answer || answer.owner)  ? false:true} 
            perm={data.new_permission ? undefined : make_permOwnerIconProp(answer) }
            event={(type, value, id) => {
              data.new_permission = type === 'check' ? value : false
              setData({...data});
            }}/> 
            {data.new_permission && (<SettingInputText  disabled fullWidth value='Create a new Permission, and as the creator, you hold all permission indexes'
                placeholder='Create a new Permission, and as the creator, you hold all permission indexes' maxlength={66} 
                    sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: '#A6008B',
                        },
                      }}/>) }
            {!data.new_permission &&  <SettingInputText placeholder='Enter Permission object address' err_empty='Please enter Permission object address' 
                validator={ADDRESS_VALIDATOR} maxlength={66} value={data.permission} noPerm={answer && !answer.owner}
                  id='permission' event={(type, value, id) => {
                    data.permission = value ;
                    setData({...data})
                }}/>}
            <SettingTitle title='Policy mode' required={!id} perm={make_permIconProp(answer, PermissionIndex.repository_policy_mode)}
                tips={<div style={{ whiteSpace: 'pre-line' }}>{tips}</div>} />
            <Select value={data.mode} sx={{width:'40%'}} disabled={WowokPermission.HasPermission(answer, PermissionIndex.repository_policy_mode)?.has === false}
              onChange={(e) => {
                data.mode = e.target.value as Repository_Policy_Mode;
                setData({...data});
            }}>
                <MenuItem value={0}>Relax mode</MenuItem>
                <MenuItem value={1}>Strict mode</MenuItem>
            </Select>
   
             <SettingLaunch text='Launch' event={(t, v, _) => {
            if (t === 'click') {
              if (!data.new_permission && !IsValidAddress(data.permission)) {
                enqueueSnackbar('Permission not specified', { variant: "error" });
                document.getElementById('permission')?.focus();
                return;
              }
        
              if (!wallet.connected) {
                enqueueSnackbar('Please login wallet', { variant: "error" });
                document.getElementById('header-wallet-cmd')?.click();
                return 
              }

              if (id) {
                const txb = new TransactionBlock(); // new session
                const obj = Repository.From(txb,  permission, id);
                const pid = generateRandomString(8);
                const perms: PermissionIndexType[] = [];
                if (data.description !== props?.contents?.fields?.description) {
                  perms.push(PermissionIndex.repository_description);
                };
                if (data.mode !== props?.contents?.fields?.policy_mode) {
                  perms.push(PermissionIndex.repository_policy_mode);
                };
                props.PermissionCheck({id:pid, txb:txb, answer:answer, index:perms, handler:
                  (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:WowokPassport) => {
                    try {
                      if (id === pid) {
                        if (index.includes(PermissionIndex.repository_description)) {
                          obj.set_description(data.description, passport?.get_object());
                        };  
                        if (index.includes(PermissionIndex.repository_policy_mode)) {
                          obj.set_policy_mode(data.mode, passport?.get_object());
                        }     
                        passport?.destroy(); // destroy passport
                        launch(txb, obj);          
                      }
                    } catch (e) {
                      console.log(e)
                      enqueueSnackbar( 'Launch Failed', { variant: "error" });
                    }
                  }
                });
              } else {
                launch(new TransactionBlock());
              }
            }
          }}/>
        </Box>
    );  
  }