import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Pannel, a11yProps,  } from '../util/Common';
import 'reactflow/dist/style.css';
import  GuardGraph from './GuardGraph';
import { useLocation } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';

export default function Guard(props:any) {
/*  const location = useLocation()
  var t = parseInt(location.hash.slice(1));
  if (t < 0 || t > 1 ||  isNaN(t))  { t = 0; }
  window.location.hash = '#' + t;

  const [value, setValue] = React.useState(t);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    window.location.hash = '#' + newValue;
    setValue(newValue);
  };
*/
    return (
      <Paper sx={{pt:'1em', pl:'1em', pr:'1em',  mb:'1em'}} >
        <Box sx={{width:'100%', height:'42em'}}>
          <ReactFlowProvider>
            <GuardGraph  {...props} />
          </ReactFlowProvider>
        </Box>
      {/*      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Graph" {...a11yProps(0)} />
                <Tab label="Reference" {...a11yProps(1)} />
              </Tabs>
            <Pannel value={value} index={0}>
                <Box sx={{width:'100%', height:'40em'}}>
                    <GuardGraph  {...props} />
                    <></>
                </Box>
            </Pannel>
            <Pannel value={value} index={1}>
              ...soon
            </Pannel> */}
      </Paper>
    )
}
