import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import 'reactflow/dist/style.css';
import  MachineGraph from '../launch/machine/MachineGraph';
import { Pannel, a11yProps,  } from '../util/Common';
import Progress from './Progress';
import Details from './Details';
import { useLocation } from 'react-router-dom';


export default function MachineSetting(props:any) {
  const location = useLocation()
  var t = parseInt(location.hash.slice(1));
  if (t < 0 || t > 2 ||  isNaN(t))  { t = 0; }
  window.location.hash = '#' + t;

  const id = props?.contents?.fields?.id?.id ?? '';
  const [value, setValue] = React.useState(t);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    window.location.hash = '#' + newValue;
    setValue(newValue);
  };

    return (
          <Paper sx={{pt:'.4em', pl:'1em', pr:'1em',  mb:'2em'}} >
              <Tabs value={value} onChange={handleChange} aria-label="machine">
                <Tab label="Graph" {...a11yProps(0)} sx={{textTransform:'none', pb:'.2em'}} value={0}/>
                <Tab label="Progress" {...a11yProps(1)} sx={{textTransform:'none', pb:'.2em'}} value={1}/>
                <Tab label="Promise" {...a11yProps(2)} sx={{textTransform:'none', pb:'.2em'}} value={2}/>
              </Tabs>
            <Pannel value={value} index={0}>
              <MachineGraph  {...props} />
            </Pannel>
            <Pannel value={value} index={1}>
              <Progress {...props}/>
            </Pannel>
            <Pannel value={value} index={2}>
             <Details {...props} />
            </Pannel>
        </Paper>
    )
}
