import React, { useCallback, useEffect } from 'react';
import Permission from '../permission/Permission';
import Repository from '../repository/Repository';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import StarIcon from '@mui/icons-material/Star';
import styled from "styled-components";
import { useTheme} from '@mui/material/styles';
import Button from '@mui/material/Button';
import { pink, grey, green, deepOrange } from '@mui/material/colors';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Demand from '../demand/Demand';
import Machine from '../machine/Machine';
import Treasury from '../treasury/Treasury';
import Guard from '../guard/Guard';
import Service from  '../service/Service';
import { useLocation } from 'react-router-dom';
import ServiceSetting from '../launch/service/Service';
import NotFound from './NotFound';
import PermissionSetting from '../launch/permission/Permission';
import DemandSetting from '../launch/demand/Demand';
import { Entity, Protocol, MAX_DESCRIPTION_LENGTH, Bcs, Resource, Permission as WowokPermission, PermissionAnswer, Machine_Node, WithdrawMode} from 'wowok';
import { SettingInputText } from '../launch/Settings';
import RepositorySetting from '../launch/repository/Repository';
import MachineSetting from '../launch/machine/Machine';
import Progress from '../progress/Progress';
import Order from '../order/Order';
import ProgressSetting from '../launch/progress/Progress';
import Discount from '../discount/Discount';
import {Icons} from '../util/Icons';
import { Address } from '../util/Address';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { useState } from 'react';
import { useWallet } from '@suiet/wallet-kit';
import { useAccount } from '../util/Account';
import { useSnackbar } from 'notistack';
import { store_key_machine_nodes, store_object_type } from '../../util';
import { Transaction as TransactionBlock } from '@mysten/sui/transactions';
import { generateRandomString } from '../../util';
import TreasurySetting from '../launch/treasury/Treasury';
import Payment from '../payment/payment';
import ArbitrationSetting from '../launch/arbitration/Arbitration';
import Arbitration from '../arbitration/Arbitration';
import Arb from '../arb/Arb';

interface ObjectStatus {
  text: string;
  color: string;
  invisible: boolean;
}

function Header(props:any) {
  const id = props.contents.fields.id.id;
  const wallet = useWallet();

//  const [forceRefresh, setForceRefresh] = useState(false);
  const account = useAccount(false);
//  setForceRefresh(false);

  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const AvatarContainer = styled.div`
      display: flex;
      margin-bottom: 14px;
      & > * {
        margin: 16px;
        margin-right: 0;
      }
    `;
const AvatarLabel = styled.div`
      margin:4px;
      margin-top:6px;
      & > button {
        text-transform: none;
      }
`;
const RightButton = styled.div`
      margin-top:18px;
      margin-right:12px;
      margin-left:auto;
`;
const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: props.status.color ?? '#44b700',
    color: props.status.color ?? '#44b700',
    boxShadow: `0 0 0 2px `,
    right: '24%',
    height: '6px',
    minWidth: '6px',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

  const [likes, setLikes] = useState(0);
  const [dislikes, setDislikes] = useState(0);
  const [ilike, setIlike] = useState(false);
  const [idislike, setIdislike] = useState(false);

  const launch = async (op:'like'|'dislike') => {
    if (!wallet.connected) {
      enqueueSnackbar('Please login wallet', { variant: "error" });
      document.getElementById('header-wallet-cmd')?.click();
      return 
    }
      
    try {
      const txb = new TransactionBlock()
      const entity = Entity.From(txb);
      let obj : any;
      if (account.account.resource) {
        obj = Resource.From(txb, account.account.resource);
      } else {
        obj = Resource.From(txb, Entity.From(txb).create_resource2());
      }
      
      entity.mark(obj, id, op);
      if (!account.account.resource) {
        obj.launch();
      }
      props.exe(generateRandomString(8), txb);
    } catch (e) {
      console.log(e);
      enqueueSnackbar('Launch failed', { variant: "error" });
    }
  }

  const query_likes = async () => {
    const txb = new TransactionBlock();        
    Entity.From(txb).query_ent(id);

    Protocol.Client().devInspectTransactionBlock({sender:id, transactionBlock:txb}).then((res) => {
      if (res.results && res.results[0].returnValues && res.results[0].returnValues[0][0][0] === 0 )  {
        return 
      }

      const r1 = Bcs.getInstance().de_ent(Uint8Array.from((res.results as any)[0].returnValues[0][0]!));
      setLikes(r1.like); setDislikes(r1.dislike);
      if (res.results && res.results?.length > 1 && res.results[1].returnValues && res.results[1].returnValues[0][0][0] === 0) {
        return
      }

      const f1 = account.resource.find((v)=>v.name === Resource.LikeName);
      if (f1) setIlike(f1.address.includes(id));
      const f2 = account.resource.find((v)=>v.name === Resource.DislikeName);
      if (f2) setIdislike(f2.address.includes(id));
    }).catch(e=>{
      console.log(e)
    })
  }

  query_likes();

  return (
    <Paper sx={{ width: '100%'}}>
    <AvatarContainer>
      <Tooltip title={props.type} arrow>
      <StyledBadge 
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        variant="dot"
        invisible={props.status.invisible}
      >
          <Avatar
            style={{ marginRight: "8px" , width:48, height:48, backgroundColor:'transparent'}}
            alt={props.type}
          > <Icons type={props.type}/></Avatar>
        </StyledBadge>     
      </Tooltip>
      <AvatarLabel style={{marginTop:'10px'}}>
        <div style={{display:'flex', alignItems:'center'}}>
          <Button sx={{pl:0, pb:0}}><Address address={id} fontSize={'1.2em'} maxWidth={'16em'}/></Button>
          {props.bSetting && <Tooltip title='Settings' arrow placement='bottom'>
              <IconButton sx={{m:'4px 3px 0 0px', padding:'3px'}} className='cmdText' onClick={()=> { window.location.href = ('/'+id+'?setting') }}>
                <MenuOpenIcon className='cmdText' sx={{height:'.8em!important', width:'.8em!important', color:idislike ? theme.palette.primary.main:grey[400]}}/>
              </IconButton>  
          </Tooltip>   } 
        </div>
        <Typography variant='subtitle2' sx={{pb:"0px", pl:'1px', fontWeight:200,mt:"-6px", color:grey[600]}}> {props.status.text}</Typography>
      </AvatarLabel>
      <RightButton>
        <Tooltip title='likes' arrow placement='bottom'>
          <Badge anchorOrigin={{vertical:'top', horizontal:'left'}} badgeContent={likes > 0 ? likes : undefined} color="primary" sx={{ "& .MuiBadge-badge": {mt:'6px', mr:'6px', fontSize: 11, fontWeight:100, height: 14, minWidth: 14 } }}>
              <IconButton className='cmdText' sx={{pd:'3px'}} onClick={()=> {
                launch('like');
              }}>
              <ThumbUpIcon className='cmdText' sx={{height:'.7em!important', width:'.7em!important', color:ilike ? theme.palette.primary.main:grey[400]}}/>
              </IconButton>  
          </Badge>
        </Tooltip>
        <Tooltip title='dislikes' arrow placement='bottom'>
          <Badge anchorOrigin={{vertical:'top', horizontal:'left'}} badgeContent={dislikes > 0 ? dislikes : undefined} color="primary" sx={{ "& .MuiBadge-badge": {mt:'6px', ml:'4px', fontSize: 11, fontWeight:100, height: 14, minWidth: 14 } }}>
            <IconButton className='cmdText' sx={{ml:'6px', pd:'3px'}} onClick={()=> {
                launch('dislike');
              }}>
              <ThumbDownIcon className='cmdText' sx={{height:'.7em!important', width:'.7em!important', color:idislike ? theme.palette.primary.main:grey[400]}}/>
            </IconButton>  
          </Badge>
        </Tooltip>
      </RightButton>
    </AvatarContainer>
    </Paper>
  )
}

function Description(props:any) {
  return (
    <Paper sx={{ width: '100%', mt:-2, mb: 2, borderRadius:'none'}}>
      <SettingInputText placeholder='Description not set yet.' maxlength={MAX_DESCRIPTION_LENGTH} multiline maxRows={6} readOnly
        value={props.description} noneBorder={true} noneScroll={true}
        />
    </Paper>
  )
}

export default function ObjectContent(props:any) {
  const path = useLocation();
  
  const p = Protocol.Instance();
  //console.log(props)
  const type = p.object_name_from_type_repr(props?.type);
  if (props?.type) store_object_type(path.pathname.slice(1), props?.type); // store type

  const wallet = useWallet();
  const permission = props?.contents?.fields?.permission;

  const [perms, setPerms] = React.useState<PermissionAnswer>({who:wallet.address ??'', object:permission});
  const [serviceContents, setServiceContents] = useState<any | undefined>(undefined);
  const [ArbitrationContents, setArbitrationContents] = useState<any | undefined>(undefined);
  const [OrderType, setOrderType] = useState<any | undefined>(undefined);

  const GetPerms = (permission:string, address:string) => {
      if (permission) {
        const txb = new TransactionBlock(); //@ new session before object.From
        const p = WowokPermission.From(txb, permission);
          p.QueryPermissions(permission, address, 'all', (answer:PermissionAnswer) => {
            setPerms(answer);
          });            
      }    
  };

  useEffect(() => {
    if (type === 'Progress') {
      Protocol.Client().getObject({id:props?.contents?.fields?.machine, options:{showContent:true, showType:true}}).then((res) => {
        GetPerms((res.data as any).content.fields.permission, wallet.address ?? '0xe386bb9e01b3528b75f3751ad8a1e418b207ad979fea364087deef5250a73d3f');
      });
    } else if (type === 'Order' || type === 'Discount') {
      Protocol.Client().getObject({id:props?.contents?.fields?.service, options:{showContent:true, showType:true}}).then((res) => {
        GetPerms((res.data as any).content.fields.permission, wallet.address ?? '0xe386bb9e01b3528b75f3751ad8a1e418b207ad979fea364087deef5250a73d3f');
        setServiceContents(res.data);
      });
    } else if (type === 'Arb') {
      Protocol.Client().multiGetObjects({ids:[props?.contents?.fields?.arbitration, props?.contents?.fields?.order], options:{showContent:true, showType:true}}).then((res) => {
        //console.log(res)
        res?.forEach((v) => {
          if (v?.data?.type?.includes('arbitration::Arbitration')) {
            GetPerms((v.data as any).content.fields.permission, wallet.address ?? '0xe386bb9e01b3528b75f3751ad8a1e418b207ad979fea364087deef5250a73d3f');
            setArbitrationContents(v.data);
          } else if (v?.data?.type?.includes('order::Order')) {
            setOrderType(v.data.type);
          }
        })

      });
    } else {
      GetPerms(permission, wallet.address ?? '0xe386bb9e01b3528b75f3751ad8a1e418b207ad979fea364087deef5250a73d3f');
    }
  }, [])

  var description = props.contents.fields.description;
  var bSetting = true; var bDescription = true;

  if (!type) {
    return <NotFound /> 
  } else if (path.search.toLowerCase().includes('setting')) {
    let ret:any = <NotFound />;
    switch(type) {
      case 'Service' :  
        ret = <ServiceSetting {...props} perms={perms}/> 
        break;
      case 'Permission' : 
        ret = <PermissionSetting {...props} perms={perms}/>
        break;
      case 'Demand': 
        ret = <DemandSetting {...props} perms={perms} />
        break;
      case 'Repository': 
        ret = <RepositorySetting {...props} perms={perms}/>
        break;
      case 'Machine': 
        ret = <MachineSetting {...props} perms={perms}/>
        break;
      case 'Progress': 
        ret = <ProgressSetting {...props} perms={perms}/>
        break;
      case 'Treasury': 
        ret = <TreasurySetting {...props} perms={perms}/>
        break;
      case 'Arbitration':
        ret = <ArbitrationSetting {...props} perms={perms}/>
        break;
    }
    return <div>{ret}</div>
  } else {
    let item = <NotFound />;
    let text = 'Active';
    let color = '#44b700';
    let status: ObjectStatus = {text:text, color:color, invisible:false};
    //console.log(props);

    switch (type) {
      case 'Permission': 
        item = <Permission {...props}/>;
        break;
      case 'Repository': 
        status.text = props?.contents?.fields?.policy_mode! === 0 ? 'Relax mode' : 'Strict mode';
        status.color = props?.contents?.fields?.policy_mode! === 0 ?  pink[200]: green[400];
        item = <Repository {...props} perms={perms}/>;
        break;
      case 'Demand': 
        status.text = props?.contents?.fields?.yes ? 'Issued' : 'Presenting';
        status.color = props?.contents?.fields?.yes ? pink[200] : green[400];
        item = <Demand {...props} perms={perms}/>;
        break;
      case 'Machine': 
        status.text = props?.contents?.fields?.bPublished  && !props?.contents?.fields?.bPaused ? 'Active' : 'Pending';
        status.color = props?.contents?.fields?.bPublished  && !props?.contents?.fields?.bPaused ? green[400]: pink[200];
        item = <Machine {...props} perms={perms}/>;
        break;
      case 'Guard': 
        status.text =  'Immutable';
        status.color =  green[400];
        item = <Guard {...props}/>;
        bSetting = false;
        break;      
      case 'Service': 
        status.text = props?.contents?.fields?.bPublished  && !props?.contents?.fields?.bPaused ? 'Ordering' : 'Pending';
        status.color = props?.contents?.fields?.bPublished  && !props?.contents?.fields?.bPaused ? green[400]: pink[200];
        item = <Service {...props} perms={perms}/>;
        break;   
      case 'Progress':
        status.text =  'Active';
        status.color =  green[400];
        bDescription = false;
        const store = localStorage.getItem(store_key_machine_nodes(props?.contents?.fields?.machine));
        if (store) { // 从machine缓存中监测状态，可能不灵敏
          const nodes:Machine_Node[] = JSON.parse(store);
          let next_nodes: Machine_Node[] = [];
          nodes.forEach((v) => {
            v.pairs = v.pairs.filter((i) => i.prior_node === props?.contents?.fields?.current);
            if (v.pairs.length > 0) {
              next_nodes.push(v);
            }
          });
          if (next_nodes.length === 0) {
            status.color = pink[200];
            status.text = 'Completed';
          }
        }
        item = <Progress {...props} perms={perms}/>;
        break;
      case 'Order':
        status.text =  'Active';
        status.color =  green[400];
        item = <Order {...props} perms={perms} service={serviceContents}/>
        bSetting = false; bDescription = false;
        break;
      case 'Treasury':
        const mode = parseInt(props?.contents?.fields?.withdraw_mode);
        if (mode === WithdrawMode.GUARD_ONLY_AND_IMMUTABLE) {
          status.text =  'External withdrawal Only';
          status.color =  deepOrange[400];
        } else if (mode === WithdrawMode.PERMISSION) {
          status.text =  'Internal withdrawal Only';
          status.color =  green[500];
        } else if (mode === WithdrawMode.BOTH_PERMISSION_AND_GUARD) {
          status.text =  'Compound withdrawal';
        }
        item = <Treasury {...props} perms={perms}/>
        break;
      case 'Discount':
        status.text =  props?.owner?.AddressOwner ? 'Effective' : 'Payed';
        status.color =  props?.owner?.AddressOwner ? green[400] : grey[400];
        description = props?.contents?.fields?.name;
        bSetting = false;
        item = <Discount {...props} perms={perms} service={serviceContents}/>
        break;
      case 'Payment':
        status.text =  'Immutable';
        status.color =  green[400];
        bDescription = false;
        bSetting = false;
        item = <Payment {...props} perms={perms} />
        break;
      case 'Arbitration':
        status.text =  props?.contents?.fields?.bPaused ? 'Pending': 'Active' ;
        status.color = props?.contents?.fields?.bPaused ? pink[200] : green[400];
        item = <Arbitration {...props} perms={perms} />
        break;
      case 'Arb':
        status.text =  props?.contents?.fields?.indemnity === null ? 'In arbitration': 'Conclusion' ;
        status.color = props?.contents?.fields?.indemnity === null ? pink[200] : green[400];
        item = <Arb {...props} perms={perms} arbitration={ArbitrationContents} OrderType={OrderType}/>
        bSetting = false;
        break;
    };
    return (
      <>
        <Header {...props} status={status} type={type} bSetting={bSetting}/>
        {bDescription && <Description description={description}/>}
        {item}
      </>);    
  }
}

