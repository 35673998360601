import React, { useEffect, useMemo, useState } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { useTheme} from '@mui/material/styles';
import { Box, Chip, Button, Tooltip, Paper, IconButton, Autocomplete, TextField, Avatar, ListItem, Tabs, Tab, List, ListItemButton, 
    ListItemAvatar, Checkbox, ListItemText, Modal, Typography, ClickAwayListener  } from '@mui/material';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import MaterialTable, {MTableToolbar} from "material-table";
import AddIcon from '@mui/icons-material/Add';
import { grey } from '@mui/material/colors';
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from '@mui/icons-material/Delete';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import { PermissionInfo, PermissionInfoType, Permission, Permission_Index, IsValidAddress } from 'wowok';
import { SettingTitle, SettingLaunch, SettingInputText, SettingTips, Validator, SettingAddressChips, ADDRESS_VALIDATOR,
    PersonalPermissionModule, PersonalPermissionDescription
 } from '../Settings';
import { Address } from '../../util/Address';
import { Icons } from '../../util/Icons';
import Dialog from '@mui/material/Dialog';
import { Pannel, a11yProps,  } from '../../util/Common';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Transition } from '../../util/Common';
import { Transaction as TransactionBlock } from '@mysten/sui/transactions';
import { PermIcon } from '../../util/Icons';
import { generateRandomString } from '../../../util';

export default function PermissionEntity (props:any) {
    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme()

    const id = props?.contents?.fields?.id?.id ?? '';
    const bAdmin = (props?.contents?.fields?.admin as string[])?.includes(wallet?.address ?? '');
    const perm = {object:id,  permission:bAdmin, admin:bAdmin};

    let len = props.dynamic.length;
    len = len >= 20 ? 20 : (len <= 5 ? 5 : 10);

    let personal: PermissionInfoType[] = props.contents.fields.user_define.fields.contents.map((v:any) => {
        return {index: parseInt(v.fields.key), name:v.fields.value, description: PersonalPermissionDescription, module:PersonalPermissionModule}
    });
    // all permission = wowok + personal
    personal = personal.concat(PermissionInfo);

    const [openNewModal, setOpenNewModal] = useState(false);
    const [addPermission, setAddPermission] = useState<PermissionInfoType[]>([]);
    const [newEntity, setNewEntity] = useState<string[]>([]);
    const [currentEntity, setCurrentEntity] = useState('');
    const [nextEntity, setNextEntity] = useState('');
    const [selects, setSelects] = useState<PermissionInfoType[]>([]);

    const ValidChangeEntity: Validator = {
        validator:(value:string) => { 
            if (value) {
                let f = props?.dynamic?.find((v:any)=>v.name.value === value);
                return IsValidAddress(value) && f === undefined;
            }; return false;
        },
        err:'Address invalid or already exists in this permission'
    };

    const launch = async (op:'add_ent' | 'remove_ent' | 'change_ent' | 'add_perm' | 'remove_perm' | 'guard_perm',  
        entity?:string, param?:any) => {
        if (!wallet.connected) {
            enqueueSnackbar('Please login wallet', { variant: "error" });
            document.getElementById('header-wallet-cmd')?.click();
            return ;
        }

        const txb = new TransactionBlock();
        const obj = Permission.From(txb, id);

        try {
            if (op === 'add_ent') {
                obj.add_entity2(newEntity);
            } else if (op === 'remove_ent') { 
                if (!entity) return ;
                obj.remove_entity([entity]);
            } else if (op === 'change_ent') { 
                if (!currentEntity || !nextEntity) return ;
                obj.change_entity(currentEntity, nextEntity);
            } else if (op === 'add_perm') {
                if (!param || !entity) return;
                const p : Permission_Index[] = (param as PermissionInfoType[]).map((v) => { return {index:v.index} } );
                obj.add_entity([{entity_address:entity, permissions:p}]);
            } else if (op === 'remove_perm') {
                if (!param || !entity) return;
                obj.remove_index(entity, (param as PermissionInfoType[]).map((v) =>v.index));
            } else if (op === 'guard_perm') {
                if (!param || !entity) return;
                obj.set_guard(entity, param.index, param.guard);
            } else {
                return
            }

            props.exe(generateRandomString(8), txb);
        } catch (e) {
            console.error('excute error: ', e);
            enqueueSnackbar('Launch failed', { variant: "error" });
        }
    }

    const [entityDialogValue, setEntityDialogValue] = useState(-1);
    return ( <Box className='noboxshadow' sx={{padding:'0 2em'}}>
        <MaterialTable title=''
        columns={[
        { title: '', field: 'index', type: 'numeric', align:'left', width:'1%', render:(data, type) => {
            return <div style={{fontStyle:'italic', color:grey[400], fontWeight:200}}>{data.index}</div>
        }},

        { title: 'Entity', field: 'entity', cellStyle:{paddingRight:0, paddingLeft:0}, render:(data, type) => {
            return (<div style={{display:'flex', alignItems:'center'}}> 
                <Address address={data.entity} />  
                <Tooltip title={<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
                    <span>Delete or Transfer all permissions</span> 
                    <PermIcon {...perm} />
                    </div>}>  
                    <span>
                    <IconButton  className='cmdText'  sx={{ml:'.4em', padding:'3px'}} 
                        disabled={!bAdmin} onClick={(e) => {
                        setCurrentEntity(data.entity);
                        setNextEntity(data.entity);
                        setEntityDialogValue(0);
                    }}>
                    <DeleteIcon  className='cmdText' sx={{color:grey[500], height:'.6em!important', width:'.6em!important'}} />
                    </IconButton></span>
                </Tooltip>
            </div>)
        }, customFilterAndSearch: (term, rowData, ref) => {
            if (rowData.entity.toLowerCase().includes(term.toLowerCase())) {
                return true
            }; return false
        }},
        { title: 'Permission', field: 'permission', render:(data, type) => {
            const per: PermissionInfoType[] = [];
            data.permission?.forEach((p:any) => {
                const key = p.fields.key;
                const value = p.fields.value;
                const i = personal.find((v:PermissionInfoType) => v.index.toString() === key as string);
                if (i) {
                    per.push({index:parseInt(key), guard:value??'', module:i.module, name:i.name, description:i.description});
                }
            })
            return (<Permissions info={perm} perms={per} handleRemove={(perm:PermissionInfoType) => launch('remove_perm', data.entity, [perm])} 
                handleAdd={() => { 
                    setCurrentEntity(data.entity);
                    setSelects([]);
                    setAddPermission(personal.filter((v:PermissionInfoType)=>per.find((i)=>i.index == v.index) === undefined)) 
                }}
                handleGuard={(perm:PermissionInfoType) => {
                    if (perm.guard && !IsValidAddress(perm.guard)) {
                        enqueueSnackbar('Guard address invalid', { variant: "error" });
                        return
                    }
                    if (!data.entity) {
                        enqueueSnackbar('Entity address invalid', { variant: "error" });
                        return     
                    }
                    launch('guard_perm', data.entity, {index:perm.index, guard:perm.guard});
                }}/>)
        }, customFilterAndSearch: (term, rowData, ref) => {
            for (let j = 0; j < rowData.permission.length; j ++) {
                const key = rowData.permission[j].fields.key as string;
                const value = rowData.permission[j].fields.value;
                const t = term.toLowerCase();

                // guard
                if (value && value.toLowerCase().includes(t)) {
                    return true
                }

                // index, module and name
                const i = personal.find((v:PermissionInfoType) => v.index.toString() === key);
                if (i && (i.module.toLowerCase().includes(t) || i.name.toLowerCase().includes(t) ||
                    i.index.toString().toLowerCase().includes(t))) {
                    return true;
                }
            };
            return false;
        }}, 
        ]}
        data={props?.fields?.map((v:any, i:number) => {
            return {index:i+1, entity:v.data.content.fields.name, permission:v.data.content.fields.value.fields.value.fields.contents}
       })}   
        options={{
            padding: "dense",
            pageSize: len, 
            pageSizeOptions: [5, 10, 20],
        }}
        components={{
            Header: (props) => {
              return (<TableHead {...props} >
                <TableRow>
                  <TableCell variant='head'>{props.columns[0].title}</TableCell>
                  <TableCell variant='head'>
                    <div style={{display:'flex', justifyContent:'center', marginRight:'1em', }}>
                      <div>{props.columns[1].title}</div>
                      <div><Tooltip title='' arrow placement='right'>
                      <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                      </Tooltip></div>                      
                    </div>
                  </TableCell>
                  <TableCell variant='head' width='100%' align='center'>
                    <div style={{display:'flex', justifyContent:'center', marginRight:'1em', }}>
                      <div>{props.columns[2].title}</div>
                      <div><Tooltip title='' arrow placement='right'>
                      <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                      </Tooltip></div>                      
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>)
            }, Toolbar: (toolbar_props) => (
                <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                    <Button variant="contained" sx={{textTransform:'none', padding:'.2em .8em', alignItems:'center', display:'flex'}} 
                                disabled={!bAdmin} onClick={ () => {
                                    setOpenNewModal(true);
                            }}>{<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
                            <span>+ Add Entity</span> 
                            <PermIcon {...perm} />
                            </div>}
                    </Button>
                    <MTableToolbar {...toolbar_props} />
                </div>
            ),
        }}/>  
        <Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth={'md'} disableRestoreFocus
            open={entityDialogValue >= 0} TransitionComponent={Transition} keepMounted id='entity-dialog'
            onClose={()=>setEntityDialogValue(-1)}
            >
            <DialogTitle sx={{textAlign:'center'}} > <div style={{display:'flex', alignItems:'center'}}>
                <Tabs value={entityDialogValue} onChange={(event: React.SyntheticEvent, newValue: number)=>{setEntityDialogValue(newValue)}} >
                    <Tab label="Delete"  {...a11yProps(0)}/>
                    <Tab label="Transfer"   {...a11yProps(1)}/>
                </Tabs>
                <IconButton className='cmdText' sx={{marginLeft:'auto', marginTop:'-.2em'}} onMouseDown={()=>{setEntityDialogValue(-1)}}> <CloseIcon /> </IconButton>  
            </div> 
            </DialogTitle>
                <DialogContent sx={{minHeight:'22em'}}>
                <Pannel value={entityDialogValue} index={0}>
                    <div>
                    <SettingTips text='All permissions for this entity would be Deleted.'/>
                    <SettingLaunch text='Delete Entity' event={(type, value, id) => {
                        if (type === 'click') launch('remove_ent', currentEntity); 
                    }}/></div>
                </Pannel>
                <Pannel value={entityDialogValue} index={1}>
                    <div>
                    <SettingTips text='All permissions for this entity would be Translated to the new entity.'/>
                    <SettingInputText maxlength={66} value={nextEntity} placeholder='Input new entity address'  
                        id='change-entity-input' 
                        autoFocus={true}  event={(type, value, id) => {
                            setNextEntity(value);
                        }}
                        validator={ValidChangeEntity} />
                    <SettingLaunch text='Transfer to' event={(type, value, id) => {
                        if (!ValidChangeEntity.validator(nextEntity)) {
                            document.getElementById('change-entity-input')?.focus();
                            return
                        }
                        setEntityDialogValue(-1);
                        launch('change_ent');
                    }}/></div>
                </Pannel>
                </DialogContent> 
            </Dialog>  
        <Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth={'sm'} disableRestoreFocus
            open={addPermission.length > 0} TransitionComponent={Transition} keepMounted id='addperms-dialog'
            onClose={()=>setAddPermission([])}
            >
            <DialogTitle sx={{textAlign:'center'}} > <div style={{display:'flex', alignItems:'center'}}> Add Permission for&nbsp; <Address address={currentEntity}/>
                <IconButton className='cmdText' sx={{marginLeft:'auto', marginTop:'-.2em'}} onMouseDown={()=>setAddPermission([])}> <CloseIcon /> </IconButton>  
            </div> 
            </DialogTitle>
                <DialogContent sx={{minHeight:'22em'}}>
                <List sx={{ width: '100%', bgcolor: 'background.paper', maxHeight:'10em', pd:0}}>
                { addPermission.map((v:PermissionInfoType) => {
                    const labelId = `checkbox-list-secondary-label-${v.index}`;
                    return (
                    <ListItem key={v.index} component="div" disablePadding sx={{padding:0}}
                        secondaryAction={
                        <Checkbox edge="end" inputProps={{ 'aria-labelledby': labelId }}
                            onChange={(e) => {
                                const s = selects.filter((i)=>i.index != v.index);
                                if (s.length === selects.length) { // not found
                                    setSelects([...selects, v]);
                                } else {
                                    setSelects(s);
                                }
                                e.stopPropagation();
                            }}
                            checked={selects.find((i)=>i.index === v.index) !== undefined}
                        />
                        }
                    >
                        <ListItemButton sx={{color:selects.find((i)=>i.index === v.index) !== undefined ? theme.palette.primary.main : undefined}}>
                        <ListItemAvatar>
                            <Avatar alt={v.module} style={{ marginRight: "8px" , width:36, height:36, padding:4, backgroundColor:'transparent'}} ><Icons type={v.module} /></Avatar>
                        </ListItemAvatar>
                        <ListItemText >
                            <div >{v.name + ' '} <span style={{fontStyle:'italic', color:grey[400]}}>{v.index}</span></div>
                            <div style={{color: selects.find((i)=>i.index === v.index) !== undefined ? theme.palette.primary.main : grey[600], whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}>{v.description}</div>                            
                        </ListItemText>
                        </ListItemButton>
                    </ListItem>
                    );
                })}
                </List>
            </DialogContent> 
            <DialogContent >
                <div style={{color:theme.palette.primary.main}}>{selects.length + ' selected'}</div>
                <SettingLaunch text='Add Permission' marginTop={'1em'} event={(type, value, id) => {
                    if (selects.length === 0)    {
                        enqueueSnackbar('Please select permissions to add', { variant: "error" });
                        return;
                    }
                    launch('add_perm', currentEntity, selects);
                }}/>                
            </DialogContent>

        </Dialog>
        <Modal disableRestoreFocus  open={openNewModal} onClose={(event, reason)=> {
            setOpenNewModal(false)
        }}>
            <Paper sx={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', width:'42em', p:'1em 2em 2em 2em',
                boxShadow:'none', border:'2px solid', borderColor:theme.palette.primary.main,
            }}>
                <IconButton sx={{float:'right'}} onMouseDown={()=>{setOpenNewModal(false)}}>
                    <CloseIcon />
                </IconButton>
                <Box width='100%' sx={{textAlign:'center'}}>
                        <Typography variant="h5">Add Entity</Typography>
                </Box>   
                <div style={{marginTop:'2em'}}>
                    <SettingTitle required title='Entity addresses' 
                        tips=' '/>
                        
                    <SettingAddressChips placeholder='Input address and press Enter'  required='Please input address and press Enter' autoFocus={true}
                        id='entityModal-address' maxTags={Permission.MAX_ENTITY_COUNT-props?.dynamic?.length} maxTags_err='Max Entity count' 
                        initValue={newEntity}  {...props}  event={(type, value, id) => {
                                setNewEntity(type==='value'?value:[]);
                            }}/>
                    <button id={props?.id} className='cmdButton' style={{marginTop:'1.5em'}} onClick={()=>{
                        if (newEntity.length === 0) {
                            document.getElementById('entityModal-address')?.focus();
                            return 
                        }
                        setOpenNewModal(false);
                        launch('add_ent');
                    }}>Add Entity</button>                    
                </div>
            </Paper>                
        </Modal>   
    </Box>
)}

interface GuardSet {
    guard: string;
    index: number;
}

function Permissions(props:any) {
    // console.log(props)
    const theme = useTheme()
    const perms: PermissionInfoType[] = [{index:-1, name:'', description:'', module:''}, ...props.perms];
    const [hidechip, setHidchip] = useState(true);
    const [open, setOpen] = useState(false);
    const [current, setCurrent] = useState<GuardSet|undefined>(undefined);

    return (<ClickAwayListener onClickAway={(e)=> {
        setHidchip(true)
    }}>     
     <Autocomplete disableClearable size='small' 
        options={[]} multiple disableListWrap forcePopupIcon={false} noOptionsText
        value={perms}
        onChange={(event, newValue, reason) => {
        }}
        renderInput={(params) => (
          <TextField /*onClick={()=> props.handleAdd()}*/ 
            {...params}
            sx={{ "& fieldset": { padding:'8px',  borderColor:'rgba(232,167,213,0.36)!important'},
            "&:hover fieldset": { borderColor:theme.palette.primary.main + '!important'},
            input: {cursor:'pointer', display:'none'}}}
          />
        )}
        renderTags={(tagValue, getTagProps) => {
            const count = 9;
            const show = hidechip ? tagValue.slice(0, count): tagValue;

            return (
                <div>
                {show.map((option, index) => {
                    if (option.index < 0)  {
                        return (<Tooltip title={<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
                            <span>Add permissions</span> 
                            <PermIcon {...props.info} />
                            </div>}><span>
                            <IconButton className='cmdText' disabled={!props.info.admin} onClick={() => {
                                props.handleAdd();
                            }}>
                                <AddIcon className='cmdText' sx={{width:'.8em', height:'.8em', color:theme.palette.primary.main}}/>
                            </IconButton></span>
                        </Tooltip>)
                    } else { return (<>
                        <Chip {...getTagProps({ index }) } 
                            label={<Tooltip title={option.index + ': ' + option.description}><span style={{fontSize:'1.1em'}} onMouseDown={() => {
                                setCurrent({guard:option?.guard ?? '', index:option.index});
                                setOpen(true);
                            }}>{option.name}</span></Tooltip>} 
                            avatar={<Avatar style={{ width:16, height:16, padding:'2px 0 4px 2px' ,backgroundColor:'transparent'}}
                            alt={option.module}><Icons type={option.module} /></Avatar>} 
                            sx={{mr:'.6em', mb:'.2em', backgroundColor:option?.guard?'rgba(66, 165, 245, 0.2)':grey[200], color:'black'}}
                            deleteIcon={    <Tooltip title={<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
                                <span>Remove Permission</span> 
                                <PermIcon {...props.info} />
                                </div>}><span>
                                <IconButton className='cmdText' disabled={!props.info.admin} sx={{pl:0, pt:0, pr:0, pb:0, mb:'.1em'}}>
                                    <CloseIcon sx={{width:'.8em', height:'.8em'}}/>
                                  </IconButton></span></Tooltip>}
                            onClick={ (e)=> {
                            // props.handlePermission('guard_perm', entity, option);
                            e.stopPropagation();
                        }} onDelete={(event) => {
                            if (props.info.admin) {
                                props.handleRemove(option);
                            }
                        }}/>
                        <Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth={'md'} disableRestoreFocus
                            open={open} TransitionComponent={Transition} keepMounted id='entity-guard'
                            onClose={()=>setOpen(false)}
                            >
                            <DialogTitle sx={{textAlign:'center'}} > Set Guard for Permission
                                <IconButton sx={{float:'right', marginTop:'-.2em'}} onMouseDown={()=>{setOpen(false)}}> <CloseIcon /> </IconButton>  
                            </DialogTitle>
                            <DialogContent sx={{minHeight:'12em'}}>
                                <SettingTitle title='Guard Address'/>
                                <SettingInputText validator={ADDRESS_VALIDATOR} maxlength={66} value={current?.guard ?? ''} 
                                placeholder='Enter guard address' event={(t,v,_) => {
                                    if (current) {
                                        current.guard = v;
                                        setCurrent({...current});
                                    }
                                }}/>
                                <SettingLaunch text='Launch' event={(t, v, _) => {
                                    if (t === 'click' && current) {
                                        props.handleGuard(current);
                                        setOpen(false);
                                    }
                                }} />
                            </DialogContent>
                        </Dialog>
                        </>)
                    }})}
                    {
                        hidechip && tagValue.length > show.length && 
                            <Tooltip title='Click to show more permissions' arrow>
                                <div style={{display:'inline-block', marginLeft:'.4em', fontSize:'1.1em', cursor:'pointer'}} onClick={() => {
                                    setHidchip(false);
                                }}>{'+' + (tagValue.length - show.length)}</div>                                  
                           </Tooltip>
                    }
                </div>);
        }}
        PaperComponent={(paperProps:any) => null}
      /></ClickAwayListener>)
}
