
import React, { useState } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { IconButton, Typography } from '@mui/material';
import NearMeIcon from '@mui/icons-material/NearMe';
import Head from '../../app/Header';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function Basic (props:any) {
    return (
        null
    );
}
export default function PostSetting (props:any) {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    return (
          <Paper sx={{ width: '100%'}}>
            <div style={{padding:'8px 16px', fontWeight:600, fontSize:'1.1em', color: theme.palette.primary.main}}>Launch Reward</div>
              <Tabs value={value} onChange={handleChange} aria-label="reward" >
                <Tab label="Basic" {...a11yProps(0)} sx={{textTransform:'none'}}/>
                <Tab label="Guard" {...a11yProps(1)} sx={{textTransform:'none'}}/>
              </Tabs>
            <Pannel value={value} index={0}>
                <Box sx={{width:'100%', height:'40em'}}>
                    <></>
                </Box>
            </Pannel>
            <Pannel value={value} index={1}>
              ...soon
            </Pannel>
        </Paper>
    );
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
function Pannel(props: TabPanelProps) {
  const { children, value, index,  ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ width:'100%' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

  
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}