
import '../../css/Launch.css';
import '../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import NearMeIcon from '@mui/icons-material/NearMe';
import { Button } from '@mui/material';

  
export default function Launch (props:any) {
    document.getElementById('root')!.style.transform = 'none';
    document.getElementById('root')!.style.paddingLeft = 'calc(100vw - 100%)'; // 右侧出现滚动条不跳动
    return (
        <div className='app-launch'>
            <div className=' Block'>
                <div className='titleTxt infoTxt'>
                <strong>Express</strong> your personalized Demand 
                </div>
                <div className='Demand-content'>
                    <div className='Demand-left'>
                             <div className='Demand-left-eg egBkgnd'>
                            <p className='Demand-eg-title'>Bonus $100 if you can plan my Kenya trip!</p>
                            <p className='Demand-eg-content'>· Trip to the Great Migration in Kenya</p>
                            <p className='Demand-eg-content'>· Professional and insightful guide </p>
                            <p className='Demand-eg-content'>· Accommodation close to nature</p>
                            <p className='Demand-eg-content'>· Cheerful and relaxing itinerary</p>
                        </div>
                        <div className='Command' ><button className='cmdButton' onClick={()=>window.location.href = '/demand'}>Launch Demand</button></div>
                    </div>   
                    <div className='Demand-right'>
                    </div>                 
                </div>
            </div>
            <div className='Block background1 '>
                <div className='titleTxt infoTxt'>
                    <strong>Service:</strong> Consensus on interactive processes, pricing and dispute resolution
                </div>
                <div style={{display:'flex'}}>
                    <div style={{width:'36em'}}>
                        <p className='t1'> · Easy and powerful </p>
                        <p className='t2'> You can implement complex multi-party collaboration contracts, such as Amazon and Ctrip, by using objects like Machine, Permission, Repository, and so on. </p>
                        <p className='t1'> · Boundless </p>
                        <p className='t2'> A vendor can seamlessly combine with other vendors to achieve services that better meet requirements, through mechanisms such as parent-child processes. </p>
                        <p className='t1'> · AI-friendly </p>
                        <p className='t2'> Easily for AI to retrieve and use services because data and collaboration are consensual, visible, and immutable on blockchain.</p>
                        <div className='Command' ><button className='cmdButton' onClick={()=>window.location.href = ('/service')}>Launch Service</button></div>   
                    </div>
                    <div className='Service-Content-Img'>
                    </div>
                </div>  
            </div>
            <div className='Machine Block  '>
                <div className='titleTxt infoTxt'>
                        <strong>Machine:</strong> The map of the collaboration that generates Progress instances
                </div>
                <div style={{display:'flex'}}>
                    <div style={{width:'54%'}}>
                    <p className='t1'> · Embodiment of the business </p>
                        <p className='t2'> Templates the definition of each state, operation, permission, and delivery criteria for the business, and in turn provides Progress instances for different customers. </p>
                        <p className='t1'> · Universality </p>
                        <p className='t2'>  Task parallelism, operation weights and thresholds, parent-child Progress; Using the blockchain data space through Guard to do delivery checks. </p>
                        <p className='t1'> · Flexibility    </p>
                        <p className='t2'>  In addition to intra-organization Permission object, support for each Progress's unique permission namespaces (such as customers or third-party partners). </p>   
                        <div className='Command'><button className='cmdButton' onClick={()=>window.location.href = ('/machine')}>Launch Machine</button></div>  
                    </div>
                    <div className='Machine-Content-Img'></div>
                </div>
                 
            </div>
            <div className=' Block background1' style={{marginBottom:'3em'}}>
            <div className='titleTxt infoTxt'>
                        <strong>Guard:</strong> Executable real-time data condition judgment
                </div>
                <div className='Guard-Content' >
                <div className='Guard-Content1' style={{width:'16em'}}>
                    <div className='Guard-Content1-img'></div>
                    <p className='t3'>Borderless data</p>
                    <p className='t4'>Data on-chain and data needed from the verified person</p>
                </div>
                <div className='Guard-Content2' style={{width:'18em'}}>
                    <div className='Guard-Content2-img'></div>
                    <p className='t3'>Guard condition </p>
                    <p className='t4'>Various query methods and logical judgment conditions</p>   
                </div>
                <div className='Guard-Content3' style={{width:'22em'}}>
                    <div className='Guard-Content3-img'></div>
                    <p className='t3'>Consensus protection</p>
                    <p className='t4'>immutably after launch to ensure the certainty of consensus protection</p>
                </div>
                </div>
                <div className='Command' >
                    <button className='cmdButton' onClick={()=>window.location.href = ('/guard')}>Launch Guard</button>
                </div>   
            </div>
            <div className='Block '>
                <div className='titleTxt infoTxt'>
                    <strong>Treasury</strong> manages funds, provides withdrawals to those who meet the Guard conditions
                </div>
                <div className='Reward'>
                        <div style={{display:'flex', marginTop:'1em', alignItems:'center', paddingBottom:'1em', paddingRight:'2em'}}>
                            <div className='Reward-content1-img'></div>
                            <div className='Reward-content1-txt' style={{marginLeft:'1em'}}>
                            <p>1,000 USTD</p>
                            <p>(7/10)</p>
                            </div>
                        </div>
                        <div className='egBkgnd' style={{marginTop:'2em', marginBottom:'1em', paddingTop:'0.6em!important', 
                            marginLeft:'8em', padding:'1em 2em'
                        }}>
                            <p>Claimed by</p>
                            <p>0x23de45      500 USTD   (5 portions) </p>
                            <p>0xe7d2a8      200 USTD   (2 portions) </p>
                        </div>
                </div>
                <div className='Command' ><button className='cmdButton' onClick={()=>window.location.href = ('/treasury')}>Launch Treasury</button></div>
            </div>
            <div className='Wrapper2 '>
                <div className='common_object'>
                    <span className='infoTxt'>Manage </span> 
                    personnel and all operation permissions; finer granularity definition by Guard, if necessary.
                    <p className='t5' onClick={()=>window.location.href = ('/permission')}>Start managing objects&nbsp;<NearMeIcon sx={{color:'#A6008B'}}/></p>
                </div>
                <div className='common_object'>
                    <span className='infoTxt'>Store </span> 
                    and manage different types of data; and achieve data consensus through policy.
                    <p className='t5' onClick={()=>window.location.href = ('/repository')}>Build my Repository&nbsp;<NearMeIcon sx={{color:'#A6008B'}}/></p>
                </div>
            </div>
            <div className='Wrapper2' style={{marginBottom:'4em'}}>
                <div className='fullwidth_object'>
                    <span className='infoTxt'>Arbitration </span> 
                    provides services for the public and traders to participate in disputed orders, and the arbitration results can be used as the basis for compensation of orders.                    <p className='t5' onClick={()=>window.location.href = ('/arbitration')}>Build Arbitration&nbsp;<NearMeIcon sx={{color:'#A6008B'}}/></p>
                </div>
            </div>
        </div>
    );
}