
import { Protocol, IsValidAddress, ResolveU64 } from "wowok";

export const sliceAddress = (addr:string | undefined) => { 
    if (!addr) return ''
    return '0x'+addr.slice(2, 8).toUpperCase() 
}

export const simpleAddress = (addr:string | undefined) => {
    if (!addr) return ''
    return addr.slice(0, 8) + '...' + addr.slice(62);
}
export const simpleName = (name: string) => {
    if (name.length > 8) {
        return name.slice(0, 3) + '..' + name.slice(name.length-3)
    }; return name;
}
export const sliceType = (type:string | null | undefined) => {
    if (!type) return '';

    let t = type.indexOf('<');
    if (t >= 0) { 
        type = type.slice(0, t);
    }
    let arr = type.split('::');
    if (arr.length !== 3 || !IsValidAddress(arr[0])) {
        return type;
    }
    
    return simpleAddress(arr[0]) + '::' + simpleName(arr[1]) + '::' + simpleName(arr[2])
}
export const isCoin = (type:string | null | undefined) : boolean => {
    const i = sliceType(type)
    return i === '0x2::coin::Coin' || i === '0x0000000000000000000000000000000000000000000000000000000000000002::coin::Coin'
}
export const SplitBalance = (balance: bigint, portions: number, bRandom:boolean) : number[]=> {
    if (balance < BigInt(portions) || portions <= 0) return [];
    const ret : number[] = [];
    const split = balance / BigInt(portions);

    for (let i = 0; i < portions; ++i) {
        const r = Number(split);
        ret.push(r);
        balance = balance - BigInt(r);
    }

    if (bRandom && balance > 0) {
        const lucky = Math.floor(Math.random() * (portions + 1));
        ret[lucky] = Number(BigInt(ret[lucky]) + balance);
    } 
    return ret;
}

export const sliceTypeCoin = (type:string | null | undefined) => {
    if (!type) return '';
    const c1 = '0x2::coin::Coin<'; const c2 = '0x0000000000000000000000000000000000000000000000000000000000000002::coin::Coin<';
    const f1 = type.indexOf(c1); const f2 = type.indexOf(c2); 
    if (f1 === 0) {
        return type.slice(c1.length, type.length-1)
    }
    if (f2 === 0) {
        return type.slice(c2.length, type.length-1)
    }
    return ''
}
export function addProperty<T, K extends string, V>(obj: T, key: K, value: V) {
    (obj as Record<K, V>)[key] = value;
}

export function generateRandomString(length: number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      const randomChar = characters.charAt(randomIndex);
      result += randomChar;
    }
  
    return result;
}
const APP_VERSION = 'V1';
export function store_launch_graph(sense:string) {
    return sense+APP_VERSION;
}

export function store_key_graph(id:String, sense:string) {
    return id+Protocol.Instance().Package('wowok')+sense+APP_VERSION;
}
export function store_key_machine_nodes(id:string) {
    return id+Protocol.Instance().Package('wowok')+'machine_nodes'+APP_VERSION;
}
const OBJECT_TYPE_KEY = (id:string|undefined) => {
    if (!id) return '';
    return id + Protocol.Instance().Package('wowok') + 'object_type';
}
export const store_object_type = (id:string, type:string)  => {
    localStorage.setItem(OBJECT_TYPE_KEY(id), type);
}
export const object_type = (id:string) => {
    const key = OBJECT_TYPE_KEY(id);
    if (!key) return '';

    const type = localStorage.getItem(key);
    if (type !== null) { // '' | null | string
        return type
    } return ''
}
export const load_object_type = async (id:string,  bFetch:boolean=false) : Promise<string> => {
    const key = OBJECT_TYPE_KEY(id);
    if (!key) return '';

    const type = localStorage.getItem(key);
    if (type !== null) { // '' | null | string
        return type
    } else if (bFetch) {
        try {
            const res = await Protocol.Client().getObject({id:id, options:{showType:true}});
            if (res?.data && (res?.data as any)?.type) {
                store_object_type(res.data?.objectId, (res?.data as any)?.type);
                return (res?.data as any)?.type;                  
            }
        } catch (e) {
            console.log(e)
        }
    } return ''
}

interface QueryTypeData {
    id: string;
    type?: string;
    onData:(id:string, type:string)=>void;
}

var QUERY_TYPES_DATA : QueryTypeData[] = [];
var ERR_FLAG = false;

const query_type_helper = () => {
    const copy = [...QUERY_TYPES_DATA.splice(0, 30)];
    Protocol.Client().multiGetObjects({ids:copy.map((v)=>v.id), options:{showType:true}}).then((res) => {
        ERR_FLAG = false;
        res.forEach((i) => {
            if (i?.data) {
                store_object_type(i.data?.objectId, (i?.data as any)?.type);
                copy.find((j) => j.id === i.data?.objectId)?.onData(i.data?.objectId, (i?.data as any)?.type);
            }
        })
    }).catch((e) => {
        ERR_FLAG = true;
        console.log(e)
        QUERY_TYPES_DATA = QUERY_TYPES_DATA.concat([...copy]);
    })
}
export const load_object_type_string = (id:string, onData?:(id:string, type:string)=>void) : string => {
    const key = OBJECT_TYPE_KEY(id);
    if (!key)   return ''

    const type = localStorage.getItem(key);
    if (type) {
        return type;
    } else if (onData) {
        const f = QUERY_TYPES_DATA.find((v)=>v.id === id);
        if (f) return '';
        QUERY_TYPES_DATA.push({id:id, onData:onData});

        if (QUERY_TYPES_DATA.length >= 30) { // fretch immdiat
            query_type_helper();
        } else {
            setTimeout(() => {
                query_type_helper();
            }, ERR_FLAG?100000:3000);
        }
    } 
    return ''
}


var INDEX = 0;
export function Index() { return INDEX++}

export const PermissionShow = (permission?:string|number, namedOperator?:string) : string => {
    return (permission? permission:'') + 
    ((permission && namedOperator) ? '/' : '') +
    (namedOperator? namedOperator : '');
}

