
import React, { useState } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { useTheme} from '@mui/material/styles';
import { Box, Button, } from '@mui/material';
import { PermissionAnswer, PermissionIndex, Demand as WowokDemand, PermissionIndexType, Passport as WowokPassport,} from 'wowok';
import { SettingTips, } from '../Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { make_permIconProp } from '../PermCheck';
import { generateRandomString } from '../../../util';
import { Clock } from '../../demand/Demand';
import Countdown, {CountdownTimeDelta} from 'react-countdown';
import { PermIcon } from '../../util/Icons';
import { Transaction as TransactionBlock } from '@mysten/sui/transactions';

export default function Refund (props:any) {
    const id = props?.contents?.fields?.id?.id ?? '';
    const type = WowokDemand.parseObjectType(props?.type);
    let time = props.contents.fields.time_expire ??  999999999999999;
    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();
    const answer: PermissionAnswer | undefined = props?.perms;
    const permission  = props?.contents?.fields?.permission;
    const [bComplete, setComplete] = useState(false);

    const OnCountdownComplete = (timeDelta: CountdownTimeDelta, completedOnStart: boolean) => {
        setComplete(true)
    }

    const launch = async () => {      
        if (!wallet.connected) {
          enqueueSnackbar('Please login wallet', { variant: "error" });
            document.getElementById('header-wallet-cmd')?.click();
            return 
        }
          
        const txb = new TransactionBlock();
        const obj = WowokDemand.From(txb, type, permission, id);
        const pid = generateRandomString(8);
        props.PermissionCheck({id:pid, txb:txb, answer:answer, index:[PermissionIndex.demand_refund], handler:
          (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:WowokPassport) => {
            try {
              if (id === pid) {
                if (index.includes(PermissionIndex.demand_refund)) {
                  obj.refund(passport?.get_object());
                };  
                passport?.destroy(); // destroy passport
                props.exe(id, txb);            
              }
            } catch (e) {
              console.log(e)
              enqueueSnackbar( 'Launch Failed', { variant: "error" });
            }
          }
        });
    }
    
    const disabled = !bComplete || props.contents.fields.bounty.length === 0;
    return (
        <Box display="flex"   margin="0 2em " flexDirection="column" pb='3em'>
        <SettingTips text='Bounty Remained would be Refunded while time over.'/>
            <Box display="flex"  mt='1em' alignItems="center" flex="1 1 auto">
                <Countdown date={parseInt(time)} {...props} renderer={Clock} onComplete={OnCountdownComplete}/>
            </Box>
            <Button variant='contained' disabled={disabled} 
                sx={{mt:'2em', width:'8em', textTransform:'none', fontSize:'1.2em'}} onClick={() => {
                    launch();
                }}>{!disabled && <div style={{display:'flex', alignItems:'center'}}>
                    <span>Refund</span><PermIcon {...make_permIconProp(answer, PermissionIndex.demand_refund)}/>
                    </div>}{disabled && <span>Refund</span>}</Button>
        </Box>
    );  
}
