
import React, { useState, useEffect } from 'react';
import '../../css/Launch.css';
import '../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useNavigate, useLocation } from 'react-router-dom';
import Data from '../launch/repository/Data';
import { Protocol, RepositoryValueType, PermissionIndexType } from 'wowok';
import Policy, {PolicyData} from '../launch/repository/Policy';
import Details from './Details';
import { TabPanelProps, Pannel, a11yProps} from '../util/Common';


export default function RepositorySetting (props:any) {  
    const location = useLocation()
    var t = parseInt(location.hash.slice(1));
    if (t < 0 || t > 2 ||  isNaN(t))  { t = 0; }
    window.location.hash = '#' + t;

    const [data, setData] = useState<PolicyData[]>([]);

    const QueryPolicy = (count:number=0, cursor?:any) => {
      if (!props?.contents?.fields?.policies?.fields?.id?.id)
        return;
      
      count += 1;
      //@ 取4页
      Protocol.Client().getDynamicFields({parentId:props?.contents?.fields?.policies?.fields?.id?.id, cursor:cursor}).then((res:any)=>{
          Protocol.Client().multiGetObjects({ids:res.data.map((i:any)=>i.objectId), options:{showContent:true}}).then((policy) => {
              policy.forEach((v) => {
                  var f = data.find((i)=>i.name == (v?.data?.content as any)?.fields?.name);
                  if (f) {
                      f = {name:(v?.data?.content as any)?.fields?.name, description:(v?.data?.content as any)?.fields?.value?.fields?.description,
                          dataType:(v?.data?.content as any)?.fields?.value?.fields?.value_type as RepositoryValueType,
                          permission:(v?.data?.content as any)?.fields?.value?.fields?.permission_index as number}
                  } else {
                      data.push({name:(v?.data?.content as any)?.fields?.name, description:(v?.data?.content as any)?.fields?.value?.fields?.description,
                          dataType:(v?.data?.content as any)?.fields?.value?.fields?.value_type as RepositoryValueType,
                          permission:(v?.data?.content as any)?.fields?.value?.fields?.permission_index as PermissionIndexType,
                      });
                  }
              });
              setData([...data])
          });
          if (res.hasNextPage && count <= 4) {
              QueryPolicy(count, res.nextCursor);
          }
      })
  }

  useEffect(()=> {
      setData([]);
      QueryPolicy();
  }, []);

  const id = props?.contents?.fields?.id?.id ?? '';
  const [value, setValue] = React.useState(t);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    window.location.hash = '#' + newValue;
    setValue(newValue);
  };
    
    return (
      <Paper sx={{ width: '100%', pt:'.4em',}} >
              <Tabs value={value} onChange={handleChange} sx={{height:id ? '3em' : '3.8em', margin:'.4em 1em'}}>
                <Tab label='Data' {...a11yProps(1)} sx={{textTransform:'none', pb:'.2em'}}/>
                <Tab label="Policy"  {...a11yProps(2)} sx={{textTransform:'none', pb:'.2em'}}/>
                <Tab label="Promise"   {...a11yProps(3)} sx={{textTransform:'none', pb:'.2em'}}/>
              </Tabs>
            <Pannel value={value} index={0}>
              <Data {...props} policies={data}/>
            </Pannel>
            <Pannel value={value} index={1}>
              <Policy {...props} policies={data}/>
            </Pannel>
            <Pannel value={value} index={2}>
              <Details {...props} />
            </Pannel>
        </Paper>
    );
}