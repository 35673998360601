
import React, { useEffect, useState, ReactNode } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { useTheme} from '@mui/material/styles';
import { Box, Button, Tooltip, IconButton, Chip, Autocomplete, TextField, Paper, ClickAwayListener,
  Dialog, DialogContent, DialogTitle, 
} from '@mui/material';
import { IsValidAddress, Resource as WowokResource, Permission, ResourceData, MAX_NAME_LENGTH, IsValidName, Entity } from 'wowok';
import { ADDRESS_VALIDATOR, SettingInputText, SettingLaunch, SettingTitle, } from '../Settings';
import MaterialTable from "material-table";
import { grey } from '@mui/material/colors';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { MTableToolbar } from 'material-table';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import { Address } from '../../util/Address';
import EditIcon from '@mui/icons-material/Edit';
import { Transition } from '../../util/Common';
import DeleteIcon from '@mui/icons-material/Delete';
import { Transaction as TransactionBlock } from '@mysten/sui/transactions';
import { generateRandomString } from '../../../util';


export default function Resource (props:any) {  
    //console.log(props)
    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();

    let len = props?.resource.length;
    len = len >= 20 ? 20 : (len <= 5 ? 5 : 10);

    const launch = async (op:'remove_ent'  | 'add_ent' | 'add_mark' | 'remove_mark' | 'rename_mark',  param?:any) => {      
      if (!wallet.connected) {
        enqueueSnackbar('Please login wallet', { variant: "error" });
        document.getElementById('header-wallet-cmd')?.click();
        return 
      }
            
      try {
        const txb = new TransactionBlock()
        let resource : any;
        if (props?.info?.resource) {
          resource = WowokResource.From(txb, props?.info?.resource);
        } else {
          resource = WowokResource.From(txb, Entity.From(txb).create_resource2());
        }

        if (op === 'add_ent') {
          resource.add(param.name, param.address);
        } else if (op === 'remove_ent') {
          resource.remove(param.name, param.address);
        } else if (op === 'add_mark') {
          resource.add(newMarkName, []);
        } else if (op === 'remove_mark') {
          resource.remove(param, [], true);
        } else if (op === 'rename_mark') {
          resource.rename(currentMarkName, editMarkName);
        }
        if (!props?.info?.resource) {
          resource.launch();
        }
        
        props.exe(generateRandomString(8), txb)
      } catch (e) {
        console.log('excute error: ', e);
        enqueueSnackbar('Launch failed', { variant: "error" });
      }
    }

    const [openNewMark, setOpenNewMark] = useState(false);
    const [newMarkName, setNewMarkName] = useState('');
    const [currentMarkName, setCurrentMarkName] = useState('');
    const [editMarkName, setEditMarkName] = useState('');

    return ( <Box className='noboxshadow' sx={{padding:'0 2em'}}>
      <MaterialTable title=''
      columns={[
      { title: '', field: 'index', type: 'numeric', align:'left', width:'1em', render:(data, type) => {
          return <div style={{fontStyle:'italic', color:grey[400], fontWeight:200}}>{data.index}</div>
      }},
      { title: 'Mark', field: 'category', cellStyle:{paddingRight:0, paddingLeft:0}, render:(data, type) => {
          return (<div style={{display:'flex', alignItems:'center', maxHeight:'2.4em'}}> 
              <div style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', maxWidth:'10em'}}>{data.category}
              </div>  
              { data.category !== WowokResource.LikeName && data.category !== WowokResource.DislikeName && 
                data.category !== WowokResource.FavorName && data.category !== WowokResource.LaunchName &&
              <div style={{display:'flex'}}>
                <Tooltip title='Edit mark name' arrow placement='bottom'>
                    <IconButton className='cmdText' sx={{padding:'3px'}} onClick={() => {
                        setCurrentMarkName(data.category);
                        setEditMarkName(data.category);
                    }}>
                      <EditIcon sx={{width:'.6em!important', height:'.6em!important', ml:'.2em'}}/>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Delete mark name' arrow placement='bottom' onClick={() => {
                    launch('remove_mark', data.category);
                  }}>
                    <IconButton className='cmdText' sx={{padding:'3px'}}>
                      <DeleteIcon sx={{width:'.6em!important', height:'.6em!important', }}/>
                    </IconButton>
                  </Tooltip>  
              </div>}    

          </div>)
      }},
      { title: 'Entity', field: 'entity', render:(data, type) => {
          return (<Entities {...data} launch={launch}/>)
      }},
      ]}
      data={props.resource.map((v:ResourceData, i:number) => {
          return {index:i+1, category:v.name, entity:v.address}
     })}   
      options={{
          padding: "dense",  
          pageSize: len, 
          pageSizeOptions: [5, 10, 20],
      }}
      components={{
          Header: (props) => {
            return (<TableHead {...props} >
              <TableRow>
                <TableCell variant='head' width='1%'>{props.columns[0].title}</TableCell>
                <TableCell variant='head' style={{paddingLeft:0}}>
                  <div style={{display:'flex', justifyContent:'left',  }}>
                    <div>{props.columns[1].title}</div>
                    <div><Tooltip title='Mark catalog by subject' arrow placement='right'>
                    <HelpOutlineIcon sx={{width:'0.6em!important', height:'0.6em!important', paddingLeft:'0.1em', color:grey[500]}} />
                    </Tooltip></div>                      
                  </div>
                </TableCell>
                <TableCell variant='head' width='100%' align='center'>
                  <div style={{display:'flex', justifyContent:'center', marginRight:'1em', }}>
                    <div>{props.columns[2].title}</div>
                    <div><Tooltip title='Addresses in the Mark catalog' arrow placement='right'>
                    <HelpOutlineIcon sx={{width:'0.6em!important', height:'0.6em!important', paddingLeft:'0.1em', color:grey[500]}} />
                    </Tooltip></div>                      
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>)
          },  Toolbar: (toolbar_props) => (
            <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between'}}>
              <Button variant="contained" sx={{textTransform:'none', padding:'.2em .8em', alignItems:'center', display:'flex'}} 
                    onClick={ () => {
                      setNewMarkName('');
                      setOpenNewMark(true);
                }}>+ Add Mark</Button>
                <MTableToolbar {...toolbar_props} />
            </div>
        ),
        }}
    />       
    <Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth={'sm'} disableRestoreFocus
      open={currentMarkName.length > 0} TransitionComponent={Transition} keepMounted id='renameMark-dialog'
      onClose={()=>setCurrentMarkName('')}
      >
      <DialogTitle sx={{textAlign:'center'}} > Edit Mark Name
          <IconButton sx={{float:'right', marginTop:'-.2em'}} onMouseDown={()=>{setCurrentMarkName('')}}> <CloseIcon /> </IconButton>  
      </DialogTitle>
          <DialogContent >
            <SettingTitle title='Edit name of the mark' required />
            <SettingInputText maxlength={MAX_NAME_LENGTH/2} placeholder='Enter name of the mark' err_empty='Please enter name of the mark'
              id='editMark-input' value={editMarkName} event={(type, value, id) => {
                setEditMarkName(value);
              }}/>
              <SettingLaunch text='Rename Mark' event={(type, value, id) => {
                  if (type === 'click') {
                    if (!IsValidName(editMarkName)) {
                        enqueueSnackbar('Mark name invalid', { variant: "error" });
                        document.getElementById('editMark-input')?.focus();
                        return;
                    }
                    launch('rename_mark', editMarkName); 
                  }
              }}/>
          </DialogContent> 
      </Dialog>  
      <Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth={'sm'} disableRestoreFocus
      open={openNewMark} TransitionComponent={Transition} keepMounted id='newMark-dialog'
      onClose={()=>setOpenNewMark(false)}
      >
      <DialogTitle sx={{textAlign:'center'}} >Add Mark
          <IconButton sx={{float:'right', marginTop:'-.2em'}}  onMouseDown={()=>{setOpenNewMark(false)}}> <CloseIcon /> </IconButton>  
      </DialogTitle>
          <DialogContent>
            <SettingTitle title='Name of new mark' required />
            <SettingInputText maxlength={MAX_NAME_LENGTH/2} placeholder='Enter name of new mark' err_empty='Please enter name of new mark'
              id='newMark-input' value={newMarkName} event={(type, value, id) => {
                setNewMarkName(value);
              }}/>
              <SettingLaunch text='Add Mark' event={(t, v, d) => {
                    if (!IsValidName(newMarkName)) {
                        enqueueSnackbar('Mark name invalid', { variant: "error" });
                        document.getElementById('newMark-input')?.focus();
                        return;
                    }
                    launch('add_mark'); 
              }}/>
          </DialogContent> 
      </Dialog>  
    </Box>);  
}

function Entities(props:any) {
  const [pending, setPending] = useState<string[]>([]);
  const [input, setInput] = useState('');
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const handleAddEntity = () => {
      props.launch('add_ent', {name:props.category, address:pending} as ResourceData);
  }

  return (
      <Autocomplete multiple disableClearable limitTags={7} freeSolo disableCloseOnSelect size='small'
      getOptionLabel={(option:any) => {
          return option
      }}   
      options={pending}
      open={open}
      onChange={(event, newValue, reason) => {
      }}
      onOpen={(e) => {
          setOpen(true)
      }}
      onClose={(e, reason) => {
          setOpen(false)
      }}
      value={props.entity}
      renderTags={(value, myprops) => {
          let res: ReactNode[] = [];
          value.forEach((option, index) => {
              if (IsValidAddress(option)) {
                  res.push(
                        <Chip label={<Address address={option} showType/>} {...myprops({ index })} onDelete={() => {
                          props.launch('remove_ent', {name:props.category, address:[option]} as ResourceData);
                        }}/>  
              );} 
          });
          return res;
      }}
      renderInput={(params) => (
          <TextField error={error?true:false} helperText={error} 
            {...params}
            placeholder={ props.entity.length === 0 ? "Input personal address and press Enter" :'' }
            sx={{"& fieldset": { padding:'8px',  borderColor:'rgba(232,167,213,0.36)!important'}, 
            "& input": { minWidth:'16em!important'},
            "&:hover fieldset": { borderColor:theme.palette.primary.main + '!important'},
            input: {color: grey[600]}}}
            value={input}
            onChange={(e) => {
              setInput(e.target.value);
              setError('');
            }}
            onKeyDown={(event) => {
              event.stopPropagation();
              if (pending.length + props.entity.length >= Permission.MAX_ENTITY_COUNT) {
                  setError('max entity');
                  return 
              }
              if(event.code === 'Enter') {
                  if (!IsValidAddress(input)) {
                      setError(ADDRESS_VALIDATOR.err);
                  } else {
                      let n = pending.filter((v) => v !== input);
                      n.unshift(input);
                      setPending([...n]);
                      setOpen(true)
                  }
              }
          }}/>
      )}
      filterOptions={(options, state) => options.sort((a, b)=> {
          return  (b as string).indexOf(input) - (a as string).indexOf(input)
      })}
      renderOption={(props, option) => { 
          const arr = (option as string).split(input);
          return (
          <li {...props} key={option} >
              <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                  <div>
                      {arr.map((v, i) => {
                          if (i < arr.length-1) {
                              return <span>{v}<span style={{fontWeight:400, color:theme.palette.primary.main}}>{input}</span></span>
                          } else {
                              return <span>{v}</span>
                          }
                      })}
                  </div>
                  <Tooltip title='Remove this address'>
                      <IconButton className='cmdText' sx={{ml:'auto'}} onClick={() => {
                          setPending([...pending.filter((v) => v !== option)]);
                      }}><CloseIcon /></IconButton>
                  </Tooltip>
              </div>
          </li>
      )}}
      PaperComponent={(paperProps:any) => (
        <CustomPaperComponent
            {...paperProps}
            selects={pending}
            handleAddEntity={handleAddEntity}
            onClickAway={(e:any)=>{
            }}
        />
    )}
  />) 
}

const CustomPaperComponent = (props:any) => {  
  return (
    <Paper {...props}>
        <ClickAwayListener onClickAway={props.onClickAway}>
            <div key="click-away-wrapper">
              <div style={{display:'flex', alignItems:'center', padding:'.4em 1em',  
                   borderBottom:'1px solid', borderColor:grey[200], fontWeight:400}}>
                  <div style={{margin:'.4em 0'}}> New <span style={{fontSize:'1.1em', fontWeight:400, margin:'0 .1em'}}>{props.selects.length} </span>address added</div>      
                  {props.selects.length > 0 && <Button variant='outlined' className='cmdText' onMouseDown={(ev) => ev.preventDefault()}
                      style={{marginLeft:'2em', textTransform:'none', padding:'.2em .8em'}} onClick={() => {
                          props.handleAddEntity();
                      }}>+ Launch</Button>}     
              </div>
                {props.children}
            </div>
        </ClickAwayListener>
    </Paper>
  );
};