import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';
import { Paper, Box, Tooltip} from "@mui/material";
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import React, { useState, useCallback } from "react";
import { TransitionProps } from '@mui/material/transitions';
import { grey } from '@mui/material/colors';
export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function useForceUpdate() {
  const [, setTick] = useState(0);
  const update = useCallback(() => {
    setTick(tick => tick + 1);
  }, [])
  return update;
}

export function OriginNode_Dot(props:any) {
  const size = {height: props?.size ? props.size : '.8em',  width: props?.size ? props.size : '.8em'};
  return (        <Box style={{...size, alignItems: 'center',
        justifyContent: 'center',borderRadius:"100%", backgroundColor:'#E8A7D5'}}>
            <Tooltip title='Origin Node'>
                <Typography> &nbsp;</Typography>
            </Tooltip>
    </Box>)
}
export function OriginNode(props:any) {
  return (     
            <Tooltip title='Origin Node' placement="bottom-start">
                <Typography sx={{color:'#E8A7D5'}}>Origin</Typography>
            </Tooltip>
    )
}
export function Pannel(props: TabPanelProps) {
  const { children, value, index,  ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{pt:0}}>
          {children}
        </Box>
      )}
    </div>
  );
}

export function NotSetYet(props:any) {
  return <span style={{color:grey[500], fontWeight:200}}>Not set yet.</span>
}

export function a11yProps(index: number) {
  return {
    id: `wowok-tab-${index}`,
    'aria-controls': `wowok-tabpanel-${index}`,
  };
}

export interface MessageModalProp {
    open: boolean;
    title: string;
    description: string;
    handleClose : () => void;
}

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function toLocaleStringWithMinutes(date:Date) {
  // 使用 toLocaleString 获取日期和时间字符串
  var dateStr = date.toLocaleString();
 
  // "YYYY-MM-DDTHH:mm:ss"
  var regex = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}):\d{2}$/;
  return dateStr.replace(regex, '$1');
}

export function MessageModal (props: MessageModalProp) {
    const theme = useTheme();

    return (<Modal
        open={props.open}
        onClose={()=>props.handleClose()}
    >
        <Paper sx={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', width:'20em', p:' 1em',
            boxShadow:'none', border:'2px solid', borderColor:theme.palette.primary.main,
        }}>
        <Typography id="modal-modal-title" sx={{fontWeight:600, fontSize:'1.1em', textAlign:'center', mb:'1em'}} color={theme.palette.primary.main}>
            {props.title}
        </Typography>
        <Alert  sx={{letterSpacing:'0.04em'}} severity="error">
        {props.description}
      </Alert>
        </Paper>
    </Modal>);
}

export type SeverityType = 'error' | 'info' | 'success' | 'warning';
export interface MessageSnackProp {
    open: boolean;
    severity:  SeverityType;
    description: string;
    handleClose : () => void;
}

export function MessageSnack(props:MessageSnackProp) {
    return (
        <div>
          <Snackbar open={props.open} autoHideDuration={4000} onClose={props.handleClose} 
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
            <Alert
              onClose={props.handleClose}
              severity={props.severity}
              variant="filled"
              color={props.severity}
              sx={{ width: '100%' }}
            >
              {props.description}
            </Alert>
          </Snackbar>
        </div>
      );
}

export interface PermissionIndexData {
  index: number;
  description: string;
  module: string;
  guard?: string;
}

export interface PermissionEntityData {
  id: number;
  entity: string;
  index: PermissionIndexData[];
}

export function capitalizeFirstLetter(str?: string) : string {
  if (!str) return ''
  return str.split(' ').map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }).join(' ');
}
 