import { Box, IconButton , Stack, Tooltip, Typography, Button, Select, MenuItem, Autocomplete, TextField } from '@mui/material';
import React, { useState, forwardRef, useEffect } from "react";
import '../../../css/Common.css';
import { getBezierPath, useReactFlow, BaseEdge, EdgeLabelRenderer, Node} from 'reactflow';
import { grey, red, pink } from '@mui/material/colors';
import { generateRandomString } from '../../../util'
import { MAX_NAME_LENGTH, Machine_Node_Pair, Machine_Forward, PermissionIndex, Machine, Permission, PermissionAnswer, 
    PermissionIndexType, Protocol, 
    IsValidAddress} from 'wowok';
import { Unstable_Popup as Popup } from '@mui/base/Unstable_Popup';
import MaterialTable from "material-table";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Paper from '@mui/material/Paper';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import Draggable, {DraggableEvent, DraggableData} from 'react-draggable';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SettingInputText, SettingTitle, ADDRESS_VALIDATOR, PersonalPermissionDescription } from '../Settings';
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useSnackbar } from 'notistack';
import { Transition } from '../../util/Common';
import { Address } from '../../util/Address';
import { PermIcon } from '../../util/Icons';
import { make_permIconProp } from '../PermCheck';
import { useTheme, } from '@mui/material/styles';

interface ForwardEdge {
    name: string; // foward name
    namedOperator?: string; // dynamic operator
    permission?: PermissionIndexType; // this.permission-index or named-operator MUST one defined.
    weight?: number;
    guard?: string;
    perm_choose:'progress' | 'permission' ;
}

interface ExtraPerm {
    name: string;
    index: string;
}

const ForwardPanel = (props:any) => {
    //console.log(props)
    const theme = useTheme();
    const [value, setValue] = useState<ForwardEdge | null>(props?.bNew ? {name:'', perm_choose:'permission'}:null);
    const [select, setSelect] = useState<ForwardEdge | null>(null);
    const forwards:ForwardEdge[] = props?.pair?.forwards?.map((v:Machine_Forward)=>{
        return {name:v.name, namedOperator:v.namedOperator, permission:v.permission, 
            weight:v.weight, guard:v.guard, perm_choose:v.namedOperator? 'progress' : 'permission'}}) ?? [];
    const [threshold, setThreshold] = useState(props?.pair?.threshold ?? '0');
    const { enqueueSnackbar } = useSnackbar();
    const { getNodes } = useReactFlow();
    const answer:PermissionAnswer | undefined = props?.answer;
    const INIT_NODE = getNodes().find((v) => v.type === 'InitNode')?.id;

    const [extraPerms, setExtraPerms] = useState<ExtraPerm[]>([]);

    useEffect(() => {
        if (props?.permission) {
            Protocol.Client().getObject({id:props?.permission, options:{showContent:true}}).then((res) => {
                setExtraPerms((res?.data?.content as any)?.fields?.user_define?.fields?.contents?.map((v:any) => {
                    return {index: v.fields.key, name:v.fields.value}
                }) ?? []);
            });                
        }
    }, [props?.permission])
      
    const source = props.source === INIT_NODE ? <FiberManualRecordIcon color='primary' /> : <Typography variant='button' >{props.source}</Typography>;
    const target = props.target === INIT_NODE ? <FiberManualRecordIcon color='primary' /> : <Typography variant='button' >{props.target}</Typography>;
    return   (
        <Box boxShadow='none' border='1px solid #E8A7D5'>     
            <Paper className='handleEdge' sx={{backgroundColor:'#ffcc0057', cursor:'pointer'}} >
                <Stack direction="row" spacing={0.6} display='flex' alignItems='center' padding='4px' borderBottom='1px solid #E8A7D5'>
                <div style={{textTransform:'uppercase', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', maxWidth:'10em'}}>{source}</div>  
                <ArrowForwardIcon sx={{color:grey[400], fontSize:"1em"}}/> 
                    <div style={{textTransform:'uppercase', whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', maxWidth:'10em'}}>{target}</div>  
                    <Box sx={{marginLeft:'auto !important', padding:"0", fontSize:'1em', display:'flex', alignItems:'center'}}>
                        <IconButton onClick={props.handleClose} ><CloseIcon  />  </IconButton > 
                    </Box>        
                </Stack>
            </Paper>                    
            <MaterialTable title=""
                columns={[
                    {
                        title: "Operation", field: "name", cellStyle:{paddingLeft:8}, type: "string" as const, render:(data:any, type:any) => {
                        return (<div style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', maxWidth:'10em', }}>
                            <Tooltip title={data.name} arrow>
                            <span style={{fontSize:'.9em'}}>{data.name} </span>
                            </Tooltip>
                        </div>)
                        },
                    },
                    { title: "Weight", field: "weight", type: "string" as const, width:"5%",  cellStyle: {paddingRight: 0, paddingLeft:0},
                        render:(data:ForwardEdge, type:any) => {
                        const w = data.weight + '/' + props.pair.threshold;
                        return (<div style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', maxWidth:'6em', fontSize:'.9em'}}>
                            <span >{w} </span>
                        </div>)
                        },
                    },
                    { title: "Permission", field: "permission", type: "string" as const, cellStyle: {paddingRight: 4, paddingLeft:4, textAlign:'center'}, render:(data:Machine_Forward, type:any) => {
                        var r:any = ''
                        var tips : any;
                        if (data.permission) {
                            r = extraPerms.find((v)=>v.index === data.permission?.toString())?.name;
                            tips = <span><span style={{color:theme.palette.primary.main, fontWeight:800}}>{data.permission}</span><span>&nbsp;{r}</span></span>;
                        } else {
                            r = data.namedOperator;
                        }
                        const color = r===Machine.OPERATOR_ORDER_PAYER ? {color:theme.palette.primary.main, fontWeight:400} : {color:undefined};
                        return (<div style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', maxWidth:'10em'}}>
                            <Tooltip title={tips} arrow><span style={{...color, fontSize:'.9em'}}>{r}</span></Tooltip>
                        </div>)
                        },
                    },
                    {
                        title: "Guard", field: "guard", type: "string" as const, 
                        cellStyle: {paddingRight: 12, paddingLeft:12},  render:(data:ForwardEdge, type:any) => {
                            return (<Address address={data.guard?.toString() ?? ''} showType={'Guard'} fontSize={'.9em'}/>)
                            },
                    }, {
                        title:'', field:'op', sorting:false, render:(data:ForwardEdge, type:any) => {
                            return ( 
                                <div style={{textAlign:'center'}}>
                                    <Tooltip arrow placement='left' title={<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
                                        <span>Edit</span> 
                                        <PermIcon {...make_permIconProp(props.answer, PermissionIndex.machine_node)} />
                                        </div>}> 
                                        <span> 
                                        <IconButton className='cmdText'  size='small' sx={{ padding:'3px', margin:'auto'}}
                                        disabled={Permission.HasPermission(props.answer, PermissionIndex.machine_node, true)?.has === false} onClick={() => {
                                            setSelect({...data});
                                            setValue({...data});
                                        }}>
                                        <EditIcon  className='cmdText' sx={{color:grey[500], height:'.6em!important', width:'.6em!important'}} />
                                        </IconButton></span>
                                    </Tooltip>            
                                    <Tooltip arrow placement='right' title={<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
                                        <span>Delete</span> 
                                        <PermIcon {...make_permIconProp(props.answer, PermissionIndex.machine_node)} />
                                        </div>}> 
                                        <span> 
                                        <IconButton className='cmdText'  size='small' sx={{ padding:'3px', margin:'auto'}}
                                        disabled={Permission.HasPermission(props.answer, PermissionIndex.machine_node, true)?.has === false} onClick={() => {
                                            props.launch('forward delete', {prior_name:props?.pair?.prior_node, name:props?.target, forward_name:data.name});

                                        }}>
                                        <CloseIcon  className='cmdText' sx={{color:grey[500], height:'.7em!important', width:'.7em!important'}} />
                                        </IconButton></span>
                                    </Tooltip>                                                            
                                </div>
                             )
                        },  cellStyle: { paddingLeft: 0, paddingRight: 3, }, headerStyle:{width:10, maxWidth:10, paddingLeft:0, paddingRight:3}
                    }, {
                        title:'', field:'namedOperator', hidden:true, 
                    }, 
                    ]}
                options={{toolbar: false, paging:false, padding: "dense",}}
                actions={[]}
                icons={{ SortArrow: forwardRef((props, ref) => <ArrowDownwardIcon{...props} ref={ref}/>)}}
                data={forwards}
                components={{
                    Header: (header_props) => {
                      return (<TableHead {...header_props} style={{borderBottom:'1px solid', borderColor:grey[300]}}>
                        <TableRow>
                          <TableCell  variant='head' style={{paddingLeft:8, paddingTop:0, paddingBottom:0, fontSize:'.9em'}}>{header_props.columns[0].title} </TableCell>
                          <TableCell   variant='head' style={{paddingTop:0, paddingBottom:0,paddingLeft:0,paddingRight:0, fontSize:'.9em'}}>
                            <div style={{display:'flex', justifyContent:'center',  }}>
                              <div>{header_props.columns[1].title}</div>
                              <div><Tooltip title="Wight/Threshold. When the sum of the operators' weights reaches a threshold, it triggers the next node. " arrow placement='right'>
                              <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                              </Tooltip></div>                      
                            </div>
                          </TableCell>
                          <TableCell  variant='head' align='center' style={{paddingTop:0, paddingBottom:0, fontSize:'.9em'}}>
                            <div style={{display:'flex', justifyContent:'center',  }}>
                              <div>{header_props.columns[2].title}</div>
                              <div><Tooltip title='Permission index and Progress operator' arrow placement='right'>
                              <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                              </Tooltip></div>                      
                            </div>
                          </TableCell>
                          <TableCell variant='head' align='center' style={{padding:0, fontSize:'.9em'}}>
                            <div style={{display:'flex', justifyContent:'center', }}>
                              <div>{header_props.columns[3].title}</div>
                              <div><Tooltip title='If set, Operation succeeded only that the guard conditions meet.' arrow placement='right'>
                              <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                              </Tooltip></div>                      
                            </div>
                          </TableCell>
                          <TableCell  variant='head' align='center' style={{paddingLeft:6, paddingRight:6, paddingTop:0, paddingBottom:0, fontSize:'.9em'}}>
                          <Tooltip title={<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
                            <span>New Forward</span> 
                            <PermIcon {...make_permIconProp(props.answer, PermissionIndex.machine_node)} />
                            </div>}> 
                            <span> 
                            <IconButton className='cmdText'  size='small'
                            disabled={Permission.HasPermission(props.answer, PermissionIndex.machine_node, true)?.has === false} onClick={() => {
                                setSelect(null);
                                setValue({name:'', perm_choose:'permission'});
                            }}>
                                <AddBoxIcon className='cmdText' color='primary' /> 
                            </IconButton></span>
                        </Tooltip>           
                          </TableCell>
                        </TableRow>
                      </TableHead>)
                    }
                  }}
            /> 
      <Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth={'md'} disableRestoreFocus
      open={value ? true:false}
      TransitionComponent={Transition}
      keepMounted
      onClose={()=>setValue(null)}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={{textAlign:'center'}} > Forward Operation
        <IconButton sx={{float:'right', marginTop:'-.2em'}} onMouseDown={()=>{setValue(null)}}> <CloseIcon /> </IconButton>   
      </DialogTitle>
      {value && <DialogContent>
        <SettingTitle title='Operation name' required />
        <SettingInputText maxlength={MAX_NAME_LENGTH}  fullWidth placeholder='Enter operation name' value={value!.name}
            id='forward-name' err_empty='Please enter operation name' autoFocus event={(type, v, id) => {
                value!.name = v;
                setValue({...value})
            }}/>
        <SettingTitle title='Weight' required tips="Wight/Threshold. When the sum of the operators' weights reaches a threshold, it triggers the next node. "/>
        <div style={{display:'flex', alignItems:'center', width:'100%'}}>
            <SettingInputText maxlength={4} style={{width:'4em'}} value={value!.weight? value!.weight.toString() : '1'} type='number'
                id='forward-weight' size='small' noNumberSpin event={(type, v, id) => {
                    value.weight = v;
                    setValue({...value})
                }}/>
                <p style={{fontWeight:1000}}>&nbsp;&nbsp;/&nbsp;&nbsp;</p>
            <SettingInputText maxlength={4} style={{width:'4em'}}  value={threshold} type='number'
                id='forward-threshold' size='small' noNumberSpin   event={(t, v, id) => {
                    setThreshold(v);
                }}/>            
        </div>
        <SettingTitle title='Permission From'  required tips="Permission from Machine's Permission Object, or set by every Progress dynamically."/>
        <Select value={value.perm_choose} fullWidth onChange={(e) => {
            value.perm_choose = e.target.value === 'progress' ? 'progress' : 'permission';
            setValue({...value})
        }}>
                <MenuItem value='permission'>{<div style={{display:'flex', alignItems:'center'}}><span>Operators in&nbsp;</span><Address address={answer?.object ?? ''} showType='Permission'/></div>}</MenuItem>
                <MenuItem value='progress'>Operators set by Progress dynamically</MenuItem>
        </Select>
        { value.perm_choose === 'permission' && 
            <div>
                <div style={{display:'flex', alignItems:'baseline'}}>
                    <SettingTitle title={PersonalPermissionDescription} required tips= {PersonalPermissionDescription + ' are some expanded custom permissions.'}/>     
                    {extraPerms.length > 0 && <div style={{marginLeft:'auto', marginRight:'.4em', color:grey[500]}}><span onClick={()=> {
                        if (props.permission) window.open('/'+props.permission+'#1', '_blank')?.focus()
                        }} className='cmdText' style={{marginLeft:'2em', fontWeight:400}}>{'+ Add '+PersonalPermissionDescription}</span>
                    </div>}
                </div>
                { extraPerms.length > 0 && <Select placeholder={'Select '+PersonalPermissionDescription} fullWidth value={value!.permission?.toString()} onChange={(e) => {
                        value.permission = parseInt(e.target.value);
                        setValue({...value})
                    }} renderValue={(v) => <div style={{display:'flex'}}>
                        <span>{extraPerms.find((i)=>i.index.toString() === v)?.name ?? ''}</span>
                        <span style={{color:grey[400], marginLeft:'auto'}}>{'Permission Index: ' + v}</span>
                    </div>}>
                        {
                            extraPerms.map((v) => {
                                return (<MenuItem value={v.index} key={v.index}>
                                    <span>{v.name}</span>
                                    <span style={{color:grey[400], marginLeft:'auto'}}>{'Permission Index: ' + v.index}</span>
                                </MenuItem>)
                            })
                        }
                </Select> }        
                {
                    extraPerms.length === 0 && <div className='cmdText' onClick={()=> {
                        if (props.permission) window.open('/'+props.permission+'#1', '_blank')?.focus()
                        }}
                        style={{display:'flex', color:theme.palette.primary.main, marginLeft:'.2em'}}>
                        <span style={{color:grey[400]}}>{'No ' + PersonalPermissionDescription + ' found.' }</span> 
                        <span style={{marginLeft:'1em', fontWeight:400, color:theme.palette.primary.main}}>{'+ Add ' + PersonalPermissionDescription}</span>
                    </div>
                }      
            </div>
        }
        { value.perm_choose === 'progress' && 
            <div >
            <SettingTitle title='Progress Operator' required tips='Can be a protocol-provided name like "order payer" or a custom name, be confirmed only when the progress is running.'/>     
            <Autocomplete forcePopupIcon={false} id='forward-progress-operator' autoFocus value={value!.namedOperator} freeSolo
                onChange={(e, newValue) => {
                    value!.namedOperator = newValue ?? '';
                    setValue({...value})
                }}  
                renderTags={() => null}
                renderOption={(props, option, { selected }) => { 
                    if (option === Machine.OPERATOR_ORDER_PAYER) {
                        option = option + ' (Build-in Operator)';
                    }

                    return (<li {...props} key={option}>
                        {option}
                    </li>)
                }}
                options={props?.data?.ops?? []}
                renderInput={(params) => (
                <TextField
                    {...params}
                    autoFocus
                    onChange={(e) => {
                        value!.namedOperator = e.target.value ?? '';
                        setValue({...value})
                    }}
                    value={value!.namedOperator}
                    inputProps = {{...params.inputProps, maxLength:MAX_NAME_LENGTH}}
                    variant="outlined"
                    placeholder='Select or Enter new Progress Operator name'
                />
                )}
            />
            {/*<SettingInputText maxlength={MAX_NAME_LENGTH} placeholder='Enter Progress Operator name' value={value!.namedOperator} fullWidth
                    id='forward-progress-operator' noNumberSpin event={(type, v, id) => {
                        value!.namedOperator = v;
                        setValue({...value})
                    }}/>    */}             
            </div>
        }
        <SettingTitle title='Guard' tips='If set, Operation succeeded only that the guard conditions meet.'/>   
        <SettingInputText maxlength={66} placeholder='Enter Guard address' value={value!.guard as string} 
            id='machine-edge-forward-guard' validator={ADDRESS_VALIDATOR} noNumberSpin event={(type, v, id) => {
                value!.guard = v;
                setValue({...value})
            }}/>     
        <div style={{display:'flex', alignItems:'baseline'}}>
        <Button variant='contained' disabled={Permission.HasPermission(props.answer, PermissionIndex.machine_node, true)?.has === false}
            sx={{mt:'2em', fontSize:'1.2em', textTransform:'none', padding:'.2em .8em', alignItems:'center', display:'flex'}} 
            onClick={ () => {
                if (!value) {
                    enqueueSnackbar('Forward setting invalid', { variant: "error" });
                    return
                }
                if (value.guard && !IsValidAddress(value.guard)) {
                    enqueueSnackbar('Guard invalid', { variant: "error" });
                    document.getElementById('machine-edge-forward-guard')?.focus();
                    return 
                }

                const f:Machine_Forward = {name:value.name, guard:value.guard, weight:value.weight, 
                    permission:value.perm_choose === 'progress' ? undefined : value.permission,
                    namedOperator:value.perm_choose === 'permission' ? undefined : value.namedOperator};
                const err =  Machine.checkValidForward(f);
                if (err !== '') {
                    enqueueSnackbar(err, { variant: "error" });
                    return 
                };

                if (select && select.name !== value.name) { 
                    props.launch('forward add', {prior_name:props?.pair?.prior_node, name:props?.target, 
                        old_forward_name:select.name, forward:f, threshold:threshold});
                } else {
                    props.launch('forward add', {prior_name:props?.pair?.prior_node, name:props?.target, 
                        old_forward_name:undefined, forward:f, threshold:threshold});
                }
            }}>{<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
            <span>Launch</span> 
            <PermIcon {...make_permIconProp(props.answer, PermissionIndex.machine_node)} />
            </div>}
        </Button>               
        </div>

      </DialogContent> }
    </Dialog>              
    </Box>)
}

const MachineEdge = (props:any) => {
    const {id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, markerEnd, target, source} = props;
    const { getNodes, setEdges, getEdges } = useReactFlow();
    var [edgePath, labelX, labelY] = getBezierPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
    });
    const INIT_NODE = getNodes().find((v) => v.type === 'InitNode')?.id;
    const getPair = () : Machine_Node_Pair | undefined => {
        let n = getNodes().filter((n:Node) => n.id === target);
        if (n.length === 1) {
            let p = n[0]?.data?.pairs.filter((t:any) => t.prior_node ? (t.prior_node === source) : (source === INIT_NODE));
            if (p && p.length === 1)  {
                // [pair]
                return p[0]
            }
        }
        return undefined
    }

    let t = getPair() ; 
    let color = red[500] as string;
    let label = 'NEW';
    let strokeWidth = 1;
    let bkgrdColor = red[300] as string;
    if (t && t.forwards.length > 0) { 
        bkgrdColor = '#ffcc00';
        color = grey[500] as string;
        label = t.forwards.length === 1 ? t.forwards[0].name : (t.forwards.length + ' ops');
        if (t.forwards.length > 7) {
            strokeWidth = 3;
        } else if (t?.forwards.length > 5) {
            strokeWidth = 2.5;
        } else if (t?.forwards.length > 3) {
            strokeWidth = 2;
        } else if (t.forwards.length > 1) {
            strokeWidth = 1.5;
        }
    }

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const pop_id = generateRandomString(16);

    const handleDragStop = (e: DraggableEvent, data: DraggableData)  => {
        /*console.log(e)
        console.log(data)
        console.log('parent left: ' )
        console.log(data.node.parentElement!.getBoundingClientRect())
        console.log('current left: ' )
        console.log(data.node.getBoundingClientRect())*/
        data.node.parentElement!.style.pointerEvents = 'none!important' // perfect!!!
    }
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    }
    const handleClose = () =>  {
        setAnchorEl(null);
    }

    const zIndex = props?.selected ? {zIndex:'101'} :  {zIndex:'100'};
    if (props.source === props.target) { // 自循环节点
        const radiusY = (props.sourceY - props.targetY) ;
        const radiusX = 100;
        edgePath = `M ${props.sourceX - 5} ${props.sourceY} A ${radiusX} ${radiusY} 0 1 0 ${
            props.targetX + 2
        } ${props.targetY}`;
        labelX = labelX + radiusX;
        labelY = labelY + radiusY;
    } else if (getEdges().find(v => v.source === props.target && v.target === props.source)) {
        if (props.source.localeCompare(props.target) >= 0) {
            console.log(labelX); console.log(labelY);
            labelY = labelY + 15;
        } else {
            labelY = labelY - 15;
        }
    }

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={{strokeWidth:1, stroke:color, strokeLinejoin:'round'}} />
            <EdgeLabelRenderer>
                <div
                onClick={handleClick}
                style={{
                    position: 'absolute',
                    transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                    fontSize: 12,
                    // everything inside EdgeLabelRenderer has no pointer events by default
                    // if you have an interactive element, set pointer-events: all
                    background: bkgrdColor,
                    paddingLeft: 10,
                    paddingRight:10,
                    paddingTop:4,
                    paddingBottom:4,
                    borderRadius: 5,
                    fontWeight: 700,
                    pointerEvents: 'all',
                }}
                className="nopan edgeObject"
                >
                    {label}           
                </div>
                <Popup id={pop_id} open={open} anchor={anchorEl} className='popEdg' style={{...zIndex}}>
                    <Draggable  handle='.handleEdge' onStop={handleDragStop} >
                        <Paper><ForwardPanel handleClose={handleClose} pair={t} {...props} bNew={t?.forwards.length===0 ? true: false}/></Paper>
                    </Draggable>
                </Popup> 
            </EdgeLabelRenderer>
        </>
    );
}

export default MachineEdge;