
import React, { useState } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Box, Paper, Tab, Tabs } from '@mui/material';
import Basic from './Basic'
import Buyer from './Buyer'
import Discount from './Discount'
import Item from './Item'
import CustomTrading from './CustomTrading';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import SettingHeader from '../SettingHeader';
import { useLocation } from 'react-router-dom';
import Status from './Status';
import { Pannel, a11yProps,  } from '../../util/Common';
import Treasury from './Treasury';

export default function ServiceSetting (props:any) {  
  const location = useLocation()
  var t = parseInt(location.hash.slice(1));
  if (t < 0 || t > 6 ||  isNaN(t))  { t = 0; }
  window.location.hash = '#' + t;

  const id = props?.contents?.fields?.id?.id ?? '';
  const [value, setValue] = React.useState(t);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    window.location.hash = '#' + newValue;
    setValue(newValue);
  };
    
    return (
      <Paper sx={{ width: '100%', pt:'.4em', }} >
        <SettingHeader name={id ? 'Service Setting' : 'Launch Service'}/>

        <Tabs value={value} onChange={handleChange} aria-label="reward" sx={{height:id ? '3em' : '3.8em', margin:'.4em 1em'}}>
          <Tab label="Basic" {...a11yProps(0)} sx={{textTransform:'none'}} value={0}/>
          <Tab disabled={id ? undefined : true} value={1}
            icon={id ? undefined : <VpnKeyIcon className='trans270' 
            sx={{with:'0.6em', height:'0.6em', marginRight:'-0.1em!important'}}/>} iconPosition="start" 
            label={'Custom Tradding'} {...a11yProps(1)} sx={{textTransform:'none'}}/>
          <Tab disabled={id ? undefined : true} value={2}
            icon={id ? undefined : <VpnKeyIcon className='trans270' 
            sx={{with:'0.6em', height:'0.6em', marginRight:'-0.1em!important'}}/>} iconPosition="start" label="Sales" 
            {...a11yProps(2)} sx={{textTransform:'none'}}/>
          <Tab disabled={id ? undefined : true} value={3}
            icon={id ? undefined : <VpnKeyIcon className='trans270' 
            sx={{with:'0.6em', height:'0.6em', marginRight:'-0.1em!important'}}/>} iconPosition="start" label="Discount" 
            {...a11yProps(3)} sx={{textTransform:'none'}}/>
          <Tab disabled={id ? undefined : true} value={4}
            icon={id ? undefined : <VpnKeyIcon className='trans270' 
            sx={{with:'0.6em', height:'0.6em', marginRight:'-0.1em!important'}}/>} iconPosition="start" label="Buyer" 
            {...a11yProps(4)} sx={{textTransform:'none'}}/>
          <Tab disabled={id ? undefined : true} value={5}
            icon={id ? undefined : <VpnKeyIcon className='trans270' 
            sx={{with:'0.6em', height:'0.6em', marginRight:'-0.1em!important'}}/>} iconPosition="start" label="Treasury" 
            {...a11yProps(5)} sx={{textTransform:'none'}}/>
          <Tab disabled={id ? undefined : true} value={6}
            icon={id ? undefined : <VpnKeyIcon className='trans270' 
            sx={{with:'0.6em', height:'0.6em', marginRight:'-0.1em!important'}}/>} iconPosition="start" label="Status" 
            {...a11yProps(6)} sx={{textTransform:'none'}}/>
        </Tabs>
        <Pannel value={value} index={0}>
            <Basic {...props}/>
        </Pannel>
        <Pannel value={value} index={1}>
          <CustomTrading {...props}/>
        </Pannel>
        <Pannel value={value} index={2}>
            <Item {...props}/>
        </Pannel>
        <Pannel value={value} index={3}>
          <Discount {...props}/>
        </Pannel>
        <Pannel value={value} index={4}>
          <Buyer {...props}/>
        </Pannel>
        <Pannel value={value} index={5}>
          <Treasury {...props}/>
        </Pannel>
        <Pannel value={value} index={6}>
          <Status {...props}/>
        </Pannel>
    </Paper>
    );
}
