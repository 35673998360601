
import React, { useEffect, useState } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { useTheme} from '@mui/material/styles';
import { Box, Modal, Button, Tooltip, IconButton, Paper, Typography, MenuItem, Select, TextField} from '@mui/material';
import { PermissionIndex, Repository, Permission, RepositoryValueTypeInfo, PermissionIndexType, RepositoryValueType, 
  MAX_NAME_LENGTH, MAX_DESCRIPTION_LENGTH, PermissionAnswer, Passport as WowokPassport} from 'wowok';
import { SettingTitle,  SettingLaunch, SettingTimePickerResult,
  SettingInputText, SettingValueType, 
} from '../Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import MaterialTable, {MTableToolbar} from "material-table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import { grey } from '@mui/material/colors';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Transaction as TransactionBlock } from '@mysten/sui/transactions';
import { PermIcon } from '../../util/Icons';
import { make_permIconProp } from '../PermCheck';
import { generateRandomString } from '../../../util';
export interface PolicyData {
    name: string;
    description: string;
    permission?: number;
    dataType?:RepositoryValueType;
}
  
export default function Policy (props:any) {
    const data = props.policies as PolicyData[];
    const id = props?.contents?.fields?.id?.id ?? '';
    const permission = props?.contents?.fields?.permission;
    const answer: PermissionAnswer | undefined = props?.perms;
    const INIT_POLICY:PolicyData = {name:'', description:'', dataType:RepositoryValueType.String};
    const [policy, setPolicy] = useState<PolicyData>(INIT_POLICY);
    const [openNewModal, setOpenNewModal] = useState(false);

    let len = data.length;
    len = len >= 20 ? 20 : (len <= 5 ? 5 : 10);

    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();

    const launch = async (op:'add' | 'delete', value?:string) => {      
      if (!wallet.connected) {
        enqueueSnackbar('Please login wallet', { variant: "error" });
        document.getElementById('header-wallet-cmd')?.click();
        return 
      }
      
      const txb = new TransactionBlock();
      const obj = Repository.From(txb, permission, id);
      const pid = generateRandomString(8);
      props.PermissionCheck({id:pid, txb:txb, answer:answer, index:[PermissionIndex.repository_policies], handler:
        (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:WowokPassport) => {
          try {
            if (id === pid) {
              if (index.includes(PermissionIndex.repository_policies)) {
                if (op === 'add') {
                  obj.add_policies([{key:policy.name, description:policy.description, data_type:policy.dataType!, 
                    permission:policy.permission}], passport?.get_object());                   
                } else if (op === 'delete' && value) {
                    obj.remove_policies([value], passport?.get_object());
                }
              };    
              passport?.destroy(); // destroy passport
              props.exe(pid, txb);        
            }
          } catch (e) {
            console.log(e)
            enqueueSnackbar( 'Launch Failed', { variant: "error" });
          }
        }
      });
    }
 
    return ( <Box className='noboxshadow' sx={{padding:'0 2em'}}>
        <MaterialTable title=''
        columns={[
        { title: '', field: 'index', type: 'numeric', align:'left', width:'1%', cellStyle:{paddingRight:0,},  render:(data, type) => {
            return <div style={{fontStyle:'italic', color:grey[400], fontWeight:200}}>{data.index}</div>
        }},

        { title: 'Name', field: 'name', cellStyle:{paddingRight:0,}, render:(data, type) => {
            return (<div style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', width:'10em'}}> 
                {data.name}
            </div>)
        }},
        { title: 'Description', field: 'description', render:(data, type) => {
            return (<div style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', width:'14em'}}>{data.description}</div>)
        }}, 
        { title: 'Data type', field: 'dataType', cellStyle:{paddingRight:0,}, render:(data, type) => {
            const v = RepositoryValueTypeInfo.find((i)=>i.type === data.dataType);
            return (<div style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', width:'8em'}}>{v ? v.name : 'unknown'}</div>)
        }}, 
        { title: 'Permission', field: 'permission', cellStyle:{paddingRight:0,}, render:(data, type) => {
            return (<div>
                {data.permission && <div className='cmdText' onClick={() => {
                    window.open('/'+permission, '_blank')?.focus()
                }}>
                    {data.permission}
                    </div>}
            </div>)
        }}, 
        { title: '', field: 'operation',  render:(data, type) => {
            return (<div style={{display:'flex', alignItems:'center'}}>
                <Tooltip title={<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
                    <span>Edit Policy</span> 
                    <PermIcon {...make_permIconProp(answer, PermissionIndex.repository_policies)} />
                    </div>}>  
                  <span>
                    <IconButton  className='cmdText'  sx={{ml:'.4em', padding:'3px'}} 
                    disabled={Permission.HasPermission(answer, PermissionIndex.repository_policies, true)?.has === false} onClick={(e) => {
                        setPolicy({name:data.name, description:data.description, 
                            permission:data.permission, dataType:data.dataType as RepositoryValueType});
                        setOpenNewModal(true)
                    }}>
                    <EditIcon  className='cmdText' sx={{color:grey[500], height:'.6em!important', width:'.6em!important'}} />
                    </IconButton></span>
                </Tooltip>
                <Tooltip title={<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
                    <span>Delete Policy</span> 
                    <PermIcon {...make_permIconProp(answer, PermissionIndex.repository_policies)} />
                    </div>}> 
                    <span> 
                    <IconButton className='cmdText'  sx={{marginLeft:'.1em', padding:'3px'}} 
                    disabled={Permission.HasPermission(answer, PermissionIndex.repository_policies, true)?.has === false} onClick={() => {
                        launch('delete', data.name)
                    }}>
                        <DeleteIcon   className='cmdText' sx={{color:grey[500], height:'.6em', width:'.6em'}} />
                    </IconButton></span>
                </Tooltip>   
            </div>)
        }},
        ]}
        data={data.map((v, i) => {
            return {index:i+1, name:v.name, description:v.description, dataType:v.dataType, permission:v.permission}
       })}   
        options={{
            padding: "dense", 
            pageSize: len, 
            pageSizeOptions: [5, 10, 20],
        }}
        components={{
            Header: (props) => {
              return (<TableHead {...props} style={{}}>
                <TableRow>
                  <TableCell variant='head' style={{}}>{props.columns[0].title}</TableCell>
                  <TableCell variant='head' align='left' style={{}}>
                    <div style={{display:'flex',  marginRight:'1em', }}>
                      <div>{props.columns[1].title}</div>
                      <div><Tooltip title='Data name' arrow placement='right'>
                      <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                      </Tooltip></div>                      
                    </div>
                  </TableCell>
                  <TableCell variant='head' align='left' width='100%'>
                    <div style={{display:'flex', justifyContent:'start', marginRight:'1em', }}>
                      <div>{props.columns[2].title}</div>
                      <div><Tooltip title='Data consensus description' arrow placement='right'>
                      <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                      </Tooltip></div>                      
                    </div>
                  </TableCell>
                  <TableCell variant='head' align='left' style={{}}>
                    <div style={{display:'flex', justifyContent:'start', marginRight:'1em', }}>
                      <div>{props.columns[3].title}</div>
                      <div><Tooltip title='Data type' arrow placement='right'>
                      <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                      </Tooltip></div>                      
                    </div>
                  </TableCell>
                  <TableCell variant='head' width='100%' align='center' style={{}}>
                    <div style={{display:'flex', justifyContent:'center', marginRight:'1em', }}>
                      <div>{props.columns[4].title}</div>
                      <div><Tooltip title='permission index, Used for write permission on this data' arrow placement='right'>
                      <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                      </Tooltip></div>                      
                    </div>
                  </TableCell>
                  <TableCell variant='head'>{props.columns[5].title}</TableCell>
                </TableRow>
              </TableHead>)
            }, Toolbar: toolbar_props => (
              <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                <Button variant='contained' disabled={Permission.HasPermission(answer, PermissionIndex.repository_policies, true)?.has === false}
                  sx={{ml:'1em', textTransform:'none', padding:'.2em .8em', alignItems:'center', display:'flex'}} 
                  onClick={ () => {
                        setPolicy(INIT_POLICY);
                        setOpenNewModal(true);
                  }}>{<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
                    <span>+ Add Policy</span> 
                    <PermIcon {...make_permIconProp(answer, PermissionIndex.repository_policies)} />
                    </div>}</Button>
                  <MTableToolbar {...toolbar_props} />
              </div>
          ),
          }}/>  
        <Modal disableRestoreFocus  open={openNewModal} onClose={(event, reason)=> {
            setPolicy(INIT_POLICY); 
            setOpenNewModal(false)
        }}>
            <Paper sx={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', width:'42em', p:'1em 2em 2em 2em',
                boxShadow:'none', border:'2px solid', borderColor:theme.palette.primary.main,
            }}>
                <IconButton sx={{float:'right'}} onMouseDown={()=>{ setOpenNewModal(false)}}>
                    <CloseIcon />
                </IconButton>
                <Box width='100%' sx={{textAlign:'center'}}>
                        <Typography variant="h5">{'Add Policy'}</Typography>
                </Box>   
                <div style={{marginTop:'2em'}}>
                    <SettingTitle required title='Name' tips='Data name'/>
                    <SettingInputText maxlength={MAX_NAME_LENGTH} err_empty='Please enter policy name' placeholder='Enter policy name' 
                        autoFocus id='policy-name' value={policy.name} event={(type, value, id) => {
                        policy.name = value;
                        setPolicy({...policy})
                    }}/>
                    <SettingTitle required title='Data type' tips='Data type'/>
                    <SettingValueType value={policy?.dataType ?? RepositoryValueType.String} err='Please select data type' event={(type, value, id) => {
                        policy.dataType = type === 'value' ? value : undefined;
                        setPolicy({...policy})
                    }}/>

                    <SettingTitle title='Permission index' tips='Business-Permissions Index must be greater than or equal to 1000'/>
                    <SettingInputText maxlength={8} placeholder='Enter permission index' type='number' err_bigint='Be positive number' 
                        min={PermissionIndex.user_defined_start} err_min={'Personal permission index >= '+PermissionIndex.user_defined_start}
                         id='policy-permission' sx={{width:'44%'}} value={policy?.permission ? policy.permission.toString() : ''}
                         event={(type, value, id) => {
                            policy.permission = value;
                            setPolicy({...policy})
                    }}/>
                    <SettingTitle title='Description' tips=' '/>
                    <SettingInputText maxlength={MAX_DESCRIPTION_LENGTH} placeholder='Enter Policy, define and interpret data name' 
                        multiline maxRows={3} value={policy.description}
                        event={(type, value, id) => {
                            policy.description = value;
                            setPolicy({...policy})
                    }}/>
                    <button id={props?.id} className='cmdButton' style={{marginTop:'1.5em', textTransform:'none', pointerEvents:'all'}} onClick={()=>{
                        if (!policy.name) {
                            enqueueSnackbar('Policy name invalid', { variant: "error" });
                            document.getElementById('policy-name')?.focus();
                            return;
                        }
                        if (!policy.dataType) {
                            enqueueSnackbar('Policy value type invalid', { variant: "error" });
                            return
                        }
                        if (policy.permission && !Permission.IsValidUserDefinedIndex(policy.permission)) {
                            enqueueSnackbar('Policy permission invalid', { variant: "error" });
                            document.getElementById('policy-permission')?.focus();
                            return
                        }

                        launch('add');
                        setOpenNewModal(false);
                    }}>Add Policy</button>                    
                </div>
            </Paper>                
        </Modal>   
    </Box>
    )}