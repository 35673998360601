
import React, { useState } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Box, Button, Skeleton, Dialog, DialogContent, DialogTitle, IconButton, Typography, Tooltip, Select, MenuItem } from '@mui/material';
import { CoinTypeInfo, PermissionAnswer, Treasury as WowokTreasury, Protocol, ResolveBalance, WithdrawMode, Passport,
   IsValidAddress, MAX_U64, IsValidU64, PermissionIndexType, PermissionIndex, Permission as WowokPermission } from 'wowok';
import { SettingTitle, SettingLaunch, ADDRESS_VALIDATOR, SettingInputText, SettingTips, SettingCoinInput} from '../Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { Transaction as TransactionBlock } from '@mysten/sui/transactions';
import { sliceAddress, generateRandomString } from '../../../util';
import { Address } from '../../util/Address';
import { Transition } from '../../util/Common';
import CloseIcon from '@mui/icons-material/Close';
import { grey } from '@mui/material/colors';
import { load_object_type } from '../../../util';
import EditIcon from '@mui/icons-material/Edit';
import { make_permIconProp } from '../PermCheck';

export interface Guard {
    index?: number;
    guard: string;
    amount: string;
}

export interface BasicData {
    withdraw_mode: WithdrawMode;
    guards: Guard[];
}
  
export default function WithdrawGuard (props:any) {
    const id = props?.contents?.fields?.id?.id ?? '';
  const type = WowokTreasury.parseObjectType(props?.type);
  const permission = props?.contents?.fields?.permission;
  const answer: PermissionAnswer | undefined = props?.perms;

    const [data, setData] = useState<BasicData>({
      withdraw_mode:parseInt(props?.contents?.fields?.withdraw_mode), 
      guards:props?.contents?.fields?.withdraw_guard?.fields?.contents?.map((v:any, index:number) => {
            return {guard:v?.fields?.key, amount:v?.fields?.value, index:index}
        })
      });

    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();

    const [current, setCurrent] = useState<Guard | undefined>(undefined);
    const [coinType, setCoinType] = useState<CoinTypeInfo | 'loading'>(Protocol.Instance().GetCoinTypeInfo(type, (info) => {
        setCoinType(info);
      }));
    
    const tips = <div>The amount can be withdrawn when the guardian condition is met.<br/> {'token decimals: ' + (coinType !== 'loading' ? coinType.decimals.toString() : 'unknown' )}</div>;
    const onAddGuard = (guard:Guard) => {
        if ((!guard.index || guard?.index < 0) && data.guards.length >= WowokTreasury.MAX_WITHDRAW_GUARD_COUNT) {
            enqueueSnackbar('The maximum number of guards: ' + WowokTreasury.MAX_WITHDRAW_GUARD_COUNT.toString(), { variant: "error" });
            return;
        }
        setCurrent({...guard});
    }
    
    const disabledGuard = parseInt(props?.contents?.fields?.withdraw_mode) === WithdrawMode.GUARD_ONLY_AND_IMMUTABLE ||
         !WowokPermission.HasPermission(answer, PermissionIndex.treasury_withdraw_guard)?.has; //@ 不用等于false
    const disabledMode = parseInt(props?.contents?.fields?.withdraw_mode) === WithdrawMode.GUARD_ONLY_AND_IMMUTABLE ||
         !WowokPermission.HasPermission(answer, PermissionIndex.treasury_withdraw_mode)?.has;

    return (
        <Box sx={{maxWidth:'100%', padding:'0 2em', pb:'2em',}}>
            <SettingTips text='If Withdraw Guard Mode is set, Withdraw Guards and mode Settings cannot be modified in the future.' />
            <SettingTitle title='Withdraw Mode' required tips='The permissions for a withdrawal operation will follow the following modes.'
                perm={make_permIconProp(answer, PermissionIndex.treasury_withdraw_mode)}/>
            <Select fullWidth value={data.withdraw_mode}  disabled={disabledMode} autoFocus onChange={e => {
                if (typeof(e.target.value) === 'string') data.withdraw_mode = parseInt(e.target.value);
                else data.withdraw_mode = e.target.value;
                setData({...data});
            }}>
                <MenuItem value={WithdrawMode.PERMISSION}>Internal: Only according to the Permission Object</MenuItem>
                <MenuItem value={WithdrawMode.GUARD_ONLY_AND_IMMUTABLE}>External: Only according to the Withdraw Guards blow, and not reversible after setting</MenuItem>
                <MenuItem value={WithdrawMode.BOTH_PERMISSION_AND_GUARD}>Compound: The Permission Object and Withdraw Guard allow withdrawals, and they can be edited at any time</MenuItem>
            </Select>
            {(data.withdraw_mode == WithdrawMode.BOTH_PERMISSION_AND_GUARD || data.withdraw_mode == WithdrawMode.GUARD_ONLY_AND_IMMUTABLE) && <>
                <div style={{display:'flex', alignItems:'center'}}>
            <SettingTitle title='Withdrawal Guard' tips='When the Guard condition is met, the amount set by the corresponding Guard will be withdrawn.'
                perm={make_permIconProp(answer, PermissionIndex.treasury_withdraw_guard)}/>
            <Button variant='text' style={{fontSize:'1em', fontWeight:300, marginLeft:'1em', textTransform:'none', marginTop:'1em'}} 
                disabled={disabledGuard}
                onClick={()=>{ onAddGuard({index:-1, guard:'', amount:''})}}>+ Add Guard</Button>
            </div>
            <div style={{backgroundColor:'#e8a7d53b', marginTop:'.4em', padding:'.6em 1em', maxWidth:'28em', }}>
                { data.guards.length === 0 && <span className='cmdText' onClick={()=>onAddGuard({index:-1, guard:'', amount:''})} style={{color:grey[500]}}>
                    Please add Guard for withdrawal</span>
                }   
                { data.guards.length > 0 && coinType !== 'loading' && 
                    data.guards.map((v, index:number)=> {
                        const color = disabledGuard ? {color:grey[500]} : {};
                        return (<div style={{display:'flex', alignItems:'center', ...color}}>
                            <span style={{color:grey[500], fontStyle:'italic', paddingRight:'1em'}}>{index+1}</span>
                            <Address address={v.guard} showType={'Guard'} /> 
                            {v.amount !== '' && <span style={{paddingLeft:'1em'}}>{ResolveBalance(v.amount, coinType.decimals) + ' ' + coinType.symbol}</span>}
                            <div style={{marginLeft:'auto'}}>
                            <Tooltip title='Edit' arrow placement="left"> 
                                <IconButton className="cmdText" disabled={disabledGuard} 
                                onClick={()=>{
                                    setCurrent({guard:v.guard, index:index, amount:v.amount});
                                }}><EditIcon sx={{width:'0.6em', height:'0.6em'}}/></IconButton>
                            </Tooltip>
                            <Tooltip title='Delete' arrow placement="right">
                                <IconButton className="cmdText" disabled={disabledGuard}
                                onClick={()=>{
                                    data.guards = data.guards.filter((i)=>i.guard !== v.guard);
                                    setData({...data})
                                }} sx={{ml:'.2em'}}><CloseIcon sx={{width:'0.7em', height:'0.7em'}}/></IconButton>
                            </Tooltip>
                        </div>
                        </div>)
                    })
                }
            </div></>}
          <SettingLaunch text='Launch' event={ async (t) => {
            if (t === 'click') {      
                if (data.withdraw_mode === WithdrawMode.GUARD_ONLY_AND_IMMUTABLE && data.guards.length === 0) {
                    enqueueSnackbar('Please add Withdraw Guard', { variant: "error" });
                    return;
                }
                if (data.guards.filter((v)=>!IsValidAddress(v.guard)).length > 0) {
                    enqueueSnackbar('Guard address '+data.guards[0].guard+' invalid', { variant: "error" });
                    return 
                }
                if (data.guards.filter((v)=>!IsValidU64(v.amount, 1)).length > 0) {
                    enqueueSnackbar('Amount with Guard ' + sliceAddress(data.guards[0].guard) + ' invalid', { variant: "error" });
                    return;
                }

                if (!wallet.connected) {
                    enqueueSnackbar('Please login wallet', { variant: "error" });
                    document.getElementById('header-wallet-cmd')?.click();
                    return 
                }
                //console.log(data)
                try {
                    const txb = new TransactionBlock(); // new session
                    const obj = WowokTreasury.From(txb, type, permission, id);
                    const pid = generateRandomString(8);
                    const perms:PermissionIndexType[] = []; 
              
                    if (data.withdraw_mode !== props?.contents?.fields?.withdraw_mode && props?.contents?.fields?.withdraw_mode != WithdrawMode.GUARD_ONLY_AND_IMMUTABLE) {
                      perms.push(PermissionIndex.treasury_withdraw_mode);
                    }
                    //@ 原模式不是only guard， 且现模式不是permission，则可提交
                    if (data.withdraw_mode !== WithdrawMode.PERMISSION && parseInt(props?.contents?.fields?.withdraw_mode) !== WithdrawMode.GUARD_ONLY_AND_IMMUTABLE) {
                        perms.push(PermissionIndex.treasury_withdraw_guard);
                    }

                    props.PermissionCheck({id:pid, txb:txb, answer:answer, index:perms, handler:
                      (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:Passport) => {
                        try {
                          if (id === pid) {
                            if (index.includes(PermissionIndex.treasury_withdraw_guard)) {
                                obj.remove_withdraw_guard([], true, passport?.get_object());
                                data.guards.forEach((e) => {
                                    obj.add_withdraw_guard(e.guard, BigInt(e.amount), passport?.get_object());
                                })
                             };   
                             if (index.includes(PermissionIndex.treasury_withdraw_mode)) { //@ 后设置模式
                                obj.set_withdraw_mode(data.withdraw_mode);
                            };  
                            passport?.destroy(); // destroy passport
                            props.exe(pid, txb);
                          }
                        } catch (e) {
                          console.log(e)
                          enqueueSnackbar( 'Launch Failed', { variant: "error" });
                        }
                      }
                    });              
                } catch (e) {
                    console.log(e)
                    enqueueSnackbar( 'Launch Failed', { variant: "error" });
                } 
            }
          }}/>
          <Dialog disableRestoreFocus  open={current!==undefined}  fullWidth maxWidth='md' TransitionComponent={Transition}
            keepMounted  onClose={(event, reason)=> { setCurrent(undefined) }}>
            <DialogTitle sx={{textAlign:'center'}} > { 'Withdrawal Guard Setting'}
                <IconButton sx={{float:'right', marginTop:'-.2em'}} onMouseDown={()=>{ setCurrent(undefined)}}> <CloseIcon /> </IconButton>   
            </DialogTitle>
            <DialogContent sx={{mb:'1em'}}>
                { coinType === 'loading' && <>
                    <Skeleton animation='pulse' sx={{height:'4em'}} />
                    <Typography variant='body2' >Fetching Token information...</Typography>
                </>}
                { coinType !== 'loading' && current && <>
                    <SettingTitle title='Guard' required tips='Only transactions that meet Guard conditions can withdraw funds from Treasury object.'/>
                    <SettingInputText value={current?.guard??''} maxlength={66} validator={ADDRESS_VALIDATOR} autoFocus
                    id='treasury-guard-guard' placeholder='Enter the Guard object address'event={(t,v,d) => {
                        current.guard = v;
                        setCurrent({...current});
                    }}/>
                    <SettingTitle title='Amount' required tips={tips}/>
                    <SettingCoinInput placeholder='Enter the amount can withdraw when Guard conditions met' fullWidth value={current.amount} max={MAX_U64.toString()}
                    symbol={coinType.symbol} decimals={coinType.decimals} min='1' id='treasury-withdraw-amount' event={(t,v,i) => {
                        current.amount = v;
                        setCurrent({...current});
                    }}/>
                    <SettingLaunch text='Set' event={async (t,v,d) => {
                        if (!IsValidU64(current.amount, 1)) {
                            enqueueSnackbar('Amount invalid', { variant: "error" });
                            document.getElementById('treasury-withdraw-amount')?.focus();
                            return;
                        }
                        if (!IsValidAddress(current.guard)) {
                            enqueueSnackbar('Guard address invalid', { variant: "error" });
                            document.getElementById('treasury-guard-guard')?.focus();
                            return;
                        }
                        const obj_type =  await load_object_type(current.guard, true); 
                        if (!obj_type || Protocol.Instance().object_name_from_type_repr(obj_type) !== 'Guard') {
                            enqueueSnackbar('Please make sure the address is Guard object', { variant: "error" });
                            document.getElementById('treasury-guard-guard')?.focus();
                            return;
                        }     
                        const f = data.guards.find((v)=>v.guard === current.guard);
                        if (f) {
                            f.amount = current.amount;
                        } else {
                            data.guards.push({guard:current.guard, amount:current.amount});
                        }
                        setCurrent(undefined)
                    }}/>
                </>}

            </DialogContent>
        </Dialog>
        </Box>
    );  
  }