
import React, { useState, useEffect } from 'react';
import '../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Paper, Tab, Tabs, } from '@mui/material';
import { Pannel, a11yProps,  } from '../util/Common';
import { useLocation } from 'react-router-dom';
import Realtime from './Realtime';


export default function Explore (props:any) {
    document.getElementById('root')!.style.transform = 'none';
    document.getElementById('root')!.style.paddingLeft = 'calc(100vw - 100%)'; // 右侧出现滚动条不跳动

    const location = useLocation()
    var t = parseInt(location.hash.slice(1));
    if (t < 0 || t > 3 ||  isNaN(t))  { t = 0; }
    window.location.hash = '#' + t;

    const [value, setValue] = React.useState(t);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      window.location.hash = '#' + newValue;
      setValue(newValue);
    };

    return (
      <Paper sx={{ width: '100%', pt:'1em'}} >
        <Tabs value={value} onChange={handleChange} aria-label="explore" sx={{pl:'1em', pr:'1em',  }}>
          <Tab label="Hot-Active" {...a11yProps(0)} sx={{textTransform:'none'}}/>
        </Tabs>
        <Pannel value={value} index={0}>
            <Realtime {...props}/>
        </Pannel>
        <Pannel value={value} index={1}>
        </Pannel>
        <Pannel value={value} index={2}>
        </Pannel>
        <Pannel value={value} index={3}>
        </Pannel>
    </Paper>
    );
}