import { Box, Button, Typography, Autocomplete, IconButton, TextField, Tabs, Tab } from '@mui/material';
import * as React from 'react';
import '../../../css/Common.css';
import { Handle, Position } from 'reactflow';
import { useTheme} from '@mui/material/styles';
import { useReactFlow, Node} from 'reactflow';
import { useSnackbar } from 'notistack';
import { OriginNode_Dot, Pannel, a11yProps,  } from '../../util/Common';
import CloseIcon from '@mui/icons-material/Close';
import { SettingInputText, SettingTitle, SettingTips } from '../Settings';
import { MAX_NAME_LENGTH, Machine_Node, Permission, PermissionIndex} from 'wowok';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Transition } from '../../util/Common';
import { grey } from '@mui/material/colors';
import { PermIcon } from '../../util/Icons';
import { make_permIconProp } from '../PermCheck';

const Setting = (props:any) => {
    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = React.useState<Machine_Node>({name:props.data.name, pairs:[{prior_node:props.data.name, forwards:[]}]})
    const { getNodes } = useReactFlow();
    const nodes = getNodes().filter((n:Node) => n.id !== props.data.name && n.type !== 'InitNode').map((v)=>v.id);
    const [err, setErr] = React.useState('');
    return (<>
        <SettingTitle title='Node name' />
        <SettingInputText maxlength={MAX_NAME_LENGTH} autoFocus fullWidth placeholder='Enter operation name' value={data.name}
            id='node-name' validator={{
                validator:(v) => { return (v !=='' && !nodes.includes(v)) },
                err:'The name existed in machine modes or no value set'
            }} required event={(type, v, id) => {
                data.name = v;  setErr(type==='value'?'':'error');
                setData({...data});
            }}/>
        <Button variant='contained' disabled={Permission.HasPermission(props.answer, PermissionIndex.machine_node, true)?.has === false}
            sx={{mt:'2em', fontSize:'1.2em', textTransform:'none', padding:'.2em .8em', alignItems:'center', display:'flex'}} 
            onClick={ () => {
                if (err) {
                    enqueueSnackbar('Node name invalid', { variant: "error" });
                    document.getElementById('node-name')?.focus();
                    return;
                }
                props.onLaunch('rename', {old_name:props.data.name, name:data.name});
            }}>{<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
            <span>Launch</span> 
            <PermIcon {...make_permIconProp(props.answer, PermissionIndex.machine_node)} />
            </div>}
        </Button>
    </>)
}

const NextNode = (props:any) => {
    const { enqueueSnackbar } = useSnackbar();
    const { getNodes } = useReactFlow();

    const nodes:string[] = [];
    getNodes().forEach((v) => {
        if (!v.data.name) return 

        // 找到没有连接的节点
        const f = v.data?.pairs?.find((i:any) => i.prior_node === props.data.name);
        const e = nodes.find((j) => j === v.data.name);
        if (f === undefined) {
            if (!e) nodes.push(v.data.name);
        }
        // 允许自回归方式
        //if (!e) nodes.push(v.data.name)
    })

    const [input, setInput] = React.useState('');
    const [error, setError] = React.useState('');

    return (<>
    <SettingTitle title='Next Node name' tips='If there is any node matched, a new next node created.'/>
    <Autocomplete freeSolo={true} forcePopupIcon={false} id='node-next-node-name' autoFocus
        value={input} size='medium'
        renderTags={() => null}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option}>
            <Box
              sx={{
                flexGrow: 1,
                color:grey[700]
              }}
            >
              {option} 
            </Box>
          </li>
        )}
        options={[...nodes].sort((a, b) => {
          // Display the selected labels first.
          let ai = input.indexOf(a);
          ai = ai === -1 ? input.length + nodes.indexOf(a) : ai;
          let bi = input.indexOf(b);
          bi = bi === -1 ? input.length + nodes.indexOf(b) : bi;
          return ai - bi;
        })}
        onInputChange={(event, value, reason)=>{
            setError('')
            setInput(reason === 'clear' ? '' : value);
        }}
        getOptionLabel={(option) => option}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            variant="outlined"
            error = {error ? true:false}
            helperText = {error}
            placeholder='Enter new Node name or select one'
          />
        )}
      />
    <Button variant='contained' disabled={Permission.HasPermission(props.answer, PermissionIndex.machine_node, true)?.has === false}
        sx={{mt:'2em', fontSize:'1.2em', textTransform:'none', padding:'.2em .8em', alignItems:'center', display:'flex'}} 
        onClick={ () => {
            if (!input) {
                enqueueSnackbar('Next node name empty', { variant: "error" });
                document.getElementById('node-next-node-name')?.focus();
                setError('Please enter next Node name')
                return;
            }
            props.onLaunch('next', {name:input, prior_name:props.data.name});
        }}>{<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
        <span>Launch</span> 
        <PermIcon {...make_permIconProp(props.answer, PermissionIndex.machine_node)} />
        </div>}
    </Button> 
    </>)
}
interface NextNodeInfo {
    target:string;
    count:number;
}
const UnNextNode = (props:any) => {
    const { enqueueSnackbar } = useSnackbar();
    const { getNodes } = useReactFlow();
    const [select, setSelect] = React.useState('');
    const [error, setError] = React.useState('');

    const nodes:NextNodeInfo[] = [];
    getNodes().forEach((v) => {
        v.data?.pairs?.forEach((i:any) => {
            if (i.prior_node === props.data.name) {
                const f = nodes.find((j) => j.target === v.data.name);
                if (f) { f.count += i.forwards.length; } 
                else { nodes.push({target:v.data.name, count:i.forwards.length}) }
            }
        })
    })

    return (<>
    <SettingTips text='Forwards between this node and the next node will be deleted.'/>
    <SettingTitle title='Next Node name' tips='Forwards between this node and the next node will be deleted.'/>
    <Autocomplete forcePopupIcon={false} id='node-next-node-name' autoFocus
        size='medium' onChange={(e, value, reason) => {
            setSelect(value?.target ?? '');
            setError(value?.target ? '' : 'Please select next Node name')
        }}
        renderTags={() => null}
        noOptionsText='No Forwards to the next node yet'
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.target}>
            <Box
              sx={{
                flexGrow: 1,
                color:grey[700]
              }}
            >
              {option.target} 
            </Box>
            <Box         
                sx={{
                color:grey[500]
              }}>{option.count + ' Forwards'}</Box>
          </li>
        )}
        options={[...nodes]}
        getOptionLabel={(option) => typeof(option) === 'string' ? option : option.target}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            variant="outlined"
            error = {error ? true:false}
            helperText = {error}
            placeholder='Enter new Node name or select one'
          />
        )}
      />
    <Button variant='contained' disabled={Permission.HasPermission(props.answer, PermissionIndex.machine_node, true)?.has === false}
        sx={{mt:'2em', fontSize:'1.2em', textTransform:'none', padding:'.2em .8em', alignItems:'center', display:'flex'}} 
        onClick={ () => {
            if (!select) {
                enqueueSnackbar('Next node name empty', { variant: "error" });
                document.getElementById('node-next-node-name')?.focus();
                setError('Please select next Node name')
                return;
            }
            props.onLaunch('unnext', {name:select, prior_name:props.data.name});
        }}>{<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
        <span>Launch</span> 
        <PermIcon {...make_permIconProp(props.answer, PermissionIndex.machine_node)} />
        </div>}
    </Button> 
    </>)
}

const DeleteNode = (props:any) => {
    return (<>
    <SettingTips text='All forwards with this node would be Deleted.'/>
    <Button variant='contained' disabled={Permission.HasPermission(props.answer, PermissionIndex.machine_node, true)?.has === false}
        sx={{mt:'2em', fontSize:'1.2em', textTransform:'none', padding:'.2em .8em', alignItems:'center', display:'flex'}} 
        onClick={ () => {
            props.onLaunch('delete', props.data.name);
        }}>{<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
        <span>Delete this Node</span> 
        <PermIcon {...make_permIconProp(props.answer, PermissionIndex.machine_node)} />
        </div>}
    </Button>    
    </> )    
}

export const MachineInitNode = (props:any) => {
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(0);

    const onLaunch = (op:'rename' | 'next' | 'delete', param:any) => {
        setOpen(false);
        props.launch(op, param);
    }

    return  (
        <div className='InitNode' onClick={()=> {
            setOpen(true);
        }}>
        <OriginNode_Dot />
        {/*<Handle type='target' position={Position.Top} style={{width:'1px', height:'1px'}} isConnectableStart={false}></Handle>*/}
        <Handle type='source' position={Position.Bottom} style={{width:'1px', height:'1px'}} isConnectableStart={false}></Handle>
        <Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth={'sm'} disableRestoreFocus
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={()=>setOpen(false)}
            >
            <DialogTitle sx={{textAlign:'center'}} > <div style={{display:'flex', alignItems:'center'}}>
                <Tabs value={value} onChange={(event: React.SyntheticEvent, newValue: number)=>{setValue(newValue)}} >
                    <Tab label="Connect Node"   {...a11yProps(0)} value={0}/>
                    <Tab label="Disconnect Node"   {...a11yProps(1)} value={1}/>
                </Tabs>
                <IconButton className='cmdText' sx={{marginLeft:'auto', marginTop:'-.2em'}} onMouseDown={()=>{setOpen(false)}}> <CloseIcon /> </IconButton>  
            </div>    
            </DialogTitle>
                <DialogContent >
                <Pannel value={value} index={0}>
                    <NextNode {...props} onLaunch={onLaunch}/>
                </Pannel>
                <Pannel value={value} index={1}>
                    <UnNextNode {...props} onLaunch={onLaunch}/>
                </Pannel>
                </DialogContent> 
        </Dialog> 
        </div>
    );
}

const MachineNode = (props:any) => {
    //console.log(props)
    const theme = useTheme();
    const { getEdges } = useReactFlow();
    const [focus, setFocus] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(0);

    const border =  focus? {border:'1px solid', borderColor:theme.palette.primary.main}  : {border:'1px solid grey'} 
    const hasNextNode = getEdges().find((v)=>v.source === props?.id);
    const onLaunch = (op:'rename' | 'next' | 'delete' | 'unnext', param:any) => {
        props.launch(op, param);
        //setOpen(false);
    }
    return (
        <div className='CustomNode' onMouseOver={()=>{setFocus(true);}} onMouseOut={()=>setFocus(false)} >
        <Box padding={1} fontWeight={700} bgcolor={'#eee'} borderRadius={3} 
            style={{display:'inline-block', ...border,}} onClick={(e)=>{
                setOpen(true); 
            }}>
            <Typography>{props.data.name}</Typography> 
        </Box>
        <Handle type='target' position={Position.Top}  isConnectableStart={false}></Handle>
        {hasNextNode && <Handle type='source' position={Position.Bottom}  isConnectableStart={false}></Handle>}
        <Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth={'md'} disableRestoreFocus
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={()=>setOpen(false)}
            >
            <DialogTitle sx={{textAlign:'center'}} > <div style={{display:'flex', alignItems:'center'}}>
                <Tabs value={value} onChange={(event: React.SyntheticEvent, newValue: number)=>{setValue(newValue)}} >
                    <Tab label="Setting"  {...a11yProps(0)} value={0}/>
                    <Tab label="Connect Node"   {...a11yProps(1)} value={1}/>
                    <Tab label="Disconnect Node"   {...a11yProps(2)} value={2}/>
                    <Tab label="Delete"  {...a11yProps(3)} value={3}/>
                </Tabs>
                <IconButton className='cmdText' sx={{marginLeft:'auto', marginTop:'-.2em'}} onMouseDown={()=>{setOpen(false)}}> <CloseIcon /> </IconButton>  
            </div> 
            </DialogTitle>
                <DialogContent sx={{minHeight:'16em'}}>
                <Pannel value={value} index={0}>
                    <Setting {...props} onLaunch={onLaunch}/>
                </Pannel>
                <Pannel value={value} index={1}>
                    <NextNode {...props} onLaunch={onLaunch}/>
                </Pannel>
                <Pannel value={value} index={2}>
                    <UnNextNode {...props} onLaunch={onLaunch}/>
                </Pannel>
                <Pannel value={value} index={3}>
                    <DeleteNode {...props} onLaunch={onLaunch}/>
                </Pannel>
                </DialogContent> 
            </Dialog>          
        </div>
    );
}

export default MachineNode;