import React, { useState } from 'react';
import '../../css/App.css';
import { Paper, Tabs, Tab } from '@mui/material';
import ServiceSales from './ServiceSales';
import { TabPanelProps, Pannel, a11yProps} from '../util/Common';
import { useLocation } from 'react-router-dom';
import Details from './Details';
import Order from './Order';

export default function Service (props:any) {
  const location = useLocation()
  var t = parseInt(location.hash.slice(1));
  if (t < 0 || t > 2 ||  isNaN(t))  { t = 0; }
  window.location.hash = '#' + t;

  const id = props?.contents?.fields?.id?.id ?? '';
  const [value, setValue] = React.useState(t);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    window.location.hash = '#' + newValue;
    setValue(newValue);
  };

    return (
      <Paper sx={{ pl:'1em', pr:'1em', pt:'.4em'}}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Sales list" {...a11yProps(0)} sx={{textTransform:'none', pb:'.2em'}} value={0}/>
            <Tab label="Orders" {...a11yProps(1)} sx={{textTransform:'none', pb:'.2em'}} value={1}/>
            <Tab label="Promise" {...a11yProps(2)} sx={{textTransform:'none', pb:'.2em'}} value={2}/>
          </Tabs>
          <Pannel value={value} index={0}>
              <ServiceSales {...props} />
          </Pannel>
          <Pannel value={value} index={1}>
            <Order {...props} />
          </Pannel>
          <Pannel value={value} index={2}>
            <Details {...props} />
          </Pannel>
      </Paper>
    );
}