
import React, { useState, useEffect } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Box, } from '@mui/material';
import { PermissionAnswer, Machine, Permission, Progress, Machine_Node, PermissionIndex, PermissionIndexType, Passport} from 'wowok';
import { SettingTitle,  SettingLaunch, SettingAddressChips, SettingTips, } from '../Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { Transaction as TransactionBlock } from '@mysten/sui/transactions';
import { Address } from '../../util/Address';
import { make_permIconProp } from '../PermCheck';
import { generateRandomString } from '../../../util';
interface OperatorData {
    operator: string;
    address: string[];
}

interface OperatorInfo {
    prev_node: string;
    next_node: string;
    forward: string;
}

const SolveNodeName = (name:string) => {
    return name ? name : '⚪';
}

const Tips = (props:any) => {
    let info : OperatorInfo[] = [];
    props.nodes.forEach((v:Machine_Node)=>{
        v.pairs.forEach((i)=>{
            i.forwards.forEach((k) => {
                if (k?.namedOperator && k.namedOperator === props.operator) {
                    info.push({prev_node:i.prior_node, next_node:v.name, forward:k.name});
                }
            })
        })
    })   

    return (<>
        {props.operator === Machine.OPERATOR_ORDER_PAYER && <span>OrderPayer can only be modified through the Order agents setting.</span>}
        <br/><span>Forwards in Machine: </span>
        {
         info.map((v)=>{
            const r = v.forward + ' (' + SolveNodeName(v.prev_node) + ' -> ' + SolveNodeName(v.next_node) + ')';
            return (<div>{r}</div>)
         })
        }
    </>)
}

export default function Operator (props:any) {
    //console.log(props)
    const id = props?.contents?.fields?.id?.id ?? '';
    const machine = props?.contents?.fields?.machine;
    const answer: PermissionAnswer | undefined = props?.perms;
    const operators  = Machine.namedOperators(props.nodes);
    const [data, setData] = useState<OperatorData[]>(operators.map((v)=> {
        const addr = props.contents.fields.namedOperator.fields.contents.find((i:any)=>i.fields.key === v);
        return {operator:v, address:addr ? addr.fields.value : []} as OperatorData
    }));

    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();

    const IsModified = (operator:string, address:string[]) => {
        const addr_old = props.contents.fields.namedOperator.fields.contents.find((i:any)=>i.fields.key === operator)?.fields?.value ?? [];
        if (addr_old.length !== address.length) return true;
        if (addr_old.filter((v:string)=>address.includes(v))?.length !== addr_old.length) return true;
        return false;
    }
    const launch = async () => {      
      if (!wallet.connected) {
        enqueueSnackbar('Please login wallet', { variant: "error" });
        document.getElementById('header-wallet-cmd')?.click();
        return 
      }
      if (!answer) {
        enqueueSnackbar('Permission error', { variant: "error" });
        return 
      }

      const txb = new TransactionBlock(); // new session
      const obj = Progress.From(txb, machine, answer?.object!, id);
      const pid = generateRandomString(8);
      props.PermissionCheck({id:pid, txb:txb, answer:answer, index:[PermissionIndex.progress_namedOperator], handler:
        (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:Passport) => {
          try {
            if (id === pid) {
              if (index.includes(PermissionIndex.progress_namedOperator)) {
                data.forEach((v)=> {
                    if (IsModified(v.operator, v.address)) {
                        obj.set_namedOperator(v.operator, v.address);                
                    }
                })
              };  
              passport?.destroy(); // destroy passport
              props.exe(pid, txb);
            }
          } catch (e) {
            console.log(e)
            enqueueSnackbar( 'Launch Failed', { variant: "error" });
          }
        }
      });
    }

    return (
        <Box sx={{maxWidth:'100%', padding:'0 2em', pb:'2em',}}>
            { data.length === 0 &&
                <SettingTips text={<div style={{display:'flex', alignItems:'center'}}>
                    <span>No Progress Operator set by &nbsp;&nbsp;</span>
                    <Address address={machine} showType={true}/>
                    <span>&nbsp;&nbsp;of the Progress.</span>
                </div>}/>
            }
            { data.length > 0 && 
                data.map((v)=> {
                    return (<div>
                        <SettingTitle title={v.operator} tips={<Tips nodes={props.nodes} operator={v.operator}/>} 
                            perm={make_permIconProp(answer, PermissionIndex.progress_namedOperator)}/>
                        <SettingAddressChips placeholder={v.operator === Machine.OPERATOR_ORDER_PAYER ? 'OrderPayer can only be modified through the Order agents setting' : 'Input Operator address and press Enter'}
                            readonly={v.operator === Machine.OPERATOR_ORDER_PAYER}
                            noPerm={Permission.HasPermission(answer, PermissionIndex.progress_namedOperator, true)?.has === false}
                            maxTags={Progress.MAX_NAMED_OPERATOR_COUNT} maxTags_err='Max Operator count'
                                id={'operator-' + v.operator} initValue={v.address} event={(type, value, id) => {
                                  v.address = type === 'value' ? value : null;
                                  setData([...data]);
                        }}/>
                    </div>)
                })
            }
   
            <SettingLaunch text='Launch' event={(t) => {
                if (t === 'click') {
                    launch();
                }
          }}/>
        </Box>
    );  
  }