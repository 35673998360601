
import React, { useState, useRef } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Box, List, ListItem, ListItemButton, Button, ListItemText, IconButton } from '@mui/material';
import { PermissionAnswer, PermissionIndex, Arbitration as WowokArbitration, Permission as WowokPermission, 
    IsValidAddress, IsValidInt, 
    PermissionIndexType, Passport as WowokPassport,
    IsValidU64
} from 'wowok';
import { SettingTitle, ADDRESS_VALIDATOR, SettingInputText, SettingTips} from '../Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { grey } from '@mui/material/colors';
import EditIcon from '@mui/icons-material/Edit';
import { Address } from '../../util/Address';
import { Transaction as TransactionBlock } from '@mysten/sui/transactions';
import { generateRandomString } from '../../../util';
import { make_permIconProp } from '../PermCheck';

interface VotingGuard {
    guard: string,
    voting_weight:string, // bigint
}
export default function Voting (props:any) {
    //console.log(props)
    const id = props?.contents?.fields?.id?.id ?? '';
    const type = WowokArbitration.parseObjectType(props?.type);
    const answer: PermissionAnswer | undefined = props?.perms;
    const permission = props?.contents?.fields?.permission;

    const [current, setCurrent] = useState<VotingGuard>({guard:'', voting_weight:'1'});

    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();

    const launch = async (op: 'add' | 'delete', param?:any) => {      
      if (!wallet.connected) {
        enqueueSnackbar('Please login wallet', { variant: "error" });
        document.getElementById('header-wallet-cmd')?.click();
        return 
      }
            
        const txb = new TransactionBlock();
        const obj = WowokArbitration.From(txb, type, permission, id);
        const pid = generateRandomString(8);
        props.PermissionCheck({id:pid, txb:txb, answer:answer, index:[PermissionIndex.arbitration_voting_guard], handler:
            (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:WowokPassport) => {
                try {
                    if (id === pid) {
                        if (index.includes(PermissionIndex.arbitration_voting_guard)) {
                            if (op === 'add') {
                                obj.add_voting_guard([{guard:current.guard, voting_weight:current.voting_weight}], passport?.get_object());
                            } else if(op === 'delete') {
                                obj.remove_voting_guard([param as string], undefined, passport?.get_object());
                            }
                        };  
                        passport?.destroy(); // destroy passport
                        props.exe(pid, txb);
                    }
                } catch (e) {
                    console.log(e)
                    enqueueSnackbar( 'Launch Failed', { variant: "error" });
                }
        }});
    }
  
    return (
        <Box sx={{maxWidth:'100%', padding:'0 2em', pb:'4em',}}>
            <SettingTips text='If there are no relevant guard, you can&nbsp;' command=' Build New Guard' to='/guard'/>
            <SettingTitle title='Voting Guard and Weight' perm={make_permIconProp(answer, PermissionIndex.arbitration_voting_guard)}
                tips='The vote weight when the guard condition is met. '/>
            <div style={{display:'flex', alignItems:'stretch', justifyContent:'space-between'}}>
                <SettingInputText placeholder='Enter the guard address'  maxlength={66} autoFocus noPerm={WowokPermission.HasPermission(answer, PermissionIndex.arbitration_voting_guard)?.has === false}
                    validator={ADDRESS_VALIDATOR} sx={{minWidth:'40em'}} value={current.guard} 
                    id='guard-guard' event={(type, value, id) => {
                        current.guard = value;
                        setCurrent({...current});
                    }}/>     
                <SettingInputText maxlength={12} type="number" noPerm={WowokPermission.HasPermission(answer, PermissionIndex.arbitration_voting_guard)?.has === false}
                    sx={{minWidth:'4em', ml:'1em',}} min={1} err_min="Be greater than 0"  noNumberSpin 
                    placeholder='Enter voting weight'
                    id='guard-weight' value={current.voting_weight} event={(type, value, id) => {
                        current.voting_weight = value
                        setCurrent({...current});
                    }}
                />        
                <Button variant='contained' sx={{ml:'auto', height:'4em'}} disabled={WowokPermission.HasPermission(answer, PermissionIndex.arbitration_voting_guard)?.has === false}
                onClick={() => {
                    if (!current.guard || !IsValidAddress(current.guard)) {
                        enqueueSnackbar('Guard address invalid', { variant: "error" });
                        document.getElementById('guard-guard')?.focus();
                        return;
                    }
                    if (!current.voting_weight || !IsValidU64(current.voting_weight, 1)) {
                        enqueueSnackbar('Portions invalid', { variant: "error" });
                        document.getElementById('guard-weight')?.focus();
                        return;
                    }

                    if (props?.contents?.fields?.voting_guard?.fields?.contents?.length >= WowokArbitration.MAX_VOTING_GUARD_COUNT) {
                        enqueueSnackbar('Max guard count: ' + WowokArbitration.MAX_VOTING_GUARD_COUNT, { variant: "error" });
                        return
                    }
                    launch('add');
                }}>+ Set</Button>            
            </div>
            { props?.contents?.fields?.voting_guard?.fields?.contents?.length !== 0 &&
            <List sx={{border:'1px solid', borderColor:grey[200], mt:'.4em'}}>
                { props?.contents?.fields?.voting_guard?.fields?.contents?.map((v:any, index:number) => {
                   return ( <ListItem key={v.fields.key} component="div" disablePadding sx={{width:'70%'}} >
                        <ListItemButton sx={{pl:'.7em', pr:'.7em', color:grey[600]}}>
                            <ListItemText sx={{fontStyle:'italic', maxWidth:'2em', color:grey[500],}}>{index+1+'.'}</ListItemText>
                            <ListItemText sx={{width:'10em'}}><Address address={v.fields.key} showType={'Guard'}/>
                            </ListItemText>

                            <ListItemText sx={{width:'8em'}}>{v.fields.value}</ListItemText>
                            <IconButton sx={{ml:'1em'}} className='cmdText' disabled={WowokPermission.HasPermission(answer, PermissionIndex.arbitration_voting_guard)?.has === false}
                            onClick={() => {
                                current.guard = v.fields.key; current.voting_weight = v.fields.value;
                                setCurrent({...current});
                            }}><EditIcon sx={{width:'.6em', height:'.6em'}}/></IconButton>
                            <IconButton className='cmdText' disabled={WowokPermission.HasPermission(answer, PermissionIndex.arbitration_voting_guard)?.has === false}
                            onClick={() => {
                                launch('delete', v.fields.key);
                            }}><CloseIcon sx={{width:'.7em', height:'.7em', ml:'.2em'}}/></IconButton>
                        </ListItemButton>   
                    </ListItem>)                      
                    })
                }
                </List>                   
            }
        </Box>
    );  
}