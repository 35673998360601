
import React, { useState, useEffect } from 'react';
import '../../css/Launch.css';
import '../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Paper, Tabs, Tab, Box, Typography, Tooltip } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Data from './Data';
import Details from './Details';
import { Pannel, a11yProps} from '../util/Common';
import { Treasury as WowokTreasury, CoinTypeInfo, Protocol, ResolveBalance} from "wowok";
import { grey, pink } from '@mui/material/colors';

export default function Treasury (props:any) {  
    //console.log(props)
    const location = useLocation()
    var t = parseInt(location.hash.slice(1));
    if (t < 0 || t > 1 ||  isNaN(t))  { t = 0; }
    window.location.hash = '#' + t;

    const id = props?.contents?.fields?.id?.id ?? '';
    const [value, setValue] = React.useState(t);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        window.location.hash = '#' + newValue;
        setValue(newValue);
    };
    
    return (<>
        <Balance {...props} />
        <Paper sx={{ width: '100%', pt:'.4em',}} >
            <Tabs value={value} onChange={handleChange} sx={{height:id ? '3em' : '3.8em', margin:'.4em 1em'}}>
                <Tab label='Flows' {...a11yProps(0)} sx={{textTransform:'none', pb:'.2em'}} value={0}/>
                <Tab label="Promise"  {...a11yProps(1)} sx={{textTransform:'none', pb:'.2em'}} value={1}/>
            </Tabs>
            <Pannel value={value} index={0}>
                <Data {...props}/>
            </Pannel>
            <Pannel value={value} index={1}>
                <Details {...props} />
            </Pannel>
        </Paper>
    </>);
}

function Balance(props:any) {
    const type = WowokTreasury.parseObjectType(props?.type)
    const [coinType, setCoinType] = useState<CoinTypeInfo | 'loading'>(Protocol.Instance().GetCoinTypeInfo(type, (info) => {
        setCoinType(info);
      }))

    const balance  = BigInt(props.contents.fields?.balance ?? 0);
    const deposits = BigInt(props.contents.fields?.inflow ?? 0);
    const withdraws  = BigInt(props.contents.fields?.outflow ?? 0);

    let tips = ''; let dep = ''; let wit = '';
    if (coinType !== 'loading') {
        tips = ResolveBalance(balance.toString(), coinType.decimals);
        dep = ResolveBalance(deposits.toString(), coinType.decimals);
        wit = ResolveBalance(withdraws.toString(), coinType.decimals);
    }

    return (<Box margin='1em 0em'  width="100%" height="10em" bgcolor="primary.main" >
            <Box display="flex" width="100%" justifyContent="center" alignItems="center" height='10em'>
                <Tooltip title='Current balance' arrow placement='top'>
                <Typography  variant="h3" gutterBottom fontWeight="800">
                    {tips}
                </Typography>
                </Tooltip>
                <Tooltip title={type} arrow >
                    <Typography  variant="h5" gutterBottom fontWeight="200" paddingLeft="0.4em" paddingTop="0.2em">
                        {coinType !== 'loading' && coinType.symbol}
                    </Typography>
                </Tooltip>
            </Box>
            {(deposits > 0 || withdraws > 0) && <Box marginTop={'-2em'} sx={{width:'100%', textAlign:'center', fontWeight:'400', color:grey[700], marginBottom:'1em', display:'flex'}}>
                <Box sx={{width:'50%', textAlign:'right', borderRight:'solid 1px', borderColor:'#ea74c7a3'}}>
                    <Tooltip title='Total inflow amount' arrow >
                    <span style={{marginRight:'.6em', }}>{dep}</span>
                    </Tooltip>
                </Box>
                <Box sx={{textAlign:'left'}}>
                    <Tooltip title='Total outflow amount' arrow >
                    <span style={{marginLeft:'.6em' }}>{wit}</span>
                    </Tooltip>
                </Box>
            </Box>}
        </Box>)
}