

import '../../css/Launch.css';
import '../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Box } from '@mui/material';
import { Repository_Policy_Mode, Repository, } from 'wowok';
import { SettingTitle,  SettingLaunch, SettingAddressChips, SettingShowValue, 
} from '../launch/Settings';
import { Address } from '../util/Address';
import { NotSetYet } from '../util/Common';

  
export default function Details (props:any) {
    const id = props?.contents?.fields?.id?.id ?? '';
    const mode = id ? props?.contents?.fields?.policy_mode as Repository_Policy_Mode: Repository_Policy_Mode.POLICY_MODE_FREE;
    const txt_mode = mode===Repository_Policy_Mode.POLICY_MODE_FREE ? 'Relax mode' : 'Strict mode';

    const tips = 'Relax mode: Allows entry of data other than policy. Used for informal, non-consensus situations.\nStrict mode: Prohibits entry of data beyond policy. Used in formal, fully consensus contexts.'
    return (
        <Box sx={{maxWidth:'100%', padding:'0 2em', pb:'2em', mt:'2em'}}>
          <SettingShowValue title='Permission' tips='Manage objects and personnel. Achieve separation of operations and management.'
          value={<Address address={props?.contents?.fields?.permission ?? ""} showType={'Permission'}  maxWidth='20em'/> }/>
          <SettingShowValue title='Policy mode'  tips={<div style={{ whiteSpace: 'pre-line' }}>{tips}</div>} value={txt_mode}/>
          <SettingShowValue title='Reference' tips='Confirm that Objects that reference this repository' 
            value={props?.contents?.fields?.reference?.length === 0 ? <NotSetYet/> : undefined}/>
          {props?.contents?.fields?.reference?.length > 0 && <SettingAddressChips readonly={true}
              maxTags={Repository.MAX_REFERENCE_COUNT} maxTags_err='Max reference count' 
                  initValue={props?.contents?.fields?.reference??[]} />}
          <SettingLaunch text='More...' event={(type, value, id) => {
            if (type === 'click') {
                window.location.href = '/' + (props?.contents?.fields?.id?.id ?? '') + '?setting';
            }
            }}/>
        </Box>
    );  
  }