
import React, { useState, useEffect } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTheme } from '@mui/material/styles';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import SettingHeader from '../SettingHeader';
import { useLocation } from 'react-router-dom';
import Basic from './Basic';
import { Machine_Node, query_object, Machine } from 'wowok';
import { Pannel, a11yProps,  } from '../../util/Common';
import Parent from './Parent';
import {store_key_machine_nodes} from '../../../util';
import Operator from './Operator';

export default function ProgressSetting (props:any) {  
    //console.log(props)
    const location = useLocation()
    var t = parseInt(location.hash.slice(1));
    if (t < 0 || t > 2 ||  isNaN(t))  { t = 0; }
    window.location.hash = '#' + t;

    const id = props?.contents?.fields?.id?.id ?? '';
    const machine = props?.contents?.fields?.machine;
    const [machineNodes, setMachineNodes] = useState<Machine_Node[] | null>(null);

    useEffect(()=>{ // machine nodes 不会再改变，所以可以缓存
      const store = localStorage.getItem(store_key_machine_nodes(machine));
      if (store) {
        const nodes = JSON.parse(store);
        setMachineNodes(nodes); 
      } else {
        query_object({id:machine, onFieldsRes:(id, fieldsResult)=>{
          const nodes = Machine.rpc_de_nodes(fieldsResult);
          localStorage.setItem(store_key_machine_nodes(machine), JSON.stringify(nodes));
          setMachineNodes(nodes); 
        }
      })}      
    }, []);

    const theme = useTheme();
    const [value, setValue] = React.useState(t);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      window.location.hash = '#' + newValue;
      setValue(newValue);
    };
      
    return (
      <Paper sx={{ width: '100%', pt:'.4em'}} >
        <SettingHeader name={id ? 'Progress Setting' : 'Launch Progress'}/>

        <Tabs value={value} onChange={handleChange} sx={{height:id ? '3em' : '3.8em', margin:'.4em 1em'}}>
          <Tab label="Basic" {...a11yProps(0)} sx={{textTransform:'none'}}/>
          <Tab disabled={id ? undefined : true}
            icon={id ? undefined : <VpnKeyIcon className='trans270' 
            sx={{with:'0.6em', height:'0.6em', marginRight:'-0.1em!important'}}/>} iconPosition="start" 
            label={'Operator'} {...a11yProps(1)} sx={{textTransform:'none'}}/>
          <Tab disabled={id ? undefined : true}
            icon={id ? undefined : <VpnKeyIcon className='trans270' 
            sx={{with:'0.6em', height:'0.6em', marginRight:'-0.1em!important'}}/>} iconPosition="start" 
            label={'Parent'} {...a11yProps(2)} sx={{textTransform:'none'}}/>
        </Tabs>
        <Pannel value={value} index={0}>
            <Basic {...props} />
        </Pannel>
        <Pannel value={value} index={1}>
          {machineNodes && <Operator {...props} nodes={machineNodes}/>}
        </Pannel>
        <Pannel value={value} index={2}>
          <Parent {...props} />
        </Pannel>
    </Paper>
    );
}

