
import React, { useEffect, useRef, useState } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { useTheme} from '@mui/material/styles';
import { Box, Dialog, Button, Tooltip, IconButton, Chip, Select, Autocomplete, TextField, MenuItem } from '@mui/material';
import { Repository, RepositoryValueType, array_unique, MAX_NAME_LENGTH, RepData, RepositoryValueTypeInfo,
    IsValidAddress} from 'wowok';
import { SettingTitle, SettingInputText, SettingValueType, ADDRESS_VALIDATOR, SettingChips, U64_VALIDATOR, 
} from '../Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { Index } from '../../../util';
import MaterialTable, {MTableToolbar} from "material-table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import { grey } from '@mui/material/colors';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { PolicyData } from './Policy'
import { Address } from '../../util/Address';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Transition } from '../../util/Common';
import { generateRandomString } from '../../../util';
import { Transaction as TransactionBlock } from '@mysten/sui/transactions';
  
export default function Data (props:any) {
    //console.log(props?.fields)
    const EmptyData = (type:RepositoryValueType=RepositoryValueType.String) => {
        return {name:'', object:'', id:'', dataType:type, data:''}
    } 

    const id = props?.contents?.fields?.id?.id ?? '';
    const permission = props?.contents?.fields?.permission;
    const policies = props.policies as PolicyData[];

    const rep: RepData[] = Repository.rpc_de_data(props?.fields);
    const [data, setData] = useState<RepData[]>([EmptyData(RepositoryValueType.Address), EmptyData(RepositoryValueType.String), 
        EmptyData(RepositoryValueType.PositiveNumber), EmptyData(RepositoryValueType.Address_Vec), 
        EmptyData(RepositoryValueType.String_Vec), EmptyData(RepositoryValueType.PositiveNumber_Vec), 
        EmptyData(RepositoryValueType.Bool),
    ]);
    const [type, setType] = useState<RepositoryValueType | undefined>(undefined);
    const [nameErr, setNameErr] = useState('');
    const theme = useTheme();
    const typeSelect = useRef<HTMLInputElement>(null);
    
    const selectData = (d: RepData) => {
        let f = data.find((v)=>v.dataType === d.dataType); // 每种类型单独存储数据
        if (f) {
            f.data = d.data; f.id = d.id; f.name = d.name; f.object = d.object;
        } else {
            data.push(d);
        }
        setData([...data]);
        setType(d.dataType);
    }

    let len = rep.length;
    len = len >= 20 ? 20 : (len <= 5 ? 5 : 10);

    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();

    const launch = async (op:'add' | 'delete', param:any) => {      
      if (!wallet.connected) {
        enqueueSnackbar('Please login wallet', { variant: "error" });
        document.getElementById('header-wallet-cmd')?.click();
        return 
      }
            
      try {
        const txb = new TransactionBlock();
        const obj = Repository.From(txb, permission, id);
        if (op === 'add') {
            const d = data.find((v)=>v.dataType===type);
            if (d) {
                obj.add_data({key:d.name, value_type:param.type, data:[{address:d.id, bcsBytes:param.data}]});
            } else {
                enqueueSnackbar('Launch Failed', { variant: "error" });
                return ;
            }
        } else if (op === 'delete') {
            obj.remove(param.id, param.name);
        } else {
            enqueueSnackbar('Launch Failed', { variant: "error" });
            return;
        }

        props.exe(generateRandomString(8), txb);
      } catch (e) {
        console.log(e);
      }
    }
    
    const name_options = array_unique(rep.map((v)=>v.name))
    var validator = undefined; // RepositoryValueType.String
    if (type === RepositoryValueType.Address) {
        validator = ADDRESS_VALIDATOR;
    } else if (type === RepositoryValueType.PositiveNumber) {
        validator = U64_VALIDATOR;
    } 

    //console.log(rep)
    return ( <Box className='noboxshadow' sx={{padding:'0 2em'}}>
        <MaterialTable title=''
        columns={[
        { title: '', field: 'index', type: 'numeric', align:'left', width:'1%', cellStyle:{paddingRight:0,}, render:(data, type) => {
            return <div style={{fontStyle:'italic', color:grey[400], fontWeight:200}}>{data.index}</div>
        }},

        { title: 'Name', field: 'name', cellStyle:{paddingRight:0,}, render:(data, type) => {
            const IsPolicy = policies.find((v) =>v.name === data.name);
            const IsPolicyIndexed = policies.find((v) => v.name === data.name && v.permission);

            return (<div style={{display:'flex', alignItems:'center'}} onClick={() => {
                selectData({name:data.name, id:data.id, object:data.object, data:data.data, dataType:data.dataType});
            }}>
                <div className='cmdText' style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', width:'10em'}}> 
                    {data.name}
                { IsPolicy && 
                        <Tooltip title={IsPolicyIndexed?('Policy permission: '+IsPolicyIndexed.permission):'Policy permission NOT set.'}>
                            <VerifiedUserIcon sx={{width:'0.6em', height:'0.6em', ml:'.2em',
                                color:IsPolicyIndexed ? theme.palette.primary.main : grey[400]}}/>
                        </Tooltip>
                }
                </div>
            </div>)
        }},
        { title: 'Address/ID', field: 'id', cellStyle:{paddingRight:0,}, render:(data, type) => {
            return (<div>
                <Address address={data.id} showType={true}/>
            </div>)
        }}, 
        { title: 'Data', field: 'data', cellStyle:{paddingRight:0},render:(data, type) => {
            var type_tips = RepositoryValueTypeInfo.find((v)=>v.type===data.dataType)?.name ;
            if (type_tips) {
                type_tips = 'Data type: ' + type_tips;
            }
            return (<div  style={{maxWidth:'29em', overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis', display:'table-cell'}}/*onClick={() => {
                selectData({name:data.name, id:data.id, object:data.object, data:data.data, dataType:data.dataType});
            }}*/>
            { 
                (data.dataType === RepositoryValueType.Address_Vec || data.dataType === RepositoryValueType.String_Vec || data.dataType === RepositoryValueType.PositiveNumber_Vec) && 
                <Tooltip title={type_tips} arrow placement='left'>
                <span>
                    {
                        (data.data as string[]).map((v)=> {
                            if (IsValidAddress(v)) {
                                return <Chip label={<Address address={v}/>} style={{marginRight:'.4em'}}></Chip>
                            } else {
                                return <Chip label={v} key={Index()} style={{marginRight:'.4em', whiteSpace:'nowrap', overflow:'hidden', 
                                    textOverflow:'ellipsis', maxWidth:'8em'}}/>                                
                            }
                        })
                    }
                </span>
                </Tooltip>
            }
            {
                (data.dataType === RepositoryValueType.Address || data.dataType === RepositoryValueType.String || 
                    data.dataType === RepositoryValueType.PositiveNumber || data.dataType === RepositoryValueType.Bool) &&
                <div style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', width:'29em'}}>
                    <Tooltip title={type_tips} arrow placement='left'>
                        <span>{data.data}</span>
                    </Tooltip>
                </div>
            }
            </div>)
        }}, 
        { title: '', field: 'operation',  render:(data, type) => {
            return (<div style={{display:'flex', alignItems:'center'}}>
                <Tooltip title='Edit data'>  
                    <IconButton  className='cmdText'  sx={{ml:'.4em', padding:'3px'}} onClick={(e) => {
                        selectData({name:data.name, id:data.id, object:data.object, data:data.data, dataType:data.dataType});
                    }}>
                    <EditIcon  className='cmdText' sx={{color:grey[500], height:'.6em!important', width:'.6em!important'}} />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Delete data'>  
                    <IconButton className='cmdText'  sx={{marginLeft:'.1em', padding:'3px'}} onClick={() => {
                        launch('delete', {name:data.name, id:data.id})
                    }}>
                        <DeleteIcon   className='cmdText' sx={{color:grey[500], height:'.6em', width:'.6em'}} />
                    </IconButton>
                </Tooltip>   
            </div>)
        }},
        { title: '', field: 'object', hidden:true, render:(data, type) => {
            return (null)
        }},
        { title: '', field: 'dataType', hidden:true, render:(data, type) => {
            return (null)
        }},
        ]}
        data={rep.map((v, i) => {
            return {index:i+1, name:v.name, id:v.id, data:v.data, object:v.object, dataType:v.dataType}
       })}   
        options={{
            padding: "dense", 
            pageSize: len, 
            pageSizeOptions: [5, 10, 20],
        }}
        components={{
            Header: (props) => {
              return (<TableHead {...props} style={{}}>
                <TableRow>
                  <TableCell variant='head'>{props.columns[0].title}</TableCell>
                  <TableCell variant='head' align='left' style={{minWidth:'8em'}}>
                    <div style={{display:'flex', marginRight:'1em', }}>
                      <div>{props.columns[1].title}</div>
                      <div><Tooltip title='For data consensus. eg. Order number or Delivery time.' arrow placement='right'>
                      <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                      </Tooltip></div>                      
                    </div>
                  </TableCell>
                  <TableCell variant='head' align='left' width='8em'>
                    <div style={{display:'flex', justifyContent:'start', marginRight:'1em', }}>
                      <div>{props.columns[2].title}</div>
                      <div><Tooltip title='eg. Object id or Personal address.' arrow placement='right'>
                      <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                      </Tooltip></div>                      
                    </div>
                  </TableCell>
                  <TableCell variant='head' width='100%' align='center'>
                    <div style={{display:'flex', justifyContent:'start', marginRight:'1em', }}>
                      <div>{props.columns[3].title}</div>
                      <div><Tooltip title='Data stored and collaborative sharing.' arrow placement='right'>
                      <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                      </Tooltip></div>                      
                    </div>
                  </TableCell>
                  <TableCell variant='head'>{props.columns[4].title}</TableCell>
                </TableRow>
              </TableHead>)
            },
            Toolbar: props => (
                <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                 {<Button variant='contained' sx={{ml:'1em', textTransform:'none', padding:'.2em .8em', alignItems:'center', display:'flex'}} 
                    onClick={ () => {
                        const d = EmptyData();
                        selectData(d);
                }}>+ Add Data</Button>}
                    <MTableToolbar {...props} />
                </div>
            ),
          }}/>  
        <Dialog disableRestoreFocus  open={type ? true: false}  fullWidth maxWidth='md' TransitionComponent={Transition}
            keepMounted  onClose={(event, reason)=> {
                setType(undefined)
        }}>
        <DialogTitle sx={{textAlign:'center'}} > Repository Data 
            <IconButton sx={{float:'right', marginTop:'-.2em'}} onMouseDown={()=>{ setType(undefined)}}> <CloseIcon /> </IconButton>   
        </DialogTitle>
        <DialogContent>
            <div style={{display:'flex', marginBottom:'2em', gap:'1em', margin:'auto'}}>
                <div style={{width:'60%'}}>
                    <SettingTitle required title='Name' tips='For data consensus. eg. Order number or Delivery time.'/>
                    <Autocomplete  freeSolo={true} renderTags={() => null}  size='medium' id='data-name' value={data.find((v)=>v.dataType===type)?.name ?? 'aa'} 
                    getOptionLabel={(option) => option} autoFocus
                    options={[...name_options].sort((a, b) => {
                        const name = data.find((v)=>v.dataType===type)?.name ?? '';
                        // Display the selected labels first.
                        let ai = name.indexOf(a);
                        ai = ai === -1 ? name.length + name_options.indexOf(a) : ai;
                        let bi = name.indexOf(b);
                        bi = bi === -1 ? name.length + name_options.indexOf(b) : bi;
                        return ai - bi;
                        })}
                    renderOption={(props, option) => {
                        const IsPolicy = policies.find((v) =>v.name === option);
                        const IsPolicyIndexed = policies.find((v) => v.name === option && v.permission);
                        return (
                            <li {...props} key={option}>
                                <Box
                                sx={{
                                    flexGrow: 1,
                                    color:grey[700]
                                }}
                                >
                                {option as string} 
                                </Box>
                                <Box>                                    
                                {IsPolicy && <div>
                                <Tooltip title={IsPolicyIndexed?('Policy permission: '+IsPolicyIndexed.permission):'Policy permission NOT set.'}>
                                    <VerifiedUserIcon sx={{width:'0.6em', height:'0.6em',
                                        color:IsPolicyIndexed ? theme.palette.primary.main : grey[400]}}/>
                                </Tooltip>    
                            </div>}    
                                </Box>
                            </li>)
                    }}
                    renderInput={(params) => (
                        <TextField {...params} autoFocus variant="outlined"
                            error = {nameErr ? true:false}
                            helperText = {nameErr}
                            defaultValue={data.find((v)=>v.dataType===type)?.name ?? ''}
                            placeholder={'Enter or select data name'}
                            inputProps={{ ...params.inputProps, maxLength:MAX_NAME_LENGTH}}
                        />
                        )}
                    onInputChange={(event, value, reason)=>{
                        setNameErr('');
                        const f = data.find((v)=>v.dataType===type);
                        if (f) {
                            f.name = value;
                            setData([...data]);                                
                        }
                    }}
                    onBlur={() => {
                        if (!data.find((v)=>v.dataType===type)?.name) {
                            setNameErr('Please enter or select data name')
                        }
                    }}
                    />  </div>
                    <div style={{marginLeft:'2em', width:'36%'}}>
                    <SettingTitle required title='Data type' />
                    <SettingValueType value={type??RepositoryValueType.String} err='Please select data type' inputProps={typeSelect} 
                        event={(type, value, id) => {
                            let f = data.find((v)=>v.dataType == value);
                            if (!f) {
                                selectData(EmptyData(value));
                            } else {
                                setType(value);
                            }
                            setData([...data]);
                    }}/>
                </div>
                </div>

                <SettingTitle required title='Address or ObjectID' tips='For some address or id. eg. Object id or Personal address.'/>
                    <SettingInputText maxlength={66} err_empty='Please enter id' placeholder='Enter id' validator={ADDRESS_VALIDATOR}
                        id='data-id' value={data.find((v)=>v.dataType===type)?.id} event={(t, value, id) => {
                            const f = data.find((v)=>v.dataType===type);
                            if (f) {
                                f.id = value;
                                setData([...data])
                            }
                    }}/>
                <SettingTitle title='Data' tips={'Click data to copy.'}/>
                { 
                    (type === RepositoryValueType.Address_Vec || type === RepositoryValueType.String_Vec || type === RepositoryValueType.PositiveNumber_Vec) && 
                    <SettingChips maxTags={100} maxTags_err='max count of data' type={type}  id='data-data' 
                        tags={data.find((v)=>v.dataType===type)!.data as string[]} event={(t, value, id) => {
                            const f = data.find((v)=>v.dataType===type);
                            if (f) {
                                f.data = value;
                                setData([...data])
                            }
                    }}/>
                }
                {
                    (type === RepositoryValueType.Address || type === RepositoryValueType.String || type === RepositoryValueType.PositiveNumber) &&
                    <SettingInputText placeholder='Enter Data' multiline rows={3} maxRows={6} maxlength={Repository.MAX_VALUE_LENGTH / 2}
                        id='data-data' value={data.find((v)=>v.dataType===type)?.data} validator={validator}
                        event={(t, value, id) => {
                            const f = data.find((v)=>v.dataType===type);
                            if (f) {
                                f.data = value;
                                setData([...data])
                            }                                
                    }}/>
                }
                {
                    type === RepositoryValueType.Bool &&
                    <Select placeholder='Selete True or False'  id='data-data' value={data.find((v)=>v.dataType===type)?.data} 
                        fullWidth onChange={(e) => {
                            const f = data.find((v)=>v.dataType===type);
                            if (f) {
                                f.data = e.target.value;
                                setData([...data])
                            }    
                        }}>
                        <MenuItem value={true as any}>True</MenuItem>
                        <MenuItem value={false as any}>False</MenuItem>
                    </Select>
                }
                <button id={props?.id} className='cmdButton' style={{marginTop:'1.5em', display:'block'}} onClick={()=>{
                    const d = data.find((v)=>v.dataType===type);
                    if (!d) return;

                    if (!d.name) {
                        enqueueSnackbar('Data name invalid', { variant: "error" });
                        document.getElementById('data-name')?.focus();
                        return;
                    }
                    if (!d.id || !IsValidAddress(d.id)) {
                        enqueueSnackbar('Data Id invalid', { variant: "error" });
                        document.getElementById('data-id')?.focus();
                        return
                    }

                    const r = Repository.ResolveRepositoryData(d.dataType, d.data);
                    if (!r) {
                        enqueueSnackbar('Data dismatch data type', { variant: "error" });
                        document.getElementById('data-data')?.focus();
                        return
                    }

                    launch('add', r);
                }}>{'Add Data'}</button>   
            </DialogContent>            
        </Dialog>   
    </Box>
)}