import { IconButton } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useTheme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';

export default function SettingHeader (props:any) {
    const theme = useTheme();
    const location = useLocation();

    return ( <div style={{fontWeight:600, fontSize:'1.1em'}}>
        <IconButton sx={{color:theme.palette.primary.main, mr:'-4px', mb:'3px'}} onClick={() => {
      if (location.search.includes('setting')) {
        window.location.href = (location.pathname)
      } else {
        window.history.go(-1);
      }
    }}><NavigateBeforeIcon /></IconButton>
            {props?.name}
    </div>)
}