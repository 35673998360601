
import React, { useState } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Box, Chip } from '@mui/material';
import { BuyRequiredEnum, PermissionAnswer, PermissionIndex, Service as WowokService, Permission,
    PermissionIndexType, Passport
} from 'wowok';
import { SettingTitle, SettingTips, SettingLaunch, SettingInputText, ADDRESS_VALIDATOR
} from '../Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import Select, { SelectProps } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { SettingEvent } from '../Settings';
import { Transaction as TransactionBlock } from '@mysten/sui/transactions';
import { generateRandomString } from '../../../util';
import { make_permIconProp } from '../PermCheck';

type ServiceRequiredProp  = {
    event?: SettingEvent;
    initValue?: string[];
    noPerm?: boolean;
    readonly?: boolean;
} & SelectProps;
  

export function ServiceRequired (props: ServiceRequiredProp) {
    const {event, ...selectProps} = props;
    const [select, setSelect] = useState<string[]>(props?.initValue ?? []);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            },
        },
    };

    const handleDelete = (value:string) => {
        handlechange(select.filter((v) => v !== value));
    }
    const handlechange = (value: string[]) => {
        setSelect(value);
        if (event) {
            event('value', value, props?.id);
        }
    }

    return(<Select disabled = {props?.noPerm === true}
        multiple readOnly={props?.readonly}
        fullWidth
        id={selectProps?.id}
        value={select}
        placeholder={selectProps?.placeholder}
        onChange={(e) => {
            handlechange(e.target.value as string[])
        }}
        renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                <Chip color="primary" key={value} label={value} onDelete={()=>handleDelete(value)} onClick={()=>handleDelete(value)} 
                    onMouseDown={(event) => {
                    event.stopPropagation();
                }}/>
                ))}
            </Box>
        )}
        MenuProps={MenuProps}
    >
        {Object.keys(BuyRequiredEnum).map((i) => (<MenuItem key={i} value={i}>{i}</MenuItem>))}
    </Select>)
}

export interface BuyerData {
    guard: string;
    guard_err: boolean;
    required: string[];
    pubkey: string;
}

export default function Buyer (props:any) {
    const id = props?.contents?.fields?.id?.id ?? '';
    const type = WowokService.parseObjectType(props?.type);
    const permission = props?.contents?.fields?.permission;
    const answer: PermissionAnswer | undefined = props?.perms;

    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();
    let pub = props?.contents?.fields?.customer_required?.fields?.service_pubkey ?? ''; 
    const req:string[] = props?.contents?.fields?.customer_required?.fields?.customer_required_info ?? [];

    const [data, setData] = useState<BuyerData>({
        guard:props?.contents?.fields?.buy_guard ?? '', guard_err:false,
        required:req, 
        pubkey:pub});

    const launch = async () => {
      if (data.guard_err) {
        enqueueSnackbar('Buyer Guard invalid', { variant: "error" });
        document.getElementById('guard')?.focus();
        return;
      }

      if (data.required.length > 0 && !data.pubkey) {
        enqueueSnackbar('Public key not specified', { variant: "error" });
        document.getElementById('pubkey')?.focus();
        return;
      }
  
      if (!wallet.connected) {
        enqueueSnackbar('Please login wallet', { variant: "error" });
        document.getElementById('header-wallet-cmd')?.click();
        return ;
      }

      if (!answer) {
        enqueueSnackbar('Permission error', { variant: "error" });
        return 
      }

      const req = data.required.map((v) => (BuyRequiredEnum[v as keyof typeof BuyRequiredEnum]));
      if (req.length > 0 && !data.pubkey) {
        enqueueSnackbar('Public key invalid', { variant: "error" });
        return ;
      }

      const txb = new TransactionBlock(); // new session
      const obj = WowokService.From(txb, type, permission, id);
      const pid = generateRandomString(8);
      const perms:PermissionIndexType[] = [PermissionIndex.service_customer_required]; //@ 暂时required不做差异化判断和提交

      if (data.guard !== props?.contents?.fields?.buy_guard && (data.guard || props?.contents?.fields?.buy_guard)) {
        perms.push(PermissionIndex.service_buyer_guard);
      }
      
      props.PermissionCheck({id:pid, txb:txb, answer:answer, index:perms, handler:
        (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:Passport) => {
          try {
            if (id === pid) {
              if (index.includes(PermissionIndex.service_buyer_guard)) {
                    obj.set_buy_guard(data.guard);
              };  
              if (index.includes(PermissionIndex.service_customer_required)) {
                    
                    if (data.pubkey && data.required.length > 0) {
                        obj.set_customer_required(data.pubkey, req);
                    }
              };   
              passport?.destroy(); // destroy passport
              props.exe(pid, txb);
            }
          } catch (e) {
            console.log(e)
            enqueueSnackbar( 'Launch Failed', { variant: "error" });
          }
        }
      });
    }

    return (
        <Box sx={{maxWidth:'100%', padding:'0 2em', pb:'2em',}}>
            <SettingTips text='If there are no relevant guard, you can&nbsp;' command=' Build New Guard' to='/guard'/>
            <SettingTitle title='Buyer Guard' tips='Only buyers who meet the conditions can place orders for the service. '
                perm={make_permIconProp(answer, PermissionIndex.service_buyer_guard)}/>
            <SettingInputText placeholder='Enter the guard address'  maxlength={66}
                validator={ADDRESS_VALIDATOR} value={data.guard}
                noPerm={Permission.HasPermission(answer, PermissionIndex.service_buyer_guard, true)?.has === false}
                id='guard' event={(type, value, id) => {
                    data.guard = value;  data.guard_err = type==='value'?false:true
                    setData({...data});
                }}/>

            <SettingTitle title='Required information' tips='Buyer information required, such as contact information and shipping address.'
                 perm={make_permIconProp(answer, PermissionIndex.service_customer_required)}/>
            <ServiceRequired placeholder="Enter the buyer's infomation required" initValue={data.required}
                noPerm={Permission.HasPermission(answer, PermissionIndex.service_customer_required, true)?.has === false}
                id='required' event={(type, value, id) => {
                    data.required = type === 'value' ? value : [];
                    setData({...data});
                }}/>
            <SettingTitle title='Public key' tips="Used to encrypt buyers' private information." required={data.required.length > 0}
                perm={make_permIconProp(answer, PermissionIndex.service_customer_required)}/>
            <SettingInputText placeholder='Enter your public key' maxlength={512} value={data.pubkey}  rows={6} multiline
                err_empty={data.required.length > 0 ? 'Please enter your pulic key to encrypt buyers\' private information': ''}
                noPerm={Permission.HasPermission(answer, PermissionIndex.service_customer_required, true)?.has === false}
                id='pubkey' event={(type, value, id) => {
                    data.pubkey = value;
                    setData({...data});
                }}/>

            <SettingLaunch text='Launch' event={(t) => {
                if (t === 'click') launch();
            }}/>
        </Box>
    );  
}