
import {  Autocomplete, TextField, Select, MenuItem, Avatar, InputAdornment, Tooltip } from '@mui/material';
import * as React from 'react';
import { Node, useReactFlow } from 'reactflow';
import { FirstLetterUppercase, OperatorType, Guard, ValueType, Guard_Options, IsValidAddress, GuardMaker, Protocol, LogicsInfo } from 'wowok';
import { NodeData,} from './Guard';
import '../../../css/Guard.css';
import '../../../css/Common.css';
import { useTheme} from '@mui/material/styles';
import { grey, red } from '@mui/material/colors';
import { SettingInputText, SettingTitle } from '../Settings';
import { useSnackbar } from 'notistack';
import { Icons } from '../../util/Icons';
import { ChangeNodeData } from './GuardGraph';
import { load_object_type } from '../../../util';
import { constTable, ChangeConstTable, constItem, constReference } from './GuardGraph';

export interface  QueryPannelProp  {
    node:Node;
    onSuccess: (bReload:boolean) => void;
}

export default function QueryPannel(props:QueryPannelProp) {
    //console.log(props)
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const { getNodes } = useReactFlow();
    
    // 查询
    React.useEffect(() =>{
        var q: Guard_Options | undefined = undefined;
        const data:NodeData = props.node.data;
        if (data.type === OperatorType.TYPE_QUERY) {
            q = data.cmd === undefined ? undefined : Guard.GetCmdOption(data.cmd);
        } else { //@ logics 加减乘除呢？
            const crunch = Guard.Crunchings.find((v)=>v.value === data.type);
            if (crunch) {
                q = crunch;
            } else {
                q = Guard.Logics().find((v)=>v.value === data.type);             
            }
        }      
        setQueryFunc(q);
        setQueryObj({...data});
        if (data.identifier !== undefined && data.cmd !== undefined) {
            const item = constTable('any').find(v => v.identifier === data.identifier);
            setWitness(item?.bWitness? true : false);
        } else {
            setWitness(false);
        }
        // logics and 和 or 的数量呢？
    }, [props.node.data])

    const [queryFunc, setQueryFunc] = React.useState<Guard_Options | undefined>(undefined);
    const [queryObj, setQueryObj] = React.useState<NodeData>(props.node.data);
    const [witness, setWitness] = React.useState(false);
    const [logics, setLogics] = React.useState<number>(props.node.data.value ?? 2);

    return (<div>
        <SettingTitle title={'Function to Query'} required/>
        <Autocomplete fullWidth freeSolo id='guard-query-func' 
            options={Guard.Options(props.node.data.ret_type)}
            groupBy={(option) => option?.group ?? ''}
            value={queryFunc ?? ''}
            onChange={(event, value, reason) => {
                if (typeof(value) === 'string') {
                    setQueryFunc(undefined);
                } else {
                    setQueryFunc(value ?? undefined);   
                }
                queryObj.value = ''; // 清空值
                queryObj.identifier = undefined; 
            }}
            getOptionLabel={(option) => {
                if (typeof(option) === 'string') {
                    return option;
                }
                return option.name;
            }}
            renderInput={(params) => <TextField {...params} autoFocus value={queryFunc ?? ''} InputProps={{ ...params.InputProps, 
                startAdornment: queryFunc?.from === 'query' ? <InputAdornment position="start">{<span style={{textAlign:'right'}}>
                    {<Avatar style={{ width:22, height:22, padding:'2px 6px 2px 0' ,backgroundColor:grey[50]}}>
                        <Icons type={queryFunc.group} defaultIcon={true}/>
                    </Avatar>}</span>}</InputAdornment> : null,
            }}/>}
            renderGroup={(params) => (
                <li key={params.key} style={{}}>
                    <div style={{color:theme.palette.primary.main, backgroundColor:grey[50], 
                        padding:'.4em .4em', display:'flex', alignItems:'center'}}>
                            <Avatar style={{ width:22, height:22, padding:'2px 6px 2px 0' ,backgroundColor:grey[50]}}
                            ><Icons type={params.group}  defaultIcon={true}/>
                        </Avatar>
                        <span style={{fontSize:'1.2em', fontWeight:300}}>{params.group}</span>
                    </div>
                    <div style={{textIndent:'1em'}}> 
                        {params.children}
                    </div>
                </li>
            )}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderOption={(op_props, option) => {
                
                //const input = cmd ? cmd[6] : '';
                var description = '';
                if (option?.from === 'query') {
                    const cmd = Guard.QUERIES.find((v)=>v[2] === option.value);
                    if (cmd) description = cmd[5];
                }
                if (option?.from === 'type') {
                    const logic = LogicsInfo.find(v => v[0] === option.value);
                    if (logic) description = logic[2] as string;
                }
                return (<li {...op_props} key={option.value} style={{display:'block'}}>
                    <div style={{display:'flex'}}>
                        <span>{option.name}</span>
                        {/*input && <span style={{marginLeft:'1em', color:grey[500]}}>{input}</span>*/}
                    </div>
                    {description &&  <div style={{color:grey[500],}}>{description}</div> }
                </li>)
            }}
        />
        {queryFunc && queryFunc.from === 'query' && <>
            <SettingTitle title='Constant or Witness' required/>
            <Select fullWidth value={witness} onChange={(event) => {
                    setWitness(Boolean(event.target.value));
                    queryObj.value = '';
                    setQueryObj({...queryObj})
                }}>
                <MenuItem value={false as any}>{'Constant ' + queryFunc.group + ' Object'}</MenuItem>
                <MenuItem value={true as any}>{'Witness ' + queryFunc.group + ' Object that provided by the passer when passing Guard'}</MenuItem>
            </Select>                    
        </>}
        {queryFunc && queryFunc.from === 'query' && witness && <>
            <SettingTitle title='Witness ID' required/>
            <Select fullWidth displayEmpty={false} native={false}
            value={queryObj.identifier === undefined ? -1 : queryObj.identifier} 
            id='guard-query-witness' onChange={(e) => {
                if (e.target.value === -1) {
                    queryObj.identifier = -1;
                } else if (typeof(e.target.value) === 'number') {
                    queryObj.identifier = e.target.value;
                } else if (typeof(e.target.value) === 'string') {
                    queryObj.identifier = parseInt(e.target.value)
                } else {
                    queryObj.identifier = -1;
                }
                setQueryObj({...queryObj})
            }} renderValue={(value) => {
                if (value === -1) {
                    return <span>Gen a new ID to tag this object</span>
                } else {
                    const v = constTable('any', true).find((v)=>v.identifier === value);
                    if (!v) <span>Gen a new ID for this object</span>
                    return (<div style={{display:'flex'}}>
                        <span>{queryFunc.group + ' Object '}</span>
                        <span style={{marginLeft:'.2em', color:red[400], fontWeight:1000, fontStyle:'italic'}}>{v!.identifier}</span>
                    </div>)
                }
            }}>
            {
                <MenuItem value={-1}>Gen a new ID for this object</MenuItem>
            }
            { constReference(getNodes(), constTable(ValueType.TYPE_ADDRESS, true)).map((v) => {
                let disabled = false; 
                
                v.cmd.forEach((i) => {
                    const c = Guard.GetCmd(i);
                    if (c && c[0].toLowerCase() !== queryFunc.group?.toLowerCase()) {
                        disabled = true;
                    }
                });
                
                let module = '';
                if (v.cmd.length > 0) {
                    const c = Guard.GetCmd(v.cmd[0]);
                    if (c) module = c[0];
                }
                module = module ? FirstLetterUppercase(module) + ' Object' : '';

                return (
                    <MenuItem style={{display:'flex'}} value={v.identifier} disabled={disabled}>
                    <span>{module}</span>
                    <span style={{marginLeft:'.2em', color:red[400], fontWeight:1000, fontStyle:'italic'}}>{v.identifier}</span>
                    <Tooltip title={v.cmd.length > 0 ? <div> <div>Query cited: </div>
                        { v.cmd.map((v)=> {
                            const c = Guard.GetCmd(v);
                            if (c) {
                                return <div style={{display:'block'}}>{FirstLetterUppercase(c[0]) + '.' + c[1]}</div>
                            } else {
                                return null
                            }
                    })}</div>:'Not cited by query'} arrow placement='left'>
                    <span style={{marginLeft:'auto', color:grey[500]}}>{'Cited ' + v.reference + ' times'}</span>
                    </Tooltip>
                </MenuItem>
                )
            })
            }
        </Select>
        </>}
        {queryFunc && queryFunc.from === 'query' && !witness && <>
        <SettingTitle required title='Object Address' />
        <Autocomplete fullWidth freeSolo id='guard-query-object'
                options={constReference(getNodes(), constTable(ValueType.TYPE_ADDRESS, false))} 
                onChange={(event, value, reason) => { //@select
                    if (typeof(value) !== 'string') {
                        setQueryObj({...queryObj})
                    }
                }}
                onInputChange={(event, value, reason) => {
                    if (value) {
                        value = value.slice(0, 66);
                    }

                    queryObj.value = value;
                    queryObj.identifier = undefined;
                    setQueryObj({...queryObj})
                }}
                value={queryObj.value}
                getOptionLabel={(option) => {
                    if (typeof(option) === 'string') {
                        return option;
                    }
                    return option?.value ?? '';
                }}
                renderOption={(op_props, option, { selected }) => {
                    return ( <li {...op_props} key={option.identifier} style={{display:'flex'}}>
                        <span style={{color:red[400], fontWeight:1000, fontStyle:'italic'}}>{option.identifier}</span>
                        <span style={{marginLeft:'1em'}}>{option.value}</span>
                        <span style={{marginLeft:'auto', marginRight:2, color:grey[500]}}>{'Cited ' + option.reference + ' times'}</span>  
                  </li>)
                }}
                renderInput={(params) => <TextField {...params} placeholder='Enter or Select object address' 
                    InputProps={{ ...params.InputProps, endAdornment: <InputAdornment position="end">
                        {queryObj?.identifier !== undefined && <span style={{textAlign:'right', color:red[400], fontWeight:1000, fontStyle:'italic'}}>{queryObj.identifier}</span>}
                        </InputAdornment>,}} 
                    inputProps={{...params.inputProps, maxLength:66}} 
                    />}
                isOptionEqualToValue={(option, value) => option.value === value}
            />
            </>}
            { queryFunc && queryFunc.from === 'type' && (GuardMaker.is_multi_input_op(queryFunc.value)) &&
                <>
                <SettingTitle title='Number of Items' required tips='It is recommended to use number [2-6].'/>
                <SettingInputText placeholder='Enter number of logical conditions' maxlength={1} min={2} max={6} err_empty='Please enter number[2-6] of logical conditions'
                    err_min='The number needs to be greater than 1'  err_max='The recommended maximum number is 6'
                    id='guard-query-logics' value={logics} type='number' event={(t, v, _) => {
                        setLogics(parseInt(v));
                    }}/>
                </>
            }
        <button className='cmdButton' style={{marginTop: '3em'}}
            onClick={async () => {
                if (!queryFunc) {
                    enqueueSnackbar('Please select Query Function', { variant: "error" });
                    document.getElementById('guard-query-func')?.focus();
                    return;
                } 
                //console.log(queryFunc)
                if (queryFunc.from === 'type') {
                    props.node.data.type = queryFunc.value; // Guard_Options
                    props.node.data.identifier = undefined; // identifier
                    props.node.data.cmd = undefined;
                    if (GuardMaker.is_multi_input_op(queryFunc.value)) {
                        if (logics < 2) {
                            enqueueSnackbar('Number of Items invalid', { variant: "error" });
                            document.getElementById('guard-query-logics')?.focus();
                            return;
                        }
                        props.node.data.value = logics;
                    }
                } else if (queryFunc.from === 'query') {
                    if (!witness) {
                        if (!IsValidAddress(queryObj.value)) {
                            enqueueSnackbar('Object Address Invalid', { variant: "error" });
                            document.getElementById('guard-query-object')?.focus();
                            return;                            
                        }

                        const obj_type =  await load_object_type(queryObj.value, true); 
                        if (!obj_type || !obj_type.includes(Protocol.Instance().Package('wowok'))) {
                            enqueueSnackbar('Invalid Type of the Object', { variant: "error" });
                            document.getElementById('guard-query-object')?.focus();
                            return;
                        }          
                        if (queryFunc.group && !obj_type.toLowerCase().includes(queryFunc.group.toLowerCase())) {
                            enqueueSnackbar('Enter ' + queryFunc.group + ' Type Object', { variant: "error" });
                            document.getElementById('guard-query-object')?.focus();
                            return; 
                        }              
                    } else {
                    } 

                    props.node.data.cmd = queryFunc.value; //@ 命令
                    props.node.data.value = witness ? undefined : queryObj.value; //@ 查询对象
                    if (queryObj.identifier === -1 || queryObj.identifier === undefined) {
                        props.node.data.identifier = ChangeConstTable(getNodes(), queryObj.value, ValueType.TYPE_ADDRESS, witness, witness?queryFunc.value:undefined);
                    } else {
                        props.node.data.identifier = queryObj.identifier;
                    }

                    //@ 查询 data.cmd, witness看type
                    props.node.data.type = OperatorType.TYPE_QUERY;
                }

                try {
                    ChangeNodeData(props.node)
                    props.onSuccess(true);                    
                } catch (e) {
                    console.log(e);
                    enqueueSnackbar('Change node error', { variant: "error" });
                }
            }}>Apply</button>
    </div>)
}