import React, { useState, } from "react";
import '../../css/App.css';
import { Machine_Node, Machine_Node_Pair, IsValidAddress, Progress, Machine_Forward,
  PermissionAnswer, PermissionIndex, PermissionIndexType, Passport, } from 'wowok'
import { Paper, IconButton, Box, Button, MenuItem, Select, DialogContent, Dialog, DialogTitle } from '@mui/material';
import { SettingInputText, SettingLaunch, SettingTitle, ADDRESS_VALIDATOR, SettingEvent } from "../launch/Settings";
import { Transition } from '../util/Common';
import CloseIcon from '@mui/icons-material/Close';
import { Transaction as TransactionBlock } from '@mysten/sui/transactions';
import { useWallet } from "@suiet/wallet-kit";
import { useSnackbar } from 'notistack';
import { make_permIconProp } from '../launch/PermCheck';
import { generateRandomString } from '../../util';
import { Address } from "../util/Address";
import { grey } from "@mui/material/colors";
import { PermIcon, PermissionIconProp } from "../util/Icons";

interface HoldData {
  nextNode:string;
  nextForward:string;
  bHold: boolean;
}

interface PushData {
  nextNode:string;
  nextForward:string;
  deliverables?:string;
  subProgress?:string;
}

export default function Actions(props:any) {
    const wallet = useWallet();
    const { enqueueSnackbar } = useSnackbar();
    const [holdOpen, setHoldOpen] = useState(false);
    const [pushOpen, setPushOpen] = useState(false);
    const [holdData, setHoldData] = useState<HoldData>({nextNode:'', nextForward:'', bHold:true});
    const [pushData, setPushData] = useState<PushData>({nextNode:'', nextForward:''});
    const id = props?.contents?.fields?.id?.id;
    const current = props?.contents?.fields?.current;
    const machine = props?.contents?.fields?.machine;
    const machineNodes:Machine_Node[] = props.machineNodes;
    const nextNodes:Machine_Node[] = props.nextNodes;
    const answer: PermissionAnswer | undefined = props?.perms;

    const next_forward = (node:string) : Machine_Forward[] => {
      const ret = machineNodes.find((v)=>v.name === node)?.pairs?.find(i=>i.prior_node===current)?.forwards;
      return ret ? ret : [];
    }

    const launch = async (op:'push' | 'hold') => {
      if (!wallet.connected) {
        enqueueSnackbar('Please login wallet', { variant: "error" });
        document.getElementById('header-wallet-cmd')?.click();
        return 
      }
      
      if (!answer) {
        enqueueSnackbar('Permission Error', { variant: "error" });
        return;
      }

      try {
        if (op === 'hold') {
          const txb = new TransactionBlock();
          const pid = generateRandomString(8);
          const obj = Progress.From(txb, machine, answer?.object!, id);
          obj.hold({next_node_name:holdData.nextNode, forward:holdData.nextForward}, holdData.bHold);
          props.exe(pid, txb)
        } else if (op === 'push') {
          const f = next_forward(pushData.nextNode)?.find(v => v.name === pushData.nextForward);
          if (!f || (!f?.namedOperator && f?.permission === undefined)) {
            enqueueSnackbar('Launch Failed', { variant: "error" });
            return;
          }

          const txb = new TransactionBlock();
          const pid = generateRandomString(8);
          //@ 注意：id 命名冲突！！
          const obj = Progress.From(txb, machine, answer?.object!, props?.contents?.fields?.id?.id); 
          if (f?.namedOperator) {
            props.GuardCheck({id:pid, txb:txb, guards:f?.guard ? [f.guard] : [], //@BUG FIX [f?.guard ? [f.guard] : []]
              handler:(id:string, txb:TransactionBlock, passport?:Passport)=>{
                if (id === pid) {
                  obj.next({next_node_name:pushData.nextNode, forward:pushData.nextForward}, 
                    pushData.deliverables, pushData.subProgress, passport?.get_object());
                }
                passport?.destroy();
                props.exe(pid, txb);
            }})
          } else if (f?.permission !== undefined) {
            props.PermissionCheck({id:pid, txb:txb, answer:answer, index:[f.permission], handler:
              (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:Passport) => {
                try {
                  if (id === pid) {
                    if (index.includes(f.permission!)) {
                      obj.next({next_node_name:pushData.nextNode, forward:pushData.nextForward}, 
                        pushData.deliverables, pushData.subProgress, passport?.get_object());
                    };    
                    passport?.destroy(); // destroy passport
                    props.exe(pid, txb);        
                  }
                } catch (e) {
                  console.log(e)
                  enqueueSnackbar( 'Launch Failed', { variant: "error" });
                }
              }
            });
          }
        }
      } catch(e) {
          console.log(e)
          enqueueSnackbar( 'Launch Failed', { variant: "error" });
      }
    }

    return (
      <Box sx={{ width: '100%'}} >
        <Paper sx={{ width: '100%'}}>
          {<div style={{display:'flex', alignItems:'center',  /*backgroundColor:'rgba(232,167,213,0.36)'*/}}>
              <Button size="small" style={{marginLeft:'1em', textTransform:'none'}} variant='contained' onClick={()=>{
                if (!wallet.connected) {
                  enqueueSnackbar('Please login wallet', { variant: "error" });
                  document.getElementById('header-wallet-cmd')?.click();
                  return 
                }
                setPushOpen(true);
                }}>Push &#9658;</Button>
              <Button size="small" style={{marginLeft:'2em', textTransform:'none'}} variant='contained' onClick={()=>{
                if (!wallet.connected) {
                  enqueueSnackbar('Please login wallet', { variant: "error" });
                  document.getElementById('header-wallet-cmd')?.click();
                  return 
                }
                setHoldOpen(true)
                }} >Hold &#9889;</Button>
          </div> }
        </Paper>
        <Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth='md' disableRestoreFocus id='progress-hold'
            open={holdOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={()=>setHoldOpen(false)}
            >
            <DialogTitle sx={{textAlign:'center'}} > Progress Hold
                <IconButton sx={{float:'right', marginTop:'-.2em'}} onMouseDown={()=>{setHoldOpen(false)}}> <CloseIcon /> </IconButton>   
            </DialogTitle>
                <DialogContent >
                    <SettingTitle title="Next Node" required tips='Next nodes with its Forward count.'/>
                    <Select autoFocus sx={{width:'100%'}}  id='holddata-nextnode' value={holdData.nextNode} renderValue={(v) => v}
                      onChange={(v)=> {
                        holdData.nextNode = (v.target.value as string);
                        setHoldData({...holdData})
                      }}>
                      {
                        nextNodes.map((v)=>{
                          return (<MenuItem key={v.name} value={v.name} style={{display:'flex'}}>
                              <div>{v.name}</div>                              
                              <div style={{marginLeft:'auto'}}>{next_forward(v.name).length + ' Forward'}</div>
                          </MenuItem>)
                        })
                      }
                    </Select>
                    <SettingTitle title="Forward" required tips='Forwards between nodes with permission(weight/threshold).'/>
                    <ForwardSelect forward_value={holdData.nextForward} next_node={holdData.nextNode} {...props} event={(t:any,v:any,_:any)=>{
                      holdData.nextForward = v;
                      setHoldData({...holdData});
                    }}/>
                    <SettingLaunch text='Launch' event={(t) => {
                        if (t === 'click') {
                          if (!holdData.nextNode) {
                            enqueueSnackbar('Next Node invalid', { variant: "error" });
                            document.getElementById('holddata-nextnode')?.focus();
                            return ;
                          }
                          if (!holdData.nextForward) {
                            enqueueSnackbar('Forward invalid', { variant: "error" });
                            document.getElementById('holddata-nextforward')?.focus();
                            return ;
                          }
                          launch('hold');       
                          setHoldOpen(false);  
                      }
                }}/>
                </DialogContent> 
        </Dialog>
        <Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth='md' disableRestoreFocus id='progress-push'
            open={pushOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={()=>setPushOpen(false)}
            >
            <DialogTitle sx={{textAlign:'center'}} > Progress Push
                <IconButton sx={{float:'right', marginTop:'-.2em'}} onMouseDown={()=>{setPushOpen(false)}}> <CloseIcon /> </IconButton>   
            </DialogTitle>
                <DialogContent >
                <SettingTitle title="Next Node" required tips='Next nodes with its Forward count.'/>
                    <Select autoFocus sx={{width:'100%'}}  id='pushdata-nextnode' value={pushData.nextNode} renderValue={(v) => v}
                      onChange={(v)=> {
                        pushData.nextNode = (v.target.value as string);
                        setPushData({...pushData})
                      }}>
                      {
                        nextNodes.map((v)=>{
                          // const t = v.pairs?.find((i)=>i.prior_node===current)?.threshold ?? 0;
                          // const name = v.name + '(Threshold:' + t + ')';
                          return (<MenuItem key={v.name} value={v.name} style={{display:'flex'}}>
                              <div>{v.name}</div>                              
                              <div style={{marginLeft:'auto'}}>{next_forward(v.name).length + ' Forward'}</div>
                          </MenuItem>)
                        })
                      }
                    </Select>
                    <SettingTitle title="Forward" required tips='Forwards between nodes with permission(weight/threshold).'/>
                    <ForwardSelect forward_value={pushData.nextForward} next_node={pushData.nextNode} {...props} event={(t:any,v:any,_:any)=>{
                      pushData.nextForward = v;
                      setPushData({...pushData});
                    }}/>
                    <SettingTitle title="Deliverables" />
                    <SettingInputText maxlength={66} validator={ADDRESS_VALIDATOR} placeholder="Input deliverables address, if any"
                      value={pushData.deliverables ?? ''}
                      id='pushdata-deleverbles' event={(type, value, id) => {
                        pushData.deliverables = value;
                        setPushData({...pushData})
                      }}/>
                    <SettingTitle title="Sub Progress" />
                    <SettingInputText maxlength={66} validator={ADDRESS_VALIDATOR} placeholder="Input sub Progress address, if any"
                      value = {pushData.subProgress ?? ''}
                      id='pushdata-subprogress' event={(type, value, id) => {
                        pushData.subProgress = value;
                        setPushData({...pushData})
                      }}/>

                    <SettingLaunch text='Launch' event={(t) => {
                    if (t === 'click') {
                      if (!pushData.nextNode) {
                        enqueueSnackbar('Next Node invalid', { variant: "error" });
                        document.getElementById('pushdata-nextnode')?.focus();
                        return ;
                      }
                      if (!pushData.nextForward) {
                        enqueueSnackbar('Forward invalid', { variant: "error" });
                        document.getElementById('pushdata-nextforward')?.focus();
                        return ;
                      }
                      if (pushData?.subProgress && !IsValidAddress(pushData?.subProgress)) {
                        enqueueSnackbar('Sub Progress invalid', { variant: "error" });
                        document.getElementById('pushdata-subprogress')?.focus();
                        return ;
                      }
                      if (pushData?.deliverables && !IsValidAddress(pushData?.deliverables)) {
                        enqueueSnackbar('Deliverables invalid', { variant: "error" });
                        document.getElementById('pushdata-deleverbles')?.focus();
                        return ;
                      }
                      launch('push'); 
                    }
                }}/>
                </DialogContent> 
        </Dialog>  
    </Box>
  );
}

interface ForwardSelectProp {
  forward_value: string;
  next_node: string;
  event: SettingEvent;
} 

interface Operator {
  name: string;
  address: string[];
}
export function ForwardSelect (props: ForwardSelectProp|any) {
  const current = props?.contents?.fields?.current;
  const machineNodes:Machine_Node[] = props.machineNodes;
  const nextNodes:Machine_Node[] = props.nextNodes;
  const answer: PermissionAnswer | undefined = props?.perms;
  const operators: Operator[] = [];
  const wallet = useWallet();

  (props?.contents?.fields?.namedOperator?.fields?.contents as any[])?.forEach((v:any) => {
    const addr : string[] = [];
    v.fields.value.forEach((i:any) => {
        addr.push(i);
    })
    operators.push({name:v.fields.key, address:addr});
  })

  const pair : Machine_Node_Pair|undefined = nextNodes.find((i)=>i.name===props.next_node)?.pairs?.find((k)=>k.prior_node===current);
  
  
  const GetPermProf = (forward_name:string) : PermissionIconProp => {
    var permProf : any = undefined;
    const forward = pair?.forwards?.find((v)=>v.name === forward_name);
    if (forward?.namedOperator) {
      permProf = {object:undefined, 
        permission:operators.find((v)=>v.name === forward?.namedOperator)?.address?.includes(wallet.address??''),
        guard:forward?.guard ? forward.guard.toString() : undefined}; //@ guard可能不是字符串类型
    } else if (forward?.permission !== undefined) {
      permProf = make_permIconProp(answer, forward.permission);
    }
    return permProf
  }

  const next_forward = (node:string) : Machine_Forward[] => {
    const ret = machineNodes?.find((v:Machine_Node)=>v.name === node)
      ?.pairs?.find((i:Machine_Node_Pair)=>i.prior_node===current)?.forwards;
    return ret ? ret : [];
  }

    return (<Select displayEmpty sx={{width:'100%'}} value={props?.forward_value}
    renderValue={(v)=> {
      if (!v) return v;
      return (<div style={{display:'flex', alignItems:'center'}}>
        {v} {<PermIcon {...GetPermProf(v)}/>}
      </div>)
    }} onChange={(v)=> {
      props.event('value', v.target.value as string, props?.id)
    }}>
    { 
        next_forward(props.next_node).map((v)=>{
          const t = pair?.threshold ?? 0;
          const p = GetPermProf(v.name);
          const enable:boolean = p?.permission === true;
          return (<MenuItem key={v.name} value={v.name} style={{display:'flex'}} disabled={!enable}>
              <div style={{display:'flex', alignItems:'center'}}>
                {v.name}
                {<PermIcon {...p}/>}
              </div>                              
              <div style={{marginLeft:'auto', display:'flex',  alignItems:'center', }}>
                <span style={{color:grey[400], fontWeight:200}}>{'Permission: '}</span>
                {v?.namedOperator && 
                    <div className="cmdText" style={{display:'flex', alignItems:'center', fontWeight:400}} >
                      <Address address={props?.contents?.fields?.id?.id} showType='Progress' disableLink={true}/><span>.</span>
                    <span>{v.namedOperator}</span></div>
                }
                {!v?.namedOperator && v?.permission !== undefined &&
                    <div style={{display:'flex', alignItems:'center',  fontWeight:400}} className="cmdText" onClick={() => 
                      window.open('/'+ answer?.object + '#1', '_blank')?.focus()}>
                    <Address address={answer?.object ?? ''} showType='Permission' disableLink={true}/><span>.</span>
                  <span>{v.permission}</span></div>
                }
                <div style={{marginLeft:'1em'}}>
                  <span style={{fontWeight:400}}>{'(' + (v?.weight ?? 1) + '/' + t + ')'}</span>
                </div>
              </div>
          </MenuItem>)
        })
    }
  </Select>)
}

