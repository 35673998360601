
import React, { useReducer } from 'react';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Paper } from '@mui/material';
import GuardGraph from './GuardGraph';
import SettingHeader from '../SettingHeader';
import { Data_Type, DeGuardConstant, ValueType } from 'wowok';
import { generateRandomString } from '../../../util'
import { ReactFlowProvider } from 'reactflow';

export interface NodeData {
  index: number;
  type?: Data_Type | any; // 类型
  value?: any; // 值
  identifier?: number; // 常量表里的id
  cmd?: number; // 查询的命令
  ret_type: ValueType | 'number' | 'any'; // 返回值类型
//  constant?: DeGuardConstant[],
  root?:boolean;
}


export var ROOT_ID = generateRandomString(16);

export default function GuardSetting (props:any) {
  

    return (
      <Paper sx={{ width: '100%', pt:'1em', }} >
        <SettingHeader name={'Launch Guard'}/>
        <ReactFlowProvider>
          <GuardGraph {...props} />
        </ReactFlowProvider>
      </Paper>
    );
}
