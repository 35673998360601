
import '../../css/Launch.css';
import '../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Box } from '@mui/material';
import { SettingLaunch, SettingAddressChips, SettingShowValue, } from '../launch/Settings';
import { Address } from '../util/Address';
import { NotSetYet } from '../util/Common';

export default function Details (props:any) {
    return (
        <Box sx={{maxWidth:'100%', padding:'0 1em', pb:'2em', mt:'2em'}}>
        <SettingShowValue title='Machine' tips='Object that Launch Progress'
            value={<Address address={props?.contents?.fields?.machine ?? ""} showType={'Machine'}  maxWidth='20em'/> }/>
        <SettingShowValue title='Permission' tips='Manage objects and personnel. Achieve separation of operations and management.'
            value={<Address address={props?.perms?.object ?? ""} showType={'Permission'}  maxWidth='20em'/> }/>
        <SettingShowValue title='Task'  tips='Task, eg. Order-id that Progress works for.' 
            value={props?.contents?.fields?.task ? <Address address={props?.contents?.fields?.task} showType={true}  maxWidth='20em'/> : <NotSetYet/>}/>
        <SettingShowValue title='Context Repository' tips='Store the consensus data for this Progress.' 
            value={props?.contents?.fields?.context_repository ? <Address address={props?.contents?.fields?.context_repository} maxWidth='20em' showType={'Repository'}/> : <NotSetYet/>}/>
        <SettingShowValue title='Parent Progress' tips='Progress that combines other Progress' 
            value={props?.contents?.fields?.parent?.fields?.parent ? 
                (<div style={{display:'flex', alignItems:'center'}}>
                    <Address address={props?.contents?.fields?.parent?.fields?.parent} showType={'Progress'} />
                    <div style={{marginLeft:'1em' }}>{'(' + props?.contents?.fields?.parent?.fields?.forward} + ' in Session ' + {props?.contents?.fields?.parent?.fields?.session_index + ')'}</div>
                </div>) : <NotSetYet />
            }/>

        <SettingLaunch text='More...' event={(type, value, id) => {
        if (type === 'click') {
            window.location.href = '/' + (props?.contents?.fields?.id?.id ?? '') + '?setting';
        }
          }}/>
        </Box>
    );  
}