
import React, { useState } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Paper, Tab, Tabs } from '@mui/material';
import Basic from './Basic'
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import SettingHeader from '../SettingHeader';
import { useLocation } from 'react-router-dom';
import { Pannel, a11yProps,  } from '../../util/Common';
import Voting from './Voting';
import Status from './Status';
import Customer from './Customer';

export default function ArbitrationSetting (props:any) {  
  const location = useLocation()
  var t = parseInt(location.hash.slice(1));
  if (t < 0 || t > 3 ||  isNaN(t))  { t = 0; }
  window.location.hash = '#' + t;

  const id = props?.contents?.fields?.id?.id ?? '';
  const [value, setValue] = React.useState(t);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    window.location.hash = '#' + newValue;
    setValue(newValue);
  };
    
    return (
      <Paper sx={{ width: '100%', pt:'.4em', }} >
        <SettingHeader name={id ? 'Arbitration Setting' : 'Launch Arbitration'}/>

        <Tabs value={value} onChange={handleChange} aria-label="reward" sx={{height:id ? '3em' : '3.8em', margin:'.4em 1em'}}>
          <Tab label="Basic" {...a11yProps(0)} sx={{textTransform:'none'}} value={0}/>
          <Tab disabled={id ? undefined : true} value={1}
            icon={id ? undefined : <VpnKeyIcon className='trans270' 
            sx={{with:'0.6em', height:'0.6em', marginRight:'-0.1em!important'}}/>} iconPosition="start" 
            label={'Voting Guard'} {...a11yProps(1)} sx={{textTransform:'none'}}/>
          <Tab disabled={id ? undefined : true} value={2}
            icon={id ? undefined : <VpnKeyIcon className='trans270' 
            sx={{with:'0.6em', height:'0.6em', marginRight:'-0.1em!important'}}/>} iconPosition="start" label="Customer" 
            {...a11yProps(2)} sx={{textTransform:'none'}}/>
          <Tab disabled={id ? undefined : true} value={3}
            icon={id ? undefined : <VpnKeyIcon className='trans270' 
            sx={{with:'0.6em', height:'0.6em', marginRight:'-0.1em!important'}}/>} iconPosition="start" label="Status" 
            {...a11yProps(3)} sx={{textTransform:'none'}}/>
        </Tabs>
        <Pannel value={value} index={0}>
            <Basic {...props}/>
        </Pannel>
        <Pannel value={value} index={1}>
            <Voting {...props}/>
        </Pannel>
        <Pannel value={value} index={2}>
           <Customer {...props}/>
        </Pannel>
        <Pannel value={value} index={3}>
           <Status {...props}/>
        </Pannel>
    </Paper>
    );
}
