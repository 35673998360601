
import '../../css/Common.css';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import {Box} from '@mui/material';

export default function Loading (props:any) {
    return (<Stack spacing={1}>
        <Skeleton variant="rectangular" width='100%' height={160} />
      <Box sx={{width:'100%'}}>
       <Skeleton variant="circular" width={40} height={40} /> 
       <Skeleton variant="text" sx={{ fontSize: '1rem'}} />
      </Box>
      
      <Skeleton variant="rectangular" width='100%' height={260} />
      <Skeleton variant="rounded" width='100%' height={160} />
    </Stack>);
}
