
import React, { useState, useEffect } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import {Box, Tab, Tabs, Paper} from '@mui/material'
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { useLocation } from 'react-router-dom';
import Basic from './Basic';
import Repository from './Repository';
import Status from './Status';
import MachineGraph from './MachineGraph';
import { Pannel, a11yProps,  } from '../../util/Common';
import SettingHeader from '../SettingHeader';
import {ReactFlowProvider} from 'reactflow';

export default function MachineSetting (props:any) {  
    const location = useLocation()
    var t = parseInt(location.hash.slice(1));
    if (t < 0 || t > 3 ||  isNaN(t))  { t = 0; }
    window.location.hash = '#' + t;

    const id = props?.contents?.fields?.id?.id ?? '';
    const [value, setValue] = React.useState(t);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      window.location.hash = '#' + newValue;
      setValue(newValue);
    };
      
    return (
      <Paper sx={{ width: '100%', pt:'.4em'}} >
        <SettingHeader name={id ? 'Machine Setting' : 'Launch Machine'}/>
        <Tabs value={value} onChange={handleChange} sx={{height:id ? '3em' : '3.8em', margin:'.4em 1em'}}>
          <Tab label="Basic" {...a11yProps(0)} sx={{textTransform:'none'}} value={0}/>
          <Tab disabled={id ? undefined : true} value={1} 
            icon={id ? undefined : <VpnKeyIcon className='trans270' 
            sx={{with:'0.6em', height:'0.6em', marginRight:'-0.1em!important'}}/>} iconPosition="start" 
            label={'Graph'} {...a11yProps(1)} sx={{textTransform:'none'}}/>
          <Tab disabled={id ? undefined : true} value={2}
            icon={id ? undefined : <VpnKeyIcon className='trans270' 
            sx={{with:'0.6em', height:'0.6em', marginRight:'-0.1em!important'}}/>} iconPosition="start" 
            label={'Repository'} {...a11yProps(2)} sx={{textTransform:'none'}}/>
          <Tab disabled={id ? undefined : true} value={3}
            icon={id ? undefined : <VpnKeyIcon className='trans270' 
            sx={{with:'0.6em', height:'0.6em', marginRight:'-0.1em!important'}}/>} iconPosition="start" label="Status" 
            {...a11yProps(3)} sx={{textTransform:'none'}}/>
        </Tabs>
        <Pannel value={value} index={0}>
            <Basic {...props}/>
        </Pannel>

        <Pannel value={value} index={1}>
          <ReactFlowProvider>
            <MachineGraph {...props} />
          </ReactFlowProvider>
        </Pannel>

        <Pannel value={value} index={2}>
          <Repository {...props} />
        </Pannel>
        <Pannel value={value} index={3}>
          <Status {...props} />
        </Pannel>
    </Paper>

    );
}

