
import React, { useState } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Box } from '@mui/material';
import { PermissionIndex, PermissionAnswer, Treasury as WowokTreasury, Permission as WowokPermission, IsValidAddress, PermissionIndexType,
  MAX_DESCRIPTION_LENGTH,  Passport,  } from 'wowok';
import { SettingTitle, SettingLaunch, SettingCoinType, ADDRESS_VALIDATOR, SettingInputText, } from '../Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { Transaction as TransactionBlock } from '@mysten/sui/transactions';
import { generateRandomString } from '../../../util';
import { make_permIconProp, make_permOwnerIconProp } from '../PermCheck';
import { Address } from '../../util/Address';
import { useAccount, add_resource_launched } from '../../util/Account';
import { ERROR, Errors } from 'wowok/src/exception';

export interface BasicData {
    description: string;
    new_permission: boolean;
    permission: string;
    token: string;
    deposit_guard?: string;
}
  
export default function Basic (props:any) {
  const id = props?.contents?.fields?.id?.id ?? '';
  const permission = props?.contents?.fields?.permission;
  const answer: PermissionAnswer | undefined = props?.perms;
  const type = WowokTreasury.parseObjectType(props?.type);
    const [data, setData] = useState<BasicData>({
      description:props?.contents?.fields?.description ?? "", 
      permission:props?.contents?.fields?.permission ?? "", 
      token:type, 
      deposit_guard:props?.contents?.fields?.deposit_guard ?? '', 
      new_permission:false});

    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();
    const account = useAccount();

    const launch = async (txb:TransactionBlock, obj?:WowokTreasury) => {
      try {
        if (obj) { // modify old
          if (data.new_permission) {
              let permission = WowokPermission.New(txb, '');
              obj.change_permission(permission.get_object());
              permission.launch();            
          } else if (data.permission !== props?.contents?.fields?.permission) {
            obj.change_permission(data.permission);
          }     
          props.exe(generateRandomString(8), txb);
        } else { // new
            if (data.new_permission) {
                let permission = WowokPermission.New(txb, ''); 
                let d = WowokTreasury.New(txb, data.token, permission.get_object(), data.description);
                if (data.deposit_guard) {
                  d.set_deposit_guard(data.deposit_guard);
                }
                add_resource_launched(account, txb, [d.launch(), permission.launch()]);
                props.exe(generateRandomString(8), txb, 'treasury::Treasury');           
            } else {
              const query_txb = new TransactionBlock(); //@ new session before object.From
              const p = WowokPermission.From(query_txb, data.permission);
              const pid = generateRandomString(8);
              const perm:PermissionIndex[] = [PermissionIndex.treasury];
              if (data.deposit_guard) {
                perm.push(PermissionIndex.treasury_deposit_guard);
              }
              const tips = <div style={{display:'flex', alignItems:'center'}}>
                <Address address={data.permission} />
                <span>: No Permission for Launching Treasury</span>
                </div>;
              p.QueryPermissions(permission, wallet.address??'', perm, (answer:PermissionAnswer) => {
                try {
                  perm.forEach(v => {
                    const i = WowokPermission.HasPermission(answer, v, true);
                    if (!i || !i.has) {
                      enqueueSnackbar(tips, { variant: "error" });
                      ERROR(Errors.Fail, 'treasury new');
                    }
                    //用原有的txb继续提交
                    props.GuardCheck({id:pid, txb:txb, answer:answer, guards:i!.guard ? [i!.guard] : [], handler:
                      (id:string, txb:TransactionBlock, passport?:Passport) => {
                        try {
                          if (id === pid) {
                            let d = WowokTreasury.New(txb, data.token, data.permission, data.description, passport?.get_object());
                            passport?.destroy(); // destroy passport
                            add_resource_launched(account, txb, [d.launch()]);
                            props.exe(generateRandomString(8), txb, 'treasury::Treasury');
                          }
                        } catch (e) {
                          console.log(e)
                          enqueueSnackbar( 'Launch Failed', { variant: "error" });
                        }
                      }
                    });                    
                  })

                } catch(e) {

                }
              });  
            }          
        }
      } catch (e) {
        console.error('excute error: ', e);
        enqueueSnackbar('Launch failed', { variant: "error" });
      }
    }
  
    return (
        <Box sx={{maxWidth:'100%', padding:'0 2em', pb:'2em',}}>
          <SettingTitle title='Description' perm={make_permIconProp(answer, PermissionIndex.treasury_descritption)}/>
          <SettingInputText placeholder='Enter the name or description of the treasury' maxlength={MAX_DESCRIPTION_LENGTH} multiline maxRows={6}
            value={data.description} noPerm={WowokPermission.HasPermission(answer, PermissionIndex.treasury_descritption)?.has === false}
            event={(type, value, id)=>{
              data.description = value;
              setData({...data});
            }}/>
  
          <SettingTitle title='Permission' tips='Manage your objects and personnel. Achieve separation of operations and management.' required={!id} checkbox='Create a New Permission for me' 
            id='permission' noPerm={(!answer || answer.owner)  ? false:true} 
            perm={data.new_permission ? undefined : make_permOwnerIconProp(answer) } event={(type, value, id) => {
              data.new_permission = type === 'check' ? value : false
              setData({...data});
            }}/> 
            {data.new_permission && (<SettingInputText  disabled fullWidth value='Create a new Permission, and as the creator, you hold all permission indexes'
                placeholder='Create a new Permission, and as the creator, you hold all permission indexes' maxlength={66} 
                    sx={{
                        "& .MuiInputBase-input.Mui-disabled": {
                          WebkitTextFillColor: '#A6008B',
                        },
                      }}/>) }
            {!data.new_permission &&  <SettingInputText placeholder='Enter Permission object address' err_empty='Please enter Permission object address' 
                validator={ADDRESS_VALIDATOR} maxlength={66} value={data.permission} noPerm={answer && !answer.owner}
                  id='permission' event={(type, value, id) => {
                    data.permission = value;
                    setData({...data})
                }}/>}
          <SettingTitle title='Token' required={!id} tips='Types name of the token managed by the treasury.'/>
          <SettingCoinType err_empty='Please enter token type name'  placeholder='Enter token type name' 
            disabled={props?.type ? true:false} disablePortal={false}
            id='token' initValue={data.token} event={(type, value, id) => {
              data.token = type === 'value' ? value : '';
              setData({...data})
            }}/>
          <SettingTitle title='Deposit Guard' tips='Deposits from addresses other than the Permission user are allowed. For example, by setting up Everyone Guard, anyone will be allowed to deposit money with the Treasury.' 
            perm={make_permIconProp(answer, PermissionIndex.treasury_deposit_guard)}/>
          <SettingInputText placeholder='Enter Guard object address' 
              validator={ADDRESS_VALIDATOR} maxlength={66} value={data.deposit_guard} noPerm={WowokPermission.HasPermission(answer, PermissionIndex.treasury_deposit_guard)?.has === false}
              id='treasury-deposit-guard' event={(type, value, id) => {
                data.deposit_guard = value;
                setData({...data})
              }}
          />
          <SettingLaunch text='Launch' event={(t) => {
            if (t === 'click') {      
              if (!data.new_permission && !IsValidAddress(data.permission)) {
                enqueueSnackbar('Permission not specified', { variant: "error" });
                document.getElementById('permission')?.focus();
                return;
              }
              
              if (!data.token) {
                enqueueSnackbar('Token not specified', { variant: "error" });
                document.getElementById('token')?.focus();
                return
              }
              if (data.deposit_guard && !IsValidAddress(data.deposit_guard)) {
                enqueueSnackbar('Deposit Guard invalid', { variant: "error" });
                document.getElementById('treasury-deposit-guard')?.focus();
                return                
              }

              if (!wallet.connected) {
                enqueueSnackbar('Please login wallet', { variant: "error" });
                document.getElementById('header-wallet-cmd')?.click();
                return 
              }
              
              if (id) {
                const txb = new TransactionBlock(); // new session
                const obj = WowokTreasury.From(txb, type, permission, id);
                const pid = generateRandomString(8);
                const perms: PermissionIndexType[] = [];
                if (data.description !== props?.contents?.fields?.description) {
                  perms.push(PermissionIndex.treasury_descritption);
                };

                if (data.deposit_guard !== props?.contents?.fields?.deposit_guard && (data.deposit_guard || props?.contents?.fields?.deposit_guard)) {
                  perms.push(PermissionIndex.treasury_deposit_guard);
                }

                props.PermissionCheck({id:pid, txb:txb, answer:answer, index:perms, handler:
                  (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:Passport) => {
                    try {
                      if (id === pid) {
                        if (index.includes(PermissionIndex.treasury_descritption)) {
                          obj.set_description(data.description, passport?.get_object());
                        };  
                        if (index.includes(PermissionIndex.treasury_deposit_guard)) {
                          obj.set_deposit_guard(data.deposit_guard, passport?.get_object());
                        };  
                        passport?.destroy(); // destroy passport
                        launch(txb, obj);          
                      }
                    } catch (e) {
                      console.log(e)
                      enqueueSnackbar( 'Launch Failed', { variant: "error" });
                    }
                  }
                });
              } else {
                launch(new TransactionBlock());
              }
            }
          }}/>
        </Box>
    );  
  }