import { Avatar, Typography } from '@mui/material';
import { getBezierPath, useReactFlow, BaseEdge, EdgeLabelRenderer} from 'reactflow';
import { grey, red, pink } from '@mui/material/colors';
import { NodeData } from './Guard';
import { NumberIcon } from '../../util/Icons';
import { EdgeLabel } from './Label';

const GuardEdge = (props:any) => {
    const {id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, markerEnd, target, source} = props;
    const { getNodes, getEdges } = useReactFlow();

    const s = getNodes().find((v) => v.id === source);
    const d = getNodes().find((v) => v.id === target);
    if (!s || !d) return null;
    const data:NodeData = s.data; 

    // label
    var label:string = EdgeLabel(data);

    // index
    const len = getEdges().filter((v) => v.target === d.id).length;

    const [edgePath, labelX, labelY] = getBezierPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
    });
    
    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={{strokeWidth:2, stroke:grey[600]}} />
            <EdgeLabelRenderer>
                <div
                style={{
                    position: 'absolute',
                    transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                    fontSize: 12,
                    // everything inside EdgeLabelRenderer has no pointer events by default
                    // if you have an interactive element, set pointer-events: all
                    background: grey[200],
                    paddingLeft: 2,
                    paddingRight:2,
                    paddingTop:1,
                    paddingBottom:1,
                    borderRadius: 5,
                    fontWeight: 400,
                    color:grey[600],
                    pointerEvents: 'all',
                }}
                className="nopan edgeObject"
                >
                    <div style={{display:'flex', alignItems:'center', padding:'2px 4px'}}>
                        {len > 1 && <Avatar sx={{width:'.7em', height:'.7em', background: grey[200], color:pink[100], paddingRight:'2px'}} variant='square'>
                            <NumberIcon number={data.index+1} sx={{width:'.7em', height:'.7em'}}/></Avatar>}
                            <Typography>{label}</Typography>
                    </div>
                </div>
            </EdgeLabelRenderer>
        </>
    );
}

export default GuardEdge;