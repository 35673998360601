import { Box, Dialog, DialogTitle, DialogContent, IconButton, Tabs, Tab,} from '@mui/material';
import * as React from 'react';
import { Handle, Position, useReactFlow, Node, Edge, MarkerType } from 'reactflow';
import { ContextType, IsValidValueType, SER_VALUE } from 'wowok';
import { Transition } from '../../util/Common';
import CloseIcon from '@mui/icons-material/Close';
import { NodeData,} from './Guard';
import '../../../css/Guard.css';
import '../../../css/Common.css';
import { a11yProps, Pannel } from '../../util/Common';
import { useTheme} from '@mui/material/styles';
import { green, red } from '@mui/material/colors';
import QueryPannel from './QueryPannel';
import ConstPannel from './ConstPannel';
import { Label, PENDING_SETTING } from './Label';
import { constTable } from './GuardGraph';

const GuardNode = (props:any) => {
    //console.log(props)
    const theme = useTheme();
    const { getEdges } = useReactFlow();
    const data:NodeData = props.data;

    var bConst = 0;
    if (data.type !== undefined && IsValidValueType(data.type)) {
        bConst = 1;
    } else if (data?.cmd === undefined && data?.value && IsValidValueType(data.type)) {
        bConst = 1;
    }

    const [focus, setFocus] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [tab, setTab] = React.useState(0);

    const border =  focus? {border:'1px solid', borderColor:theme.palette.primary.main}  : {border:'1px solid grey'} 

    if (!data) {
        //console.log('err')
        return null;
    }

    const ret_type = () =>  {
        var r = '';
        if (data?.ret_type === 'number' || data?.ret_type === 'any') {
            r = data.ret_type ;
        } else if (data) {
            r = SER_VALUE.find((v)=>v.type === data?.ret_type)?.name ?? '';
        } 
        if (r) { r = ' (' + r + ')' };
        return r
    }

    const txt = Label(data, constTable('any')); // 存储空间的常量
    var bgcolor = '#eee';
    if (open) {
        bgcolor = red[100];
    } else if (bConst) {
        bgcolor = green[50];
    } 

    const onSuccess = (bReload:boolean) => {
        if (bReload) {
            window.location.reload(); 
        } 
        setOpen(false);      
    }

    const handleTab = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
      };

    return (
        <div className='GuardCustomNode' onMouseOver={()=>{setFocus(true);}} onMouseOut={()=>setFocus(false)} >
            <Box padding={1}  bgcolor={bgcolor}  borderRadius={3} id={props.id}  onClick={()=>{ setOpen(true)}} 
                sx={{...border, fontsize:'.8em'}}>
                    <div style={{display:'flex', alignItems:'center'}}>
                        <span style={{color:txt===PENDING_SETTING ? red[300]:'black', padding:'0 8px',
                            whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', maxWidth:'28em'}}>
                            {txt}
                        </span>                       
                    </div>
            </Box>
            {getEdges().find((v)=>v.target === props.id) && <Handle type='target' position={Position.Bottom} isConnectableStart={false}></Handle> }
            {getEdges().find((v)=>v.source === props.id) && <Handle type='source' position={Position.Top} isConnectableStart={false}></Handle> }
            <Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth={'md'} disableRestoreFocus 
                open={open}
                style={{}}
                TransitionComponent={Transition}
                keepMounted
                onClose={()=>setOpen(false)}
                >
                <DialogTitle sx={{textAlign:'center'}} > {'Guard Condition' + ret_type()} 
                    <IconButton sx={{float:'right', marginTop:'-.2em'}} onMouseDown={()=>{setOpen(false)}}> <CloseIcon /> </IconButton>   
                </DialogTitle>
                    <DialogContent >
                        <Tabs value={tab} onChange={handleTab} sx={{mb:'1em'}}>
                            <Tab label="Via querying data on the chain"  {...a11yProps(0)} value={0}/>
                            <Tab label="Via Specifying data by entering"   {...a11yProps(1)} value={1}/>
                        </Tabs>
                    <Pannel value={tab} index={0}>
                        <QueryPannel onSuccess={onSuccess} node={props}/>
                    </Pannel>
                    <Pannel value={tab} index={1}>
                        <ConstPannel onSuccess={onSuccess} node={props}/>
                    </Pannel>
                    </DialogContent> 
            </Dialog> 
        </div>
    );
}

export default GuardNode;