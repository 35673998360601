import React, { useState } from 'react';
import '../../css/Launch.css';
import '../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Tooltip, Button, Paper, Badge, Avatar, IconButton, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import 'reactflow/dist/style.css';
import { useLocation } from "react-router-dom";
import PersonalSetting from '../launch/personal/Personal';
import styled from "styled-components";
import { Entity, Protocol, MAX_DESCRIPTION_LENGTH, Bcs, Resource} from 'wowok';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { useAccount } from '../util/Account';
import { Address } from '../util/Address';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { pink, grey, green, orange } from '@mui/material/colors';
import { SettingInputText } from '../launch/Settings';
import { Icons } from '../util/Icons';
import { Transaction as TransactionBlock } from '@mysten/sui/transactions';
import { generateRandomString } from '../../util';

const ACTIVE_STR = 'Recently Active Onchain'
const NOT_ACTIVE_STR = 'Active long time ago Onchain'

export default function Header(props:any) {
    // const protocol = Protocol.Instance();
    const wallet = useWallet();
    const location = useLocation();
    const id = location.pathname.length === 67 ? location.pathname.slice(1) : '';
    
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const account = useAccount(false);
    const [activeTime, setActiveTime] = React.useState('');

    const likes = props.info.like;
    const dislikes = props.info.dislike;
    let ilike = false; let idislike = false;

    const f1 = account.resource.find((v)=>v.name === Resource.LikeName);
    if (f1) ilike = f1.address.includes(id);

    const f2 = account.resource.find((v)=>v.name === Resource.DislikeName);
    if (f2) idislike = f2.address.includes(id);
    
    if (location.search.toLowerCase().includes('setting')) {
      return <PersonalSetting {...props} />
    }
  
    if (props?.info?.lastActive) {
      Protocol.Client().getTransactionBlock({digest:props?.info?.lastActive}).then((r) => {
        if (r.timestampMs) {
          const tm = new Date();
          const active = tm.getTime() - parseInt(r.timestampMs);
          if (active <= 30*24*60*60*1000) { // 30 days
            setActiveTime(ACTIVE_STR);
          } else {
            setActiveTime(NOT_ACTIVE_STR);
          }
        } else {
          setActiveTime(NOT_ACTIVE_STR);
        }
      })
    }
    const AvatarContainer = styled.div`
        display: flex;
        margin-bottom: 14px;
        & > * {
          margin: 16px;
          margin-right: 0;
        }
      `;
  const AvatarLabel = styled.div`
        margin:4px;
        margin-top:6px;
        & > button {
          text-transform: none;
        }
  `;
  const RightButton = styled.div`
        margin-top:18px;
        margin-right:12px;
        margin-left:auto;
  `;
  const StyledBadge = styled(Badge)(() => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color:  '#44b700',
      boxShadow: `0 0 0 2px `,
      right: '24%',
      height: '6px',
      minWidth: '6px',
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));
  
  const launch = async (op:'like'|'dislike') => {
    if (!wallet.connected) {
      enqueueSnackbar('Please login wallet', { variant: "error" });
      document.getElementById('header-wallet-cmd')?.click();
      return 
    }
      
    try {
      const txb = new TransactionBlock();
      const entity = Entity.From(txb);
      let obj : any;
      if (account.account.resource) {
        obj = Resource.From(txb, account.account.resource);
      } else {
        obj = Resource.From(txb, Entity.From(txb).create_resource2());
      }
      
      entity.mark(obj, id, op);
      if (!account.account.resource) {
        obj.launch();
      }
      props.exe(generateRandomString(8), txb);
    } catch (e) {
      console.log('excute error: ', e);
      enqueueSnackbar('Launch failed', { variant: "error" });
    }
  }

  return (
      <Paper sx={{ width: '100%'}}>
      <AvatarContainer>
          <Tooltip arrow title={props.avatar ? null : 'Personal avatar not set'}>
            <StyledBadge 
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot"
            >
              <Avatar
                style={{ marginRight: "8px" , width:48, height:48, backgroundColor:'transparent'}}
                alt={props.name}
                src={props.avatar ?? undefined}
              >{!props.avatar && <Icons type='personal' />} </Avatar>
            </StyledBadge>     
          </Tooltip>

        <AvatarLabel style={{}}>
          <div style={{display:'flex', alignItems:'center'}}>
            <Box sx={{pl:0, pb:0, color:theme.palette.primary.main}}>{<Address address={id} name={props.name} fontSize={'1.2em'}/>}</Box>
            <Tooltip title='Settings' arrow placement='bottom'>
                <IconButton sx={{m:'4px 3px 0 3px', pd:'3px'}} className='cmdText' onClick={()=> { window.location.href = ('/'+id+'?setting') }}>
                  <MenuOpenIcon className='cmdText' sx={{height:'.8em', width:'.8em', color:idislike ? theme.palette.primary.main:grey[400]}}/>
                </IconButton>  
            </Tooltip>    
          </div>
          <Typography variant='subtitle2' sx={{pb:"0px", pl:'1px', fontWeight:200,mt:"-6px", color:activeTime===ACTIVE_STR ? green[400] : grey[600]}}>{activeTime}</Typography>
        </AvatarLabel>
        <RightButton>
          <Tooltip title='likes' arrow placement='bottom'>
            <Badge anchorOrigin={{vertical:'top', horizontal:'left'}} badgeContent={likes > 0 ? likes : undefined} color="primary" sx={{ "& .MuiBadge-badge": {mt:'6px', mr:'6px', fontSize: 11, fontWeight:100, height: 14, minWidth: 14 } }}>
                <IconButton className='cmdText' sx={{pd:'3px'}} onClick={()=> {
                  launch('like');
                }}>
                <ThumbUpIcon className='cmdText' sx={{height:'.7em', width:'.7em', color:ilike ? theme.palette.primary.main:grey[400]}}/>
                </IconButton>  
            </Badge>
          </Tooltip>
          <Tooltip title='dislikes' arrow placement='bottom'>
            <Badge anchorOrigin={{vertical:'top', horizontal:'left'}} badgeContent={dislikes > 0 ? dislikes : undefined} color="primary" sx={{ "& .MuiBadge-badge": {mt:'6px', ml:'4px', fontSize: 11, fontWeight:100, height: 14, minWidth: 14 } }}>
              <IconButton className='cmdText' sx={{ml:'6px', pd:'3px'}} onClick={()=> {
                  launch('dislike');
                }}>
                <ThumbDownIcon className='cmdText' sx={{height:'.7em', width:'.7em', color:idislike ? theme.palette.primary.main:grey[400]}}/>
              </IconButton>  
            </Badge>
          </Tooltip>
        </RightButton>
      </AvatarContainer>
      </Paper>
    )
}

export function Description(props:any) {
  return (
    <Paper sx={{ width: '100%', mt:-2, mb: 2, borderRadius:'none'}}>
      <SettingInputText placeholder='Description not set yet.' maxlength={MAX_DESCRIPTION_LENGTH} multiline maxRows={6} readOnly
        value={props.description} noneBorder={true} noneScroll={true}
        />
    </Paper>
  )
}