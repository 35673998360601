import React, { useState, ReactNode } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { useTheme} from '@mui/material/styles';
import { Box, Chip, Button, Tooltip, Paper, IconButton, Autocomplete, TextField, Modal, ClickAwayListener, Typography, Avatar,
    Dialog, DialogContent, DialogTitle, Tabs, Tab
 } from '@mui/material';
import { SettingInputText, ADDRESS_VALIDATOR, SettingTitle, SettingLaunch, SettingTips, Validator,
    PersonalPermissionDescription, PersonalPermissionModule
} from '../Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import MaterialTable from "material-table";
import { grey } from '@mui/material/colors';
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { GuardGrey, PermIcon } from '../../util/Icons';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import { PermissionInfo, PermissionInfoType, Permission, IsValidAddress, MAX_NAME_LENGTH, } from 'wowok'
import { Icons } from '../../util/Icons';
import { Address } from '../../util/Address';
import { Transaction as TransactionBlock } from '@mysten/sui/transactions';
import { generateRandomString } from '../../../util';
import { MTableToolbar } from 'material-table';
import EditIcon from '@mui/icons-material/Edit';
import { Pannel, a11yProps, Transition } from '../../util/Common';

interface EntityData {
    entity: string;
    guard?: string;
}
interface IndexData {
    permission: PermissionInfoType;
    entity: EntityData[];
}
interface PersonalPermission {
    name: string;
    index: string;
}



export default function PermissionIndex (props:any) {
    //console.log(props)
    const id = props?.contents?.fields?.id?.id ?? '';
    const theme = useTheme();
    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();
    const bAdmin = (props?.contents?.fields?.admin as string[])?.includes(wallet?.address ?? '');
    const perm = {object:id,  permission:bAdmin, admin:bAdmin};

    let businessPerms: PermissionInfoType[] = props.contents.fields.user_define.fields.contents.map((v:any) => {
        return {index: parseInt(v.fields.key), name:v.fields.value, description: PersonalPermissionDescription, module:PersonalPermissionModule}
    });
    const personal = businessPerms.concat(PermissionInfo);
    
    const index_validator:Validator = {err:PersonalPermissionDescription+' index already existed.', validator:(value:string) => {
        return businessPerms.find((v) => v.index.toString() === value) === undefined
    }}
    const name_validator:Validator = {err:PersonalPermissionDescription+' name already existed.', validator:(value:string) => {
        return businessPerms.find((v) => v.name.toString() === value) === undefined
    }}

    // user_defined + static
    let ids:IndexData[] = personal.map((v) => { 
        const entity: EntityData[]  = [];
        props?.fields?.forEach((i:any) => {
            const f = i.data.content.fields.value.fields.value.fields.contents.find((k:any) => k.fields.key == v.index);
            if (f) {
                entity.push({entity:i.data.content.fields.name, guard: f.fields.value??''});
            }
        })
        return {permission:v, entity:entity};
    }).sort((a,b) => b.entity.length - a.entity.length);

    let len = ids.filter((v)=>v.entity.length > 0).length;
    len = len >= 20 ? 20 : 10;

    const launch = async (op:'add_perm' | 'remove_ent'  | 'add_ent' | 'guard_ent' | 'remove_perm' | 'extra-perm-name',  
        permission?:number, param?:any) => {
        if (!wallet.connected) {
            enqueueSnackbar('Please login wallet', { variant: "error" });
            document.getElementById('header-wallet-cmd')?.click();
            return ;
        }
        const txb = new TransactionBlock();
        const obj = Permission.From(txb, id);

        try {
            if (op === 'add_perm') {
                obj.add_userdefine(parseInt(personalPermission.index), personalPermission.name);
            } else if (op === 'remove_perm') {
                if (permission) obj.remove_userdefine(permission);
            } else if (op === 'remove_ent') {
                if (permission === undefined || !param) return;
                obj.remove_index(param, [permission])
            } else if (op === 'add_ent') {
                if (permission === undefined || !param) return ;
                obj.add_entity2(param, permission);
            } else if (op === 'guard_ent') {
                if (permission === undefined || !param) return ;
                if ((param as EntityData).guard && !IsValidAddress((param as EntityData).guard)) {
                    enqueueSnackbar('Guard address invalid', { variant: "error" });
                    return ;
                }
                obj.set_guard((param as EntityData).entity, permission, (param as EntityData).guard);
            } else if (op === 'extra-perm-name') {
                if (permission === undefined || !param) return ;
                obj.add_userdefine(permission, param);
            } else {
                return 
            }            
            props.exe(generateRandomString(8), txb);
        } catch (e) {
            console.error('excute error: ', e);
            enqueueSnackbar('Launch failed', { variant: "error" });
        }
    }

    const [openNewModal, setOpenNewModal] = useState(false);
    const [personalPermission, setPersonalPermission] = useState<PersonalPermission>({name:'', index:''});
    const [tab, setTab] = useState(0);
    const [select, setSelect] = useState<PersonalPermission | null>(null);


    return ( <Box className='noboxshadow' sx={{padding:'0 2em'}}>
        <MaterialTable title=''
        columns={[
        { title: 'Permission', field: 'permission',  width:'20%',cellStyle:{ paddingRight:0, }, render:(data, type) => {
            return (<div style={{display:'flex', alignItems:'center'}} className='truncate-3-lines'> 
                <Avatar style={{ width:16, height:16, padding:'2px 2px 4px 0' ,backgroundColor:'transparent'}}
                alt={data.permission.module}><Icons type={data.permission.module} /></Avatar>
                <Tooltip title={data.permission.index + ': ' + data.permission.description}>  
                   <p>{data.permission.name}</p>
                </Tooltip>
                {
                    data.permission.module === PersonalPermissionModule && <Tooltip title='Edit or Delete'>
                        <IconButton className='cmdText'  sx={{ml:'.4em', padding:'3px'}} onClick={() => {
                            setSelect({name:data.permission.name, index:data.permission.index.toString()})
                        }}>
                            <EditIcon  className='cmdText' sx={{color:grey[500], height:'.6em!important', width:'.6em!important'}} />
                        </IconButton>
                    </Tooltip>
                }
            </div>)
        }, customFilterAndSearch: (term, rowData, ref) => {
            const t = term.toLowerCase();
            if (rowData.permission.description.toLowerCase().includes(t) || rowData.permission.name.toLowerCase().includes(t) ||
                rowData.permission.index.toString().toLowerCase().includes(t)) {
                return true
            }; return false
        }},
        { title: 'Entity', field: 'entity', render:(data, type) => {
            return (<Entities {...data} info={perm} launch={launch}/>)
        }, customFilterAndSearch: (term, rowData, ref) => {
            const t = term.toLowerCase();
            for (let i = 0; i < rowData.entity.length; ++i) {
                if (rowData.entity[i]?.guard?.toLowerCase()?.includes(t) || rowData.entity[i].entity.toLowerCase().includes(t)) {
                    return true;
                }
            }; return false;
        }},
        ]}
        data={ids.map((v, i) => {
            return {index:i+1, permission:v.permission, entity:v.entity}
       })}   
        options={{
            padding: "dense", /*rowStyle:{border:'1px solid', borderColor:grey[300]}, */
            pageSize: len, 
            pageSizeOptions: [5, 10, 20],
        }}
        components={{
            Header: (props) => {
              return (<TableHead {...props} /*style={{border:'1px solid', borderColor:grey[300]}}*/>
                <TableRow>
                  <TableCell variant='head' align='left' style={{paddingRight:0}}>
                    <div style={{display:'flex', justifyContent:'center',}}>
                      <div>{props.columns[0].title}</div>
                      <div><Tooltip title='Manage your objects and personnel. Achieve separation of operations and management.' arrow placement='right'>
                      <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                      </Tooltip></div>                      
                    </div>
                  </TableCell>
                  <TableCell variant='head' width='80%' align='center'>
                    <div style={{display:'flex', justifyContent:'center', marginRight:'1em', }}>
                      <div>{props.columns[1].title}</div>
                      <div><Tooltip title='Entities who owned the permission.' arrow placement='right'>
                      <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                      </Tooltip></div>                      
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>)
            }, Toolbar: (toolbar_props) => (
                <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                    <Button variant="contained" sx={{textTransform:'none', padding:'.2em .8em', alignItems:'center', display:'flex'}} 
                            disabled={!bAdmin} onClick={ () => {
                                //@ 设置默认的权限index
                                for (let i = Permission.BUSINESS_PERMISSIONS_START; i < (Permission.BUSINESS_PERMISSIONS_START+1000); i++) {
                                    if (index_validator.validator(i.toString())) {
                                        personalPermission.index = i.toString();
                                        setOpenNewModal(true);
                                        return 
                                    }
                                }
                            }}>{<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
                            <span>{'+ Add '+PersonalPermissionDescription}</span> 
                            <PermIcon {...perm} />
                            </div>}
                    </Button>
                    <MTableToolbar {...toolbar_props} />
                </div>
            ),
          }}/>    
        <Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth={'md'} disableRestoreFocus 
            open={select ? true : false}
            style={{}}
            TransitionComponent={Transition}
            keepMounted
            onClose={()=>setSelect(null)}
            >
            <DialogTitle sx={{textAlign:'center'}} > {'Edit '+PersonalPermissionDescription+'(' + select?.index  + ')'}
                <IconButton sx={{float:'right', marginTop:'-.2em'}} onMouseDown={()=>{setSelect(null)}}> <CloseIcon /> </IconButton>   
            </DialogTitle>
                <DialogContent >
                    <Tabs value={tab} onChange={(event: React.SyntheticEvent, newValue: number)=>{setTab(newValue)}} sx={{mb:'1em'}}>
                        <Tab label="Name"  {...a11yProps(0)} value={0}/>
                        <Tab label="Delete"   {...a11yProps(1)} value={1}/>
                    </Tabs>
                <Pannel value={tab} index={0}>
                    <SettingTitle title='Name' required/>
                    <SettingInputText value={select?.name} maxlength={MAX_NAME_LENGTH} placeholder={'Enter '+PersonalPermissionDescription+' name' }
                        id='permission-extra-rename' err_empty={'Please enter '+PersonalPermissionDescription+' name'} autoFocus event={(t, v, _) => {
                            if (select) {
                                select.name = v;
                                setSelect({...select})
                            }
                        }}/>
                    <SettingLaunch text='Launch' event={(t,v,_) => {
                        if (!select?.name) {
                            enqueueSnackbar('Name invalid', { variant: "error" });
                            document.getElementById('permission-extra-rename')?.focus();
                            return;
                        }
                        if (select) {
                            launch('extra-perm-name', parseInt(select.index), select.name);
                        }
                    }} />
                </Pannel>
                <Pannel value={tab} index={1}>
                    <SettingTips text='Removing a permission will make transactions that rely on this permission impossible.' />
                    <SettingLaunch text={'Delete this ' + PersonalPermissionDescription} event={(t,v,_) => {
                        if (select) launch('remove_perm', parseInt(select.index));
                    }} />
                </Pannel>
                </DialogContent> 
        </Dialog> 
        <Modal disableRestoreFocus  open={openNewModal} onClose={(event, reason)=> {
            setOpenNewModal(false)
        }}>
            <Paper sx={{position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', width:'42em', p:'1em 2em 2em 2em',
                boxShadow:'none', border:'2px solid', borderColor:theme.palette.primary.main,
            }}>
                <IconButton sx={{float:'right'}} onMouseDown={()=>{setOpenNewModal(false)}}>
                    <CloseIcon />
                </IconButton>
                <Box width='100%' sx={{textAlign:'center'}}>
                        <Typography variant="h5">{'Add '+PersonalPermissionDescription}</Typography>
                </Box>  
                <div style={{marginTop:'2em'}}>
                    <SettingTitle required title='Permission Name'  tips='eg. Your permission purpose?'/>
                    <SettingInputText maxlength={MAX_NAME_LENGTH} placeholder='Enter permission name' err_empty='Please enter permission name' 
                        id='personal-permission-name' value={personalPermission.name} validator={name_validator} autoFocus event={(t, v, _) => {
                        personalPermission.name = v;
                        setPersonalPermission({...personalPermission});
                    }}/>
                    <SettingTitle required title='Permission Index' 
                        tips={PersonalPermissionDescription+' index must greater than or equal to '+Permission.BUSINESS_PERMISSIONS_START}/>
                    <SettingInputText maxlength={8} min={Permission.BUSINESS_PERMISSIONS_START} max={9999}
                        validator={ index_validator }  err_bigint={PersonalPermissionDescription+' Index must be greater than or equal to '+Permission.BUSINESS_PERMISSIONS_START}
                        id='personal-permission-index' value={personalPermission.index} placeholder={'Enter '+PersonalPermissionDescription+' index'}
                        err_min={PersonalPermissionDescription+' index must greater than or equal to '+Permission.BUSINESS_PERMISSIONS_START} event={(t, v, _) => {
                            personalPermission.index = v;
                            setPersonalPermission({...personalPermission});     
                        }}/>    
                    <button id={props?.id} className='cmdButton' style={{marginTop:'1.5em'}} onClick={()=>{
                        if (!personalPermission.name || !name_validator.validator(personalPermission.name)) { 
                            document.getElementById('personal-permission-name')?.focus();
                            return 
                        }
                        const i = parseInt(personalPermission.index);
                        if (isNaN(i) || i < Permission.BUSINESS_PERMISSIONS_START || !index_validator.validator(personalPermission.index)) {
                            document.getElementById('personal-permission-index')?.focus();
                            return 
                        }

                        launch('add_perm');
                        setOpenNewModal(false);
                    }}>Launch</button>                    
                </div>
            </Paper>                
        </Modal>    
    </Box>
)}

function Entities(props:any) {
    //if (props.entity.length > 0) console.log(props)
    const [pending, setPending] = useState<string[]>([]);
    const [input, setInput] = useState('');
    const [error, setError] = useState('');
    const [open, setOpen] = useState(false);
    const [guard, setGuard] = useState<EntityData|undefined>(undefined);
    const theme = useTheme();

    const handleAddEntity = () => {
        props.launch('add_ent', props.permission.index, pending);
    }
    const handleGuardClick = (entity:string, guard:string) => {
        setGuard({entity:entity, guard:guard});
    }

    return (<>
        <Autocomplete multiple   limitTags={9} freeSolo disableCloseOnSelect  
        size='small' getOptionLabel={(option:any) => {
            if (typeof(option) === 'string') {
                return option;
            } else {
                return option.entity;
            }
        }} 
        options={pending}
        open={open}
        onChange={(event, newValue, reason) => {
            //console.log('onChange ')
        }}
        onOpen={(e) => {
            setOpen(true)
        }}
        onClose={(e, reason) => {
            //console.log(reason)
            setOpen(false)
        }}
        value={props.entity}
        renderTags={(value, myprops) => {
            let res: ReactNode[] = [];
            value.forEach((option, index) => {
                if (IsValidAddress(option.entity)) {
                    res.push(
                        <Chip {...myprops({ index })} sx={{backgroundColor:option?.guard?'rgba(66, 165, 245, 0.2)':grey[200]}} 
                        label={<div style={{display:'flex', alignItems:'center'}}>
                                <Tooltip title={<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
                                <span>Set Guard</span> 
                                <PermIcon {...props.info} />
                                </div>}><span>
                                    <IconButton className='cmdText'  disabled={!props.info.admin} onClick={() => {
                                        handleGuardClick(option.entity, option.guard);
                                    }}
                                        sx={{pl:0, pt:0, pr:0, pb:0, mb:'.1em', mr:'.1em',  width:'.6em', height:'.6em'}}>
                                            { option?.guard !== '' && <Icons type='guard'/>}
                                            {!option?.guard && <GuardGrey/>}
                                    </IconButton>
                            </span></Tooltip><Address address={option.entity}/>
                            </div>}
                        deleteIcon={
                            <Tooltip title={<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
                                <span>Remove Entity</span> 
                                <PermIcon {...props.info} />
                                </div>}><span>
                                    <IconButton className='cmdText' disabled={!props.info.admin} sx={{pl:0, pt:0, pr:0, pb:0, mb:'.1em', ml:'.1em'}}>
                                        <CloseIcon className='cmdText' sx={{width:'.8em', height:'.8em'}}/>
                                    </IconButton>
                            </span></Tooltip>}
                        onDelete={() => {
                            if (props.info.admin) {
                                props.launch('remove_ent', props.permission.index, option.entity);
                            }
                        }}/>  
                );} 
            });
            return res;
        }}
        renderInput={(params) => (
            <TextField error={error?true:false} helperText={error} 
              {...params}
              placeholder={ props.entity.length === 0 ? "Input personal address and press Enter" :'' }
              inputProps={{ ...params.inputProps, maxLength: 66}} 
              sx={{"& fieldset": { padding:'8px',  borderColor:'rgba(232,167,213,0.36)!important'}, 
              "& input": { minWidth:'16em!important'},
              "&:hover fieldset": { borderColor:theme.palette.primary.main + '!important'},
              input: {color: grey[600]}}}
              value={input}
              onChange={(e) => {
                setInput(e.target.value);
                setError('');
              }}
              onKeyDown={(event) => {
                event.stopPropagation();
                if (pending.length + props.entity.length >= Permission.MAX_ENTITY_COUNT) {
                    setError('max entity');
                    setOpen(false)
                    return 
                }
                if(event.code === 'Enter') {
                    if (!IsValidAddress(input)) {
                        //console.log(input)
                        setError(ADDRESS_VALIDATOR.err);
                        setOpen(false)
                    } else {
                        let n = pending.filter((v) => v !== input);
                        n.unshift(input);
                        setPending([...n]);
                        setOpen(true)
                        //setInput(''); // 清空输入框
                        //event.stopPropagation();
                        
                    }
                }
            }}/>
        )}
        filterOptions={(options, state) => options.sort((a, b)=> {
            return  (b as string).indexOf(input) - (a as string).indexOf(input)
        })}
        renderOption={(props, option) => { 
            const arr = (option as string).split(input);
            return (
            <li {...props} key={option.entity} >
                <div style={{display:'flex', alignItems:'center', width:'100%'}} >
                    <div onClick={()=>{}}>
                        {arr.map((v, i) => {
                            if (i < arr.length-1) {
                                return <span>{v}<span style={{fontWeight:400, color:theme.palette.primary.main}}>{input}</span></span>
                            } else {
                                return <span onClick={()=>setInput(v)}>{v}</span>
                            }
                        })}
                    </div>
                    <Tooltip title='Remove this address'>
                        <IconButton className='cmdText' sx={{ml:'auto'}} onClick={() => {
                            setPending([...pending.filter((v) => v !== option)]);
                        }}><CloseIcon /></IconButton>
                    </Tooltip>
                </div>
            </li>
        )}}
        PaperComponent={(paperProps:any) => (
            <CustomPaperComponent
                {...paperProps}
                selects={pending}
                info={props.info}
                handleAddEntity={handleAddEntity}
                onClickAway={(e:any)=>{
                }}
            />
        )}
    />
    <Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth={'md'} disableRestoreFocus
        open={guard ? true:false} TransitionComponent={Transition} keepMounted id='index-guard'
        onClose={()=>setGuard(undefined)}
        >
        <DialogTitle sx={{textAlign:'center'}} > Set Guard for Permission
            <IconButton sx={{float:'right', marginTop:'-.2em'}}  onMouseDown={()=>{setGuard(undefined)}}> <CloseIcon /> </IconButton>  
        </DialogTitle>
        <DialogContent sx={{minHeight:'12em'}}>
            <SettingTitle title='Guard Address'/>
            <SettingInputText validator={ADDRESS_VALIDATOR} maxlength={66} value={guard?.guard ?? ''} 
            placeholder='Enter guard address' event={(t,v,_) => {
                if (guard) {
                    guard.guard = v;
                    setGuard({...guard});
                }
            }}/>
            <SettingLaunch text='Launch' event={(t, v, _) => {
                if (t === 'click' && guard) {
                    props.launch('guard_ent', props.permission.index, guard);
                    setGuard(undefined)
                }
            }} />
        </DialogContent>
    </Dialog>
    </>)
}

const CustomPaperComponent = (props:any) => {  
    return (
      <Paper {...props}>
          <ClickAwayListener onClickAway={props.onClickAway}>
              <div key="click-away-wrapper">
                <div style={{display:'flex', alignItems:'center', padding:'.4em 1em',  
                     borderBottom:'1px solid', borderColor:grey[200], fontWeight:400}}>
                    <div style={{margin:'.4em 0'}}> New <span style={{fontSize:'1.1em', fontWeight:400, margin:'0 .1em'}}>{props.selects.length} </span>address added</div>      
                    {props.selects.length > 0 && <Button variant='contained' disabled={!props.info.admin}
                        style={{marginLeft:'2em', textTransform:'none', padding:'.2em .8em'}} onMouseDown={() => {
                            props.handleAddEntity();
                        }}>{<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
                        <span>+ Launch</span> 
                        <PermIcon {...props.info} />
                        </div>}</Button>}     
                </div>
                  {props.children}
              </div>
          </ClickAwayListener>
      </Paper>
    );
};
