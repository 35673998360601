import { IconButton, List, Skeleton, Dialog, DialogTitle, DialogContent,ListItem, ListItemText,
     ListItemButton, Typography,  } from "@mui/material";
import '../../css/Common.css';
import CloseIcon from '@mui/icons-material/Close';
import { useState, } from "react";
import { grey } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';
import { IsValidAddress, Protocol, CoinTypeInfo, IsValidU64,MAX_DESCRIPTION_LENGTH, IsValidDesription,} from "wowok";
import { useSnackbar } from 'notistack';
import { Address } from "../util/Address";
import { SettingTitle, SettingCoinInput, SettingInputText, ADDRESS_VALIDATOR, U64_LENGTH, U64_VALIDATOR, SettingLaunch, SettingEvent } from "./Settings";
import { pink } from "@material-ui/core/colors";
import { useWallet } from "@suiet/wallet-kit";
import { Transition } from '../util/Common';

export interface GuardInfo {
    guard: string;
    info: string;
}
export interface SettingWithdrawProp {
    coinType: CoinTypeInfo | 'loading';
    withdrawGuards : GuardInfo[];
    balance?: string;
    event: SettingEvent;
    open: boolean;
    to?: string;
    hideAmount?: boolean;
}

export interface WithdrawInfo {
    amount: string;
    guard: string;
    to: string;
    bSelf: boolean;
    to_type: string;
    remark: string;
    index: string;
    for_object: string;
    for_guard: string;
}

export function SettingWithdraw (props: SettingWithdrawProp) {
    //console.log(props)
    const tips = <div>{props.coinType === 'loading' ? 'Loading Coin Infomation...' : props.coinType.symbol} <br/> {'Token decimals: ' + (props.coinType !== 'loading' ? props.coinType.decimals.toString() : 'unknown' )}</div>;
    const [data, setData] = useState<WithdrawInfo>({amount:'', to:'', guard:'', bSelf:false, to_type:'', remark:'', index:'', for_object:'', for_guard:''});

    const wallet = useWallet();
    const { enqueueSnackbar } = useSnackbar();
        
    return ( <Dialog disableRestoreFocus  open={props.open}  fullWidth maxWidth='md' TransitionComponent={Transition}
        keepMounted  onClose={(event, reason)=> { props.event('close', undefined) }}>
        <DialogTitle sx={{textAlign:'center'}} > Withdraw 
            <IconButton sx={{float:'right', marginTop:'-.2em'}} onMouseDown={()=>{ props.event('close', undefined)}}> <CloseIcon /> </IconButton>   
        </DialogTitle>
        <DialogContent>
        { props.coinType === 'loading' && <>
                <Skeleton animation='pulse' sx={{height:'4em'}} />
                <Typography variant='body2' >Fetching Token information...</Typography>
            </>}
        { props.coinType !== 'loading' && !props?.hideAmount && props.withdrawGuards.length > 0 && <>
                <SettingTitle title='Select Guard and its Allowed Withdrawal Amount'  required tips='Select the withdrawal amount and the withdrawal will be verified automatically. If the corresponding Guard conditions pass the verification, the withdrawal succeeds. If the verification fails, the withdrawal fails.' />
                <List sx={{border:'1px solid', borderColor:grey[200], mt:'.4em', p:0}}>
                { props.withdrawGuards.map((v, index) => {
                   return ( <ListItem key={v.guard} component="div" disablePadding 
                    sx={{display:'flex', alignItems:'center', backgroundColor:v.guard === data.guard? pink[50] : undefined}}  
                    secondaryAction={
                    <Checkbox
                      edge="end"
                      onChange={(e) => {
                        if (v.guard === data.guard) {
                            data.guard = '';
                        } else {
                            data.guard = (v.guard);
                        }
                        setData({...data});
                      }}
                      checked={v.guard === data.guard}
                      inputProps={{ 'aria-labelledby': 'guard-list-checkbox' }}
                    />
                  }>
                    <ListItemButton sx={{pl:'.7em', pr:'.7em', color:grey[600], flex:'auto', pt:0, pb:0}}>
                        <ListItemText sx={{width:'6em', ml:0}}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <span style={{fontStyle:'italic', width:'1.6em', color:grey[400], marginRight:'.1em', fontSize:'.9em', paddingTop:'.1em'}}>{index+1+'.'}</span>
                                <Address address={v.guard} showType='Guard'/>
                            </div>
                        </ListItemText>
                        <ListItemText >{v.info}</ListItemText>
                    </ListItemButton>   
                </ListItem>)                      
                })
            }</List>  
        </>}
        {props.coinType !== 'loading' && !props?.hideAmount && props.withdrawGuards.length === 0 && <>
        <div style={{display:'flex', alignItems:'center', }}>
            <SettingTitle title={'Amount'} required tips={tips}/>
            { props.balance && <div style={{marginLeft:'auto', marginTop:'1em', color:grey[700], fontSize:'.9em'}}>
            <span style={{color:grey[500], }}>Available:</span>
            <span style={{marginLeft:'.2em'}}>{props.balance} </span>
            <span style={{marginLeft:'1px'}}>{props.coinType.symbol}</span>  
            </div> }           
        </div>
        <SettingCoinInput placeholder='Enter the amount to withdraw' fullWidth value={data.amount}  max={props?.balance} 
        symbol={props.coinType.symbol} decimals={props.coinType.decimals} min='1' id='withdraw-amount' event={(t,v,i) => {
            data.amount = v; setData({...data})
        }}/></>}
        {props.coinType !== 'loading' && !props?.to && <SettingTitle title='To' required tips='Withdrawals can be made to user address, and Treasury Object address.'
            checkbox='Fill my address' event={(t, v, d) => {
            if (t === 'check' && !wallet.connected) {
                document.getElementById('header-wallet-cmd')?.click();
                return
            }
            data.bSelf = (t === 'check' && wallet.address) ? v : false;
            setData({...data});
        }}/>}
        {props.coinType !== 'loading' && !props?.to && data.bSelf && <SettingInputText  disabled fullWidth
        placeholder={wallet.address} maxlength={66} 
            sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: '#A6008B',
                },
                }}/>}
        {props.coinType !== 'loading' && !props?.to && !data.bSelf && <SettingInputText value={data.to} maxlength={66} endAdornment={data?.to_type==='Treasury' ? (<span style={{color:grey[500]}}>Treasury</span>) : undefined}
            validator={{validator: async (value:string) => {
                if (IsValidAddress(value)) { //@ 用value，不用data
                    try {
                        const res = await Protocol.Client().getObject({id:value, options:{showType:true}});
                        data.to_type = Protocol.Instance().object_name_from_type_repr(res?.data?.type ?? '');
                        setData({...data})
                    } catch(e) {
                        console.log(e);
                    }
                    return true;
                } else {
                    return false;
                }
            }, err:''}} id='withdraw-to' placeholder='Enter the User/Treasury address as the withdrawal destination' event={(t,v,d) => {
                data.to = v;
                setData({...data});
            }}/>}
        {props.coinType !== 'loading' && <>
        <SettingTitle title='Remark' tips='Remark information of the flow.'/>
        <SettingInputText placeholder='Enter the remark information for withdrawals' maxlength={MAX_DESCRIPTION_LENGTH} multiline maxRows={6}
            id='withdraw-remark' value={data.remark} event={(t, v, d)=>{
            data.remark = v; setData({...data});
        }}/>
        <SettingTitle title='Biz-ID' tips='Business ID number related to transfer flow, If any. Default value: 0.'/>
        <SettingInputText value={data.index}  type='number' maxlength={U64_LENGTH} validator={U64_VALIDATOR} 
        id='withdraw-index' placeholder='Enter the business ID number related to transfer flow' event={(t,v,d) => {
            data.index = v; setData({...data});
        }}/>
        <SettingTitle title='For Object' tips='The Object related to transfer flow, If any.'/>
        <SettingInputText value={data.for_object} maxlength={66} validator={ADDRESS_VALIDATOR} 
        id='withdraw-for-object' placeholder='Enter the Object address related to transfer flow'event={(t,v,d) => {
            data.for_object = v; setData({...data});
        }}/>
        <SettingTitle title='For Guard' tips='The Guard related to deposit source, If any.'/>
        <SettingInputText value={data.for_guard} maxlength={66} validator={ADDRESS_VALIDATOR} 
        id='withdraw-for-guard' placeholder='Enter the Guard address related to transfer flow' event={(t,v,d) => {
            data.for_guard = v; setData({...data});
        }}/>
        <SettingLaunch text='Launch' event={(t,v,d) => {
            if (!wallet.connected) {
                enqueueSnackbar('Please login wallet', { variant: "error" });
                document.getElementById('header-wallet-cmd')?.click();
                return 
            }
            if (!props?.hideAmount) {
                if (props.withdrawGuards.length === 0) {
                    if (!IsValidU64(data.amount, 1)) {
                        enqueueSnackbar('Amount invalid', { variant: "error" });
                        document.getElementById('withdraw-amount')?.focus();
                        return
                    }
                    const amount = BigInt(data.amount);
                    if (props?.balance && amount > BigInt(props.balance)) {
                        enqueueSnackbar('Amount overflow balance', { variant: "error" });
                        document.getElementById('withdraw-amount')?.focus();
                        return
                    }                
                } else {
                    if (!IsValidAddress(data.guard)){
                        enqueueSnackbar('Select Guard and its allowed withdrawal amount', { variant: "error" });
                        return
                    }
                }
            }
            if (data.remark && !IsValidDesription(data.remark)) {
                enqueueSnackbar('Remark invalid', { variant: "error" });
                document.getElementById('withdraw-remark')?.focus();
                return
            }
            if (data.for_object && !IsValidAddress(data.for_object)) {
                enqueueSnackbar('For Object invalid', { variant: "error" });
                document.getElementById('withdraw-for-object')?.focus();
                return
            }
            if (data.index && !IsValidU64(data.index)) {
                enqueueSnackbar('Biz-ID invalid', { variant: "error" });
                document.getElementById('withdraw-index')?.focus();
                return
            }
            if (!props?.to) {
                if (!data.bSelf && !IsValidAddress(data.to)) {
                    enqueueSnackbar('To invalid', { variant: "error" });
                    document.getElementById('withdraw-to')?.focus();
                    return
                }                
            }
            if (data.for_guard && !IsValidAddress(data.for_guard)) {
                enqueueSnackbar('For Guard invalid', { variant: "error" });
                document.getElementById('withdraw-for')?.focus();
                return
            }
            props.event('value', data);
        }}/> 
        </>}
        </DialogContent>
    </Dialog>)
}