
import '../../css/App.css';
import { Session, PermissionIndex, Permission, PermissionAnswer, PermissionIndexType, Progress, Passport} from 'wowok'
import { Tooltip, Paper, IconButton } from '@mui/material';
import MaterialTable, {MTableToolbar} from "material-table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Address } from "../util/Address";
import { green, grey } from '@mui/material/colors';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import { OriginNode } from "../util/Common";
import Actions from "./Actions";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { useTheme} from '@mui/material/styles';
import { PermIcon } from '../util/Icons';
import { make_permIconProp } from '../launch/PermCheck';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { Transaction as TransactionBlock } from '@mysten/sui/transactions';
import { generateRandomString } from '../../util';

export default function ProgressCurrent(props:any) {
  const id = props?.contents?.fields?.id?.id ?? '';
  const current = props?.contents?.fields?.current;
  const machine = props?.contents?.fields?.machine;
  const answer: PermissionAnswer | undefined = props?.perms;
  const sessions: Session[] = props.sessions.sort((a:Session,b:Session) => {return a.id! - b.id!});
  const len = sessions.length >= 20 ? 20 : (sessions.length <= 5 ? 5 : 10);
  const sid = props?.contents?.fields?.history?.fields?.contents?.fields?.size;
  const theme = useTheme();
  const wallet = useWallet();
  const { enqueueSnackbar } = useSnackbar();

  const launch = async (who:string, next_node:string, next_forward:string) => {      
    if (!wallet.connected) {
        enqueueSnackbar('Please login wallet', { variant: "error" });
        document.getElementById('header-wallet-cmd')?.click();
        return 
    }
    if (!answer) {
      enqueueSnackbar('Permission error', { variant: "error" });
      return 
    }

    const txb = new TransactionBlock();
    const obj = Progress.From(txb, machine, answer?.object!, id);
    const pid = generateRandomString(8);

    props.PermissionCheck({id:pid, txb:txb, answer:answer, index:[PermissionIndex.progress_unhold], handler:
      (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:Passport) => {
        try {
          if (id === pid) {
            //@ notice: 没有权限，自己也可以取消
            if (index.includes(PermissionIndex.progress_unhold) || wallet.address === who) {
              obj.unhold({next_node_name:next_node, forward:next_forward})
            };  
            passport?.destroy(); // destroy passport
            props.exe(id, txb);            
          }
        } catch (e) {
          console.log(e)
          enqueueSnackbar( 'Launch Failed', { variant: "error" });
        }
      }
    });
  }

  return (
    <Paper className='noboxshadow' sx={{}}>
        <MaterialTable title=''
        columns={[
          { title:'SID', field:'id', cellStyle: {paddingRight: 0, paddingLeft:'4px'}, render:(data,type) => {
            return (<div style={{fontStyle:'italic', color:grey[400], fontWeight:200}}>{data.id}</div>);
          }},
          { title: 'Next Node', field: 'next', cellStyle: {paddingLeft: 0},  render:(data, type) => {
              return (<div  className='cmdText' style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis',width:'12em', }} onClick={()=>{
                window.open('/'+machine, '_blank')?.focus();
              }}>
                {data.next}
              </div>)
          }},
          { title: 'Loading', field: 'loading', render:(data, t) => {
            return (<div style={{textAlign:'right', color: grey[500]}}>
              {data.loading}
              </div>)
          }},
          { title: 'Operation', field: 'op', cellStyle: {paddingRight: 4}, render:(data, type) => {
            return (<div >
            {data.op.length > 0 && data.op.map((v) => {
              //v.sub_progress = '0xe386bb9e01b3528b75f3751ad8a1e418b207ad979fea364087deef5250a73d3f';
              //v.deliverables = '0xe386bb9e01b3528b75f3751ad8a1e418b207ad979fea364087deef5250a73d3f';
              //v.accomplished = true;
              const enableUnhold:boolean = (v.who === wallet.address) || Boolean(Permission.HasPermission(props.answer, PermissionIndex.progress_unhold, true)?.has);
              const sub_progress = v.sub_progress ? 
                (<div style={{display:'flex', alignItems:'center'}}><span>Sub Progress generated:&nbsp;</span> <Address address={v.sub_progress} showType='Progress'/></div>) 
                : 'No sub Progress generated';
              const deliverables = v.deliverables ? 
                (<div style={{display:'flex', alignItems:'center'}}><span>Operation deliverable:&nbsp;</span><Address address={v.deliverables} showType={true} /></div>)
                : 'No operation deliverable'
              const sub_progress_color = v.sub_progress ? {color:'rgba(112,182,3, 1)'} : {color:grey[400]}
              const deliverables_color = v.deliverables ? {color:'rgba(112,182,3, 1)'} : {color:grey[400]}
              return (<div style={{display:'flex', alignItems:'center', marginTop:'.2em', marginBottom:'.2em'}}>
                {v.accomplished && <div style={{backgroundColor:'rgba(112,182,3, 1)', borderRadius:'.6em', 
                  padding:'.2em .4em .1em .4em', marginRight:'1em', fontSize:'.8em'}}>Done</div>}
                {!v.accomplished && v.who && <div style={{backgroundColor:'rgba(95,0,170, 0.12)', borderRadius:'.6em', 
                  padding:'.2em .4em .1em .4em', marginRight:'1em', fontSize:'.8em'}}>Pending</div>}
                <div>{v.who && <div style={{display:'flex', color:grey[400]}}><Address address={v.who}/><span>:&nbsp;</span></div>}</div>
                {v.who && <div className='cmdText' style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis',maxWidth:'26em',}} onClick={() => {
                  window.open('/'+machine, '_blank')?.focus();
                }}>{v.forward}</div>}
                { v.accomplished && <div style={{marginLeft:'auto', display:'flex', alignItems:'center'}}>
                    <Tooltip title={sub_progress}>
                      <IconButton style={{padding:'5px'}} className={v.sub_progress ? "cmdText" :''} onClick={() => {
                        if (v.sub_progress) window.open('/'+v.sub_progress, '_blank')?.focus();
                      }}><AccountTreeIcon style={{width:'.8em', height:'.8em', ...sub_progress_color}}/></IconButton>
                    </Tooltip>
                    <Tooltip title={deliverables}>
                      <IconButton style={{padding:'5px'}} className={v.sub_progress ? "cmdText" :''} onClick={() => {
                        if (v.deliverables) window.open('/'+v.deliverables, '_blank')?.focus();
                      }}><InsertChartIcon style={{width:'.82em', height:'.82em', ...deliverables_color}}/></IconButton>
                    </Tooltip>
                </div>}
                {v.who && !v.accomplished && <div style={{marginLeft:'auto'}}>
                  <Tooltip title={<div style={{display:'flex', alignItems:'center', pointerEvents:'all'}}>
                      <span>Cancel Hold</span> 
                      <PermIcon {...make_permIconProp(answer, PermissionIndex.progress_unhold, enableUnhold)} />
                      </div>}> 
                      <span><IconButton style={{padding:'3px'}} className={v.sub_progress ? "cmdText" :''} 
                        disabled={!enableUnhold}
                        onClick={() => {
                          launch(v.who??'', data.next, v.forward);
                      }}><SettingsBackupRestoreIcon style={{width:'.9em', height:'.9em', color:theme.palette.primary.main }}/>
                    </IconButton>
                    </span></Tooltip>
                  </div>}
              </div>)
            })}
            {
              data.op.length === 0 && <div></div>
            }
            </div>)
          }},
        ]}
        data={sessions.map((v, i) => {
          return {id: sid, next:v.next_node, loading:(v.weights + '/' + v.threshold), op:v.holders}
        })}   
        options={{padding: "dense", tableLayout:'fixed',
            pageSize: len, 
            pageSizeOptions: [5, 10, 20],}}
        actions={[]}
        components={{
          Header: (props) => {
            return (<TableHead {...props} >
              <TableRow>
              <TableCell variant='head' align='left' style={{width:'2em',paddingLeft:'4px'}}>
                  <div style={{display:'flex', alignItems:'center'}}>
                    <div>{props.columns[0].title}</div>
                      <div><Tooltip title='Session-id, used for parent progress setting.' arrow placement='right'>
                      <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                      </Tooltip></div> 
                  </div>    
                </TableCell>
                <TableCell variant='head' align='center' style={{width:'9em'}}>
                  <div style={{display:'flex'}}>
                    <div>{props.columns[1].title}</div>                  
                  </div>
                </TableCell>
                <TableCell variant='head' align='right' style={{width:'6em'}}>
                  <div> {props.columns[2].title}</div>
                  </TableCell>
                <TableCell variant='head' align='left' style={{paddingRight:'2em'}}>{props.columns[3].title}</TableCell>
              </TableRow>
            </TableHead>)
          },
            Toolbar: (toolbar_props) => (
                <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                  <div style={{display:'flex', alignItems:'center'}}>
                    <PauseCircleIcon sx={{color:grey[400], mr:'2px'}}/>
                    <span style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', maxWidth:'14em', fontSize:'1.2em', fontWeight:600, color:grey[600], marginRight:'2em'}}>
                      {props?.contents?.fields?.current ? props?.contents?.fields?.current : <OriginNode/>}</span>
                  </div>
                  <Actions {...props}/>
                    <MTableToolbar {...toolbar_props} />
                </div>
            ),
        }}
      />     
    </Paper>
  );  
}