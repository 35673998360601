
import React, { useEffect, useState, ReactNode } from 'react';
import '../../css/Launch.css';
import '../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { useTheme} from '@mui/material/styles';
import { Box, Button, Tooltip, IconButton, Chip, Autocomplete, TextField, Paper, ClickAwayListener,
  Dialog, DialogContent, DialogTitle, 
} from '@mui/material';
import { IsValidAddress, Resource as WowokResource, Permission, ResourceData, MAX_NAME_LENGTH, IsValidName, Entity, Safer as WowokSafer} from 'wowok';
import { ADDRESS_VALIDATOR, SettingInputText, SettingLaunch, SettingTitle, SettingPwd, SettingTips} from '../launch/Settings';
import MaterialTable from "material-table";
import { grey } from '@mui/material/colors';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CloseIcon from '@mui/icons-material/Close';
import { Address } from '../util/Address';
import EditIcon from '@mui/icons-material/Edit';
import { Transition } from '../util/Common';
import DeleteIcon from '@mui/icons-material/Delete';
import { Transaction as TransactionBlock } from '@mysten/sui/transactions';
import { generateRandomString } from '../../util';
import { MTableToolbar } from 'material-table';

export default function Safer (props:any) {  
    //console.log(props)
    const wallet = useWallet();
     
    const { enqueueSnackbar } = useSnackbar();
    const safer:WowokSafer[] = props?.current?.safer ?? [];
    let len = props?.resource.length;
    len = len >= 20 ? 20 : (len <= 5 ? 5 : 10);

    const [pwd, setPwd] = useState<string | undefined>(undefined);

    return ( <Box className='noboxshadow' sx={{padding:'0 2em'}}>
      <MaterialTable title=''
      columns={[
      { title: '', field: 'index', type: 'numeric', align:'left', width:'1em', render:(data, type) => {
          return <div style={{fontStyle:'italic', color:grey[400], fontWeight:200}}>{data.index}</div>
      }},
      { title: 'Name', field: 'name', cellStyle:{paddingRight:0, paddingLeft:0}, render:(data, type) => {
          return (data.name)
      }},
      { title: 'Data Encrypted', field: 'value', render:(data, type) => {
        return (data.value)
      }},
      ]}
      data={safer.map((v, index) => {
        return {index:index+1, name:v.name, value:v.value}
        })}  
      options={{
          padding: "dense", rowStyle:{}, 
          pageSize: len, 
          pageSizeOptions: [5, 10, 20],
      }}
      components={{
          Header: (props) => {
            return (<TableHead {...props} style={{}}>
              <TableRow>
                <TableCell variant='head' width='1%'>{props.columns[0].title}</TableCell>
                <TableCell variant='head' width='20%' style={{paddingLeft:0}}>
                  <div style={{display:'flex', justifyContent:'left',  }}>
                    <div>{props.columns[1].title}</div>
                    <div><Tooltip title='Personal sensitive data that Used-Onchain but Invisible-Onchain, Such as PhoneNumber, HomeAddress, etc...' arrow placement='right'>
                    <HelpOutlineIcon sx={{width:'0.6em!important', height:'0.6em!important', paddingLeft:'0.1em', color:grey[500]}} />
                    </Tooltip></div>                      
                  </div>
                </TableCell>
                <TableCell variant='head' width='100%' align='center'>
                  <div style={{display:'flex', justifyContent:'center', marginRight:'1em', }}>
                    <div>{props.columns[2].title}</div>
                    <div><Tooltip title='Protected by Private Key and Password, Password is Invisible-Onchain.' arrow placement='right'>
                    <HelpOutlineIcon sx={{width:'0.6em!important', height:'0.6em!important', paddingLeft:'0.1em', color:grey[500]}} />
                    </Tooltip></div>                      
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>)
          }, Toolbar: (toolbar_props) => (
            <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                <Button variant="contained" sx={{textTransform:'none', padding:'.2em .8em', alignItems:'center', display:'flex'}} 
                        onClick={ () => {
                            setPwd('')
                    }}>+ Add Mark</Button>
                <MTableToolbar {...toolbar_props} />
            </div>
        ),
        }}
    />       
    <Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth={'sm'} disableRestoreFocus
      open={pwd !== undefined} TransitionComponent={Transition} keepMounted id='renameMark-dialog'
      onClose={()=>setPwd(undefined)}
      >
      <DialogTitle sx={{textAlign:'center'}} > Enter Password
          <IconButton sx={{float:'right', marginTop:'-.2em'}}  onMouseDown={()=>{setPwd(undefined)}}> <CloseIcon /> </IconButton>  
      </DialogTitle>
          <DialogContent sx={{minHeight:'14em'}}>
            <SettingTips text={'Password will not be saved anywhere. It is strongly recommended that you keep the password yourself.'}/>
            <SettingTitle title='Passpord' required />
            <SettingPwd  placeholder='Enter your password' fullWidth
              id='personal-safer-pwd' value={pwd ?? ''} event={(type, value, id) => {
                setPwd(value);
              }}/>
              <SettingLaunch text='OK' event={(type, value, id) => {
                  if (type === 'click') {
                  }
              }}/>
          </DialogContent> 
      </Dialog>  
    </Box>);  
}
