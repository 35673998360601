import { useState,  } from 'react';
import '../../css/App.css';
import { Service, Service_Discount_Type, Protocol, CoinTypeInfo, ResolveBalance, IsValidAddress,} from 'wowok'
import { Paper, Button, Tooltip, Dialog, DialogTitle, DialogContent, IconButton} from '@mui/material';
import { ADDRESS_VALIDATOR, SettingInputText, SettingLaunch, SettingShowValue, SettingTitle, } from '../launch/Settings';
import { Address } from '../util/Address';
import { toLocaleStringWithMinutes } from '../util/Common';
import { useWallet } from '@suiet/wallet-kit';
import CloseIcon from '@mui/icons-material/Close';
import { Transition } from '../util/Common';
import { useSnackbar } from 'notistack';
import { Transaction as TransactionBlock } from '@mysten/sui/transactions';
import { generateRandomString } from '../../util';

export default function Discount (props:any) {
    //console.log(props)
    const id:string = props?.contents?.fields?.id?.id ?? '';
    const wallet = useWallet();
    const { enqueueSnackbar } = useSnackbar();
    const type = Service.parseObjectType(props?.service?.type);
    const t = Protocol.Instance().GetCoinTypeInfo(type, (res) => {
        if (res.type === type) {
          setCoinType(coinType);      
        }
      });

    const [coinType, setCoinType] = useState<CoinTypeInfo | undefined>( t === 'loading' ? undefined : t);
    const [to, setTo] = useState<string | undefined>(undefined);

    const tips = (coinType && coinType.decimals > -1 && coinType.symbol)? (coinType.symbol + ' decimals: ' + coinType.decimals) : '';

    const terms = coinType ? (ResolveBalance(props?.contents?.fields?.price_greater ?? 0, coinType.decimals)+' ' + coinType.symbol) : (props?.contents?.fields?.price_greater??0);
    const off = (props?.contents?.fields?.type === Service_Discount_Type.minus ? (props?.contents?.fields?.off) : (props?.contents?.fields?.off+'%'));
    const time_end = toLocaleStringWithMinutes(new Date(parseInt(props?.contents?.fields?.time_end)));
    const time_start = toLocaleStringWithMinutes(new Date(parseInt(props?.contents?.fields?.time_start)));

    var btnTips:any = undefined;
    if (!props?.owner?.AddressOwner) {
        btnTips = 'This Discount had payed yet.'
    } else if (wallet?.address  && wallet.address !== props?.owner?.AddressOwner) {
        btnTips = 'You are not the Owner of this Discount.'
    } 

    return ( 
    <Paper sx={{pt:'.4em', pl:'1em', pr:'1em',  mb:'2em', pb:'4em'}}>
        <SettingShowValue title='For Service' value={<Address address={props?.contents?.fields?.service} showType='Service'/>}/>
        <SettingShowValue title='Terms' value={terms} tips={tips}/>
        <SettingShowValue title='Off' value={off}/>
        <SettingShowValue title='Expires' value={time_start + ' - ' + time_end}/>
        <SettingShowValue title='Owner' value={props?.owner?.AddressOwner ? <Address address={props?.owner?.AddressOwner!}/> : 'Payed yet.'}/> 
        <Tooltip title={btnTips} arrow>
            <span>
            <Button variant='outlined' className='cmdButton' sx={{mt:'2em', mr:'2em', textTransform:'none'}} 
                disabled={btnTips ? true : false} onClick={()=> {
                    setTo('')
                }}>Transfer to ...
            </Button>  
            </span>
        </Tooltip> 
        <Dialog onClick={(e)=>e.stopPropagation()} fullWidth maxWidth={'md'} disableRestoreFocus 
            open={to !== undefined}
            TransitionComponent={Transition}
            keepMounted
            onClose={()=>setTo('')}
            >
            <DialogTitle sx={{textAlign:'center'}} > Transfer to ...
                <IconButton sx={{float:'right', marginTop:'-.2em'}} onMouseDown={()=>{setTo(undefined)}}> <CloseIcon /> </IconButton>   
            </DialogTitle>
            <DialogContent >
                <SettingTitle title='Receiver Address'  required/>
                <SettingInputText validator={ADDRESS_VALIDATOR} maxlength={66} value={to ?? ''} placeholder='Enter the receiver address' 
                    autoFocus event={(t, v, _) => { setTo(v); }}/>
                <SettingLaunch text='Launch' event={(t, v, _) => {
                    if (!IsValidAddress(to)) {
                        enqueueSnackbar( 'Receiver address invalid', { variant: "error" });
                        return 
                    }

                    const txb = new TransactionBlock();
                    txb.transferObjects([txb.object(id)], txb.pure.address(to!));
                    props.exe(generateRandomString(8), txb);
                }} />
            </DialogContent>
        </Dialog>
    </Paper>
    );
}