
import '../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { useLocation } from 'react-router-dom';
import { useWallet } from '@suiet/wallet-kit';
import { useTheme, styled } from '@mui/material/styles';

export default function NotFound (props:any) {
/*    let local = localStorage.getItem(SEARCH_HISTORY_KEY);
    if (local && props?.objectid) {
        localStorage.setItem(SEARCH_HISTORY_KEY, JSON.stringify((JSON.parse(local) as string[]).filter(v => v!==props.objectid)));
    }*/

    const theme = useTheme();

    return (
        <div style={{backgroundImage:'linear-gradient(to bottom, #F2E3F0, rgba(229, 212, 225, 0.18))'}}> 
            <div style={{display:'flex', alignItems:'center', justifyContent:'center', paddingTop:'10em', paddingBottom:'10em', 
                flexWrap:'wrap', }}>
                <div className='notfound'></div>
                <div className='flexline' style={{textAlign:'center', marginBottom:'2em',}}>Oops... Not found!</div>
                <div onClick={()=> { window.location.href = '/explore'}}>
                    <button className='cmdButton'>Back to explore</button></div>
            </div>       
            { 
                    <div className='cmdText' style={{paddingBottom:'2em', margin:'auto', width:'100%', textAlign:'center',
                        color:theme.palette.primary.main, fontSize:'1.1em', fontWeight:200, 
                    }} onClick={() => {
                        window.location.href = '/personal';
                    }}>I Own this address, and wanna Build it.</div>
            }        
        </div>
       
    );
}
