import { ContextType, OperatorType, Guard, ValueType, DeGuardConstant, IsValidValueType, SER_VALUE, DeGuardData } from 'wowok';
import { NodeData } from './Guard';
import '../../../css/Guard.css';
import '../../../css/Common.css';
import { Address } from '../../util/Address';
import { red } from '@mui/material/colors';
import { Tooltip } from '@mui/material';
import { constItem } from './GuardGraph';
import { capitalizeFirstLetter } from '../../util/Common';

export const PENDING_SETTING = 'Click Setting';
export const WITNESS_ID_TIPS = 'The same Witness ID represent the same object or value.';

export const Label = (data: NodeData | DeGuardData, constTable:constItem[]) => {
    if (!data || !data.type) { return PENDING_SETTING};
    switch(data.type as number) {
        case OperatorType.TYPE_QUERY:
            if (data?.cmd === undefined) return PENDING_SETTING;
            let cmd = Guard.GetCmd(data.cmd!);
            if (!cmd) return PENDING_SETTING;
            let c = constTable.find((v)=>v.identifier === data?.identifier);
            if (c) {
                if (c.type === ValueType.TYPE_ADDRESS && !c.bWitness) {
                    return (<div style={{}}>
                        <Address address={c.value} showType={true}/>
                        <Tooltip title={cmd[5]}>
                            <div style={{textAlign:'center', fontWeight:'600'}}>{cmd[1]}</div>
                        </Tooltip>
                    </div>)
                } else if (c.type === ValueType.TYPE_ADDRESS && c.bWitness) {
                    return (<>
                        <div style={{display:'flex', alignItems:'center', textAlign:'center'}}>
                            <span>{Guard.GetModuleName(data.cmd!)}</span>
                            <Tooltip title={WITNESS_ID_TIPS} arrow>
                            <span style={{color:red[400], fontWeight:1000, marginLeft:'.1em', fontStyle:'italic'}}>{(data?.identifier  ?? '')}</span>
                            </Tooltip>
                        </div>
                        <Tooltip title={cmd[5]}>
                            <div style={{textAlign:'center', fontWeight:'600'}}>{cmd[1]}</div>
                        </Tooltip>
                        </>)
                }
            } else {
                return (<div style={{display:'flex', alignItems:'center'}}>
                    <Address address={data.value} showType={true}/>
                    <Tooltip title={cmd[5]}>
                        <span>{'.' + cmd[1]}</span>                        
                    </Tooltip>
                </div>)
            }
            return PENDING_SETTING;
        case OperatorType.TYPE_LOGIC_ALWAYS_TRUE:
            return 'Always true';
        case OperatorType.TYPE_LOGIC_AND:
            return 'And';
        case OperatorType.TYPE_LOGIC_AS_U256_EQUAL:
            return 'Number =';
        case OperatorType.TYPE_LOGIC_AS_U256_GREATER:
            return 'Number >';
        case OperatorType.TYPE_LOGIC_AS_U256_GREATER_EQUAL:
            return 'Number >=';
        case OperatorType.TYPE_LOGIC_AS_U256_LESSER:
            return 'Number <';
        case OperatorType.TYPE_LOGIC_AS_U256_LESSER_EQUAL:
            return 'Number <=';
        case OperatorType.TYPE_NUMBER_ADD:
            return 'Number Add (+)'
        case OperatorType.TYPE_NUMBER_DEVIDE:
            return 'Number Devide (/)'
        case OperatorType.TYPE_NUMBER_MOD:
            return 'Number Mod (%)'
        case OperatorType.TYPE_NUMBER_MULTIPLY:
            return 'Number Multiply (*)'
        case OperatorType.TYPE_NUMBER_SUBTRACT:
            return 'Number Subtract (-)'
        case OperatorType.TYPE_LOGIC_EQUAL:
            return 'Equal';
        case OperatorType.TYPE_LOGIC_HAS_SUBSTRING:
            return 'Sub String';
        case OperatorType.TYPE_LOGIC_NOT:
            return 'Not';
        case OperatorType.TYPE_LOGIC_OR:
            return 'Or';
        case ContextType.TYPE_CLOCK:
            return 'Txn Time';
        case ContextType.TYPE_SIGNER:
            return 'Txn Signer';
        case ContextType.TYPE_GUARD:
            return 'Current Guard Address';
        case ValueType.TYPE_ADDRESS:
            if (data.identifier !== undefined) {
                let c = constTable.find((v)=>v.identifier === data?.identifier);
                if (c?.bWitness) {
                    return (<div>
                        <span>Address</span>
                        <Tooltip title={WITNESS_ID_TIPS} arrow>
                        <span style={{color:red[400], fontWeight:1000, marginLeft:'.1em', fontStyle:'italic'}}>{(data?.identifier  ?? '')}</span>
                        </Tooltip>
                    </div>)                    
                }
            } 
            return (<div style={{padding:'0 .6em'}}><Address address={data.value} showType={true}/></div>)
            
        case ValueType.TYPE_BOOL:
            if (data.identifier !== undefined) {
                let c = constTable?.find((v)=>v.identifier === data?.identifier);
                if (c?.bWitness) {
                    return (<div>
                        <span>Bool</span>
                        <Tooltip title={WITNESS_ID_TIPS} arrow>
                        <span style={{color:red[400], fontWeight:1000, marginLeft:'.1em', fontStyle:'italic'}}>{(data?.identifier  ?? '')}</span>
                        </Tooltip>
                        </div>)
                }
            }
            return data.value ? 'True' : 'False';
        case ValueType.TYPE_U8:
        case ValueType.TYPE_U64:
        case ValueType.TYPE_U128:
        case ValueType.TYPE_U256:
        case ValueType.TYPE_VEC_U8:
        case ValueType.TYPE_VEC_U64:
        case ValueType.TYPE_VEC_U128:
        case ValueType.TYPE_VEC_ADDRESS:
        case ValueType.TYPE_VEC_BOOL:
        case ValueType.TYPE_VEC_VEC_U8:
        case ValueType.TYPE_OPTION_ADDRESS:
        case ValueType.TYPE_OPTION_BOOL: 
        case ValueType.TYPE_OPTION_U128:
        case ValueType.TYPE_OPTION_U8:
        case ValueType.TYPE_OPTION_U64:
        case ValueType.TYPE_OPTION_U256:
        case ValueType.TYPE_VEC_U256:
        case ValueType.TYPE_STRING:
            if (data.identifier !== undefined) {
                let c = constTable?.find((v)=>v.identifier === data?.identifier);
                if (c?.bWitness) {
                    return (<div>
                        <span>{capitalizeFirstLetter(SER_VALUE.find(v=>v.type === data.type)?.name)}</span>
                        <Tooltip title={WITNESS_ID_TIPS} arrow>
                        <span style={{color:red[400], fontWeight:1000, marginLeft:'.1em', fontStyle:'italic'}}>{(data?.identifier  ?? '')}</span>
                        </Tooltip>
                    </div>)
                }
            } 
            if (data.value === undefined) {
                return PENDING_SETTING;
            } else if (data.type === ValueType.TYPE_STRING) {
                return (
                    <Tooltip arrow title={data.value.length > 16 ? data.value : undefined}>
                        <span style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', maxWidth:'28em'}}>{data.value}</span>
                    </Tooltip>
                );
            }
            return data.value;        
        case ContextType.TYPE_CONSTANT:
            let v = constTable.find((v)=>v.identifier === data.identifier);
            if (!v) return PENDING_SETTING;
            if (v.bWitness) {
                const t = SER_VALUE.find((i)=>i.type === v!.type);
                if (t) {
                    return (<div>
                        <span>{t.name}</span>
                        <Tooltip title={WITNESS_ID_TIPS} arrow>
                        <span style={{color:red[400], fontWeight:1000, marginLeft:'.1em', fontStyle:'italic'}}>{data.identifier}</span>
                        </Tooltip>
                    </div>)
                }
            } else {
                if (v.type === ValueType.TYPE_ADDRESS) {
                    return (<div style={{padding:'0 .6em'}}><Address address={v.value} showType={true}/></div>)
                } else if (v.type === ValueType.TYPE_BOOL) {
                    return v.value ? 'True' : 'False';
                } else if (IsValidValueType(v.type) && v.value === undefined) {
                    return PENDING_SETTING;
                } else {
                    return (<Tooltip arrow title={v.value.toString().length > 16 ? v.value : undefined}>
                    <span style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', maxWidth:'28em'}}>{v.value}</span>
                    </Tooltip>);                    
                }                
            }
            break;
        default:
            console.log(data)
    }   
}

export const EdgeLabel = (data: NodeData | DeGuardData, constant_table?:DeGuardConstant[]) : string => {
    var label = 'any';         
    if (data.ret_type === 'any') {
        if (data?.type === OperatorType.TYPE_QUERY && data?.cmd !== undefined) {
            const r1 = Guard.GetCmd(data.cmd);
            if (r1) {
                const r2 = SER_VALUE.find((v) => v.type === r1[4]);
                if (r2) {
                    label = r2.name;
                }
            }
        } else if (data?.type === ContextType.TYPE_CLOCK) {
            label = 'number'
        } else if (data?.type === ContextType.TYPE_SIGNER || data?.type === ContextType.TYPE_GUARD) {
            label = 'address'
        } else {
            const r = SER_VALUE.find((v) => v.type === data.type);
            if (r) {
                label = r.name;
            } else {
                label = data.ret_type;
            }           
        }
    } else  if (data.ret_type === 'number') {
        label = 'number'
    } else {
        const r = SER_VALUE.find((v) => v.type === data.ret_type);
        if (r) {
            label = r.name;
        } else {
            label = 'unknown';
        }
    }
    return label;
}

export const TypeLabel = (type: ValueType | 'number' | 'any' ) : string => {
    if (type === ValueType.TYPE_U128 || type === ValueType.TYPE_U256 || type === ValueType.TYPE_U64 || type === ValueType.TYPE_U8) {
        return 'number'
    } else if (type === ValueType.TYPE_ADDRESS) {
        return 'address'
    } else if (type === ValueType.TYPE_STRING) {
        return 'string'
    } else if (type === ValueType.TYPE_BOOL) {
        return 'bool'
    } else if (type === 'number' || type === 'any') {
        return type
    }
    return 'any'
}