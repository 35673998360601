
import React, { useState } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { useNavigate, useLocation } from 'react-router-dom';
import Basic from './Basic';
import PermissionEntity from './Entity';
import PermissionIndex from './Index';
import Owner from './Owner';
import SettingHeader from '../SettingHeader';

export default function PermissionSetting (props:any) {
    const location = useLocation()
    var t = parseInt(location.hash.slice(1));
    if (t < 0 || t > 3 ||  isNaN(t))  { t = 0; }
    window.location.hash = '#' + t;

    const id = props?.contents?.fields?.id?.id ?? '';
    const [value, setValue] = React.useState(t);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      window.location.hash = '#' + newValue;
      setValue(newValue);
    };

    return (
      <Paper sx={{ width: '100%', pt:'.4em'}} >
          <SettingHeader name={id ? 'Permission Setting' : 'Launch Permission'}/>

        <Tabs value={value} onChange={handleChange} sx={{height:id ? '3em' : '3.8em', margin:'.4em 1em'}}>
          <Tab label="Basic" {...a11yProps(0)} sx={{textTransform:'none'}}/>
          <Tab disabled={id ? undefined : true}
            icon={id ? undefined : <VpnKeyIcon className='trans270' 
            sx={{with:'0.6em', height:'0.6em', marginRight:'-0.1em!important'}}/>} iconPosition="start" 
            label={'Entity'} {...a11yProps(1)} sx={{textTransform:'none'}}/>
          <Tab disabled={id ? undefined : true}
            icon={id ? undefined : <VpnKeyIcon className='trans270' 
            sx={{with:'0.6em', height:'0.6em', marginRight:'-0.1em!important'}}/>} iconPosition="start" label="Index" 
            {...a11yProps(2)} sx={{textTransform:'none'}}/>
          <Tab disabled={id ? undefined : true}
            icon={id ? undefined : <VpnKeyIcon className='trans270' 
            sx={{with:'0.6em', height:'0.6em', marginRight:'-0.1em!important'}}/>} iconPosition="start" label="Owner" 
            {...a11yProps(3)} sx={{textTransform:'none'}}/>
        </Tabs>
      <Pannel value={value} index={0}>
          <Basic {...props}/>
      </Pannel>
      <Pannel value={value} index={1}>
        <PermissionEntity {...props}/>
      </Pannel>
      <Pannel value={value} index={2}>
      <PermissionIndex {...props}/>
      </Pannel>
      <Pannel value={value} index={3}>
      <Owner {...props}/>
      </Pannel>
  </Paper>
    );
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
function Pannel(props: TabPanelProps) {
  const { children, value, index,  ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ width:'100%' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

  
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}