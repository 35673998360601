
import { ReactComponent as PermissionIcon } from "../../images/icons/permission.svg";
import { ReactComponent as MachineIcon } from "../../images/icons/machine.svg";
import { ReactComponent as ProgressIcon } from "../../images/icons/progress.svg";
import { ReactComponent as RepositoryIcon } from "../../images/icons/repository.svg";
import { ReactComponent as DemandIcon } from "../../images/icons/demand.svg";
import { ReactComponent as RewardIcon } from "../../images/icons/reward.svg";
import { ReactComponent as ServiceIcon } from "../../images/icons/service.svg";
import { ReactComponent as GuardIcon } from "../../images/icons/guard.svg";
import { ReactComponent as DefaultIcon } from "../../images/icons/default.svg";
import { ReactComponent as AvatarIcon } from "../../images/icons/avatar.svg";
import { ReactComponent as OrderIcon } from "../../images/icons/order.svg";
import { ReactComponent as EntityIcon } from "../../images/icons/entity.svg";
import { ReactComponent as TransactionIcon } from "../../images/icons/transaction.svg";
import { ReactComponent as WowokIcon } from "../../images/icons/wowok.svg";
import { ReactComponent as LogicIcon } from "../../images/icons/logic.svg";
import { ReactComponent as PassportIcon } from "../../images/icons/passport.svg";
import { ReactComponent as ForbiddenIcon } from "../../images/icons/forbidden.svg"; 
import { ReactComponent as YesIcon } from "../../images/icons/yes.svg";
import { ReactComponent as UnlockWithGuardIcon } from "../../images/icons/use_guard.svg";
import { ReactComponent as GuardGreyIcon } from "../../images/icons/use_guard_grey.svg";
import { ReactComponent as UnlockIcon } from "../../images/icons/unlock.svg";
import { ReactComponent as DiscountIcon } from "../../images/icons/discount.svg";
import { ReactComponent as PersonalPermIcon } from "../../images/icons/personalperm.svg";
import { ReactComponent as TreasuryIcon } from "../../images/icons/treasury.svg";
import { ReactComponent as PaymentIcon } from "../../images/icons/payment.svg";
import { ReactComponent as ArbitrationIcon } from "../../images/icons/arbitration.svg";
import { ReactComponent as ArbIcon } from "../../images/icons/arb.svg";

import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import Looks6Icon from '@mui/icons-material/Looks6';
import { Paper, Box, Avatar,} from '@mui/material';
import { Unstable_Popup as Popup } from '@mui/base/Unstable_Popup';
import React from 'react';
import { generateRandomString,} from '../../util';
import { deepOrange, red, green, grey } from '@mui/material/colors';
import { useTheme, styled} from '@mui/material/styles';
import { Address } from "./Address";

export function GuardGrey (props:any) {
  return (<GuardGreyIcon />)
}

export function Icons(props:any) {
    if (!props.type) return null;

    let icon = null;
    const type = (props.type as string).toLowerCase();

    if (type === 'permission') {
        icon = <PermissionIcon />;
    } else if (type === 'machine') {
        icon = <MachineIcon/>;
    } else if (type === 'progress') {
        icon = <ProgressIcon />;
    } else if (type === 'service') {
      icon = <ServiceIcon />;
    } else if (type === 'guard') {
      icon = <GuardIcon />;
    } else if (type === 'demand') {
      icon = <DemandIcon />;
    } else if (type === 'reward') {
      icon = <RewardIcon />;
    } else if (type === 'repository') {
      icon = <RepositoryIcon />;
    } else if (type === 'personal') {
      icon = <AvatarIcon {...props.sx}/>;
    } else if (type === 'order') {
      icon = <OrderIcon />;
    } else if (type === 'entity') {
      icon = <EntityIcon />;
    } else if (type === 'wowok') {
      icon = <WowokIcon />;
    } else if (type === 'txn functions') {
      icon = <TransactionIcon />;
    } else if (type === 'compare or logic') {
      icon = <LogicIcon />
    } else if (type === 'passport') {
      icon = <PassportIcon />
    } else if (type === 'discount') {
      icon = <DiscountIcon />
    } else if (type === 'personalperm') {
      icon = <PersonalPermIcon />
    } else if (type === 'treasury') {
      icon = <TreasuryIcon />
    } else if (type === 'payment') {
      icon = <PaymentIcon />
    } else if (type === 'arbitration') {
      icon = <ArbitrationIcon />
    } else if (type === 'arb') {
      icon = <ArbIcon />
    }
    
    if (props?.defaultIcon && !icon) {
      icon = <DefaultIcon />;
    };

    return (<>{icon}</>)
}

export function NumberIcon(props:any) {
  switch(props.number) {
    case 1:
      return <LooksOneIcon {...props}/>;
    case 2:
      return <LooksTwoIcon {...props}/>;
    case 3:
      return <Looks3Icon {...props}/>;
    case 4:
      return <Looks4Icon {...props}/>;
    case 5:
      return <Looks5Icon {...props}/>;
    case 6:
      return <Looks6Icon {...props}/>;
  }
  return null;
}

export interface PermissionIconProp {
  owner?: boolean;
  admin?: boolean;
  permission?: boolean;
  object?: string;
  guard?: string;
  force?: boolean;
  guards?: string[];
}

export function PermIcon(props:PermissionIconProp | undefined) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);

  if (!props) return null;

  let icon:any = null;
  let tips = '';
  let bkColor:any = deepOrange[400];
  if (props.force === true) {
    icon = <UnlockIcon/>;
    tips = 'Revisable: Yes';
  } else if (props.force === false) {
    icon = <ForbiddenIcon />
    tips="Revisable: No Permission"
  } else if (props?.permission === false) {
    icon = <ForbiddenIcon />
    tips="Revisable: No Permission"
    bkColor = grey[400];
  } else if (props.permission && (props.guard || (props?.guards && props?.guards?.length > 0))) {
    icon = <UnlockWithGuardIcon />
    tips="Revisable: Yes with Guard";
    bkColor = green[400]
  } else {
    icon = <UnlockIcon />;
    tips = 'Revisable: Yes';
    bkColor = deepOrange[400];
  }

  //if (!props.object) return null;
  const size  = (tips === 'Revisable: Yes') ? {width:'.6em', height:'.6em', marginLeft:1} : {width:'.8em', height:'.8em', marginLeft:-2, };
  return (
      <div style={{marginLeft:'.2em'}} onMouseEnter={(e:any)=>{setAnchorEl(e.currentTarget);}} onMouseLeave={() => {setAnchorEl(null);}}
          >
        <Avatar variant="square" style={{...size, marginRight:2, backgroundColor:'transparent', color:green[700]}}>
            {icon}
        </Avatar>
      <Popup onClick={(e) => e.stopPropagation()} onMouseLeave={()=> {setAnchorEl(null); }}
      id={generateRandomString(16)} className='popPermission' style={{fontSize:'.8em',zIndex:'9999' }} 
      open={anchorEl ? true : false}
      anchor={anchorEl}
      >
      <Box boxShadow='none' border='1px solid #E8A7D5' sx={{}} >
      <Paper sx={{maxWidth:'24em', p:'.4em 1em'}}>
          <div style={{  whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', width:'100%',
              letterSpacing:'.08em', maxWidth:'14em', color:theme.palette.primary.main, borderBottom:'1px solid', borderColor:grey[200]
          }}>{tips}      
          </div>
          <div style={{display:'flex', alignItems:'center'}}>
            { props?.object &&  
              <div style={{display:'flex', alignItems:'center', marginTop:'.6em', marginRight:'1em'}} className='cmdText'
                /*onClick={() => {window.open('/'+props.object, '_blank')?.focus()}}*/>
                <Avatar style={{ width:'.8em', height:'.8em' , marginRight:2, marginLeft:-2, backgroundColor:'transparent'}}>
                  <PermissionIcon />
                </Avatar>
                  <Address address={props.object} />
              </div>
            }
            { props?.guard && 
              <div style={{display:'flex', alignItems:'center', marginTop:'.6em'}} className='cmdText'
              onClick={() => {window.open('/'+props.guard, '_blank')?.focus()}}>
              <Avatar style={{ width:'.8em', height:'.8em' , marginRight:2, marginLeft:-2, backgroundColor:'transparent'}}>
                <GuardIcon />
              </Avatar>
                <Address address={props.guard}/>
            </div>
            }
          </div>
          { props?.guards && props?.guards.length > 0 && 
              <div style={{display:'flex', alignItems:'center', marginTop:'.6em'}} className='cmdText'>
                  <Avatar style={{ width:'.8em', height:'.8em' , marginRight:2, marginLeft:-2, backgroundColor:'transparent'}}>
                    <GuardIcon />
                  </Avatar>
                    <Address address={props.guards[0]}/> 
                    { props.guards.length > 1 &&
                    <div>
                      <span style={{marginLeft:'.2em'}}>...</span>
                      <span style={{marginLeft:'1em', color:grey[500]}}>{(props.guards.length) + ' Guards in total.'}</span>
                    </div>
                    }
                </div>
            }
      </Paper>
      </Box>
    </Popup>
    </div>
  )
}

export interface PermGuardIconProp {
  guard: string[];
  text: string;
}

export function PermGuardIcon(props: PermGuardIconProp) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const icon = <UnlockWithGuardIcon />;
  const tips = 'Protected by Guard';
  const theme = useTheme();
  //console.log(props)
  if (props.guard.length === 0) {
    return <div>{props.text}</div>
  }

  return (      
  <div style={{marginLeft:'.2em', }} 
    onMouseEnter={(e:any)=>{setAnchorEl(e.currentTarget);}} 
    onMouseLeave={() => {setAnchorEl(null);}}>
      <div style={{justifyContent:'center', display:'flex', alignItems:'center', }}>
        <Avatar style={{ width:'.8em', height:'.8em' , marginRight:2, marginLeft:-2, backgroundColor:'transparent', color:green[700]}}>
            {icon}
        </Avatar>
        <div style={{marginRight:'.4em'}}>{props.text}</div>
      </div>
    <Popup onClick={(e) => e.stopPropagation()} onMouseLeave={()=> {setAnchorEl(null); }}
    id={generateRandomString(16)} className='popPermission' style={{fontSize:'.8em'}} 
    open={anchorEl ? true : false}
    anchor={anchorEl}
    >
      <Box boxShadow='none' border='1px solid #E8A7D5' sx={{}} >
        <Paper sx={{maxWidth:'24em', p:'.4em 1em'}}>
          <div style={{  whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', width:'100%',
              letterSpacing:'.08em', maxWidth:'14em', color:theme.palette.primary.main, borderBottom:'1px solid', borderColor:grey[200]
          }}>{tips}      
          </div>
          <div style={{display:'flex', alignItems:'center', marginRight:'1em'}}>
              { props?.guard.length > 0 && 
                  <div style={{display:'flex', alignItems:'center', marginTop:'.6em'}} className='cmdText'>
                  <Avatar style={{ width:'.8em', height:'.8em' , marginRight:2, marginLeft:-2, backgroundColor:'transparent'}}>
                    <GuardIcon />
                  </Avatar>
                    <Address address={props.guard[0]}/> 
                    { props.guard.length > 1 &&
                    <div>
                      <span style={{marginLeft:'.2em'}}>...</span>
                      <span style={{marginLeft:'1em', color:grey[500]}}>{(props.guard.length) + ' Guards in total.'}</span>
                    </div>
                    }
                </div>
              }
          </div>
        </Paper>
      </Box>
    </Popup>
  </div>)
}
