
import React, { useState, useEffect } from 'react';
import '../../css/Launch.css';
import '../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Paper, Tabs, Tab, Box, Typography, Tooltip } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Details from './Details'; 
import { Pannel, a11yProps} from '../util/Common';
import Data from './Data'
import Arb from './Arb';

export default function Arbitration (props:any) {  
    //console.log(props)
    const location = useLocation()
    var t = parseInt(location.hash.slice(1));
    if (t < 0 || t > 2 ||  isNaN(t))  { t = 0; }
    window.location.hash = '#' + t;

    const id = props?.contents?.fields?.id?.id ?? '';
    const [value, setValue] = React.useState(t);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        window.location.hash = '#' + newValue;
        setValue(newValue);
    };
    
    return (<>
        <Paper sx={{ width: '100%', pt:'.4em',}} >
            <Tabs value={value} onChange={handleChange} sx={{height:id ? '3em' : '3.8em', margin:'.4em 1em'}}>
                <Tab label='Arbs' {...a11yProps(0)} sx={{textTransform:'none', pb:'.2em'}} value={0}/>
                <Tab label="Apply for"  {...a11yProps(1)} sx={{textTransform:'none', pb:'.2em'}} value={1}/>
                <Tab label="Promise"  {...a11yProps(2)} sx={{textTransform:'none', pb:'.2em'}} value={2}/>
            </Tabs>
            <Pannel value={value} index={0}>
                <Data {...props} />
            </Pannel>
            <Pannel value={value} index={2}>
                <Details {...props} />
            </Pannel>
            <Pannel value={value} index={1}>
                <Arb {...props} />
            </Pannel>
        </Paper>
    </>);
}
