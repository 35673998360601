import React, { useState } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { Box, } from '@mui/material';
import { Permission as WowokPermission, } from 'wowok';
import { SettingTitle,  SettingLaunch, SettingInputText, SettingAddressChips, SettingTips, } from '../Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { Transaction as TransactionBlock } from '@mysten/sui/transactions';
import { generateRandomString } from '../../../util';
import { useAccount, add_resource_launched } from '../../util/Account';

export interface BasicData {
    description: string;
    admin: string[];
}
  
export default function Basic (props:any) {
    const id = props?.contents?.fields?.id?.id ?? '';
    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();
    const account = useAccount();

    const bOwner = props?.contents?.fields?.builder === wallet?.address;
    const bAdmin = (props?.contents?.fields?.admin as string[])?.includes(wallet?.address ?? '');

    const [data, setData] = useState<BasicData>({
      description:props?.contents?.fields?.description ?? "", 
      admin:props?.contents?.fields?.admin ?? [],
    });

    const make_permIconProp = (perm:'owner' | 'admin') => {
        return {object:id, 
          permission: perm === 'owner' ? bOwner : bAdmin,
          guard:undefined, admin:bAdmin, 
          owner:bOwner};
    }

    const launch = async () => {
      if (!wallet.connected) {
        enqueueSnackbar('Please login wallet', { variant: "error" });
        document.getElementById('header-wallet-cmd')?.click();
        return 
      }
  
      const txb = new TransactionBlock(); 

      try {
        if (id) {
          const p = WowokPermission.From(txb, id);
          const add = data.admin.filter((v) =>props?.contents?.fields?.admin?.find((i:string)=>i === v) === undefined);
          p.add_admin(add);

          const remove = props?.contents?.fields?.admin?.filter((v:string) =>data.admin.find((i:string)=>i === v) === undefined);
          p.remove_admin(remove);
          
          if (data.description !== props?.contents?.fields?.description) { // 放在权限设置下面
            p.set_description(data.description);
          } 
        } else {
            let p = WowokPermission.New(txb, data.description);
            p.add_admin(data.admin);
            add_resource_launched(account, txb, [p.launch()]);
        }
        
        props.exe(generateRandomString(8), txb, id?undefined:'permission::Permission');
      } catch (e) {
        console.error('excute error: ', e);
        enqueueSnackbar('Launch failed', { variant: "error" });
      }
    }
  
    return (
        <Box sx={{maxWidth:'100%', padding:'0 2em', pb:'2em',}}>
          <SettingTips text='Admin is responsible for specific business operations.' />
          <SettingTitle title='Description' perm={make_permIconProp('admin')}/>
          <SettingInputText placeholder='Enter the name or description of the Permission' maxlength={512} multiline maxRows={6} autoFocus
            value={data.description} noPerm={id && !bAdmin}
            event={(type, value, id)=>{
              data.description = value;
              setData({...data});
            }}/>
  
          <SettingTitle title='Admin' tips='Can manage permission indexes and entities. Creator would be admin by default.' perm={make_permIconProp('owner')}/> 
          <SettingAddressChips placeholder='Input address and press Enter'  noPerm={id && !bOwner}
            id='admin' maxTags={WowokPermission.MAX_ADMIN_COUNT} maxTags_err='Max admin count' 
                initValue={data.admin} event={(t, v, _) => {
              data.admin = t==='value' ? v : [];
              setData({...data})
            }}/>

          <SettingLaunch text='Launch' event={(t) => {
            if (t === 'click') launch();
          }}/>
        </Box>
    );  
  }