
import {  Autocomplete, TextField, Select, MenuItem, InputAdornment, Tooltip } from '@mui/material';
import * as React from 'react';
import { useReactFlow, Node, } from 'reactflow';
import { ValueType, IsNumberType, IsValidU256, IsValidAddress, SER_VALUE, Guard, MAX_DESCRIPTION_LENGTH} from 'wowok';
import { NodeData, } from './Guard';
import '../../../css/Guard.css';
import '../../../css/Common.css';
import { SettingTitle, U64_LENGTH, } from '../Settings';
import { useSnackbar } from 'notistack';
import { GUARD_LAUNCH_SOTRE_KEY_NODES, GUARD_LAUNCH_SOTRE_KEY_EDGES, constReference } from './GuardGraph';
import { constTable, ChangeConstTable, constItem } from './GuardGraph';
import { capitalizeFirstLetter } from '../../util/Common';
import { grey, red } from '@mui/material/colors';

export const WITNESS_FLAG =  'witness' ;
export const NONE_WITNESS_FLAG = 'none-witness' ;

export interface  ConstPannelProp  {
    node:Node;
    onSuccess: (bReload:boolean) => void;
}

enum SELECT  {
    number = 'unsigned integer',
    address = 'address',
    string = 'string',
    bool = 'bool',
}

export default function ConstPannel(props:ConstPannelProp) {
    const { getNodes, getEdges } = useReactFlow();
    const { enqueueSnackbar } = useSnackbar();    

    const [constant, setConstant] = React.useState<NodeData>({...props.node.data});
    const [sel, setSel] = React.useState<SELECT>(SELECT.address);
    const [type, setType] = React.useState<ValueType | undefined>(undefined);
    const [witness, setWitness] = React.useState(false);

    React.useEffect(() => {
        const data:NodeData = props.node.data;
        if (data.ret_type === 'number') {
            setType(ValueType.TYPE_U256);
        } else if (data.ret_type === ValueType.TYPE_ADDRESS) {
            setSel(SELECT.address)
            setType(ValueType.TYPE_ADDRESS);
        } else if (data.ret_type === 'any') {
            if (data?.type !== undefined) {
                if (IsNumberType(data.type)) {
                    setSel(SELECT.number);
                } else if (data.type === ValueType.TYPE_STRING) {
                    setSel(SELECT.string);
                } else if (data.type === ValueType.TYPE_ADDRESS) {
                    setSel(SELECT.address);
                } else if (data.type === ValueType.TYPE_BOOL) {
                    setSel(SELECT.bool);
                } 
                setType(data.type);
            } else {
                setSel(SELECT.address);
                setType(ValueType.TYPE_ADDRESS);
            }
        } else {
            setType(data.ret_type);
        }
        setConstant({...data})
        if (data.identifier !== undefined && data.cmd === undefined) {
            const item = constTable('any').find(v => v.identifier === data.identifier);
            setWitness(item?.bWitness? true : false);
        } else {
            setWitness(false);
        }
    }, [props.node.data])

    // 返回有无子节点被清除
    const SaveNodes = (currentNode:string) : number => {
        var nodes = getNodes();
        var edges = getEdges();

        // 清除子节点
        const DeleteChild = (parent:string) : number => {
            const n = edges.filter((v)=>v.target === parent).map((i)=>i.source); // 子节点名字
            n.forEach((v)=>DeleteChild(v)); // 递归删除子节点的子节点
            nodes = nodes.filter((v)=>n.includes(v.id) === false); // 删除子节点
            edges = edges.filter((v)=>v.target !== parent); // 删除子节点的边
            return n.length
        }

        const ret = DeleteChild(currentNode);
        localStorage.setItem(GUARD_LAUNCH_SOTRE_KEY_EDGES, JSON.stringify(edges));
        localStorage.setItem(GUARD_LAUNCH_SOTRE_KEY_NODES, JSON.stringify(nodes));
        return ret;
    }

    return (<div>
        {(props.node.data.ret_type === 'any' || props.node.data.ret_type === ValueType.TYPE_ADDRESS) && <div>
            <SettingTitle title='Data Type' required tips='The witness specifies the Machine or Service object as a future observer to ensure the legitimacy of the Progress or Order object provided by the verifier.'/>
            <Select fullWidth value={sel} displayEmpty id='guard-constant-type' 
            onChange={(e) => {
                switch (e.target.value) {
                    case SELECT.bool:
                        setType(ValueType.TYPE_BOOL); break;
                    case SELECT.address:
                        setType(ValueType.TYPE_ADDRESS); break;
                    case SELECT.string:
                        setType(ValueType.TYPE_STRING); break;
                    case SELECT.number:
                        setType(ValueType.TYPE_U256); break;
                }
                setSel(e.target.value as SELECT);
                constant.value = '';
                constant.identifier = undefined;
                setConstant({...constant});
            }}>
                { props.node.data.ret_type === 'any' && <MenuItem value={SELECT.bool}>{SELECT.bool}</MenuItem>}
                { props.node.data.ret_type === 'any' && <MenuItem value={SELECT.string}>{SELECT.string}</MenuItem>}
                <MenuItem value={SELECT.address}>{SELECT.address}</MenuItem>
                { props.node.data.ret_type === 'any' && <MenuItem value={SELECT.number}>{SELECT.number}</MenuItem>}
            </Select>            
        </div>}
        {SER_VALUE.find((v)=>v.type === type) && <>
            <SettingTitle title='Constant or Witness' required/>
            <Select fullWidth value={witness} onChange={(event) => {
                setWitness(Boolean(event.target.value));
                }}>
                <MenuItem value={false as any}>{'Constant ' + (SER_VALUE.find((v)=>v.type === type)!.name)}</MenuItem>
                <MenuItem value={true as any}>{'Witness ' + (SER_VALUE.find((v)=>v.type === type)!.name) + ' that provided by the passer when passing Guard'}</MenuItem>
            </Select>
        </>}
        { type === ValueType.TYPE_BOOL && !witness && <>
            <SettingTitle title={capitalizeFirstLetter((SER_VALUE.find((v)=>v.type===type)?.name ?? '')) + ' Type Data'} required tips={(SER_VALUE.find((v)=>v.type===type)?.description)}/>
            <Select fullWidth value={constant.value} onChange={(e) => {
                constant.value = e.target.value
                constant.identifier = undefined; // 不使用常量表
                setConstant({...constant});
            }}>
                <MenuItem value={true as any}>True</MenuItem>
                <MenuItem value={false as any}>False</MenuItem>
            </Select></>}
        { type !== ValueType.TYPE_BOOL && type && !witness && <>
            <SettingTitle title={capitalizeFirstLetter((SER_VALUE.find((v)=>v.type===type)?.name ?? '')) + ' Type Data'} required tips={(SER_VALUE.find((v)=>v.type===type)?.description)}/>
            <Autocomplete fullWidth freeSolo id='guard-constant-value' sx={{mt:'1em'}} 
            options={constTable(type, false)}
            onChange={(event, value, reason) => { //@select
                if (typeof(value) !== 'string') {
                    constant.identifier = (value as constItem)?.identifier;
                    constant.value = (value as constItem)?.value;
                    setConstant({...constant})
                }
            }}
            onInputChange={(event, value, reason) => {
                if (value) {
                    if (IsNumberType(type)) {
                        value = value.slice(0, U64_LENGTH);
                    } else if (type === ValueType.TYPE_ADDRESS) {
                        value = value.slice(0, 66);
                    } else {
                        value = value.slice(0, 1024);
                    }
                }

                constant.value = value;
                constant.identifier = undefined;
                setConstant({...constant})
            }}
            value={constant.value}
            getOptionLabel={(option) => {
                if (typeof(option) === 'string') {
                    return option;
                }
                return option?.value ?? '';
            }}
            renderOption={(op_props, option, { selected }) => {
                return ( <li {...op_props} key={option.identifier} style={{display:'flex'}}>
                    <span>{option.value}</span>
                    <span style={{marginLeft:'auto', marginRight:2}}>{'Constant id: ' + option.identifier}</span>
              </li>)
            }}
            renderInput={(params) => <TextField {...params} type={IsNumberType(type) ? 'number' : 'text'}
            sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                display: "none",
              },
                "& input[type=number]": {
                MozAppearance: "textfield",
              },}}
                placeholder={'Enter new ' + (SER_VALUE.find((v)=>v.type === type)?.name) + ' or select defined ' +(SER_VALUE.find((v)=>v.type === type)?.name) + ' from the list'} value={constant.value}
                InputProps={{ ...params.InputProps, endAdornment: <InputAdornment position="end"> 
                    {constant?.identifier !== undefined && constant?.value && 
                        <span style={{textAlign:'right'}}>{'Constant id: ' + constant.identifier }</span>}
                    </InputAdornment>,}} 
                inputProps={{...params.inputProps, maxLength:MAX_DESCRIPTION_LENGTH/2}} 
                />}
            isOptionEqualToValue={(option, value) => option.value === value}
        /></>}
        {type && SER_VALUE.find((v)=>v.type === type) && witness && <>
            <SettingTitle title='Witness ID' required/>
            <Select fullWidth displayEmpty={false} native={false}
            value={constant.identifier === undefined ? -1 : constant.identifier} 
            id='guard-const-witness' onChange={(e) => {
                if (e.target.value === -1) {
                    constant.identifier = -1;
                } else if (typeof(e.target.value) === 'number') {
                    constant.identifier = e.target.value;
                } else if (typeof(e.target.value) === 'string') {
                    constant.identifier = parseInt(e.target.value)
                } else {
                    constant.identifier = -1;
                }
                setConstant({...constant})
            }} renderValue={(value) => {
                if (value === -1) {
                    return <span>{'Gen a new ID to tag this '+(SER_VALUE.find((v)=>v.type === type)?.name)}</span>
                } else {
                    const v = constTable(type, true).find((v)=>v.identifier === value);
                    if (!v) return <span>{'Gen a new ID for this '+ SER_VALUE.find((v) => v.type === type)?.name}</span>
                    return (<div style={{display:'flex'}}>
                        <span>{SER_VALUE.find((v)=>v.type === type)!.name}</span>
                        <span style={{marginLeft:'.2em', color:red[400], fontWeight:1000, fontStyle:'italic'}}>{v!.identifier}</span>
                    </div>)
                }
            }}>
            {
                <MenuItem value={-1}>{'Gen a new ID for this '+(SER_VALUE.find((v)=>v.type === type)?.name)}</MenuItem>
            }
            { constReference(getNodes(), constTable(type, true), false).map((v) => {
                return (
                <MenuItem style={{display:'flex'}} value={v.identifier} >
                    <span>{capitalizeFirstLetter(SER_VALUE.find((i)=>i.type === v.type)?.name)}</span>
                    <span style={{marginLeft:'.2em', color:red[400], fontWeight:1000, fontStyle:'italic'}}>{v.identifier}</span>
                    <Tooltip title={v.cmd.length > 0 ? <div> <div>Queries cited: </div>
                        { v.cmd.map((v)=> {
                            const c = Guard.GetCmd(v);
                            if (c) {
                                return <div style={{display:'block'}}>{c[0] + '.' + c[1]}</div>
                            } else {
                                return null
                            }
                    })}</div> : 'Not cited by query'} arrow placement='left'>
                    <span style={{marginLeft:'auto', color:grey[500]}}>{'Cited ' + v.reference + ' times'}</span>
                    </Tooltip>
                </MenuItem>
                )
            })
            }
        </Select>
        </>}
        <button className='cmdButton' style={{marginTop: '3em'}}
            onClick={async ()=>{
                if (type === undefined) {
                    enqueueSnackbar('Constant Type invalid', { variant: "error" });
                    document.createElement('guard-constant-type')?.focus();
                    return
                }
                if (!witness) {
                    if (type === ValueType.TYPE_U256 && !IsValidU256(constant.value)) {
                        enqueueSnackbar('Number invalid', { variant: "error" });
                        document.createElement('guard-constant-value')?.focus();
                        return
                    }
                    if ((type === ValueType.TYPE_ADDRESS) && !IsValidAddress(constant.value)) {
                        enqueueSnackbar('Address or Witness invalid', { variant: "error" });
                        document.createElement('guard-constant-value')?.focus();
                        return 
                    }                    
                } else {

                }

                props.node.data.cmd = undefined;
                props.node.data.value = constant.value; 
                props.node.data.type = type;
                if (constant.identifier === undefined || constant.identifier === -1) {
                    props.node.data.identifier = ChangeConstTable(getNodes(), constant.value, type, witness);
                }  else {
                    props.node.data.identifier = constant.identifier; 
                }             
                console.log(props.node.data)
                props.onSuccess(SaveNodes(props.node.id) !== 0);
            }}>Apply</button>
    </div>)
}