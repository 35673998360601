import React from 'react';
import '../../css/Home.css';
import '../../css/Common.css';
import { useEffect } from 'react';

function Home(props:any) {
    // const navigator = useNavigate();
    useEffect(() => {
        const handleLoad = (event:Event | undefined) => {
            const elem = document.getElementById('root')!;
            const viewWidth = document.body.clientWidth; // window.screen.availWidth
            const bodyWidth = elem.offsetWidth; 
            // let viewHeight = Math.max( document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight );
            if (viewWidth <= bodyWidth) { return } // 980 / 2551

            
            const scale = viewWidth / bodyWidth;
            const transform = 'scale(' + scale + ')';
            // console.log(elem.offsetHeight);

            // document.getElementById('root')!.style.transformOrigin = (bodyWidth/2) + 'px 0px';
            elem.style.transform = transform;
        };
        if (document.readyState === "complete") {
          handleLoad(undefined);
        } else {
          window.addEventListener('load', handleLoad);
          document.addEventListener("DOMContentLoaded", handleLoad);
          window.addEventListener('resize', handleLoad)
          return () => {
              window.removeEventListener('load', handleLoad);
              window.removeEventListener('resize', handleLoad);
          };          
        }

    }, []);

  const handleApp = () => {
    window.location.href = '/launch';
  }

  return (
    <div className='main'>
      <div className='home-header'>
        <div className='header_logo'></div>
        <div className='header_name'>Wowok</div>
        <div className='header_right'>
        <span><a style={{color:'#E600A5', textDecoration:'none', border:'none'}} href="https://github.com/wowok-ai/sdk/wiki" target="_blank" rel="noopener noreferrer">Docs</a></span>
        </div> 
      </div>
      <div className='banner'>
        <div className='banner_title'>Wowok Protocol</div>
        <div className='banner_content'>Create, collaborate, and transact on your own terms <br/>with the AI-driven web3 collaboration protocol</div>
        <button className='start_app' onClick={handleApp}>Get started (Testnet)</button>
      </div>
      <div className='container'>
        <div className='introduction'>
          <div className='introduction_content1'>
            <div className='title'>Customizable</div>
            <div className='txt'>Personalized needs and customized service expression</div>
          </div>
          <div className='introduction_seperator'></div>
          <div className='introduction_content2'>
            <div className='title'>Reliable</div>
            <div className='txt'>Executable and immutable consensus ensure reliability</div>
          </div>
          <div className='introduction_seperator'></div>
          <div className='introduction_content3'>
            <div className='title'>Innovative</div>
            <div className='txt'>Unleash innovative creations through building, combining, and transacting</div>
          </div>
        </div>
        <div className='block'>
          <div className='block_title'>Demand-oriented Transaction</div>
          <div className='block_txt block_demand_txtExtra'>Express any personalized needs, making them easier to achieve with AI-driven data efficiently matching supply and demand</div>
          <div className='block_demand1_wrapper'>
            <div className='block_demand1'>
              <div className='block_demand1_content'>
                <div className='block_demand_title'>100 USDT is yours if you can plan my Kenya trip!</div>
                <p>・Trip to the Great Migration in Kenya</p>
                <p>・Professional and insightful guide </p>
                <p>・Accommodation close to nature</p>  
                <p>・Cheerful and relaxing itinerary</p>
              </div>
              <div className='block_demand1_img' />
            </div>            
            <div className='block_demand2_content'>
                  <div className='block_demand_title'>Combine on-chain supplies into a new service that perfectly suits you</div>
              </div>
          </div>
          <div className='block_demand2_img'></div>
        </div>
        <div className='block'>
          <div className='block_title'>Visualizable, secure, and reliable trade process</div>
          <div className='block_txt block_comb_txtExtra'>Chain services combine and innovate for personalized solutions, with customizable models to fit market trends</div>
          <div className='block_combo'>
            <div className='block_combo_left' style={{marginRight:'1.6em'}}><div className='block_combo_img'></div></div>
            <div className='block_combo_right'><div className='block_combo_content'></div></div>
          </div>
        </div>
        <div className='block '>
          <div className='block_title'>Combinable innovative objects</div>
          <div className='block_txt' style={{maxWidth:'46em'}}>Various creative objects, such as guard, reward, demand, repository, akin to Lego pieces, that can be combined to form new creations  </div>
          <div className='block_cons'>
            <div className='block_cons_part'>
              <div className='cons_part_left'>
                <div className='cons_title'>Guard</div>
                <div className='cons_subtitle'>Executable on-chain conditional consensus</div>
                <div className='cons_content'>
                  <p className='plist'>Combine on-chain borderless authentic data.</p>
                  <p className='plist'>Once the guard is launched, it is immutable.</p>
                  <p className='plist'>Multi-scenario consensus protection: Meet Guard, get rewards; Retweet Wowok tweets 5 times to claim airdrop.</p>
                </div>
              </div>
              <div className='cons_part_right'>
                <div className='guard_img'></div>
              </div>
            </div>
            <div className='block_cons_part'>
              <div className='cons_part_left'>
                <div className='cons_title'>Progress</div>
                <div className='cons_subtitle'>Collaborative consensus around services</div>
                <div className='cons_content'>
                  <p className='plist'>Visualize all collaborators operations around the service.</p>
                  <p className='plist'>Operator permissions are defined by the machine and managed through permissions.</p>
                  <p className='plist'>Operators with cross-progress permissions can seamlessly combine different progresses.</p>
                </div>
              </div>
              <div className='cons_part_right'>
                <div className='progress_img'></div>
              </div>
            </div>
            <div className='block_cons_part' style={{display:'block'}}>
              <div className='cons_part_left'>
                <div className='cons_title'>Permission</div>
                <div className='cons_subtitle'>Objects and personnels management</div>
                <div className='cons_content'>
                  <p className='plist'>Low coupling enables quick adjustments to personnel without impacting the business, and vice versa.</p>
                  <p className='plist'>Permissions provide you with on-chain capability and achievement verification.</p>
                  <p className='plist'>Granular permission and management layering of objects.</p>
                </div>
              </div>
              <div className='block_permission'>
            <div className='block_permission_entity block_permission_entity_color1'>
              <p>Agency staff</p>
              <p>0x538d06</p>
            </div>
            <div className='block_permission_perm block_permission_perm_color1'>
              <p>・Set description of machine</p>
              <p>・Allow running a progress</p>
            </div>
          </div>
          <div className='block_permission block_permissionExtra'>
            <div className='block_permission_entity block_permission_entity_color2'>
              <p>Agency director</p>
              <p>0x5b3e4d</p>
            </div>
            <div className='block_permission_perm block_permission_perm_color2'>
              <p>・Set description of machine; Add consensus repository; Add node</p>
              <p>・Add the policy to the repository</p>
              <p>・Create discount coupons</p>
            </div>
          </div>
            </div>
            <div className='block_cons_part'>
              <div className='cons_part_left'>
                <div className='cons_title'>Service</div>
                <div className='cons_subtitle'>Tradable commitment</div>
                <div className='cons_content'>
                  <p className='plist'>Integrate trusted on-chain services to create personalized offerings.</p>
                  <p className='plist'>Customize the service process and break down operations to manage collaborators effectively.</p>
                  <p className='plist'>Tailor more flexible and AI-recognizable transaction models, for exemple, match you up with the most satisfactory services based on your habits.  </p>
                </div>
              </div>
              <div className='cons_part_right'>
                <div className='service_img'></div>
              </div>
            </div>
          </div>
        </div>
        <div className='block'>
          <div className='block_title'>Welcome to somelike you</div>
          <div className='block_txt' style={{maxWidth:'46em'}}>Wowok offers actions and results that are sustainably accumulated and visible, easier access to collaborative partners, and reliable interactions</div>
          <div className='welcome_content'>
            <p className='plist'>I want to fulfill my personalized needs that are limited by filters.</p>
            <p className='plist'>I am looking for collaborators to create new services together.</p>
            <p className='plist'>I want to build interesting dApps with Wowok cutting-edge features.</p>
            <p className='plist'>I can build AI bots to facilitate transactions.</p>
          </div>
        </div>
      </div>
      <div className='footer'>
        <div className='footer_icons'>
          <a className='footer_icons_x' href="https://x.com/Wowok_Ai" target="_blank" rel="noopener noreferrer"></a>
          <a className='footer_icons_github' href="https://github.com/wowok-ai/sdk" target="_blank" rel="noopener noreferrer"></a>
        </div>
      </div>
    </div>
  );
}

export default Home;
