
import React, { useState } from 'react';
import '../../../css/Launch.css';
import '../../../css/Common.css';
import '@suiet/wallet-kit/style.css';
import { useTheme} from '@mui/material/styles';
import { Box, } from '@mui/material';
import { PermissionAnswer, PermissionIndex, Demand as WowokDemand, Permission as WowokPermission,
  PermissionIndexType, Passport as WowokPassport,
} from 'wowok';
import { SettingTitle,  SettingLaunch, ADDRESS_VALIDATOR, SettingInputText, SettingTips, } from '../Settings';
import { useWallet } from '@suiet/wallet-kit';
import { useSnackbar } from 'notistack';
import { make_permIconProp } from '../PermCheck';
import { generateRandomString } from '../../../util';
import { Transaction as TransactionBlock } from '@mysten/sui/transactions';
export interface GuardData {
    guard: string;
    guard_err: string;
}

export default function Guard (props:any) {
    //console.log(props)
    const id = props?.contents?.fields?.id?.id ?? '';
    const type = WowokDemand.parseObjectType(props?.type);
    const [data, setData] = useState<GuardData>(props?.contents?.fields?.guard ? 
        {guard:props?.contents?.fields?.guard, guard_err:''} : {guard:'', guard_err:''});
    const wallet = useWallet(); 
    const { enqueueSnackbar } = useSnackbar();
    const answer: PermissionAnswer | undefined = props?.perms;
    const permission  = props?.contents?.fields?.permission;
    
    return (
        <Box sx={{maxWidth:'100%', padding:'0 2em', pb:'2em',}}>
            <SettingTips text='If there are no relevant guard, you can&nbsp;' command=' Build New Guard' to='/guard'/>
            <SettingTitle title='Claim Guard' tips='Demand can be claimed only when the guard conditions are met '
              perm={make_permIconProp(answer, PermissionIndex.demand_guard)}/>
            <SettingInputText placeholder='Enter the guard address'  maxlength={66} 
                noPerm={WowokPermission.HasPermission(answer, PermissionIndex.demand_guard)?.has === false}
                validator={ADDRESS_VALIDATOR} value={data.guard}
                id='guard' event={(type, value, id) => {
                    data.guard = value;  data.guard_err = type==='value' ? '' : 'error';
                    setData({...data});
                }}/>          
            <SettingLaunch text='Launch' event={(t) => {
                if (t === 'click') {
                  if (data.guard_err) {
                    enqueueSnackbar('Guard address invalid', { variant: "error" });
                    document.getElementById('guard')?.focus();
                    return
                  }
                  
                  if (!wallet.connected) {
                    enqueueSnackbar('Please login wallet', { variant: "error" });
                    document.getElementById('header-wallet-cmd')?.click();
                    return 
                  }

                  const txb = new TransactionBlock();
                  const obj = WowokDemand.From(txb, type, permission, id);
                  const pid = generateRandomString(8);
                  const perms: PermissionIndexType[] = [];
                  if (!(!data.guard  && !props?.contents?.fields?.guard) || (data.guard === props?.contents?.fields?.guard)) {
                    perms.push(PermissionIndex.demand_guard);
                  };

                  props.PermissionCheck({id:pid, txb:txb, answer:answer, index:[PermissionIndex.demand_guard], handler:
                    (id:string, txb:TransactionBlock, index: PermissionIndexType[], passport?:WowokPassport) => {
                      try {
                        if (id === pid) {
                          if (index.includes(PermissionIndex.demand_guard)) {
                            obj.set_guard(data.guard ?? undefined, passport?.get_object())
                          };  
                          passport?.destroy(); // destroy passport
                          props.exe(id, txb);            
                        }
                      } catch (e) {
                        console.log(e)
                        enqueueSnackbar( 'Launch Failed', { variant: "error" });
                      }
                    }
                  });
                }
            }}/>
        </Box>
    );  
}