import React, { useEffect, useState } from 'react';
import '../../css/Common.css';
import { Paper, Tooltip, } from '@mui/material';
import { Progress as WowokProgress, Service as WowokService, CoinTypeInfo, ResolveBalance, Protocol } from 'wowok';
import { green, grey } from '@mui/material/colors';
import MaterialTable, {MTableToolbar} from "material-table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { PaginatedEvents, SuiEvent } from '@mysten/sui/client';
import { Address } from '../util/Address';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';


export default function Order(props:any) {
    const id = props?.contents?.fields?.id?.id ?? '';
    const type = WowokService.parseObjectType(props?.type);
    const protocol = Protocol.Instance();
    const [coinType, setCoinType] = useState<CoinTypeInfo | 'loading'>(protocol.GetCoinTypeInfo(type, (res) => {
      if (res.type === type) {
        setCoinType(coinType);      
      }
    }))

    const [events, setEvents] = useState<PaginatedEvents | null>(null);
    const QueryEvents = (count:number, result:SuiEvent[], type:string, cursor?:any) => {
        Protocol.Client().queryEvents({query:{MoveEventType:type}, cursor:cursor}).then((res) => {
            count += 1;
            res.data.filter((v) => (v.parsedJson as any)?.service === id).forEach((v) => {
                let f = result.find((i) => (i.parsedJson as any).object === (v.parsedJson as any).object);
                if (f) {
                    f = {...v};
                } else {
                    result.push(v);
                }
            });

            res.data = [...result];
            setEvents(res);

            if (res.hasNextPage && count <= 80) {
                QueryEvents(count, result, type, res.nextCursor);
            }
        }).catch((e) => {
            console.log(e)
        });
    }

    var result:SuiEvent[] = [];
    var count = 0;
    useEffect(() =>  {
        const t = Protocol.Instance().Package('wowok') + '::order::NewOrderEvent';
        QueryEvents(count, result, t);
    }, []);

    let len = events ? events.data.length : 0;
    len = len >= 20 ? 20 : (len <= 5 ? 5 : 10);

    return (
        <Paper className='noboxshadow' >
            <MaterialTable title=''
            columns={[
                { title: '', field: 'index', type: 'numeric', align:'left', width:'1%', render:(data, type) => {
                    return <div style={{fontStyle:'italic', color:grey[400], fontWeight:200}}>{data.index}</div>
                }},
              { title: 'Order', field: 'order', cellStyle:{paddingLeft:0}, render:(data, t) => {
                return (<div style={{display:'flex', alignItems:'center'}}>
                    <Address address={data.order} showType='Order'/>
                  </div>)
              }},
              { title: 'Amount', field: 'amount', cellStyle:{textAlign:'left'}, render:(data, type) => {
                const p = coinType==='loading' ? data.amount : (ResolveBalance(data.amount, coinType.decimals)+' ' + coinType.symbol);
                  return (<div >
                    {p}
                  </div>)
              }},
              { title: 'Buyer', field: 'buyer', render:(data, type) => {
                return (<div >
                  <Address address={data.buyer}/>
                </div>)
            }},
              { title: 'Porgress', field: 'progress', render:(data, type) => {
                return (<div style={{textAlign:'center'}}> 
                    {
                        data.progress && <Address address={data.progress} showType='Progress'/>
                    }
                    {
                        !data.progress && <div style={{color:grey[400]}}>-</div>
                    }
                </div>)
            }},
              { title: 'Time', field: 'time', render:(data, type) => {
                const ts = new Date(parseInt(data.time ?? ''));
                
                return (<div>
                    {ts.toLocaleString()}
                </div>)
              }},
              { title: 'Tx', field: 'tx',  align:'left', width:'6em', render:(data, type) => {
                return (<div>
                    <Tooltip title={data.tx}>
                       <span className='cmdText' onClick={()=>window.open(Protocol.Instance().ExplorerUrl(data.tx, 'txblock'), '_blank')?.focus()}>
                        {data.tx.slice(0, 6)} 
                        </span>
                    </Tooltip>
                </div>)
              }},
            ]}
            data={events ? events.data.map((v, i) => {
              return {order:(v.parsedJson as any).object, amount:(v.parsedJson as any).amount, buyer:v.sender, 
                progress:(v.parsedJson as any).progress, tx:v.id.txDigest, time:v.timestampMs, index:i+1}
            }) : []}   
            options={{padding: "dense", tableLayout:'fixed',
                pageSize: len, 
                pageSizeOptions: [5, 10, 20],}}
            actions={[]}
            components={{
              Header: (props) => {
                const tips = <div>{type} <br/> {'token decimals: ' + (coinType==='loading'?'unknown':coinType.decimals.toString())}</div>;
                return (<TableHead {...props} >
                  <TableRow>
                  <TableCell variant='head' style={{width:'18px'}}>{props.columns[0].title}</TableCell>
                    <TableCell variant='head' align='center' style={{width:'6em'}}>
                      <div style={{display:'flex'}}>
                        <div>{props.columns[1].title}</div>                  
                      </div>
                    </TableCell>
                    <TableCell variant='head' align='left' style={{width:'14em'}}>
                    <div style={{display:'flex', marginLeft:'2em'}}>
                      <div>{props.columns[2].title}</div>
                      <div><Tooltip title={tips} arrow placement='right'>
                      <HelpOutlineIcon sx={{width:'0.6em', height:'0.6em', paddingLeft:'0.1em', color:grey[500]}} />
                      </Tooltip></div>                      
                    </div>
                    </TableCell>
                    <TableCell variant='head' align='center' style={{width:'6em'}}>
                        <div>{props.columns[3].title}</div>
                    </TableCell>
                    <TableCell variant='head' align='center' style={{width:'6em'}}>
                      <div> {props.columns[4].title}</div>
                      </TableCell>
                    <TableCell variant='head' align='left' style={{width:'12em'}}>{props.columns[5].title}</TableCell>
                    <TableCell variant='head' align='center' style={{width:'4em'}}>{props.columns[6].title}</TableCell>
                  </TableRow>
                </TableHead>)
              },
                Toolbar: props => (
                    <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                        <span></span>
                <MTableToolbar {...props} />
                    </div>
                ),
            }}
          />     
        </Paper>
      );  
}